import gql from 'graphql-tag'

export const getAssemblies = (offset, limit, order, orderDirection, search, filter) => {
    return {
        query: gql`
            query GetAssemblies(
                $offset: Int
                $limit: Int
                $order: String
                $orderDirection: String
                $search: String
                $filter: String
            ) {
                allAssemblies(
                    offset: $offset
                    limit: $limit
                    order: $order
                    orderDirection: $orderDirection
                    search: $search
                    filter: $filter
                ) {
                    assemblies {
                        id
                        casnum
                        orderDate
                        arrivalDate
                        startAssemblyDate
                        endAssemblyDate
                        selfcorrectionNote
                        cabinetStudentNote
                        finalNote
                        finalValoration
                        expectedDeliveryDate
                        deliveryDate
                        historyNum
                        observations
                        actualStep
                        canceled
                        assembler {
                            id
                            name
                            lastName
                        }
                        deliveryStudent {
                            id
                            name
                            lastName
                        }
                        cabinetStudent {
                            id
                            name
                            lastName
                        }
                        teacher {
                            id
                            name
                            lastName
                        }
                        patient {
                            id
                            name
                            lastName
                        }
                        subject {
                            id
                            name
                            evaluable
                        }
                        createdAt
                    }
                    totalCount
                }
            }
        `,
        variables: {
            offset: offset,
            limit: limit,
            order: order,
            orderDirection: orderDirection,
            search: search,
            filter: filter,
        },
    }
}

export const getAssembliesForDelivery = (offset, limit) => {
    return {
        query: gql`
            query GetAssembliesForDelivery($offset: Int, $limit: Int) {
                getAssembliesForDelivery(offset: $offset, limit: $limit) {
                    assemblies {
                        id
                        casnum
                        expectedDeliveryDate
                        actualStep
                        canceled
                    }
                    totalCount
                }
            }
        `,
        variables: {
            offset: offset,
            limit: limit,
        },
    }
}

export const getAssembliesForCurrentUser = (offset, limit, pastAssemblies = false) => {
    return {
        query: gql`
            query GetAssembliesForCurrentUser($offset: Int, $limit: Int, $pastAssemblies: Boolean) {
                getAssembliesForCurrentUser(offset: $offset, limit: $limit, pastAssemblies: $pastAssemblies) {
                    assemblies {
                        id
                        casnum
                        canceled
                        assembler {
                            id
                        }
                        deliveryStudent {
                            id
                        }
                        cabinetStudent {
                            id
                        }
                        actualStep
                    }
                    totalCount
                }
            }
        `,
        variables: {
            offset: offset,
            limit: limit,
            pastAssemblies: pastAssemblies,
        },
    }
}

export const getAssembliesForValuation = (offset, limit) => {
    return {
        query: gql`
            query GetAssembliesForValuation($offset: Int, $limit: Int) {
                getAssembliesForValuation(offset: $offset, limit: $limit) {
                    assemblies {
                        id
                        casnum
                        actualStep
                        canceled
                    }
                    totalCount
                }
            }
        `,
        variables: {
            offset: offset,
            limit: limit,
        },
    }
}

export const getAssembliesOnlyStatus = () => {
    return {
        query: gql`
            {
                allAssemblies {
                    assemblies {
                        id
                        casnum
                        actualStep
                        canceled
                    }
                }
            }
        `,
    }
}

export const getAssemblyById = (id) => {
    return {
        query: gql`
            query GetAssemblyById($id: ID) {
                assembly(id: $id) {
                    id
                    casnum
                    appointmentDate
                    orderDate
                    arrivalDate
                    startAssemblyDate
                    endAssemblyDate
                    selfcorrectionNote
                    cabinetStudentNote
                    finalNote
                    finalValoration
                    expectedDeliveryDate
                    deliveryDate
                    historyNum
                    observations
                    actualStep
                    canceled
                    assembler {
                        id
                        name
                        lastName
                    }
                    deliveryStudent {
                        id
                        name
                        lastName
                    }
                    cabinetStudentId
                    cabinetStudent {
                        id
                        name
                        lastName
                    }
                    teacher {
                        id
                        name
                        lastName
                    }
                    patient {
                        id
                        name
                        lastName
                    }
                    suppliers {
                        id
                        name
                    }
                    subject {
                        id
                        name
                        evaluable
                    }
                }
            }
        `,
        variables: {
            id: id,
        },
    }
}

export const createAssembly = (
    casnum,
    historyNum,
    observations,
    patientId,
    expectedDeliveryDate,
    cabinetStudentId,
    orderDate,
    supplierId,
    appointmentDate,
    subjectId,
) => {
    return {
        mutation: gql`
            mutation CreateAssembly(
                $casnum: String
                $historyNum: String
                $observations: String
                $patientId: ID
                $expectedDeliveryDate: String
                $cabinetStudentId: ID
                $orderDate: String
                $supplierId: ID
                $appointmentDate: String
                $subjectId: ID
            ) {
                createAssembly(
                    input: {
                        casnum: $casnum
                        historyNum: $historyNum
                        observations: $observations
                        patientId: $patientId
                        expectedDeliveryDate: $expectedDeliveryDate
                        cabinetStudentId: $cabinetStudentId
                        orderDate: $orderDate
                        supplierId: $supplierId
                        appointmentDate: $appointmentDate
                        subjectId: $subjectId
                    }
                ) {
                    id
                }
            }
        `,
        variables: {
            casnum: casnum,
            historyNum: historyNum,
            observations: observations,
            patientId: patientId,
            expectedDeliveryDate: expectedDeliveryDate,
            cabinetStudentId: cabinetStudentId,
            orderDate: orderDate,
            supplierId: supplierId,
            appointmentDate: appointmentDate,
            subjectId: subjectId,
        },
    }
}

export const createAssemblyRepetition = (
    assemblyId,
    casnum,
    historyNum,
    observations,
    patientId,
    expectedDeliveryDate,
    cabinetStudentId,
    orderDate,
    supplierId,
    appointmentDate,
    subjectId,
) => {
    return {
        mutation: gql`
            mutation CreateAssemblyRepetition(
                $assemblyId: ID
                $casnum: String
                $historyNum: String
                $observations: String
                $patientId: ID
                $expectedDeliveryDate: String
                $cabinetStudentId: ID
                $orderDate: String
                $supplierId: ID
                $appointmentDate: String
                $subjectId: ID
            ) {
                createAssemblyRepetition(
                    id: $assemblyId
                    input: {
                        casnum: $casnum
                        historyNum: $historyNum
                        observations: $observations
                        patientId: $patientId
                        expectedDeliveryDate: $expectedDeliveryDate
                        cabinetStudentId: $cabinetStudentId
                        orderDate: $orderDate
                        supplierId: $supplierId
                        appointmentDate: $appointmentDate
                        subjectId: $subjectId
                    }
                ) {
                    id
                }
            }
        `,
        variables: {
            assemblyId: assemblyId,
            casnum: casnum,
            historyNum: historyNum,
            observations: observations,
            patientId: patientId,
            expectedDeliveryDate: expectedDeliveryDate,
            cabinetStudentId: cabinetStudentId,
            orderDate: orderDate,
            supplierId: supplierId,
            appointmentDate: appointmentDate,
            subjectId: subjectId,
        },
    }
}

export const updateAssembly = (
    id,
    casnum,
    historyNum,
    orderDate,
    arrivalDate,
    startAssemblyDate,
    endAssemblyDate,
    expectedDeliveryDate,
    deliveryDate,
    assemblerId,
    finalNote,
) => {
    return {
        mutation: gql`
            mutation UpdateAssembly(
                $id: ID
                $casnum: String
                $historyNum: String
                $orderDate: String
                $arrivalDate: String
                $startAssemblyDate: String
                $endAssemblyDate: String
                $expectedDeliveryDate: String
                $deliveryDate: String
                $assemblerId: ID
                $finalNote: String
            ) {
                updateAssembly(
                    id: $id
                    input: {
                        casnum: $casnum
                        historyNum: $historyNum
                        orderDate: $orderDate
                        arrivalDate: $arrivalDate
                        startAssemblyDate: $startAssemblyDate
                        endAssemblyDate: $endAssemblyDate
                        expectedDeliveryDate: $expectedDeliveryDate
                        deliveryDate: $deliveryDate
                        assemblerId: $assemblerId
                        finalNote: $finalNote
                    }
                ) {
                    id
                }
            }
        `,
        variables: {
            id: id,
            casnum: casnum,
            historyNum: historyNum,
            orderDate: orderDate,
            arrivalDate: arrivalDate,
            startAssemblyDate: startAssemblyDate,
            endAssemblyDate: endAssemblyDate,
            expectedDeliveryDate: expectedDeliveryDate,
            deliveryDate: deliveryDate,
            assemblerId: assemblerId,
            finalNote: finalNote,
        },
    }
}

export const updateObservations = (id, observations) => {
    return {
        mutation: gql`
            mutation UpdateObservations($id: ID, $observations: String) {
                updateObservations(id: $id, observations: $observations) {
                    id
                }
            }
        `,
        variables: {
            id: id,
            observations: observations,
        },
    }
}

export const goToOrderStep = (id, supplierId) => {
    return {
        mutation: gql`
            mutation GoToOrderStep($id: ID, $supplierId: ID) {
                goToOrderStep(input: { id: $id, supplierId: $supplierId }) {
                    id
                }
            }
        `,
        variables: {
            id: id,
            supplierId: supplierId,
        },
    }
}

export const goToAssemblyStep = (id, arrivalDate, assemblerId) => {
    return {
        mutation: gql`
            mutation GoToAssemblyStep($id: ID, $arrivalDate: String, $assemblerId: ID) {
                goToAssemblyStep(input: { id: $id, arrivalDate: $arrivalDate, assemblerId: $assemblerId }) {
                    id
                }
            }
        `,
        variables: {
            id: id,
            arrivalDate: arrivalDate,
            assemblerId: assemblerId,
        },
    }
}

export const goToRevisionStep = (id, selfcorrectionNote) => {
    return {
        mutation: gql`
            mutation GoToRevisionStep($id: ID, $selfcorrectionNote: String) {
                goToRevisionStep(input: { id: $id, selfcorrectionNote: $selfcorrectionNote }) {
                    id
                }
            }
        `,
        variables: {
            id: id,
            selfcorrectionNote: selfcorrectionNote,
        },
    }
}

export const goToValuationStep = (id, cabinetStudentNote) => {
    return {
        mutation: gql`
            mutation GoToValuationStep($id: ID, $cabinetStudentNote: String) {
                goToValuationStep(input: { id: $id, cabinetStudentNote: $cabinetStudentNote }) {
                    id
                }
            }
        `,
        variables: {
            id: id,
            cabinetStudentNote: cabinetStudentNote,
        },
    }
}

export const goToDeliveryStepWithoutEvaluation = (id, cabinetStudentNote) => {
    return {
        mutation: gql`
            mutation GoToDeliveryStepWithoutEvaluation($id: ID, $cabinetStudentNote: String) {
                goToDeliveryStepWithoutEvaluation(input: { id: $id, cabinetStudentNote: $cabinetStudentNote }) {
                    id
                }
            }
        `,
        variables: {
            id: id,
            cabinetStudentNote: cabinetStudentNote,
        },
    }
}

export const goToDeliveryStep = (id, finalNote, finalValoration) => {
    return {
        mutation: gql`
            mutation GoToDeliveryStep($id: ID, $finalNote: String, $finalValoration: String) {
                goToDeliveryStep(input: { id: $id, finalNote: $finalNote, finalValoration: $finalValoration }) {
                    id
                }
            }
        `,
        variables: {
            id: id,
            finalNote: finalNote,
            finalValoration: finalValoration,
        },
    }
}

export const confirmDelivery = (id, deliveryStudentId) => {
    return {
        mutation: gql`
            mutation ConfirmDelivery($id: ID, $deliveryStudentId: ID) {
                confirmDelivery(input: { id: $id, deliveryStudentId: $deliveryStudentId }) {
                    id
                }
            }
        `,
        variables: {
            id: id,
            deliveryStudentId: deliveryStudentId,
        },
    }
}

export const undoLastStep = (id) => {
    return {
        mutation: gql`
            mutation UndoLastStep($id: ID) {
                undoLastStep(id: $id) {
                    id
                }
            }
        `,
        variables: {
            id: id,
        },
    }
}
