import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

// Components imports
import TableFilter from '../TableFilter'
import Popover from '../Popover'

// Design imports
import { IconButton, InputBase, Toolbar, Tooltip, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { fade, lighten } from '@material-ui/core/styles/colorManipulator'
import grey from '@material-ui/core/colors/grey'
import DeleteIcon from '@material-ui/icons/Delete'
import CloseIcon from '@material-ui/icons/Close'
import DoneIcon from '@material-ui/icons/Done'
import FilterListIcon from '@material-ui/icons/FilterList'
import SearchIcon from '@material-ui/icons/Search'

// Constants
const textLabels = {
    filter: {
        all: 'Tots',
        title: 'Filtres',
        reset: 'Eliminar',
    },
}

class EnhancedTableToolbar extends React.Component {
    // TODO Arreglar search bar en mobile

    constructor(props) {
        super(props)

        const { filterOptions } = props
        var filterList = []
        if (filterOptions !== null && filterOptions.length !== 0) {
            for (var i = 0; i < filterOptions.length; i++) {
                filterList.push(textLabels.filter.all)
            }
        }

        this.state = {
            showSearchBar: false,
            showFilterPopover: false,
            filterList: filterList,
        }
    }

    createSortHandler = (property) => (event) => {
        this.props.onRequestSort(event, property)
    }

    onChangeSearchText = (event) => {
        const text = event.target.value
        this.props.onSearchTextChange(text)
    }

    onFilterUpdate = (index, label) => {
        const filterList = this.state.filterList.slice()
        filterList[index] = label
        this.setState({ filterList })

        var selectedValues = []

        for (var i = 0; i < filterList.length; i++) {
            const filterLabel = filterList[i]

            var value = ''
            if (filterLabel !== textLabels.filter.all) {
                const indexOfLabel = this.props.filterOptions[i].options.findIndex((item) => {
                    return item.label === filterLabel
                })
                value = this.props.filterOptions[i].options[indexOfLabel].value
            }

            selectedValues.push(value)
        }

        this.props.onFilterUpdate(selectedValues)
    }

    onFilterReset = () => {
        const filterList = this.state.filterList.map((item) => textLabels.filter.all)
        this.setState({ filterList })
        this.props.onFilterReset()
    }

    toggleSearchBar = () => {
        if (this.state.showSearchBar) {
            this.props.onSearchTextChange('')
        }
        this.setState({ showSearchBar: !this.state.showSearchBar })
    }

    toggleFilterPopover = () => {
        this.setState({ showFilterPopover: !this.state.showFilterPopover })
    }

    onValidateClicked = () => {
        if (this.props.onValidateClicked !== null) this.props.onValidateClicked()
    }

    renderTitle = () => {
        const { numSelected, title } = this.props

        if (numSelected > 0) {
            return (
                <Typography color="inherit" variant="subtitle1">
                    {numSelected} seleccionats
                </Typography>
            )
        }

        return (
            <Typography variant="h6" id="tableTitle" color="primary">
                {title}
            </Typography>
        )
    }

    renderSearchBar = () => {
        const { classes } = this.props

        var searchBar = null
        if (this.state.showSearchBar && !this.props.searchDisabled) {
            searchBar = (
                <div className={classes.search}>
                    <div className={classes.searchIcon}>
                        <SearchIcon color="primary" />
                    </div>
                    <InputBase
                        placeholder="Cercar…"
                        onChange={this.onChangeSearchText}
                        autoFocus
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        }}
                    />
                </div>
            )
        }

        return searchBar
    }

    renderAction = () => {
        const { numSelected, classes, onValidateClicked, searchDisabled } = this.props

        if (numSelected > 0) {
            return (
                <div>
                    {onValidateClicked !== null ? (
                        <Tooltip title="Validar">
                            <IconButton aria-label="Validar" onClick={() => this.onValidateClicked()}>
                                <DoneIcon />
                            </IconButton>
                        </Tooltip>
                    ) : null}
                    <Tooltip title="Eliminar">
                        <IconButton aria-label="Eliminar">
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            )
        }

        return (
            <div>
                {searchDisabled ? (
                    <div />
                ) : (
                    <Tooltip title="Cercar">
                        <IconButton aria-label="Cercar" onClick={() => this.toggleSearchBar()}>
                            {this.state.showSearchBar ? <CloseIcon /> : <SearchIcon />}
                        </IconButton>
                    </Tooltip>
                )}

                {/*<Tooltip title="Filtrar">
                    <IconButton aria-label="Filtrar" onClick={() => this.toggleFilterPopover()}>
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>*/}
            </div>
        )
    }

    renderFilterPopover = () => {
        const { filterList } = this.state
        const { filterOptions, numSelected } = this.props

        if (filterOptions === null || filterOptions.length === 0) return null

        if (numSelected > 0) return null

        return (
            <Popover
                refExit={this.toggleFilterPopover}
                container={this}
                trigger={
                    <IconButton aria-label="Filtrar" onClick={() => this.toggleFilterPopover()}>
                        <FilterListIcon />
                    </IconButton>
                }
                content={
                    <TableFilter
                        columns={filterOptions.map((item) => {
                            return {
                                label: item.label,
                                filter: true,
                            }
                        })}
                        options={{
                            filterType: 'dropdown',
                            textLabels: textLabels,
                        }}
                        filterList={filterList}
                        filterData={filterOptions.map((item) => {
                            return item.options.map((item) => {
                                return item.label
                            })
                        })}
                        onFilterUpdate={(index, value) => this.onFilterUpdate(index, value)}
                        onFilterReset={() => this.onFilterReset()}
                    />
                }
            />
        )
    }

    render() {
        const { classes, numSelected } = this.props

        return (
            <Toolbar className={classNames(classes.root, { [classes.highlight]: numSelected > 0 })}>
                <div className={classes.title}>{this.renderTitle()}</div>
                <div className={classes.spacer} />
                {/*<div className={classes.search}>
                    <div className={classes.searchIcon}>
                        <SearchIcon/>
                    </div>
                    <InputBase
                        placeholder="Cercar…"
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        }}/>
                </div>*/}
                {this.renderSearchBar()}
                <div className={classes.actions}>{this.renderAction()}</div>
                {this.renderFilterPopover()}
            </Toolbar>
        )
    }
}

const styles = (theme) => ({
    root: {
        paddingRight: theme.spacing.unit,
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                  color: theme.palette.secondary.main,
                  backgroundColor: lighten(theme.palette.secondary.light, 0.85),
              }
            : {
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.secondary.dark,
              },
    spacer: {
        flex: '1 1 100%',
    },
    actions: {
        color: theme.palette.text.secondary,
        flex: '0 0 auto',
    },
    title: {
        flex: '0 0 auto',
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        borderWidth: '1px',
        /*borderStyle: 'solid',
        borderColor: fade(theme.palette.primary.light, 1),
        '&:hover': {
            borderColor: fade(theme.palette.primary.main, 1),
        },*/
        backgroundColor: fade(grey['200'], 0.75),
        '&:hover': {
            backgroundColor: fade(grey['200'], 0.5),
        },
        marginLeft: 0,
        marginRight: '12px',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing.unit,
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing.unit * 6,
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    inputInput: {
        paddingTop: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit * 6,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 200,
            /*'&:focus': {
                width: 200,
            },*/
        },
    },
})

EnhancedTableToolbar.propTypes = {
    classes: PropTypes.object.isRequired,
    filterOptions: PropTypes.array,
    numSelected: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    onSearchTextChange: PropTypes.func,
    onFilterUpdate: PropTypes.func,
    onFilterReset: PropTypes.func,
    onValidateClicked: PropTypes.func,
}

EnhancedTableToolbar.defaultProps = {
    filterOptions: [],
    onSearchTextChange: (value) => {},
    onFilterUpdate: (selectedValues) => {},
    onFilterReset: () => {},
    onValidateClicked: null,
    searchDisabled: false,
}

export default withStyles(styles)(EnhancedTableToolbar)
