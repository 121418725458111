import PropTypes from 'prop-types'
import { Grid, Typography } from '@material-ui/core'
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import compose from '../../utils/functions'

class TextLabel extends React.Component {
    renderContent = (type, value, classes) => {
        let content = (
            <Typography className={classes.label} variant="h6">
                {value || '-'}
            </Typography>
        )

        if (type === 'email') {
            return (
                <a className={classes.link} href={'mailto:' + value}>
                    {content}
                </a>
            )
        } else if (type === 'phone') {
            return (
                <a className={classes.link} href={'tel:' + value}>
                    {content}
                </a>
            )
        }

        return content
    }

    render() {
        const { classes, title, value, type, xs, sm } = this.props

        return (
            <Grid item xs={xs} sm={sm}>
                <Typography className={classes.labelTitle} variant="subtitle2">
                    {title}
                </Typography>
                {this.renderContent(type, value, classes)}
            </Grid>
        )
    }
}

const styles = (theme) => ({
    labelTitle: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
        color: theme.palette.primary.light,
    },
    label: {
        fontWeight: 'normal',
    },
    link: {
        textDecoration: 'none',
    },
})

TextLabel.propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    type: PropTypes.string,
    xs: PropTypes.number,
    sm: PropTypes.number,
}

TextLabel.defaultProps = {
    xs: 12,
    sm: 12,
    type: 'text', // email - phone
}

export default compose(withStyles(styles))(TextLabel)
