import React from 'react'

// Components imports
import NotificationList from '../../../components/notification_list'
import AppointmentsList from '../../../components/appointment_list'

// Design imports
import { Button, Divider, Grid, Paper, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import UsualActionsIcon from '@material-ui/icons/BookmarkBorder'
import WarningIcon from '@material-ui/icons/Warning'
import NotificationIcon from '@material-ui/icons/Notifications'
import styles from './styles'

class Home extends React.Component {
    goToNewAppointment = () => {
        this.props.history.push('/new-appointment')
    }

    goToNewPatient = () => {
        this.props.history.push('/new-patient')
    }

    goToNewIncidence = () => {
        this.props.history.push('/new-incidence')
    }

    render() {
        const { classes } = this.props

        return (
            <div className={classes.root}>
                <Grid container spacing={24}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <Paper>
                                    <div className={classes.cardHeader}>
                                        <div className={classes.cardTitleContainer}>
                                            <UsualActionsIcon color="primary" style={{ fontSize: 28 }} />
                                            <Typography className={classes.title} variant="h6" color="primary">
                                                Accions més habituals
                                            </Typography>
                                        </div>
                                    </div>
                                    <Divider />
                                    <Grid container spacing={16} className={classes.cardContainer} alignItems="stretch">
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Button
                                                fullWidth
                                                variant="outlined"
                                                color="primary"
                                                className={classes.quickActionButton}
                                                classes={{ label: classes.label }}
                                                onClick={() => this.goToNewAppointment()}>
                                                Demanar hora
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Button
                                                fullWidth
                                                variant="outlined"
                                                color="primary"
                                                className={classes.quickActionButton}
                                                classes={{ label: classes.label }}
                                                onClick={() => this.goToNewPatient()}>
                                                Donar d'alta un nou pacient
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Button
                                                fullWidth
                                                variant="outlined"
                                                color="primary"
                                                className={classes.quickActionButton}
                                                classes={{ label: classes.label }}
                                                onClick={() => this.goToNewIncidence()}>
                                                Nova incidència
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper>
                                    <div className={classes.cardHeader}>
                                        <div className={classes.cardTitleContainer}>
                                            <WarningIcon color="secondary" style={{ fontSize: 28 }} />
                                            <Typography className={classes.title} variant="h6" color="secondary">
                                                Avís sobre peticions de visites
                                            </Typography>
                                        </div>
                                    </div>
                                    <Divider />
                                    <div className={classes.cardContainer}>
                                        <Typography variant="h6" color="textPrimary">
                                            <span style={{ fontWeight: 'bold' }}>COMUNICACIÓ DESEMBRE - 2024</span>
                                        </Typography>
                                        <Typography className={classes.alertTextPadding} variant="subtitle1" color="textPrimary">
                                            <span style={{ fontWeight: 'bold' }}>ASSIGNACIÓ DE VISITES</span>
                                        </Typography>
                                        <Typography variant="subtitle1" color="textPrimary">
                                            Us informem que actualment estem assignant les darreres visites del període docent actual que finalitza el 17 de gener de 2025 respectant l'ordre i el grau de prioritat indicat a la sol·licitud. De manera consecutiva iniciarem un nou període de febrer a juny de 2025. Continuem amb una llista d'espera que no ens permet assignar visites abans d'un termini aproximat de 2 mesos. En aquesta situació us volem transmetre la necessitat de fer arribar als usuaris derivats la importància de no faltar a la visita programada. El número de visites perdudes per pacients que no es presenten a la visita, malgrat els recordatoris de cita, no és despreciable i incideix de manera negativa en tot el servei.
                                        </Typography>
                                        <Typography className={classes.alertTextPadding} variant="subtitle1" color="textPrimary">
                                            <span style={{ fontWeight: 'bold' }}>RENOVACIÓ DEL CONVENI</span>
                                        </Typography>
                                        <Typography variant="subtitle1" color="textPrimary">
                                            Us informem que durant el 2025 no es podran atendre pacients derivats d'entitats amb les quals no tinguem actualitzat el conveni de col·laboració. Fa temps que estem tramitant la renovació dels convenis i a partir de l'1 de gener de 2025 no s'acceptaran sol·licituds sense conveni en vigència. Conseqüentment es podrà limitar l'accés dels treballadors socials a aquesta plataforma.
                                        </Typography>
                                        <Typography className={classes.alertTextPadding} variant="subtitle1" color="textPrimary">
                                            Per qualsevol dubte podeu contactar:
                                        </Typography>
                                        <Typography className={classes.alertTextPadding} variant="subtitle1" color="textPrimary">
                                            Telèfon: <span style={{ fontWeight: 'bold' }}>937 398 349</span><br/>
                                            Email: <span style={{ fontWeight: 'bold' }}>cuv.info.terrassa@upc.edu</span>
                                        </Typography>
                                    </div>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <Paper>
                                    <div className={classes.cardHeader}>
                                        <div className={classes.cardTitleContainer}>
                                            <NotificationIcon color="primary" style={{ fontSize: 28 }} />
                                            <Typography className={classes.title} variant="h6" color="primary">
                                                Últimes peticions d'hora
                                            </Typography>
                                        </div>
                                    </div>
                                    <Divider />
                                    <AppointmentsList />
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper>
                                    <div className={classes.cardHeader}>
                                        <div className={classes.cardTitleContainer}>
                                            <WarningIcon color="primary" style={{ fontSize: 28 }} />
                                            <Typography className={classes.title} variant="h6" color="primary">
                                                Últimes incidencies
                                            </Typography>
                                        </div>
                                        {/*<Tooltip title="Editar">
                                            <IconButton aria-label="Editar" className={classes.actions}>
                                                <FilterListIcon />
                                            </IconButton>
                                        </Tooltip>*/}
                                    </div>
                                    <Divider />
                                    <NotificationList />
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default withStyles(styles)(Home)
