import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'

// Design imports
import { Divider, List, ListItem, Paper, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import InboxIcon from '@material-ui/icons/AllInbox'
import ArrowForward from '@material-ui/icons/KeyboardArrowRight'
import compose from '../../utils/functions'

const lastAssembliesList = [
    {
        id: '3850',
        status: 'Muntatge',
    },
    {
        id: '8573',
        status: 'Muntatge',
    },
    {
        id: '3048',
        status: 'Entrega',
    },
]

class AssignedTasks extends React.Component {
    renderListItem = (item, index) => {
        const { classes } = this.props

        return (
            <ListItem
                divider={index !== lastAssembliesList.length - 1}
                button
                className={classes.listItem}
                onClick={null}>
                <div className={classes.assemblyInfo}>
                    <Typography variant="h6" color="primary">
                        {item.id}
                    </Typography>
                    <Typography variant="body1" color="textPrimary" className={classes.assemblyStatus}>
                        {item.status}
                    </Typography>
                </div>
                <ArrowForward color="primary" />
            </ListItem>
        )
    }

    render() {
        const { classes, notifications } = this.props

        return (
            <Paper>
                <div className={classes.cardHeader}>
                    <div className={classes.cardTitleContainer}>
                        <InboxIcon color="primary" style={{ fontSize: 28 }} />
                        <Typography className={classes.title} variant="h6" color="primary">
                            Tasques assignadas
                        </Typography>
                    </div>
                </div>
                <Divider />
                <List className={classes.list}>
                    {lastAssembliesList.map((item, index) => this.renderListItem(item, index))}
                </List>
            </Paper>
        )
    }
}

const styles = (theme) => ({
    list: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    listItem: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingRight: 16,
    },
    assemblyInfo: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
    assemblyStatus: {
        marginLeft: theme.spacing.unit * 4,
        marginRight: theme.spacing.unit * 2,
    },
    paperRoot: {},
    cardHeader: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        padding: theme.spacing.unit * 3,
        justifyContent: 'space-between',
        height: '80px',
    },
    cardTitleContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
    title: {
        paddingLeft: '8px',
    },
    cardContainer: {
        padding: theme.spacing.unit * 3,
    },
})

AssignedTasks.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default compose(withRouter, withStyles(styles))(AssignedTasks)
