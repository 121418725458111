import React from 'react'
import { render } from 'react-dom'
import moment from 'moment'
import { ApolloClient, ApolloLink, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client'
import { AUTH_TOKEN, ROLE } from './constants'
import { getValueFromStorage } from './utils'
import App from './containers/app'

import 'sanitize.css/sanitize.css'
import './index.css'

// Theme customization
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { onError } from '@apollo/client/link/error'
import { BrowserRouter as Router } from 'react-router-dom'

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#007bc0', //#007ac0
        },
    },
})

const target = document.querySelector('#root')
const defaultOptions = {
    watchQuery: {
        fetchPolicy: 'network-only',
        errorPolicy: 'ignore',
    },
    query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
    },
}

const httpLink = createHttpLink({
    uri: process.env.REACT_APP_HOST,
})

const authLink = new ApolloLink((operation, forward) => {
    const token = getValueFromStorage(AUTH_TOKEN)
    operation.setContext({
        headers: {
            authorization: token ? `Bearer ${token}` : '',
        },
    })
    return forward(operation)
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
        graphQLErrors.forEach((item) => {
            const codeError = item.extensions.code
            switch (codeError) {
                case 'UNAUTHENTICATED':
                    localStorage.removeItem(AUTH_TOKEN)
                    localStorage.removeItem(ROLE)
                    sessionStorage.removeItem(AUTH_TOKEN)
                    sessionStorage.removeItem(ROLE)
                    window.location.reload()
                    break
                default:
                    break
            }
        })
    if (networkError) console.log(`[Network error]: ${networkError}`)
})

const client = new ApolloClient({
    link: authLink.concat(errorLink).concat(httpLink),
    cache: new InMemoryCache(),
    defaultOptions: defaultOptions,
})

moment.locale('ca') // TODO Check if this code is really necessary

render(
    <ApolloProvider client={client}>
        <MuiThemeProvider theme={theme}>
            <Router>
                <div>
                    <App />
                </div>
            </Router>
        </MuiThemeProvider>
    </ApolloProvider>,
    target,
)
