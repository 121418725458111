import gql from 'graphql-tag'

export const getEntities = (offset, limit, order, orderDirection, search, filter) => {
    return {
        query: gql`
            query GetEntities(
                $offset: Int
                $limit: Int
                $order: String
                $orderDirection: String
                $search: String
                $filter: String
            ) {
                allEntities(
                    offset: $offset
                    limit: $limit
                    order: $order
                    orderDirection: $orderDirection
                    search: $search
                    filter: $filter
                ) {
                    entities {
                        id
                        name
                        contactPerson
                        email
                        phone
                        startAgreementDate
                        endAgreementDate
                        validated
                    }
                    totalCount
                }
            }
        `,
        variables: {
            offset: offset,
            limit: limit,
            order: order,
            orderDirection: orderDirection,
            search: search,
            filter: filter,
        },
    }
}

export const getEntitiesNames = () => {
    return {
        query: gql`
            {
                allEntities {
                    entities {
                        id
                        name
                    }
                }
            }
        `,
    }
}

export const getEntityById = (id) => {
    return {
        query: gql`
            query GetEntityById($id: String) {
                entity(id: $id) {
                    id
                    name
                    contactPerson
                    email
                    phone
                    startAgreementDate
                    endAgreementDate
                    validated
                    numberOfWorkers
                    numberOfPatients
                }
            }
        `,
        variables: {
            id: id,
        },
    }
}

export const addEntity = (name, contactPerson, email, phone, startAgreementDate, endAgreementDate) => {
    return {
        mutation: gql`
            mutation AddEntity(
                $name: String
                $contactPerson: String
                $email: String
                $phone: String
                $startAgreementDate: String
                $endAgreementDate: String
            ) {
                addEntity(
                    input: {
                        name: $name
                        contactPerson: $contactPerson
                        email: $email
                        phone: $phone
                        startAgreementDate: $startAgreementDate
                        endAgreementDate: $endAgreementDate
                    }
                ) {
                    id
                }
            }
        `,
        variables: {
            name: name,
            contactPerson: contactPerson,
            email: email,
            phone: phone,
            startAgreementDate: startAgreementDate,
            endAgreementDate: endAgreementDate,
        },
    }
}

export const updateEntity = (id, name, contactPerson, email, phone, startAgreementDate, endAgreementDate) => {
    return {
        mutation: gql`
            mutation UpdateEntity(
                $id: ID
                $name: String
                $contactPerson: String
                $email: String
                $phone: String
                $startAgreementDate: String
                $endAgreementDate: String
            ) {
                updateEntity(
                    id: $id
                    input: {
                        name: $name
                        contactPerson: $contactPerson
                        email: $email
                        phone: $phone
                        startAgreementDate: $startAgreementDate
                        endAgreementDate: $endAgreementDate
                    }
                ) {
                    id
                }
            }
        `,
        variables: {
            id: id,
            name: name,
            contactPerson: contactPerson,
            email: email,
            phone: phone,
            startAgreementDate: startAgreementDate,
            endAgreementDate: endAgreementDate,
        },
    }
}

export const grantAccess = (id) => {
    return {
        mutation: gql`
            mutation GrantAccessToEntity($id: ID) {
                grantAccessToEntity(id: $id) {
                    id
                }
            }
        `,
        variables: {
            id: id,
        },
    }
}

export const grantAccessBulk = (ids) => {
    return {
        mutation: gql`
            mutation GrantAccessToEntityBulk($ids: [ID]) {
                grantAccessToEntityBulk(ids: $ids) {
                    id
                }
            }
        `,
        variables: {
            ids: ids,
        },
    }
}

export const revokeAccess = (id) => {
    return {
        mutation: gql`
            mutation RevokeAccessToEntity($id: ID) {
                revokeAccessToEntity(id: $id) {
                    id
                }
            }
        `,
        variables: {
            id: id,
        },
    }
}
