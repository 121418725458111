import React from 'react'
import PropTypes from 'prop-types'
import compose from '../../../utils/functions'
import { APPOINTMENT_PREFERENCE_API, DEFAULT_ROWS_PER_PAGE, ROLE, ROLE_CAST } from '../../../constants'
import { getValueFromStorage } from '../../../utils'

// Apollo client
import { withApollo } from '@apollo/client/react/hoc'
import { getAppointments } from '../../../grapql/appointment'

// Components imports
import ManagementTableWrapper from '../../../components/management_table_wrapper'

// Design imports
import { Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import moment from 'moment/moment'

// Constants
const columns = [
    // { id: 'type', numeric: false, disablePadding: false, label: 'Tipus' },
    { id: 'name', numeric: false, disablePadding: false, label: 'Nom', sortable: false },
    { id: 'birthdate', numeric: false, date: true, disablePadding: false, label: 'Data de naixement', sortable: false },
    { id: 'phone', numeric: false, disablePadding: false, label: 'Telèfon', sortable: false },
    { id: 'entity', numeric: false, disablePadding: false, label: 'Entitat', sortable: false },
    // { id: 'newPatient', numeric: false, disablePadding: false, label: 'Nou pacient' },
    { id: 'favoriteTime', numeric: false, disablePadding: false, label: 'Preferència' },
    { id: 'observations', numeric: false, disablePadding: false, label: 'Observacions' }, // TODO ver si se puede limitar a dos filas
    { id: 'createdAt', numeric: false, date: true, disablePadding: false, label: 'Data de petició' },
    { id: 'proposedDate', numeric: false, disablePadding: false, label: 'Data proposada' },
    { id: 'confirmed', numeric: false, disablePadding: false, label: 'Visita confirmada' },
]

const filterOptions = [
    {
        columnId: 'preference',
        label: 'Preferència',
        options: [
            {
                value: APPOINTMENT_PREFERENCE_API.MORNING,
                label: 'Mati',
            },
            {
                value: APPOINTMENT_PREFERENCE_API.AFTERNOON,
                label: 'Tarda',
            },
            {
                value: APPOINTMENT_PREFERENCE_API.ANYTIME,
                label: 'Indiferent',
            },
        ],
    },
    {
        columnId: 'confirmed',
        label: 'Estat de la visita',
        options: [
            {
                value: true,
                label: 'Confirmada',
            },
            {
                value: false,
                label: 'No confirmada',
            },
        ],
    },
    {
        columnId: 'proposedDate',
        label: "S'ha proposat visita?",
        options: [
            {
                value: 'NOT NULL',
                label: 'Sí, ja té visita',
            },
            {
                value: 'NULL',
                label: 'No, encara no té visita',
            },
        ],
    },
]

class AppointmentsPDI extends React.Component {
    constructor(props) {
        super(props)

        const role = getValueFromStorage(ROLE)

        this.state = {
            data: [],
            totalCount: 0,
            role: role,
        }
    }

    parseData = (appointmentsFromAPI) => {
        var appointments = []

        appointmentsFromAPI.forEach((appointment) => {
            var favoriteTime = ''
            switch (appointment.preference) {
                case APPOINTMENT_PREFERENCE_API.MORNING:
                    favoriteTime = 'Mati'
                    break
                case APPOINTMENT_PREFERENCE_API.AFTERNOON:
                    favoriteTime = 'Tarda'
                    break
                default:
                    favoriteTime = 'Indiferent'
            }

            var proposedDate =
                appointment.proposedDate !== null
                    ? appointment.proposedHour + ' - ' + moment(parseInt(appointment.proposedDate)).format('DD/MM/YYYY')
                    : '-'

            appointments.push({
                id: appointment.id,
                name: appointment.patient?.name?.toUpperCase() + ' ' + appointment.patient?.lastName?.toUpperCase(),
                birthdate: appointment.patient.birthdate,
                phone: appointment.patient?.phone || '-',
                entity: appointment.patient?.entity?.name?.toUpperCase(),
                favoriteTime: favoriteTime,
                observations: appointment.observations,
                createdAt: appointment.createdAt,
                proposedDate: proposedDate,
                confirmed: appointment.confirmed ? 'Confirmada' : '',
            })
        })

        this.setState({ data: appointments })
    }

    componentDidMount() {
        this.fetchData(0, DEFAULT_ROWS_PER_PAGE)
    }

    fetchData = (offset, limit, orderBy, orderDirection, searchText, filterText) => {
        var castOrderBy = orderBy
        if (orderBy === 'favoriteTime') castOrderBy = 'preference'

        this.props.client
            .query(getAppointments(offset, limit, castOrderBy, orderDirection, searchText, filterText))
            .then((result) => {
                this.parseData(result.data.allAppointments.appointments)
                this.setState({ totalCount: result.data.allAppointments.totalCount })
            })
    }

    goToNewAppointment = () => {
        this.props.history.push('/new-appointment')
    }

    handleClick = (id) => {
        this.props.history.push('/appointment/' + id)
    }

    render() {
        const { classes } = this.props
        const { data, totalCount } = this.state

        const actionButtons =
            this.state.role === ROLE_CAST.ADMIN ? (
                <div>
                    <Button variant="outlined" color="primary" onClick={() => this.goToNewAppointment()}>
                        Nova visita
                    </Button>
                </div>
            ) : (
                <div></div>
            )

        return (
            <ManagementTableWrapper
                title="Llistat de peticions de visita"
                rows={data}
                columns={columns}
                filterOptions={filterOptions}
                actionButtons={actionButtons}
                onRowClick={(id) => this.handleClick(id)}
                totalCount={totalCount}
                fetchData={this.fetchData}
            />
        )
    }
}

const styles = (theme) => ({})

AppointmentsPDI.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default compose(withApollo, withStyles(styles))(AppointmentsPDI)
