import React from 'react'
import PropTypes from 'prop-types'
import compose from '../../utils/functions'

import SearchSelect from 'react-select'

// Apollo client
import { withApollo } from '@apollo/client/react/hoc'
import { addIncidence } from '../../grapql/incidence'
import { getPatientsNames } from '../../grapql/patient'

// Design imports
import {
    Button,
    Divider,
    FormControl,
    Grid,
    Paper,
    Step,
    StepButton,
    StepContent,
    StepLabel,
    Stepper,
    TextField,
    Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import SocialWorkerIcon from '@material-ui/icons/SupervisorAccount'
import NotesIcon from '@material-ui/icons/Notes'
import SendIcon from '@material-ui/icons/Send'

// Constants
const PATIENT_STEP = 0
const DESCRIPTION_STEP = 1
const SUMMARY_STEP = 2

function getSteps() {
    return ['Seleccioni un pacient', 'Títol i descripció de la incidència', 'Resum i confirmació de la incidència']
}

class NewIncidenceStepper extends React.Component {
    state = {
        activeStep: 0,
        completedSteps: [],
        selectedPatientId: null,
        incidenceTitle: '',
        incidenceDescription: '',
        labelWidth: 0,
        patients: [],
        incidenceTitleError: false,
        incidenceDescriptionError: false,
    }

    parseData = (patientsFromAPI) => {
        var patients = []

        patientsFromAPI.forEach((patient) => {
            patients.push({
                value: patient.id,
                label: patient.name?.toUpperCase() + ' ' + patient.lastName?.toUpperCase(),
            })
        })

        this.setState({ patients: patients })
    }

    componentDidMount() {
        if (this.props.selectedPatient !== null) {
            const selectedPatient = this.props.selectedPatient

            const patients = [
                { value: selectedPatient.id, label: selectedPatient.name + ' ' + selectedPatient.lastName },
            ]

            this.setState({
                patients,
                selectedPatientId: selectedPatient.id,
            })
        }
    }

    fetchPatients = (search = undefined) => {
        this.props.client
            .query(getPatientsNames(search))
            .then((result) => this.parseData(result.data.allPatients.patients))
    }

    validateData = () => {
        var dataIsValid = true

        var errors = {
            incidenceTitleError: false,
            incidenceDescriptionError: false,
        }

        // TODO Añadir validacion del patient
        if (this.state.incidenceTitle === '') {
            errors.incidenceTitleError = true
            dataIsValid = false
        }
        if (this.state.incidenceDescription === '') {
            errors.incidenceDescriptionError = true
            dataIsValid = false
        }

        this.setState(errors)

        return dataIsValid
    }

    handleConfirm = () => {
        if (this.validateData()) {
            const mutation = addIncidence(
                this.state.incidenceTitle,
                this.state.selectedPatientId.value,
                this.state.incidenceDescription,
            )

            this.props.client.mutate(mutation).then((result) => {
                this.props.handleConfirm()
            })
        } else {
            this.setState({ activeStep: DESCRIPTION_STEP })
        }
    }

    handleNext = () => {
        const { activeStep, completedSteps } = this.state

        const newCompletedSteps = completedSteps.slice()
        if (completedSteps.indexOf(activeStep) < 0) {
            newCompletedSteps.push(activeStep)
        }

        if (activeStep + 1 === SUMMARY_STEP && !(newCompletedSteps.includes(0) && newCompletedSteps.includes(1))) {
            return
        }

        this.setState((state) => ({
            activeStep: state.activeStep + 1,
            completedSteps: newCompletedSteps,
        }))
    }

    handleBack = () => {
        const { activeStep, completedSteps } = this.state

        const newCompletedSteps = completedSteps.slice()
        const index = completedSteps.indexOf(activeStep)
        if (index >= 0) {
            newCompletedSteps.splice(index, 1)
        }

        this.setState((state) => ({
            activeStep: state.activeStep - 1,
            completedSteps: newCompletedSteps,
        }))
    }

    handleStep = (step) => () => {
        if (
            step === SUMMARY_STEP &&
            !(this.state.completedSteps.includes(0) && this.state.completedSteps.includes(1))
        ) {
            return
        }

        this.setState({
            activeStep: step,
        })
    }

    handlePatientSelection = (name) => (value) => {
        this.setState({ selectedPatientId: value })
    }

    onSearchInputChange = (name) => (value) => {
        if (value.length >= 2) {
            this.fetchPatients(value)
        }
    }

    handleIncidenceDescriptionChanges = (name) => (event) => {
        this.setState({
            [name]: event.target.value,
        })
    }

    renderInfoStep = () => {
        const { classes } = this.props

        return (
            <Grid container spacing={16}>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                    <Paper className={classes.paperRoot}>
                        <div className={classes.cardHeader}>
                            <div className={classes.cardTitleContainer}>
                                <SocialWorkerIcon color="primary" style={{ fontSize: 28 }} />
                                <Typography className={classes.title} variant="h6" color="primary">
                                    Selecciona un pacient existent:
                                </Typography>
                            </div>
                        </div>
                        <Divider />
                        <div className={classes.cardContainer}>
                            <FormControl fullWidth variant="outlined" className={classes.formControl}>
                                <SearchSelect
                                    value={this.state.selectedPatientId}
                                    onChange={this.handlePatientSelection()}
                                    onInputChange={this.onSearchInputChange()}
                                    options={this.state.patients}
                                    placeholder={'Escriu dues lletres per començar la cerca'}
                                    noOptionsMessage={() => "No s'ha trobat cap pacient"}
                                    isClearable
                                />
                            </FormControl>
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        )
    }

    renderDescriptionStep = () => {
        const { classes } = this.props

        return (
            <Grid container spacing={16}>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                    <Paper className={classes.paperRoot}>
                        <div className={classes.cardHeader}>
                            <div className={classes.cardTitleContainer}>
                                <NotesIcon color="primary" style={{ fontSize: 28 }} />
                                <Typography className={classes.title} variant="h6" color="primary">
                                    Descriu la incidència
                                </Typography>
                            </div>
                        </div>
                        <Divider />
                        <div className={classes.cardContainer}>
                            <Grid container spacing={16}>
                                <Grid item xs={12} sm={12} md={6}>
                                    <TextField
                                        fullWidth
                                        required
                                        error={this.state.incidenceTitleError}
                                        id="incidence-title"
                                        label="Títol"
                                        value={this.state.incidenceTitle}
                                        onChange={this.handleIncidenceDescriptionChanges('incidenceTitle')}
                                        className={classes.textField}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        fullWidth
                                        required
                                        error={this.state.incidenceDescriptionError}
                                        id="incidence-description"
                                        label="Descripció"
                                        multiline
                                        rowsMax="5"
                                        rows="3"
                                        value={this.state.incidenceDescription}
                                        onChange={this.handleIncidenceDescriptionChanges('incidenceDescription')}
                                        className={classes.textField}
                                        // helperText="Per exemple, dies de la setmana que no pot anar, si necessita algun tractament especial, si ha d'anar acompanyat, etc."
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        )
    }

    renderSummaryStep = () => {
        const { classes } = this.props

        const patientIndex = this.state.patients.findIndex((item) => {
            const selectedPatientId = this.state.selectedPatientId !== null ? this.state.selectedPatientId.value : null
            return item.value === selectedPatientId
        })
        const patientName = patientIndex >= 0 ? this.state.patients[patientIndex].label : 'Cap pacient seleccionat'

        return (
            <Grid container spacing={16}>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                    <Paper className={classes.paperRoot}>
                        <div className={classes.cardHeader}>
                            <div className={classes.cardTitleContainer}>
                                <SendIcon color="primary" style={{ fontSize: 28 }} />
                                <Typography className={classes.title} variant="h6" color="primary">
                                    Revisi les dades i confirmi la incidència
                                </Typography>
                            </div>
                        </div>
                        <Divider />
                        <div className={classes.cardContainer}>
                            <Grid container spacing={16}>
                                <Grid item xs={12}>
                                    <Typography className={classes.labelTitle} variant="subtitle2">
                                        Pacient:
                                    </Typography>
                                    <Typography className={classes.label} variant="subtitle1">
                                        {patientName}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography className={classes.labelTitle} variant="subtitle2">
                                        Títol:
                                    </Typography>
                                    <Typography className={classes.label} variant="subtitle1">
                                        {this.state.incidenceTitle}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography className={classes.labelTitle} variant="subtitle2">
                                        Descripció:
                                    </Typography>
                                    <Typography className={classes.label} variant="subtitle1">
                                        {this.state.incidenceDescription}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        )
    }

    renderStepContent = (index) => {
        switch (index) {
            case PATIENT_STEP:
                return this.renderInfoStep()
            case DESCRIPTION_STEP:
                return this.renderDescriptionStep()
            case SUMMARY_STEP:
                return this.renderSummaryStep()
            default:
                return null
        }
    }

    renderStep = (label, index) => {
        const { classes } = this.props
        const { activeStep, completedSteps } = this.state
        const steps = getSteps()

        const completed = completedSteps.indexOf(index) >= 0

        var stepLabel = <StepLabel completed={completed}>{label}</StepLabel>
        if (activeStep !== index) {
            stepLabel = (
                <StepButton onClick={this.handleStep(index)} completed={completed}>
                    {label}
                </StepButton>
            )
        }

        return (
            <Step key={label}>
                {stepLabel}
                <StepContent>
                    {this.renderStepContent(index)}
                    <div className={classes.actionsContainer}>
                        <div>
                            <Button disabled={activeStep === 0} onClick={this.handleBack} className={classes.button}>
                                Anterior
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    if (activeStep === steps.length - 1) {
                                        this.handleConfirm()
                                    } else {
                                        this.handleNext()
                                    }
                                }}
                                className={classes.button}>
                                {activeStep === steps.length - 1 ? 'Confirmar' : 'Següent'}
                            </Button>
                        </div>
                    </div>
                </StepContent>
            </Step>
        )
    }

    render() {
        const { classes } = this.props
        const { activeStep } = this.state
        const steps = getSteps()

        return (
            <div className={classes.root}>
                <Stepper
                    nonLinear
                    activeStep={activeStep}
                    orientation="vertical"
                    classes={{ root: classes.stepperRoot }}>
                    {steps.map((label, index) => this.renderStep(label, index))}
                </Stepper>
            </div>
        )
    }
}

const styles = (theme) => ({
    root: {
        width: '100%',
        //padding: '8px',
    },
    stepperRoot: {
        backgroundColor: '#FAFAFA',
        padding: theme.spacing.unit,
    },
    button: {
        marginTop: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    actionsContainer: {
        marginTop: theme.spacing.unit,
    },
    resetContainer: {
        padding: theme.spacing.unit * 3,
    },
    formControl: {},
    stepContainer: {
        minHeight: 200,
        padding: theme.spacing.unit * 2,
        margin: '0px',
    },
    paperRoot: {},
    cardHeader: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        padding: theme.spacing.unit * 3,
        justifyContent: 'space-between',
        maxHeight: '100px',
    },
    cardTitleContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
    cardContainer: {
        padding: theme.spacing.unit * 3,
    },
    title: {
        paddingLeft: '8px',
    },
    radioButton: {
        marginRight: '56px',
    },
    labelTitle: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
        color: theme.palette.primary.light,
    },
    label: {
        fontWeight: 'normal',
    },
    textFieldWidth: {
        marginRight: theme.spacing.unit,
        minWidth: '200px',
    },
})

NewIncidenceStepper.propTypes = {
    classes: PropTypes.object.isRequired,
    handleConfirm: PropTypes.func.isRequired,
    selectedPatient: PropTypes.object,
}

NewIncidenceStepper.defaultProps = {
    selectedPatient: null,
}

export default compose(withApollo, withStyles(styles))(NewIncidenceStepper)
