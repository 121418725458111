import React from 'react'
import PropTypes from 'prop-types'
import compose from '../../utils/functions'

// Apollo client
import { withApollo } from '@apollo/client/react/hoc'
import { getSuppliersOnlyName } from '../../grapql/supplier'

// Design imports
import { Checkbox, FormControl, Input, ListItemText, MenuItem, Select, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

class SupplierPicker extends React.Component {
    constructor(props) {
        super(props)

        /*        var suppliersName = [];
        if (this.props.suppliersSelected !== undefined) {
            this.props.suppliersSelected.forEach(id => {
                var supplier = suppliers.find(item => item.id === id);
                suppliersName.push(supplier.name);
            });
        }*/

        this.state = {
            suppliers: [],
            selectedSuppliers: [],
        }
    }

    checkSelectedSuppliers = (suppliers) => {
        var suppliersName = []
        if (this.props.suppliersSelected !== undefined) {
            this.props.suppliersSelected.forEach((id) => {
                var supplier = suppliers.find((item) => item.id === id)
                suppliersName.push(supplier.name)
            })
        }

        this.setState({ selectedSuppliers: suppliersName })
    }

    parseData = (suppliersFromAPI) => {
        this.checkSelectedSuppliers(suppliersFromAPI)

        this.setState({ suppliers: suppliersFromAPI })
    }

    componentDidMount() {
        this.props.client
            .query(getSuppliersOnlyName())
            .then((result) => this.parseData(result.data.allSuppliers.suppliers))
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.suppliersSelected !== undefined) {
            if (
                prevProps.suppliersSelected === undefined ||
                prevProps.suppliersSelected !== this.props.suppliersSelected
            ) {
                this.checkSelectedSuppliers(this.state.suppliers)
            }
        }
    }

    handleChange = (event) => {
        if (this.props.disabled === false) {
            this.setState({ selectedSuppliers: event.target.value })
        }
    }

    render() {
        const { classes } = this.props

        return (
            <div>
                <Typography className={classes.labelTitle} variant="subtitle2">
                    Proveïdors:
                </Typography>
                <FormControl fullWidth>
                    <Select
                        multiple
                        displayEmpty
                        disable
                        value={this.state.selectedSuppliers}
                        onChange={this.handleChange}
                        input={<Input id="select-multiple-checkbox" />}
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={MenuProps}>
                        {this.state.suppliers.map((item) => (
                            <MenuItem key={item.id} value={item.name}>
                                <Checkbox checked={this.state.selectedSuppliers.indexOf(item.name) > -1} />
                                <ListItemText primary={item.name?.toUpperCase()} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        )
    }
}

const styles = (theme) => ({
    labelTitle: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
        color: theme.palette.primary.light,
    },
})

SupplierPicker.propTypes = {
    classes: PropTypes.object.isRequired,
    suppliersSelected: PropTypes.array,
    disabled: PropTypes.bool,
}

SupplierPicker.defaultProps = {
    disabled: false,
}

export default compose(withApollo, withStyles(styles))(SupplierPicker)
