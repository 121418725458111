import React from 'react'
import PropTypes from 'prop-types'
import compose from '../../../utils/functions'
import { DEFAULT_ROWS_PER_PAGE, ROLE_API } from '../../../constants'

// Apollo client
import { withApollo } from '@apollo/client/react/hoc'
import { getUsers, grantAccessBulk } from '../../../grapql/user'

// Components imports
import ManagementTableWrapper from '../../../components/management_table_wrapper'

// Design imports
import { Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

// Constants
const columns = [
    { id: 'name', numeric: false, disablePadding: false, label: 'Nom' },
    // { id: 'lastName', numeric: false, disablePadding: false, label: 'Cognoms' },
    { id: 'role', numeric: false, disablePadding: false, label: 'Rol', sortable: false },
    { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
    { id: 'phone', numeric: false, disablePadding: false, label: 'Telèfon' },
    { id: 'entityOrSubjectName', numeric: false, disablePadding: false, label: 'Entitat/Assignatura', sortable: false },
    // { id: 'group', numeric: false, disablePadding: false, label: 'Grup' },
    { id: 'validated', numeric: false, disablePadding: false, label: 'Validat' },
]

const filterOptions = [
    {
        columnId: 'validated',
        label: 'Estat de validació',
        options: [
            {
                value: true,
                label: 'Validat',
            },
            {
                value: false,
                label: 'No validat',
            },
        ],
    },
    {
        columnId: 'role',
        label: 'Rol',
        options: [
            {
                value: ROLE_API.ADMIN,
                label: 'Administrador',
            },
            {
                value: ROLE_API.TS,
                label: 'Treballador social',
            },
            {
                value: ROLE_API.ST,
                label: 'Estudiant',
            },
            {
                value: ROLE_API.MST,
                label: 'Estudiant de gestió',
            },
            {
                value: ROLE_API.PDI,
                label: 'PDI',
            },
        ],
    },
]

class Users extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            data: [],
            totalCount: 0,
        }
    }

    castRoles = (roleFromAPI) => {
        switch (roleFromAPI) {
            case 'ADMIN':
                return 'Administrador'
            case 'PDI':
                return 'PDI'
            case 'TS':
                return 'Treballador social'
            case 'ST':
                return 'Estudiant'
            case 'MST':
                return 'Estudiant de gestió'
            default:
                return ''
        }

        return ''
    }

    parseData = (usersFromAPI) => {
        var users = []

        usersFromAPI.forEach((user) => {
            users.push({
                id: user.id,
                name: user?.name?.toUpperCase() + ' ' + user?.lastName?.toUpperCase(),
                email: user?.email?.toUpperCase(),
                phone: user?.phone?.toUpperCase(),
                role: this.castRoles(user.role),
                entityOrSubjectId: user.entityOrSubject !== null ? user.entityOrSubject.id : null,
                entityOrSubjectName: user.entityOrSubject !== null ? user.entityOrSubject.name?.toUpperCase() : null,
                validated: user.validated ? '' : 'No validat',
            })
        })

        this.setState({ data: users })
    }

    componentDidMount() {
        this.fetchData(0, DEFAULT_ROWS_PER_PAGE)
    }

    fetchData = (offset, limit, orderBy, orderDirection, searchText, filterText) => {
        this.props.client
            .query(getUsers(offset, limit, orderBy, orderDirection, searchText, filterText))
            .then((result) => {
                this.parseData(result.data.allUsers.users)
                this.setState({ totalCount: result.data.allUsers.totalCount })
            })
    }

    goToNewUser = () => {
        this.props.history.push('/new-user')
    }

    handleClick = (id) => {
        this.props.history.push('/user/' + id)
    }

    onValidateClicked = (selected, fetchData) => {
        this.props.client.mutate(grantAccessBulk(selected)).then((result) => fetchData())
    }

    render() {
        const { classes } = this.props
        const { data, totalCount } = this.state

        const actionButtons = (
            <div>
                <Button variant="outlined" color="primary" onClick={() => this.goToNewUser()}>
                    Nou usuari
                </Button>
            </div>
        )

        return (
            <ManagementTableWrapper
                title="Llistat d'usuaris"
                rows={data}
                columns={columns}
                filterOptions={filterOptions}
                actionButtons={actionButtons}
                onRowClick={(id) => this.handleClick(id)}
                totalCount={totalCount}
                fetchData={this.fetchData}
                onValidateClicked={(selected, fetchData) => this.onValidateClicked(selected, fetchData)}
            />
        )
    }
}

const styles = (theme) => ({})

Users.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default compose(withApollo, withStyles(styles))(Users)
