import React from 'react'
import PropTypes from 'prop-types'
import compose from '../../../utils/functions'

// Components imports
import FeedbackSnackbar from '../../../components/feedback_snackbar/index'

// Apollo client
import { withApollo } from '@apollo/client/react/hoc'
import { addSubject } from '../../../grapql/subject'

// Design imports
import {
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    Grid,
    Paper,
    TextField,
    Typography,
    withWidth,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import SubjectsIcon from '@material-ui/icons/Class'

// DEVELOPMENT Data import

class NewSubject extends React.Component {
    state = {
        name: '',
        evaluable: false,
        confirmSnackbarOpened: false,
        nameError: false,
    }

    validateData = () => {
        var dataIsValid = true

        var errors = {
            nameError: false,
        }

        if (this.state.name === '') {
            errors.nameError = true
            dataIsValid = false
        }

        this.setState(errors)

        return dataIsValid
    }

    handleChange = (name) => (event) => {
        this.setState({
            [name]: event.target.value,
        })
    }

    handleClose = () => {
        this.setState({ confirmSnackbarOpened: false })
    }

    handleConfirmButtonClick = () => {
        if (this.validateData()) {
            const mutation = addSubject(this.state.name, this.state.evaluable)

            this.props.client.mutate(mutation).then((result) => {
                this.setState({ confirmSnackbarOpened: true })
                setTimeout(() => {
                    this.props.history.goBack()
                }, 3000)
            })
        }
    }

    handleChecboxChange = (name) => (event) => {
        this.setState({ [name]: event.target.checked })
    }

    render() {
        const { classes } = this.props

        return (
            <div className={classes.root}>
                <Grid container spacing={24} justify="center">
                    <Grid item xs={12} sm={12} md={8} lg={6}>
                        <Paper className={classes.paperRoot}>
                            <div className={classes.cardHeader}>
                                <div className={classes.cardTitleContainer}>
                                    <SubjectsIcon color="primary" style={{ fontSize: 28 }} />
                                    <Typography className={classes.title} variant="h6" color="primary">
                                        Nova assignatura
                                    </Typography>
                                </div>
                            </div>
                            <Divider />
                            <Grid className={classes.cardContainer} container spacing={24}>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        error={this.state.nameError}
                                        id="entity-name"
                                        label="Nom"
                                        name="name"
                                        type="text"
                                        className={classes.textField}
                                        value={this.state.name}
                                        onChange={this.handleChange('name')}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.state.evaluable}
                                                onChange={this.handleChecboxChange('evaluable')}
                                                value="evaluable"
                                                color="primary"
                                            />
                                        }
                                        label="Avaluable"
                                    />
                                </Grid>
                            </Grid>
                            <Divider />
                            <div className={classes.cardFooter}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    fullWidth={this.props.width === 'xs'}
                                    onClick={() => this.handleConfirmButtonClick()}>
                                    Crear assignatura
                                </Button>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
                <FeedbackSnackbar
                    showSnackbar={this.state.confirmSnackbarOpened}
                    handleClose={() => this.handleClose()}
                    timeShowed={3000}
                    text={"Assignatura donada d'alta correctament"}
                />
            </div>
        )
    }
}

const styles = (theme) => ({
    root: {
        display: 'flex',
        flex: 1,
    },
    paperRoot: {
        //flex: 0.5
        //padding: theme.spacing.unit * 3,
    },
    cardHeader: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        padding: theme.spacing.unit * 3,
        justifyContent: 'space-between',
        height: '80px',
    },
    cardFooter: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        padding: theme.spacing.unit * 3,
        justifyContent: 'flex-end',
        height: '80px',
    },
    cardTitleContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
    title: {
        paddingLeft: '8px',
    },
    cardContainer: {
        padding: theme.spacing.unit * 3,
    },
    textField: {
        //marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    textFieldWidth: {
        marginRight: theme.spacing.unit,
        minWidth: '200px',
    },
    formControl: {
        marginTop: 16,
    },
})

NewSubject.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default compose(withApollo, withWidth(), withStyles(styles))(NewSubject)
