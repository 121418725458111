import gql from 'graphql-tag'

export const getSubjects = (offset, limit, order, orderDirection, filter) => {
    return {
        query: gql`
            query GetSubjects($offset: Int, $limit: Int, $order: String, $orderDirection: String, $filter: String) {
                allSubjects(
                    offset: $offset
                    limit: $limit
                    order: $order
                    orderDirection: $orderDirection
                    filter: $filter
                ) {
                    subjects {
                        id
                        name
                        evaluable
                        numberOfStudents
                    }
                    totalCount
                }
            }
        `,
        variables: {
            offset: offset,
            limit: limit,
            order: order,
            orderDirection: orderDirection,
            filter: filter,
        },
    }
}

export const getSubjectsNames = () => {
    return {
        query: gql`
            {
                allSubjects {
                    subjects {
                        id
                        name
                    }
                }
            }
        `,
    }
}

export const getSubjectDetailById = (id) => {
    return {
        query: gql`
            query GetSubjectDetail($id: String) {
                subject(id: $id) {
                    id
                    name
                    evaluable
                    numberOfStudents
                }
            }
        `,
        variables: {
            id: id,
        },
    }
}

export const addSubject = (name, evaluable) => {
    return {
        mutation: gql`
            mutation AddSubject($name: String, $evaluable: Boolean) {
                addSubject(input: { name: $name, evaluable: $evaluable }) {
                    id
                }
            }
        `,
        variables: {
            name: name,
            evaluable: evaluable,
        },
    }
}

export const updateSubject = (id, name, evaluable) => {
    return {
        mutation: gql`
            mutation UpdateSubject($id: ID, $name: String, $evaluable: Boolean) {
                updateSubject(id: $id, input: { name: $name, evaluable: $evaluable }) {
                    id
                }
            }
        `,
        variables: {
            id,
            id,
            name: name,
            evaluable: evaluable,
        },
    }
}

export const deleteSubjectById = (id) => {
    return {
        mutation: gql`
            mutation RemoveSubject($id: ID) {
                removeSubject(id: $id) {
                    id
                }
            }
        `,
        variables: {
            id: id,
        },
    }
}
