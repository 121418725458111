import React from 'react'
import PropTypes from 'prop-types'
import compose, { getCityName, getNationalityName } from '../../../utils/functions'

// Date managment
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from 'material-ui-pickers'
import moment from 'moment'

// Components imports
import FeedbackSnackbar from '../../../components/feedback_snackbar/index'

// Apollo client
import { withApollo } from '@apollo/client/react/hoc'
import { getPatientByIdForTS, removePatient, updatePatient } from '../../../grapql/patient'
import { getAllSocialWorkerFromEntity } from '../../../grapql/social_workers'

// Design imports
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormControl,
    Grid,
    IconButton,
    Input,
    InputLabel,
    List,
    ListItem,
    MobileStepper,
    Paper,
    Select,
    Tooltip,
    Typography,
} from '@material-ui/core'
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@material-ui/lab/'
import { withStyles } from '@material-ui/core/styles'
import UserIcon from '@material-ui/icons/PermIdentity'
import CloseIcon from '@material-ui/icons/Close'
import TrackIcon from '@material-ui/icons/TrackChanges'
import SocialWorkerIcon from '@material-ui/icons/SupervisorAccount'
import HistoryIcon from '@material-ui/icons/History'
import WarningIcon from '@material-ui/icons/Warning'
import EditIcon from '@material-ui/icons/Edit'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import CheckIcon from '@material-ui/icons/Check'
import NewWarning from '@material-ui/icons/PlaylistAdd'
import DeleteIcon from '@material-ui/icons/Delete'
import { ASSEMBLY_STATUS_API } from '../../../constants'
import ca from 'moment/locale/ca'
import styles from './styles'
import TextLabel from '../../../components/atom/TextLabel'
import NewPatientComponent from '../../../components/molecule/NewPatient/NewPatient'

// Constants
const APPOINTMENT_SECTION = 0
const WORKING_SECTION = 1
const DELIVERY_SECTION = 2
const END_SECTION = 3
const steps = [
    {
        label: 'Visita programada',
        labelAfter: 'Visita realitzada',
        sectionId: APPOINTMENT_SECTION,
    },
    {
        label: 'En muntatge',
        labelAfter: 'Muntatge realitzat',
        sectionId: WORKING_SECTION,
    },
    {
        label: 'Per entregar',
        labelAfter: 'Entrega realitzada',
        sectionId: DELIVERY_SECTION,
    },
    {
        label: 'Tractament finalitzat',
        sectionId: END_SECTION,
    },
]

const actions = [
    { icon: <HistoryIcon />, name: 'Demanar visita' },
    { icon: <NewWarning />, name: 'Crear incidencia' },
    { icon: <DeleteIcon />, name: 'Eliminar pacient' },
]

class PatientDetail extends React.Component {
    constructor(props) {
        super(props)
        this.newPatientComponentRef = React.createRef()

        this.state = {
            patient: {
                appointments: [],
                incidences: [],
                lastAssembly: {},
            },
            newPatientData: null,
            activeStep: 0,
            lastStepCompleted: END_SECTION,
            fabOpened: false,
            deleteDialogOpened: false,
            confirmedDeleteSnackbarOpened: false,
            showEditPatient: false,
            showEditSocialWorker: false,
            socialWorker: '',
            socialWorkerError: false,
        }
    }

    parseData = (patientFromAPI) => {
        const age = moment().diff(moment(parseInt(patientFromAPI.birthdate)), 'years')
        const birthdateMoment = moment(parseInt(patientFromAPI.birthdate))

        const patient = {
            id: patientFromAPI.id,
            name: patientFromAPI.name?.toUpperCase(),
            lastName: patientFromAPI.lastName?.toUpperCase(),
            birthdate: moment(parseInt(patientFromAPI.birthdate)).format('DD/MM/YYYY'),
            age: age,
            email: patientFromAPI.email ? patientFromAPI.email?.toUpperCase() : '-',
            phone: patientFromAPI.phone,
            centerId: patientFromAPI.entity.id,
            center: patientFromAPI.entity?.name?.toUpperCase(),
            socialWorkerId: patientFromAPI.socialWorkerId,
            socialWorkerName:
                patientFromAPI.socialWorker !== null
                    ? patientFromAPI.socialWorker.name?.toUpperCase() +
                      ' ' +
                      patientFromAPI.socialWorker.lastName?.toUpperCase()
                    : '-',
            appointments: patientFromAPI.appointments,
            incidences: patientFromAPI.incidences,
            lastAssembly: patientFromAPI.lastAssembly,
            idNumber: patientFromAPI.idNumber,
            nationality: patientFromAPI.nationality,
            address: patientFromAPI.address,
            city: patientFromAPI.city,
            postalCode: patientFromAPI.postalCode,
        }

        const newPatientData = {
            name: patient.name,
            lastName: patient.lastName,
            idNumber: patient.idNumber,
            nationality: patient.nationality,
            birthdate: birthdateMoment,
            phone: patient.phone,
            email: patient.email,
            address: patient.address,
            city: patient.city,
            postalCode: patient.postalCode,
            center: patient.center,
        }

        var activeStep = 0
        var lastStep = 0

        if (patient.lastAssembly !== null) {
            const actualStepAPI = patient.lastAssembly.actualStep
            if (
                actualStepAPI === ASSEMBLY_STATUS_API.ORDER ||
                actualStepAPI === ASSEMBLY_STATUS_API.ASSEMBLY ||
                actualStepAPI === ASSEMBLY_STATUS_API.REVISION ||
                actualStepAPI === ASSEMBLY_STATUS_API.VALUATION
            ) {
                activeStep = WORKING_SECTION
                lastStep = WORKING_SECTION
            } else if (actualStepAPI === ASSEMBLY_STATUS_API.DELIVERY) {
                activeStep = DELIVERY_SECTION
                lastStep = DELIVERY_SECTION
            } else if (actualStepAPI === ASSEMBLY_STATUS_API.END) {
                activeStep = END_SECTION
                lastStep = END_SECTION
            }
        }

        this.setState({
            patient: patient,
            newPatientData: newPatientData,
            activeStep: activeStep,
            lastStepCompleted: lastStep,
            name: patient.name,
            lastName: patient.lastName,
            birthdate: birthdateMoment,
            email: patient.email,
            phone: patient.phone,
        })

        this.fetchSocialWorkers(patient.centerId, patient.socialWorkerId)
    }

    fetchPatientDetail = () => {
        this.props.client
            .query(getPatientByIdForTS(this.props.match.params.id))
            .then((result) => this.parseData(result.data.patient))
    }

    componentDidMount() {
        this.fetchPatientDetail()
    }

    fetchSocialWorkers = (entityId, socialWorkerId) => {
        this.props.client.query(getAllSocialWorkerFromEntity(entityId)).then((result) => {
            this.setState({ socialWorkers: result.data.allSocialWorkersFromEntity, socialWorker: socialWorkerId })
        })
    }

    goToNewAppointment = () => {
        this.props.history.push({
            pathname: '/new-appointment',
            state: {
                id: this.props.match.params.id,
                name: this.state.patient.name,
                lastName: this.state.patient.lastName,
            },
        })
    }

    goToNewIncidence = () => {
        this.props.history.push({
            pathname: '/new-incidence',
            state: {
                id: this.props.match.params.id,
                name: this.state.patient.name,
                lastName: this.state.patient.lastName,
            },
        })
    }

    goToAppointment = (id) => {
        this.props.history.push('/appointment/' + id)
    }

    goToIncidences = (id) => {
        this.props.history.push('/incidence/' + id)
    }

    validateData = () => {
        var dataIsValid = true

        var errors = {
            socialWorkerError: false,
        }

        if (this.state.socialWorker === '') {
            errors.socialWorkerError = true
            dataIsValid = false
        }

        this.setState(errors)
        return dataIsValid
    }

    updatePatient = () => {
        const newPatientComponent = this.newPatientComponentRef.current

        if (newPatientComponent.validateData()) {
            this.updatePatientWithSocialWorker(newPatientComponent.state.patient)
        }
    }

    updatePatientWithSocialWorker = (patient) => {
        if (this.validateData()) {
            if (patient) {
                const mutation = updatePatient(
                    this.props.match.params.id,
                    patient.name,
                    patient.lastName,
                    patient.birthdate.valueOf().toString(),
                    patient.email,
                    patient.phone,
                    this.state.socialWorker,
                    patient.idNumber,
                    patient.nationality,
                    patient.address,
                    patient.city,
                    patient.postalCode,
                )

                this.props.client.mutate(mutation).then((result) => this.fetchPatientDetail())
                this.setState({ showEditPatient: false, showEditSocialWorker: false })
            }
        }
    }

    deletePatient = () => {
        const mutation = removePatient(this.props.match.params.id)
        this.props.client.mutate(mutation).then((result) => {
            this.setState({ deleteDialogOpened: false, confirmedDeleteSnackbarOpened: true })
            setTimeout(() => {
                this.props.history.goBack()
            }, 3000)
        })
    }

    handleChange = (name) => (event) => {
        this.setState({
            [name]: event.target.value,
        })
    }

    handleSocialWorkerChange = (name) => (event) => {
        this.setState({
            socialWorker: event.target.value,
        })
    }

    handleCloseDialog = () => {
        this.setState({
            deleteDialogOpened: false,
            confirmedDeleteSnackbarOpened: false,
        })
    }

    handleDeletePatient = () => {
        this.setState({ deleteDialogOpened: true })
    }

    handleNext = () => {
        if (this.state.activeStep + 1 <= this.state.lastStepCompleted) {
            this.setState((state) => ({
                activeStep: state.activeStep + 1,
            }))
        }
    }

    handleBack = () => {
        this.setState((state) => ({
            activeStep: state.activeStep - 1,
        }))
    }

    handleClick = () => {
        this.setState((state) => ({
            fabOpened: !state.fabOpened,
        }))
    }

    handleActionClick = (action) => {
        if (action === actions[0].name) {
            this.goToNewAppointment()
        } else if (action === actions[1].name) {
            this.goToNewIncidence()
        } else if (action === actions[2].name) {
            this.handleDeletePatient()
        }

        this.handleClick()
    }

    handleOpen = () => {
        this.setState({
            fabOpened: true,
        })
    }

    handleClose = () => {
        this.setState({
            fabOpened: false,
        })
    }

    renderTrackSection = () => {
        const { classes } = this.props
        const { activeStep, patient } = this.state

        if (this.state.patient.lastAssembly === null) {
            return (
                <div className={classes.trackEndSection}>
                    <Typography className={classes.trackEndTitle} variant="subtitle1">
                        No hi ha cap muntatge en curs
                    </Typography>
                </div>
            )
        }

        switch (activeStep) {
            case APPOINTMENT_SECTION:
                return (
                    <Grid container spacing={16}>
                        <Grid item xs={12} sm={6}>
                            <Typography className={classes.labelTitle} variant="subtitle2">
                                Data de la visita:
                            </Typography>
                            <Typography className={classes.label} variant="h6">
                                {patient.lastAssembly !== null && patient.lastAssembly.appointmentDate !== null
                                    ? moment(parseInt(patient.lastAssembly.appointmentDate))
                                          .locale('ca', ca)
                                          .format('dddd D MMMM - DD/MM/YYYY')
                                    : '-'}
                            </Typography>
                        </Grid>
                    </Grid>
                )
            case WORKING_SECTION:
                return (
                    <Grid container spacing={16}>
                        <Grid item xs={12} sm={6}>
                            <Typography className={classes.labelTitle} variant="subtitle2">
                                Data de finalització:
                            </Typography>
                            <Typography className={classes.label} variant="h6">
                                {patient.lastAssembly.endAssemblyDate !== null
                                    ? moment(parseInt(patient.lastAssembly.endAssemblyDate))
                                          .locale('ca', ca)
                                          .format('dddd D MMMM - DD/MM/YYYY')
                                    : '-'}
                            </Typography>
                        </Grid>
                    </Grid>
                )
            case DELIVERY_SECTION:
                return (
                    <Grid container spacing={16}>
                        <Grid item xs={12} sm={6}>
                            <Typography className={classes.labelTitle} variant="subtitle2">
                                Data d'entrega programada:
                            </Typography>
                            <Typography className={classes.label} variant="h6">
                                {patient.lastAssembly.expectedDeliveryDate !== null
                                    ? moment(parseInt(patient.lastAssembly.expectedDeliveryDate))
                                          .locale('ca', ca)
                                          .format('dddd D MMMM - DD/MM/YYYY')
                                    : '-'}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography className={classes.labelTitle} variant="subtitle2">
                                Data d'entrega:
                            </Typography>
                            <Typography className={classes.label} variant="h6">
                                {patient.lastAssembly.deliveryDate !== null
                                    ? moment(parseInt(this.state.patient.lastAssembly.deliveryDate))
                                          .locale('ca', ca)
                                          .format('dddd D MMMM - DD/MM/YYYY')
                                    : '-'}
                            </Typography>
                        </Grid>
                    </Grid>
                )
            case END_SECTION:
                return (
                    <div className={classes.trackEndSection}>
                        <CheckIcon className={classes.trackEndIcon} style={{ fontSize: 40 }} />
                        <Typography className={classes.trackEndTitle} variant="subtitle1">
                            Tractament finalitzat amb èxit
                        </Typography>
                    </div>
                )
        }
    }

    renderRemoveDialogAndSnackbar = () => {
        const { classes } = this.props
        const { user } = this.state

        return (
            <div>
                <Dialog
                    open={this.state.deleteDialogOpened}
                    onClose={this.handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">{'Està segur que vol eliminar aquest pacient?'}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Un cop eliminat el pacient no es podra recuperar.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseDialog} color="secondary">
                            No
                        </Button>
                        <Button onClick={this.deletePatient} color="primary" autoFocus>
                            Sí, eliminar pacient
                        </Button>
                    </DialogActions>
                </Dialog>
                <FeedbackSnackbar
                    showSnackbar={this.state.confirmedDeleteSnackbarOpened}
                    handleClose={() => this.handleClose()}
                    timeShowed={3000}
                    text={'Pacient eliminat correctament'}
                />
            </div>
        )
    }

    renderEditSocialWorker = () => {
        const { classes } = this.props

        return (
            <div>
                <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                    <Grid className={classes.cardContainer} container spacing={16}>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth required className={classes.formControl}>
                                <InputLabel
                                    ref={(ref) => {
                                        this.SubjectLabelRef = ref
                                    }}
                                    htmlFor="user-subject">
                                    Treballador social
                                </InputLabel>
                                <Select
                                    native
                                    error={this.state.socialWorkerError}
                                    value={this.state.socialWorker}
                                    onChange={this.handleSocialWorkerChange()}
                                    input={<Input name="socialworker-selecte" id="socialworker-selected" />}>
                                    <option value={null}>-</option>
                                    {this.state.socialWorkers.map((item, index) => {
                                        return (
                                            <option value={item.id}>
                                                {item.user.name?.toUpperCase() +
                                                    ' ' +
                                                    item.user.lastName?.toUpperCase()}
                                            </option>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Divider />
                    <div className={classes.cardFooter}>
                        <Button
                            variant="outlined"
                            color="secondary"
                            size="large"
                            className={classes.actionButton}
                            onClick={() => this.setState({ showEditSocialWorker: false })}>
                            Cancel·lar
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            className={classes.actionButton}
                            onClick={() => this.updatePatientWithSocialWorker(this.state.newPatientData)}>
                            Desar els canvis
                        </Button>
                    </div>
                </MuiPickersUtilsProvider>
            </div>
        )
    }

    renderEditPatient = () => {
        const { classes } = this.props

        return (
            <div>
                <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                    <NewPatientComponent
                        innerRef={this.newPatientComponentRef}
                        patient={this.state.newPatientData}
                        centers={this.state.centers}
                        isUpdating={true}
                        isSocialWorker={true}
                    />
                    <Divider />
                    <div className={classes.cardFooter}>
                        <Button
                            variant="outlined"
                            color="secondary"
                            size="large"
                            className={classes.actionButton}
                            onClick={() => this.setState({ showEditPatient: false })}>
                            Cancel·lar
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            className={classes.actionButton}
                            onClick={() => this.updatePatient()}>
                            Desar els canvis
                        </Button>
                    </div>
                </MuiPickersUtilsProvider>
            </div>
        )
    }

    render() {
        const { classes, match } = this.props
        const { patient, activeStep, fabOpened } = this.state

        return (
            <div className={classes.root}>
                {/* Speed Dial */}
                <SpeedDial
                    ariaLabel="Actions"
                    className={classes.fab}
                    hidden={false}
                    icon={<SpeedDialIcon />}
                    onBlur={this.handleClose}
                    onClick={this.handleClick}
                    onClose={this.handleClose}
                    onFocus={this.handleOpen}
                    onMouseEnter={this.handleOpen}
                    onMouseLeave={this.handleClose}
                    open={fabOpened}>
                    {actions.map((action) => (
                        <SpeedDialAction
                            className={classes.actionDial}
                            key={action.name}
                            icon={action.icon}
                            tooltipTitle={action.name}
                            onClick={() => this.handleActionClick(action.name)}
                        />
                    ))}
                </SpeedDial>

                <Grid container spacing={24}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Paper className={classes.paperRoot}>
                            <div className={classes.cardHeader}>
                                <div className={classes.cardTitleContainer}>
                                    <UserIcon color="primary" style={{ fontSize: 28 }} />
                                    <Typography className={classes.title} variant="h6" color="primary">
                                        Informació del pacient
                                    </Typography>
                                </div>
                                <Tooltip title="Editar">
                                    {this.state.showEditPatient ? (
                                        <IconButton
                                            aria-label="Editar"
                                            className={classes.actions}
                                            onClick={() => this.setState({ showEditPatient: false })}>
                                            <CloseIcon />
                                        </IconButton>
                                    ) : (
                                        <IconButton
                                            aria-label="Editar"
                                            className={classes.actions}
                                            onClick={() => this.setState({ showEditPatient: true })}>
                                            <EditIcon />
                                        </IconButton>
                                    )}
                                </Tooltip>
                            </div>
                            <Divider />
                            {this.state.showEditPatient ? (
                                this.renderEditPatient()
                            ) : (
                                <div>
                                    <Grid className={classes.cardContainer} container spacing={16}>
                                        <TextLabel title={'Nom:'} value={patient.name} xs={12} sm={6} />
                                        <TextLabel title={'Cognoms:'} value={patient.lastName} xs={12} sm={6} />
                                        <TextLabel
                                            title={'Data de naixement:'}
                                            value={patient.birthdate}
                                            xs={12}
                                            sm={6}
                                        />
                                        <TextLabel title={'Edat:'} value={patient.age + ' anys'} xs={12} sm={6} />
                                        <TextLabel
                                            title={'DNI/NIE/Passaport:'}
                                            value={patient.idNumber}
                                            xs={12}
                                            sm={6}
                                        />
                                        <TextLabel
                                            title={'Nacionalitat:'}
                                            value={getNationalityName(patient.nationality)}
                                            xs={12}
                                            sm={6}
                                        />
                                        <TextLabel
                                            title={'Email del pacient:'}
                                            value={patient.email}
                                            type={'email'}
                                            xs={12}
                                            sm={6}
                                        />
                                        <TextLabel
                                            title={'Telèfon:'}
                                            value={patient.phone}
                                            type={'phone'}
                                            xs={12}
                                            sm={6}
                                        />
                                        <TextLabel title={'Adreça:'} value={patient.address} xs={12} sm={6} />
                                        <TextLabel
                                            title={'Municipi:'}
                                            value={getCityName(patient.city)}
                                            xs={12}
                                            sm={6}
                                        />
                                        <TextLabel title={'Codi postal:'} value={patient.postalCode} xs={12} sm={6} />
                                        <TextLabel title={'Entitat:'} value={patient.center} xs={12} sm={6} />
                                    </Grid>
                                </div>
                            )}
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                        <Paper className={classes.paperRoot}>
                            <div className={classes.cardHeader}>
                                <div className={classes.cardTitleContainer}>
                                    <TrackIcon color="primary" style={{ fontSize: 28 }} />
                                    <Typography className={classes.title} variant="h6" color="primary">
                                        Seguiment
                                    </Typography>
                                </div>
                                <Typography variant="subtitle1" color="textSecondary">
                                    Últim tractament - 24/07/2018
                                </Typography>
                            </div>
                            <Divider />
                            <div>
                                <div className={classes.trackContainer}>{this.renderTrackSection()}</div>
                                <div className={classes.trackSectionTitleContainer}>
                                    <Typography
                                        className={classes.trackSectionTitle}
                                        variant="subtitle1"
                                        color="primary">
                                        {steps[activeStep].label}
                                    </Typography>
                                </div>
                                <MobileStepper
                                    className={classes.mobileStepper}
                                    variant="progress"
                                    steps={steps.length}
                                    position="static"
                                    activeStep={this.state.activeStep}
                                    //className={classes.root}
                                    nextButton={
                                        <Button
                                            size="small"
                                            onClick={this.handleNext}
                                            disabled={
                                                this.state.activeStep === steps.length - 1 ||
                                                this.state.activeStep === this.state.lastStepCompleted
                                            }>
                                            Següent
                                            <KeyboardArrowRight />
                                        </Button>
                                    }
                                    backButton={
                                        <Button
                                            size="small"
                                            onClick={this.handleBack}
                                            disabled={this.state.activeStep === 0}>
                                            <KeyboardArrowLeft />
                                            Anterior
                                        </Button>
                                    }
                                />
                            </div>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4}>
                        <Paper className={classes.paperRoot}>
                            <div className={classes.cardHeader}>
                                <div className={classes.cardTitleContainer}>
                                    <SocialWorkerIcon color="primary" style={{ fontSize: 28 }} />
                                    <Typography className={classes.title} variant="h6" color="primary">
                                        Treballador assignat
                                    </Typography>
                                </div>
                                <Tooltip title="Editar">
                                    {this.state.showEditSocialWorker ? (
                                        <IconButton
                                            aria-label="Editar"
                                            className={classes.actions}
                                            onClick={() => this.setState({ showEditSocialWorker: false })}>
                                            <CloseIcon />
                                        </IconButton>
                                    ) : (
                                        <IconButton
                                            aria-label="Editar"
                                            className={classes.actions}
                                            onClick={() => this.setState({ showEditSocialWorker: true })}>
                                            <EditIcon />
                                        </IconButton>
                                    )}
                                </Tooltip>
                            </div>
                            <Divider />
                            {this.state.showEditSocialWorker ? (
                                this.renderEditSocialWorker()
                            ) : (
                                <div>
                                    <div className={classes.cardContainer}>
                                        <Typography className={classes.labelTitle} variant="subtitle2">
                                            Nom del treballador:
                                        </Typography>
                                        <Typography className={classes.label} variant="h6">
                                            {patient.socialWorkerName}
                                        </Typography>
                                    </div>
                                </div>
                            )}
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4}>
                        <Paper className={classes.paperRoot}>
                            <div className={classes.cardHeader}>
                                <div className={classes.cardTitleContainer}>
                                    <HistoryIcon color="primary" style={{ fontSize: 28 }} />
                                    <Typography className={classes.title} variant="h6" color="primary">
                                        Historial de visites
                                        {/* TODO Implementar mejor el listado de visitas */}
                                    </Typography>
                                </div>
                                <Typography variant="subtitle1" color="textSecondary">
                                    {patient.appointments.length +
                                        ' ' +
                                        (patient.appointments.length !== 1 ? 'visites' : 'visita')}
                                </Typography>
                            </div>
                            <Divider />
                            <List className={classes.list}>
                                {patient.appointments.map((item) => {
                                    return (
                                        <ListItem divider button onClick={() => this.goToAppointment(item.id)}>
                                            <div className={classes.listItem}>
                                                <Typography variant="subtitle1" color="textPrimary">
                                                    {item.confirmed ? 'Visita confirmada' : 'No confirmada'}
                                                </Typography>
                                                <Typography
                                                    className={classes.listItemDate}
                                                    variant="subtitle2"
                                                    color="textSecondary">
                                                    {item.proposedDate !== null && item.proposedHour
                                                        ? moment(parseInt(item.proposedDate)).format('DD/MM/YYYY') +
                                                          ' - ' +
                                                          item.proposedHour
                                                        : '-'}
                                                </Typography>
                                            </div>
                                        </ListItem>
                                    )
                                })}
                            </List>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4}>
                        <Paper className={classes.paperRoot}>
                            <div className={classes.cardHeader}>
                                <div className={classes.cardTitleContainer}>
                                    <WarningIcon color="primary" style={{ fontSize: 28 }} />
                                    <Typography className={classes.title} variant="h6" color="primary">
                                        Incidències
                                    </Typography>
                                </div>
                                <Typography variant="subtitle1" color="textSecondary">
                                    {patient.incidences.length +
                                        ' ' +
                                        (patient.incidences.length !== 1 ? 'incidencies' : 'incidencia')}
                                </Typography>
                            </div>
                            <Divider />
                            <List className={classes.list}>
                                {patient.incidences.map((item) => {
                                    return (
                                        <ListItem divider button onClick={() => this.goToIncidences(item.id)}>
                                            <div className={classes.listItem}>
                                                <Typography variant="subtitle1" color="textPrimary">
                                                    {item.title}
                                                </Typography>
                                                <Typography
                                                    className={classes.listItemDate}
                                                    variant="subtitle2"
                                                    color="textSecondary">
                                                    {moment(parseInt(item.createdAt)).format('DD/MM/YYYY - HH:mm')}
                                                </Typography>
                                            </div>
                                        </ListItem>
                                    )
                                })}
                            </List>
                        </Paper>
                    </Grid>
                </Grid>
                {this.renderRemoveDialogAndSnackbar()}
            </div>
        )
    }
}

PatientDetail.propTypes = {
    patientId: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
}

PatientDetail.defaultProps = {}

export default compose(withApollo, withStyles(styles))(PatientDetail)
