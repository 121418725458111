import React from 'react'
import PropTypes from 'prop-types'
import compose from '../../../utils/functions'

// Components imports
import VisitRequestStepperPDI from '../../../components/visit_request_stepper_pdi/index'
import FeedbackSnackbar from '../../../components/feedback_snackbar/index'

// Design imports
import { withStyles } from '@material-ui/core/styles'

class NewAppointmentPDI extends React.Component {
    state = {
        confirmSnackbarOpened: false,
    }

    handleClose = () => {
        this.setState({ confirmSnackbarOpened: false })
    }

    handleAppointmentRequest = () => {
        this.setState({ confirmSnackbarOpened: true })
        setTimeout(() => {
            this.props.history.goBack()
        }, 3000)
    }

    render() {
        const { classes } = this.props

        const patient = this.props.location.state !== null ? this.props.location.state : null

        return (
            <div className={classes.root}>
                <VisitRequestStepperPDI
                    handleConfirm={() => this.handleAppointmentRequest()}
                    selectedPatient={patient}
                />
                {/*<FeedbackMessage />*/}
                <FeedbackSnackbar
                    showSnackbar={this.state.confirmSnackbarOpened}
                    handleClose={() => this.handleClose()}
                    timeShowed={3000}
                    text={"Visita proposada, aquesta visita s'ha confirmat automàticament"}
                />
            </div>
        )
    }
}

const styles = (theme) => ({
    root: {
        display: 'flex',
        flex: 1,
    },
})

NewAppointmentPDI.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default compose(withStyles(styles))(NewAppointmentPDI)
