const styles = (theme) => ({
    root: {
        display: 'flex',
    },
    paperRoot: {},
    cardHeader: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        padding: theme.spacing.unit * 3,
        justifyContent: 'space-between',
        maxHeight: '100px',
    },
    cardTitleContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
    cardContainer: {
        padding: theme.spacing.unit * 3,
    },
    cardFooter: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        padding: theme.spacing.unit * 3,
        justifyContent: 'flex-end',
        minHeight: '80px',
    },
    cardFooterLeftAndRight: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        padding: theme.spacing.unit * 3,
        justifyContent: 'space-between',
        minHeight: '80px',
    },
    title: {
        paddingLeft: '8px',
    },
    labelTitle: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
        color: theme.palette.primary.light,
    },
    label: {
        fontWeight: 'normal',
    },
    rightIcon: {
        marginRight: theme.spacing.unit,
    },
    sendButton: {
        height: '100%',
    },
    actionButton: {
        //width: '200px',
        marginLeft: '8px',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginTop: 8,
            marginBottom: 8,
        },
    },
    textField: {
        marginTop: 16,
        marginBottom: 16,
    },
})

export default styles
