import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import compose from '../../../utils/functions'

// Date managment
import MomentUtils from '@date-io/moment'
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers'
import moment from 'moment'

// Apollo client
import { withApollo } from '@apollo/client/react/hoc'
import { getEntityById, grantAccess, revokeAccess, updateEntity } from '../../../grapql/entity'

// Design imports
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Paper,
    Snackbar,
    SnackbarContent,
    TextField,
    Tooltip,
    Typography,
    withWidth,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/Edit'
import EntitiesIcon from '@material-ui/icons/AccountBalance'
import CloseIcon from '@material-ui/icons/Close'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import green from '@material-ui/core/colors/green'
import WarningIcon from '@material-ui/icons/Warning'

class EntityDetail extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            entity: {},
            revokeDialogOpened: false,
            confirmedRevokeSnackbarOpened: false,
            showEditEntity: false,
            name: '',
            startDate: '',
            endDate: '',
            contactPerson: '',
            phone: '',
            email: '',
            nameError: false,
            startDateError: false,
            endDateError: false,
            contactPersonError: false,
            phoneError: false,
            emailError: false,
        }
    }

    fetchEntityData = () => {
        this.props.client.query(getEntityById(this.props.match.params.id)).then((result) => {
            const entity = Object.assign({}, result.data.entity)
            const startDateMoment = moment(parseInt(entity.startAgreementDate))
            const endDateMoment = moment(parseInt(entity.endAgreementDate))

            entity.startAgreementDate = startDateMoment.format('DD/MM/YYYY')
            entity.endAgreementDate = endDateMoment.format('DD/MM/YYYY')

            this.setState({
                entity,
                name: entity.name?.toUpperCase(),
                startDate: startDateMoment,
                endDate: endDateMoment,
                contactPerson: entity.contactPerson?.toUpperCase(),
                phone: entity.phone,
                email: entity.email?.toUpperCase(),
            })
        })
    }

    componentDidMount() {
        this.fetchEntityData()
    }

    validateData = () => {
        var dataIsValid = true

        var errors = {
            nameError: false,
            startDateError: false,
            endDateError: false,
            contactPersonError: false,
            phoneError: false,
            // emailError: false,
        }

        if (this.state.name === '') {
            errors.nameError = true
            dataIsValid = false
        }
        if (this.state.startDate === '') {
            errors.startDateError = true
            dataIsValid = false
        }
        if (this.state.endDate === '') {
            errors.endDateError = true
            dataIsValid = false
        }
        if (this.state.contactPerson === '') {
            errors.contactPersonError = true
            dataIsValid = false
        }
        if (this.state.phone === '') {
            errors.phoneError = true
            dataIsValid = false
        }
        /*if (this.state.email === '') {
            errors.emailError = true;
            dataIsValid = false;
        }*/

        this.setState(errors)

        return dataIsValid
    }

    updateEntity = () => {
        const { name, startDate, endDate, contactPerson, phone, email } = this.state

        const unixStartDate = moment(startDate, 'DD/MM/YYYY').valueOf().toString()
        const unixEndDate = moment(endDate, 'DD/MM/YYYY').valueOf().toString()

        if (this.validateData()) {
            this.props.client
                .mutate(
                    updateEntity(
                        this.props.match.params.id,
                        name,
                        contactPerson,
                        email,
                        phone,
                        unixStartDate,
                        unixEndDate,
                    ),
                )
                .then((result) => this.fetchEntityData())
            this.setState({ showEditEntity: false })
        }
    }

    handleValidateEntity = () => {
        this.props.client.mutate(grantAccess(this.props.match.params.id)).then((result) => this.fetchEntityData())
    }

    handleChange = (name) => (event) => {
        this.setState({
            [name]: event.target.value,
        })
    }

    handleRevokeButtonClicked = () => {
        this.setState({ revokeDialogOpened: true })
    }

    handleClose = () => {
        this.setState({
            revokeDialogOpened: false,
            confirmedRevokeSnackbarOpened: false,
        })
    }

    handleConfirmButtonClick = () => {
        this.props.client.mutate(revokeAccess(this.props.match.params.id)).then((result) => {
            this.setState({ revokeDialogOpened: false, confirmedRevokeSnackbarOpened: true })
            this.fetchEntityData()
        })
    }

    renderRevokeDialogAndSnackbar = () => {
        const { classes } = this.props
        const { user } = this.state

        return (
            <div>
                <Dialog
                    open={this.state.revokeDialogOpened}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">
                        {"Està segur que revocar l'accés a aquesta entitat?"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Un cop revocat l'accés a l'entitat, tots els usuaris d'aquesta no podran accedir a CUV
                            Social ni veure cap data del sistema.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="secondary">
                            No
                        </Button>
                        <Button onClick={this.handleConfirmButtonClick} color="primary" autoFocus>
                            Sí, revocar accés
                        </Button>
                    </DialogActions>
                </Dialog>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={this.state.confirmedRevokeSnackbarOpened}
                    autoHideDuration={1500}
                    onClose={this.handleClose}>
                    <SnackbarContent
                        className={classes.success}
                        aria-describedby="message-id"
                        message={
                            <span id="client-snackbar" className={classes.message}>
                                <CheckCircleIcon className={classNames(classes.icon, classes.iconVariant)} />
                                Accés revocat correctament
                            </span>
                        }
                        action={[
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                className={classes.close}
                                onClick={this.handleClose}>
                                <CloseIcon />
                            </IconButton>,
                        ]}></SnackbarContent>
                </Snackbar>
            </div>
        )
    }

    renderEditEntity = () => {
        const { classes } = this.props

        return (
            <div>
                <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                    <Grid className={classes.cardContainer} container spacing={16}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                error={this.state.nameError}
                                id="supplier-name"
                                label="Nom"
                                name="name"
                                type="text"
                                className={classes.textField}
                                value={this.state.name}
                                onChange={this.handleChange('name')}
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}></Grid>
                        <Grid item xs={12} sm={6}>
                            <DatePicker
                                autoOk
                                fullWidth
                                keyboard
                                placeholder={'DD/MM/YYYY'}
                                mask={(value) =>
                                    value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : []
                                }
                                error={this.state.startDateError}
                                label="Data inici del conveni:"
                                format={'DD/MM/YYYY'}
                                value={this.state.startDate}
                                className={classes.textField}
                                onChange={(date) => this.setState({ startDate: date })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DatePicker
                                autoOk
                                fullWidth
                                keyboard
                                placeholder={'DD/MM/YYYY'}
                                mask={(value) =>
                                    value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : []
                                }
                                error={this.state.endDateError}
                                label="Data final del conveni:"
                                format={'DD/MM/YYYY'}
                                value={this.state.endDate}
                                className={classes.textField}
                                onChange={(date) => this.setState({ endDate: date })}
                            />
                        </Grid>
                    </Grid>
                    <Divider />
                    <Grid className={classes.cardContainer} container spacing={16}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                error={this.state.contactPersonError}
                                id="supplier-contactPerson"
                                label="Persona de contacte"
                                name="contactPerson"
                                type="text"
                                className={classes.textField}
                                value={this.state.contactPerson}
                                onChange={this.handleChange('contactPerson')}
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}></Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                error={this.state.phoneError}
                                id="supplier-phone"
                                label="Telèfon"
                                name="phone"
                                type="text"
                                className={classes.textField}
                                value={this.state.phone}
                                onChange={this.handleChange('phone')}
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                error={this.state.emailError}
                                id="supplier-email"
                                label="Email"
                                name="email"
                                type="text"
                                className={classes.textField}
                                value={this.state.email}
                                onChange={this.handleChange('email')}
                                margin="normal"
                            />
                        </Grid>
                    </Grid>
                    <Divider />
                    <div className={classes.cardFooter}>
                        <Button
                            variant="outlined"
                            color="secondary"
                            size="large"
                            className={classes.actionButton}
                            onClick={() => this.setState({ showEditSupplier: false })}>
                            Cancel·lar
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            className={classes.actionButton}
                            onClick={() => this.updateEntity()}>
                            Desar els canvis
                        </Button>
                    </div>
                </MuiPickersUtilsProvider>
            </div>
        )
    }

    render() {
        const { classes } = this.props
        const { entity } = this.state

        return (
            <div className={classes.root}>
                <Grid container spacing={24}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Paper className={classes.paperRoot}>
                            <div className={classes.cardHeader}>
                                <div className={classes.cardTitleContainer}>
                                    <EntitiesIcon color="primary" style={{ fontSize: 28 }} />
                                    <Typography className={classes.title} variant="h6" color="primary">
                                        Informació de la entitat
                                    </Typography>
                                </div>
                                <Tooltip title="Editar">
                                    {this.state.showEditEntity ? (
                                        <IconButton
                                            aria-label="Editar"
                                            className={classes.actions}
                                            onClick={() => this.setState({ showEditEntity: false })}>
                                            <CloseIcon />
                                        </IconButton>
                                    ) : (
                                        <IconButton
                                            aria-label="Editar"
                                            className={classes.actions}
                                            onClick={() => this.setState({ showEditEntity: true })}>
                                            <EditIcon />
                                        </IconButton>
                                    )}
                                </Tooltip>
                            </div>
                            <Divider />
                            {this.state.showEditEntity ? (
                                this.renderEditEntity()
                            ) : (
                                <div>
                                    <Grid className={classes.cardContainer} container spacing={16}>
                                        <Grid item xs={12} sm={12}>
                                            <Typography className={classes.labelTitle} variant="subtitle2">
                                                Nom:
                                            </Typography>
                                            <Typography className={classes.label} variant="h6">
                                                {entity.name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography className={classes.labelTitle} variant="subtitle2">
                                                Data inici del conveni:
                                            </Typography>
                                            <Typography className={classes.label} variant="h6">
                                                {entity.startAgreementDate}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography className={classes.labelTitle} variant="subtitle2">
                                                Data final del conveni:
                                            </Typography>
                                            <Typography className={classes.label} variant="h6">
                                                {entity.endAgreementDate}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                    <Grid className={classes.cardContainer} container spacing={16}>
                                        <Grid item xs={12} sm={12}>
                                            <Typography className={classes.labelTitle} variant="subtitle2">
                                                Persona de contacte:
                                            </Typography>
                                            <Typography className={classes.label} variant="h6">
                                                {entity.contactPerson}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography className={classes.labelTitle} variant="subtitle2">
                                                Telèfon:
                                            </Typography>
                                            <a className={classes.link} href={'tel:' + entity.phone}>
                                                <Typography className={classes.label} variant="h6">
                                                    {entity.phone}
                                                </Typography>
                                            </a>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography className={classes.labelTitle} variant="subtitle2">
                                                Email:
                                            </Typography>
                                            <a className={classes.link} href={'mailto:' + entity.email}>
                                                <Typography className={classes.label} variant="h6">
                                                    {entity.email}
                                                </Typography>
                                            </a>
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                    <Grid className={classes.cardContainer} container spacing={16}>
                                        <Grid item xs={12} sm={6}>
                                            <Typography className={classes.labelTitle} variant="subtitle2">
                                                Núm. de treballadors assignats:
                                            </Typography>
                                            <Typography className={classes.label} variant="h6">
                                                {entity.numberOfWorkers}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography className={classes.labelTitle} variant="subtitle2">
                                                Núm. de pacients:
                                            </Typography>
                                            <Typography className={classes.label} variant="h6">
                                                {entity.numberOfPatients}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                    <div className={classes.cardFooter}>
                                        {entity.validated ? (
                                            <Button
                                                variant="outlined"
                                                color="secondary"
                                                size="large"
                                                className={classes.actionButton}
                                                onClick={() => this.handleRevokeButtonClicked()}>
                                                Revocar l'accés
                                            </Button>
                                        ) : (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                                className={classes.actionButton}
                                                onClick={() => this.handleValidateEntity()}>
                                                Validar entitat
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            )}
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Paper>
                            <div className={classes.cardHeader}>
                                <div className={classes.cardTitleContainer}>
                                    <WarningIcon color="secondary" style={{ fontSize: 28 }} />
                                    <Typography className={classes.title} variant="h6" color="secondary">
                                        Avis sobre registre de treballadors socials
                                    </Typography>
                                </div>
                            </div>
                            <Divider />
                            <div className={classes.cardContainer}>
                                <Typography variant="subtitle1" color="textPrimary">
                                    Els treballadors socials d'aquesta entitat podran registrar-se mitjançant el següent
                                    enllaç. Si us plau, proporcioneu aquest enllaç a l'entitat per tal que aquesta ho
                                    proporcioni als seus treballadors.
                                </Typography>
                                {/*<a className={classes.link} href={"http://devcuvsocial.com/signup/workers/" + this.props.match.params.id}>*/}
                                <Typography
                                    align={'center'}
                                    className={classes.singupLink}
                                    variant="subtitle1"
                                    color="primary">
                                    {'https://cuvsocial.upc.edu/signup/workers/' + this.props.match.params.id}
                                </Typography>
                                {/*</a>*/}
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
                {this.renderRevokeDialogAndSnackbar()}
            </div>
        )
    }
}

const styles = (theme) => ({
    root: {
        display: 'flex',
        flex: 1,
    },
    paperRoot: {},
    cardHeader: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        padding: theme.spacing.unit * 3,
        justifyContent: 'space-between',
        height: '80px',
    },
    cardFooter: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        padding: theme.spacing.unit * 3,
        justifyContent: 'flex-end',
        height: '80px',
    },
    cardTitleContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
    title: {
        paddingLeft: '8px',
    },
    cardContainer: {
        padding: theme.spacing.unit * 3,
    },
    labelTitle: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
        color: theme.palette.primary.light,
    },
    label: {
        fontWeight: 'normal',
    },
    actions: {
        //color: theme.palette.text.secondary,
        marginRight: -theme.spacing.unit * 2,
    },
    actionButton: {
        //width: '200px',
        marginLeft: '8px',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginTop: 8,
            marginBottom: 8,
        },
    },
    success: {
        backgroundColor: green[600],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing.unit,
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    link: {
        textDecoration: 'none',
    },
    singupLink: {
        marginTop: 20,
        //fontWeight: 'bold',
        fontSize: 16,
    },
})

EntityDetail.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default compose(withWidth(), withApollo, withStyles(styles))(EntityDetail)
