import React from 'react'
import PropTypes from 'prop-types'

// Components imports
import VisitRequestStepper from '../../../components/visit_request_stepper/index'
import FeedbackSnackbar from '../../../components/feedback_snackbar/index'

// Design imports
import { withStyles } from '@material-ui/core/styles'
import styles from './styles'

class AppointmentRequest extends React.Component {
    state = {
        confirmSnackbarOpened: false,
    }

    handleClose = () => {
        this.setState({ confirmSnackbarOpened: false })
    }

    handleAppointmentRequest = () => {
        this.setState({ confirmSnackbarOpened: true })
        setTimeout(() => {
            if (this.props.location.state !== null && this.props.location.state !== undefined) {
                if (this.props.location.state.prevPath === '/appointment/:id') {
                    this.props.history.push('/appointments')
                }
            } else {
                this.props.history.goBack()
            }
        }, 3000)
    }

    render() {
        const { classes } = this.props
        const patient = this.props.location.state !== null ? this.props.location.state : null

        return (
            <div className={classes.root}>
                <VisitRequestStepper handleConfirm={() => this.handleAppointmentRequest()} selectedPatient={patient} />
                <FeedbackSnackbar
                    showSnackbar={this.state.confirmSnackbarOpened}
                    handleClose={() => this.handleClose()}
                    timeShowed={3000}
                    text={
                        'Petició cursada correctament, aviat el CUV us proposarà una hora a través aquest programa o via telefònica que haureu de confirmar'
                    }
                />
            </div>
        )
    }
}

AppointmentRequest.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(AppointmentRequest)
