import React from 'react'
import PropTypes from 'prop-types'
import compose from '../../../utils/functions'
import { DEFAULT_ROWS_PER_PAGE } from '../../../constants'

// Apollo client
import { withApollo } from '@apollo/client/react/hoc'
import { getEntities, grantAccessBulk } from '../../../grapql/entity'

// Components imports
import ManagementTableWrapper from '../../../components/management_table_wrapper'

// Design imports
import { Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

// Constants
const columns = [
    { id: 'name', numeric: false, disablePadding: false, label: 'Nom' },
    { id: 'contactPerson', numeric: false, disablePadding: false, label: 'Persona de contacte' },
    { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
    { id: 'phone', numeric: false, disablePadding: false, label: 'Telèfon' },
    { id: 'startAgreementDate', numeric: false, date: true, disablePadding: false, label: 'Data inici del conveni' },
    { id: 'endAgreementDate', numeric: false, date: true, disablePadding: false, label: 'Data final del conveni' },
    { id: 'validated', numeric: false, disablePadding: false, label: 'Validat' },
    /*{ id: 'numberOfWorkers', numeric: false, disablePadding: false, label: 'Nº treballadors' },
    { id: 'numberOfPatients', numeric: false, disablePadding: false, label: 'Nº pacients' },*/
]

const filterOptions = [
    {
        columnId: 'validated',
        label: 'Estat de validació',
        options: [
            {
                value: true,
                label: 'Validat',
            },
            {
                value: false,
                label: 'No validat',
            },
        ],
    },
]

class Entities extends React.Component {
    state = {
        data: [],
        totalCount: 0,
        rowsPerPages: DEFAULT_ROWS_PER_PAGE,
    }

    parseData = (entitiesFromAPI) => {
        var entities = []

        entitiesFromAPI.forEach((entity) => {
            entities.push({
                id: entity.id,
                name: entity.name?.toUpperCase(),
                contactPerson: entity.contactPerson?.toUpperCase(),
                email: entity.email?.toUpperCase(),
                phone: entity.phone,
                startAgreementDate: entity.startAgreementDate,
                endAgreementDate: entity.endAgreementDate,
                validated: entity.validated ? '' : 'No validat',
            })
        })

        this.setState({ data: entities })
    }

    componentDidMount() {
        this.fetchData(0, DEFAULT_ROWS_PER_PAGE)
    }

    fetchData = (offset, limit, orderBy, orderDirection, searchText, filterText) => {
        this.props.client
            .query(getEntities(offset, limit, orderBy, orderDirection, searchText, filterText))
            .then((result) => {
                this.parseData(result.data.allEntities.entities)
                this.setState({ totalCount: result.data.allEntities.totalCount })
            })
    }

    goToNewEntity = () => {
        this.props.history.push('/new-entity')
    }

    handleClick = (id) => {
        this.props.history.push('/entity/' + id)
    }

    onValidateClicked = (selected, fetchData) => {
        this.props.client.mutate(grantAccessBulk(selected)).then((result) => fetchData())
    }

    render() {
        const { classes } = this.props
        const { data, totalCount } = this.state

        const actionButtons = (
            <div>
                <Button variant="outlined" color="primary" onClick={() => this.goToNewEntity()}>
                    Nova entitat
                </Button>
            </div>
        )

        return (
            <ManagementTableWrapper
                title="Llistat d'entitats"
                rows={data}
                columns={columns}
                filterOptions={filterOptions}
                actionButtons={actionButtons}
                onRowClick={(id) => this.handleClick(id)}
                totalCount={totalCount}
                fetchData={this.fetchData}
                onValidateClicked={(selected, fetchData) => this.onValidateClicked(selected, fetchData)}
            />
        )
    }
}

const styles = (theme) => ({})

Entities.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default compose(withApollo, withStyles(styles))(Entities)
