import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import compose from '../../../utils/functions'

// Apollo client
import { withApollo } from '@apollo/client/react/hoc'
import { deleteSubjectById, getSubjectDetailById, updateSubject } from '../../../grapql/subject'

// Design imports
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    Paper,
    Snackbar,
    SnackbarContent,
    TextField,
    Tooltip,
    Typography,
    withWidth,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/Edit'
import SubjectsIcon from '@material-ui/icons/Class'
import CloseIcon from '@material-ui/icons/Close'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import green from '@material-ui/core/colors/green'

class SubjectDetail extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            subject: {},
            deleteDialogOpened: false,
            confirmedDeleteSnackbarOpened: false,
            showEditUser: false,
            subjectName: '',
            subjectNameError: '',
            subjectEvaluable: false,
        }
    }

    fetchSubjectDetails = () => {
        this.props.client.query(getSubjectDetailById(this.props.match.params.id)).then((result) =>
            this.setState({
                subject: result.data.subject,
                subjectName: result.data.subject?.name?.toUpperCase(),
                subjectEvaluable: result.data.subject.evaluable,
            }),
        )
    }

    componentDidMount() {
        this.fetchSubjectDetails()
    }

    validateData = () => {
        var dataIsValid = true

        var errors = {
            subjectNameError: false,
        }
        if (this.state.subjectName === '') {
            errors.subjectNameError = true
            dataIsValid = false
        }

        this.setState(errors)

        return dataIsValid
    }

    updateSubject = () => {
        if (this.validateData()) {
            this.props.client
                .mutate(updateSubject(this.props.match.params.id, this.state.subjectName, this.state.subjectEvaluable))
                .then((result) => this.fetchSubjectDetails())
            this.setState({ showEditUser: false })
        }
    }

    handleChange = (name) => (event) => {
        this.setState({
            [name]: event.target.value,
        })
    }

    handleDeleteButtonClicked = () => {
        this.setState({ deleteDialogOpened: true })
    }

    handleClose = () => {
        this.setState({
            deleteDialogOpened: false,
            confirmedDeleteSnackbarOpened: false,
        })
    }

    handleConfirmButtonClick = () => {
        const mutation = deleteSubjectById(this.props.match.params.id)
        this.props.client.mutate(mutation).then((result) => {
            this.setState({ deleteDialogOpened: false, confirmedDeleteSnackbarOpened: true })
            setTimeout(() => {
                this.props.history.goBack()
            }, 3000)
        })
    }

    handleChecboxChange = (name) => (event) => {
        this.setState({ [name]: event.target.checked })
    }

    renderRevokeDialogAndSnackbar = () => {
        const { classes } = this.props
        const { user } = this.state

        return (
            <div>
                <Dialog
                    open={this.state.deleteDialogOpened}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">
                        {'Està segur que vol eliminar aquesta assignatura?'}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Un cop eliminada la assignatura no es podra recuperar.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="secondary">
                            No
                        </Button>
                        <Button onClick={this.handleConfirmButtonClick} color="primary" autoFocus>
                            Sí, eliminar assignatura
                        </Button>
                    </DialogActions>
                </Dialog>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={this.state.confirmedDeleteSnackbarOpened}
                    autoHideDuration={1500}
                    onClose={this.handleClose}>
                    <SnackbarContent
                        className={classes.success}
                        aria-describedby="message-id"
                        message={
                            <span id="client-snackbar" className={classes.message}>
                                <CheckCircleIcon className={classNames(classes.icon, classes.iconVariant)} />
                                Assignatura eliminada correctament
                            </span>
                        }
                        action={[
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                className={classes.close}
                                onClick={this.handleClose}>
                                <CloseIcon />
                            </IconButton>,
                        ]}></SnackbarContent>
                </Snackbar>
            </div>
        )
    }

    renderEditSubject = () => {
        const { classes } = this.props

        return (
            <div>
                <Grid className={classes.cardContainer} container spacing={16}>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            required
                            fullWidth
                            error={this.state.subjectNameError}
                            id="subject-name"
                            label="Nom"
                            name="name"
                            type="text"
                            className={classes.textField}
                            value={this.state.subjectName}
                            onChange={this.handleChange('subjectName')}
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.subjectEvaluable}
                                    onChange={this.handleChecboxChange('subjectEvaluable')}
                                    value="subjectEvaluable"
                                    color="primary"
                                />
                            }
                            label="Avaluable"
                        />
                    </Grid>
                </Grid>
                <Divider />
                <div className={classes.cardFooter}>
                    <Button
                        variant="outlined"
                        color="secondary"
                        size="large"
                        className={classes.actionButton}
                        onClick={() => this.setState({ showEditUser: false })}>
                        Cancel·lar
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        className={classes.actionButton}
                        onClick={() => this.updateSubject()}>
                        Desar els canvis
                    </Button>
                </div>
            </div>
        )
    }

    render() {
        const { classes } = this.props
        const { subject } = this.state

        return (
            <div className={classes.root}>
                <Grid container spacing={24}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Paper className={classes.paperRoot}>
                            <div className={classes.cardHeader}>
                                <div className={classes.cardTitleContainer}>
                                    <SubjectsIcon color="primary" style={{ fontSize: 28 }} />
                                    <Typography className={classes.title} variant="h6" color="primary">
                                        Informació de la assignatura
                                    </Typography>
                                </div>
                                <Tooltip title="Editar">
                                    {this.state.showEditUser ? (
                                        <IconButton
                                            aria-label="Editar"
                                            className={classes.actions}
                                            onClick={() => this.setState({ showEditUser: false })}>
                                            <CloseIcon />
                                        </IconButton>
                                    ) : (
                                        <IconButton
                                            aria-label="Editar"
                                            className={classes.actions}
                                            onClick={() => this.setState({ showEditUser: true })}>
                                            <EditIcon />
                                        </IconButton>
                                    )}
                                </Tooltip>
                            </div>
                            <Divider />
                            {this.state.showEditUser ? (
                                this.renderEditSubject()
                            ) : (
                                <div>
                                    <Grid className={classes.cardContainer} container spacing={16}>
                                        <Grid item xs={12} sm={6}>
                                            <Typography className={classes.labelTitle} variant="subtitle2">
                                                Nom:
                                            </Typography>
                                            <Typography className={classes.label} variant="h6">
                                                {this.state.subjectName}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography className={classes.labelTitle} variant="subtitle2">
                                                És avaluable:
                                            </Typography>
                                            <Typography className={classes.label} variant="h6">
                                                {subject.evaluable ? 'Sí' : 'No'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography className={classes.labelTitle} variant="subtitle2">
                                                Nº d'estudiants:
                                            </Typography>
                                            <Typography className={classes.label} variant="h6">
                                                {subject.numberOfStudents}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                    <div className={classes.cardFooter}>
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            size="large"
                                            className={classes.actionButton}
                                            onClick={() => this.handleDeleteButtonClicked()}>
                                            Eliminar assignatura
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </Paper>
                    </Grid>
                </Grid>
                {this.renderRevokeDialogAndSnackbar()}
            </div>
        )
    }
}

const styles = (theme) => ({
    root: {
        display: 'flex',
        flex: 1,
    },
    paperRoot: {},
    cardHeader: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        padding: theme.spacing.unit * 3,
        justifyContent: 'space-between',
        height: '80px',
    },
    cardFooter: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        padding: theme.spacing.unit * 3,
        justifyContent: 'flex-end',
        height: '80px',
    },
    cardTitleContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
    title: {
        paddingLeft: '8px',
    },
    cardContainer: {
        padding: theme.spacing.unit * 3,
    },
    labelTitle: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
        color: theme.palette.primary.light,
    },
    label: {
        fontWeight: 'normal',
    },
    actions: {
        //color: theme.palette.text.secondary,
        marginRight: -theme.spacing.unit * 2,
    },
    actionButton: {
        //width: '200px',
        marginLeft: '8px',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginTop: 8,
            marginBottom: 8,
        },
    },
    success: {
        backgroundColor: green[600],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing.unit,
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    link: {
        textDecoration: 'none',
    },
})

SubjectDetail.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default compose(withWidth(), withApollo, withStyles(styles))(SubjectDetail)
