import React from 'react'
import compose from '../../../utils/functions'

// Apollo client
import { withApollo } from '@apollo/client/react/hoc'
import { getAssembliesForDelivery, getAssembliesForValuation } from '../../../grapql/assembly'

// Design imports
import { Button, Divider, Grid, List, ListItem, Paper, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import InboxIcon from '@material-ui/icons/AllInbox'
import ArrowForward from '@material-ui/icons/KeyboardArrowRight'
import DeliveryIcon from '@material-ui/icons/ThumbUp'
import { ASSEMBLY_STATUS_API } from '../../../constants'
import styles from './styles'

class HomeTeacher extends React.Component {
    state = {
        assembliesForDelivery: [],
        valuationAssemblies: [],
    }

    parseDeliveryAssemblies = (assembliesFromAPI) => {
        var assemblies = []

        assembliesFromAPI.forEach((assembly) => {
            var status = ''
            switch (assembly.actualStep) {
                case ASSEMBLY_STATUS_API.APPOINTMENT:
                    status = 'Visita realitzada'
                    break
                case ASSEMBLY_STATUS_API.ORDER:
                    status = 'Comanda realitzada'
                    break
                case ASSEMBLY_STATUS_API.ASSEMBLY:
                    status = 'En muntatge'
                    break
                case ASSEMBLY_STATUS_API.REVISION:
                    status = 'En revisió'
                    break
                case ASSEMBLY_STATUS_API.VALUATION:
                    status = 'Avaluació'
                    break
                case ASSEMBLY_STATUS_API.DELIVERY:
                    status = 'Per entregar'
                    break
                case ASSEMBLY_STATUS_API.END:
                    status = 'Entregat'
                    break
                case ASSEMBLY_STATUS_API.CANCEL:
                    status = 'Cancel·lat'
                    break
            }

            if (assembly.canceled) status = 'Cancel·lat'

            assemblies.push({
                id: assembly.id,
                casnum: assembly.casnum,
                status: status,
            })
        })

        this.setState({ assembliesForDelivery: assemblies })
    }

    parseValuationAssemblies = (assembliesFromAPI) => {
        var assemblies = []
        assembliesFromAPI.forEach((assembly) => {
            assemblies.push({
                id: assembly.id,
                casnum: assembly.casnum,
                status: 'Per avaluar',
            })
        })

        this.setState({ valuationAssemblies: assemblies })
    }

    fetchData = () => {
        this.props.client
            .query(getAssembliesForDelivery(0, 10))
            .then((result) => this.parseDeliveryAssemblies(result.data.getAssembliesForDelivery.assemblies))
        this.props.client
            .query(getAssembliesForValuation(0, 10))
            .then((result) => this.parseValuationAssemblies(result.data.getAssembliesForValuation.assemblies))
    }

    componentDidMount() {
        this.fetchData()
    }

    goToAssembly = (id) => {
        this.props.history.push('/assembly/' + id)
    }

    goToAssemblies = () => {
        this.props.history.push('/assemblies')
    }

    renderListItem = (item, index) => {
        const { classes } = this.props

        return (
            <ListItem divider button className={classes.listItem} onClick={() => this.goToAssembly(item.id)}>
                <div className={classes.assemblyInfo}>
                    <Typography variant="h6" color="primary">
                        {item.casnum}
                    </Typography>
                    <Typography variant="body1" color="textPrimary" className={classes.assemblyStatus}>
                        {item.status}
                    </Typography>
                </div>
                <ArrowForward color="primary" />
            </ListItem>
        )
    }

    render() {
        const { classes } = this.props
        const { assembliesForDelivery, valuationAssemblies } = this.state

        return (
            <div className={classes.root}>
                <Grid container spacing={24}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <Paper>
                                    <div className={classes.cardHeader}>
                                        <div className={classes.cardTitleContainer}>
                                            <InboxIcon color="primary" style={{ fontSize: 28 }} />
                                            <Typography className={classes.title} variant="h6" color="primary">
                                                Tasques per avaluar:
                                            </Typography>
                                        </div>
                                    </div>
                                    <Divider />
                                    <List className={classes.list}>
                                        {valuationAssemblies.map((item, index) => this.renderListItem(item, index))}
                                        <ListItem>
                                            <Button fullWidth color="primary" onClick={() => this.goToAssemblies()}>
                                                Veure totes
                                            </Button>
                                        </ListItem>
                                    </List>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <Paper>
                                    <div className={classes.cardHeader}>
                                        <div className={classes.cardTitleContainer}>
                                            <DeliveryIcon color="primary" style={{ fontSize: 28 }} />
                                            <Typography className={classes.title} variant="h6" color="primary">
                                                Pendents d'entrega:
                                            </Typography>
                                        </div>
                                    </div>
                                    <Divider />
                                    <List className={classes.list}>
                                        {assembliesForDelivery.map((item, index) => this.renderListItem(item, index))}
                                        <ListItem>
                                            <Button fullWidth color="primary" onClick={() => this.goToAssemblies()}>
                                                Veure totes
                                            </Button>
                                        </ListItem>
                                    </List>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default compose(withApollo, withStyles(styles))(HomeTeacher)
