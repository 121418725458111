import gql from 'graphql-tag'

export const addMessage = (message, incidenceId) => {
    return {
        mutation: gql`
            mutation AddMessage($message: String, $incidenceId: ID) {
                addMessage(input: { message: $message, incidenceId: $incidenceId }) {
                    id
                }
            }
        `,
        variables: {
            message: message,
            incidenceId: incidenceId,
        },
    }
}
