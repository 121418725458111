import React from 'react'
import PropTypes from 'prop-types'
import compose from '../../utils/functions'

import { getValueFromStorage } from '../../utils'

// Date managment
import MomentUtils from '@date-io/moment'
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers'
import moment from 'moment'

// Apollo client
import { withApollo } from '@apollo/client/react/hoc'
import { getManagementStudents } from '../../grapql/students'
import {
    confirmDelivery,
    goToAssemblyStep,
    goToDeliveryStep,
    goToDeliveryStepWithoutEvaluation,
    goToOrderStep,
    goToRevisionStep,
    goToValuationStep,
} from '../../grapql/assembly'

// Design imports
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    OutlinedInput,
    Select,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { ROLE, ROLE_CAST } from '../../constants'
import amber from '@material-ui/core/colors/amber'
import { getSuppliersOnlyName } from '../../grapql/supplier'

const APPOINTMENT_TO_ORDER = 0
const ORDER_TO_ASSEMBLY = 1
const ASSEMBLY_TO_REVISION = 2
const REVISION_TO_VALUATION = 3
const VALUATION_TO_DELIVERY = 4
const DELIVERY_TO_END = 5

class UpdateAssemblyDialog extends React.Component {
    // TODO Donde se actualiza la fecha prevista de entrega?

    constructor(props) {
        super(props)

        const role = getValueFromStorage(ROLE)

        // Get the width for select patient selector label.
        this.state = {
            students: [],
            suppliers: [],
            supplier: '',
            arrivalDate: moment(),
            assemblyStudent: '',
            selfcorrectionNote: '1',
            cabinetStudentNote: 'Apte',
            finalNote: '-',
            finalValoration: '',
            deliveryStudent: '',
            supplierError: false,
            arrivalDateError: false,
            assemblyStudentError: false,
            selfcorrectionNoteError: false,
            cabinetStudentNoteError: false,
            finalNoteError: false,
            finalValorationError: false,
            deliveryStudentError: false,
            role: role,
            supplierLabelWidth: 80,
        }
    }

    /*componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.showDialog !== this.props.showDialog && this.props.showDialog === true) {
            if (this.props.actualStep === ORDER_TO_ASSEMBLY) {
                const studentInput = ReactDOM.findDOMNode(this.StudentInputLabelRef);
                if (studentInput !== null) {
                    this.setState({ studentLabelWidth: studentInput.offsetWidth })
                }
            }
        }
    }*/

    componentDidMount() {
        this.props.client
            .query(getSuppliersOnlyName())
            .then((result) => this.setState({ suppliers: result.data.allSuppliers.suppliers }))
        this.props.client
            .query(getManagementStudents())
            .then((result) => this.setState({ students: result.data.allManagementStudents }))
    }

    validateData = () => {
        var dataIsValid = true

        var errors = {
            arrivalDateError: false,
            supplierError: false,
            assemblyStudentError: false,
            selfcorrectionNoteError: false,
            finalNoteError: false,
            finalValorationError: false,
        }

        if (this.props.actualStep === ORDER_TO_ASSEMBLY) {
            if (this.state.arrivalDate === '') {
                errors.arrivalDateError = true
                dataIsValid = false
            }
            if (this.state.assemblyStudent === '') {
                errors.assemblyStudentError = true
                dataIsValid = false
            }
        } else if (this.props.actualStep === APPOINTMENT_TO_ORDER) {
            if (this.state.supplier === '') {
                errors.supplierError = true
                dataIsValid = false
            }
        } else if (this.props.actualStep === VALUATION_TO_DELIVERY) {
            if (this.state.finalValoration === '') {
                errors.finalValorationError = true
                dataIsValid = false
            }
        } /*else if (this.props.actualStep === DELIVERY_TO_END) {
            if (this.state.deliveryStudent === '' && this.state.role === ROLE_CAST.ADMIN) {
                errors.deliveryStudentError = true;
                dataIsValid = false;
            }
        }*/

        this.setState(errors)

        return dataIsValid
    }

    getMutation = () => {
        var mutation = null

        if (this.props.actualStep === APPOINTMENT_TO_ORDER) {
            mutation = goToOrderStep(this.props.assemblyId, this.state.supplier)
        } else if (this.props.actualStep === ORDER_TO_ASSEMBLY) {
            mutation = goToAssemblyStep(
                this.props.assemblyId,
                this.state.arrivalDate.valueOf().toString(),
                this.state.assemblyStudent,
            )
        } else if (this.props.actualStep === ASSEMBLY_TO_REVISION) {
            mutation = goToRevisionStep(this.props.assemblyId, this.state.selfcorrectionNote)
        } else if (this.props.actualStep === REVISION_TO_VALUATION && this.props.isEvaluable) {
            mutation = goToValuationStep(this.props.assemblyId, this.state.cabinetStudentNote)
        } else if (this.props.actualStep === REVISION_TO_VALUATION && this.props.isEvaluable === false) {
            mutation = goToDeliveryStepWithoutEvaluation(this.props.assemblyId, this.state.cabinetStudentNote)
        } else if (this.props.actualStep === VALUATION_TO_DELIVERY) {
            mutation = goToDeliveryStep(this.props.assemblyId, this.state.finalNote, this.state.finalValoration)
        } else if (this.props.actualStep === DELIVERY_TO_END) {
            mutation = confirmDelivery(this.props.assemblyId, this.state.deliveryStudent)
        }

        return mutation
    }

    handleConfirm = () => {
        if (this.validateData()) {
            const mutation = this.getMutation()

            if (mutation !== null) {
                this.props.client.mutate(mutation).then((result) => {
                    this.props.handleConfirm()
                })
            }
        }
    }

    handlePickerSelection = (name) => (event) => {
        this.setState({ [name]: event.target.value })
    }

    renderGoToOrderStep = () => {
        const { classes } = this.props

        return (
            <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                <DialogContent>
                    <FormControl fullWidth required variant="outlined" className={classes.textField}>
                        <InputLabel
                            ref={(ref) => {
                                this.SupplierInputLabelRef = ref
                            }}
                            htmlFor="assembly-supplier">
                            Proveïdor
                        </InputLabel>
                        <Select
                            native
                            error={this.state.supplierError}
                            value={this.state.supplier}
                            onChange={this.handlePickerSelection('supplier')}
                            input={
                                <OutlinedInput
                                    name="supplier"
                                    labelWidth={this.state.supplierLabelWidth}
                                    id="assembly-supplier"
                                />
                            }>
                            <option value="" />
                            {this.state.suppliers
                                ? this.state.suppliers.map((item, index) => {
                                      return <option value={item.id}>{item.name?.toUpperCase()}</option>
                                  })
                                : null}
                        </Select>
                    </FormControl>
                </DialogContent>
            </MuiPickersUtilsProvider>
        )
    }

    renderGoToAssemblyStep = () => {
        const { classes } = this.props

        return (
            <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                <DialogContent>
                    {/*<TextField
                        required
                        fullWidth
                        error={this.state.arrivalDateError}
                        id="arrival-date"
                        label="Data d'arribada"
                        name="arrival-date"
                        type="date"
                        value={this.state.arrivalDate}
                        onChange={(event) => this.setState({ arrivalDate: event.target.value })}
                        margin="normal"
                        variant="outlined"
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}/>*/}
                    <DatePicker
                        autoOk
                        fullWidth
                        keyboard
                        placeholder={'DD/MM/YYYY'}
                        mask={(value) => (value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : [])}
                        error={this.state.arrivalDateError}
                        label="Data d'arribada"
                        format={'DD/MM/YYYY'}
                        value={this.state.arrivalDate}
                        variant="outlined"
                        className={classes.textField}
                        onChange={(date) => this.setState({ arrivalDate: date })}
                    />
                    <FormControl fullWidth required variant="outlined" className={classes.textField}>
                        <InputLabel
                            ref={(ref) => {
                                this.StudentInputLabelRef = ref
                            }}
                            htmlFor="assembly-student">
                            Estudiant de muntatge
                        </InputLabel>
                        <Select
                            native
                            error={this.state.assemblyStudentError}
                            value={this.state.assemblyStudent}
                            onChange={this.handlePickerSelection('assemblyStudent')}
                            input={
                                <OutlinedInput
                                    name="supplier"
                                    /*labelWidth={this.state.studentLabelWidth}*/ id="assembly-student"
                                />
                            }>
                            <option value="" />
                            {this.state.students
                                ? this.state.students.map((item, index) => {
                                      return (
                                          <option value={item.id}>
                                              {item.user.name?.toUpperCase() + ' ' + item.user.lastName?.toUpperCase()}
                                          </option>
                                      )
                                  })
                                : null}
                        </Select>
                    </FormControl>
                </DialogContent>
            </MuiPickersUtilsProvider>
        )
    }

    renderGoToRevisionStep = () => {
        const { classes } = this.props

        return (
            <DialogContent>
                <FormControl fullWidth required variant="outlined" className={classes.textField}>
                    <InputLabel
                        ref={(ref) => {
                            this.autoevaluationNoteLabelRef = ref
                        }}
                        htmlFor="autoevaluationNoteRef">
                        Nota d'autoavaluació:
                    </InputLabel>
                    <Select
                        native
                        error={this.state.selfcorrectionNoteError}
                        value={this.state.selfcorrectionNote}
                        onChange={this.handlePickerSelection('selfcorrectionNote')}
                        input={
                            <OutlinedInput
                                name=""
                                /*labelWidth={this.state.studentLabelWidth}*/ id="autoevaluationNoteRef"
                            />
                        }>
                        <option value={'1'}>{'1'}</option>
                        <option value={'2'}>{'2'}</option>
                        <option value={'3'}>{'3'}</option>
                    </Select>
                </FormControl>
                {/*<TextField
                    required
                    fullWidth
                    error={this.state.selfcorrectionNoteError}
                    id="selfcorrectionNote"
                    label="Nota d'autoavaluació:"
                    name="selfcorrectionNote"
                    type="number"
                    placeholder='0'
                    value={this.state.selfcorrectionNote}
                    onChange={(event) => this.setState({ selfcorrectionNote: event.target.value })}
                    margin="normal"
                    variant="outlined"
                    className={classes.textField}
                    InputLabelProps={{
                        shrink: true,
                    }}/>*/}
            </DialogContent>
        )
    }

    renderGoToValuationStep = () => {
        const { classes } = this.props

        return (
            <DialogContent>
                {/*<FormControl fullWidth required variant="outlined" className={classes.textField}>
                    <InputLabel ref={ref => { this.cabinetStudentNoteInputLabelRef = ref}} htmlFor="cabinetStudentNote">
                        Nota revisió estudiant de gestió:
                    </InputLabel>
                    <Select
                        native
                        error={this.state.cabinetStudentNoteError}
                        value={this.state.cabinetStudentNote}
                        onChange={this.handlePickerSelection('cabinetStudentNote')}
                        input={ <OutlinedInput name="" id="cabinetStudentNote"/> }>
                        <option value=""/>
                        <option value={'Apte'}>{"Apte"}</option>
                        <option value={'No apte'}>{"No apte"}</option>
                    </Select>
                </FormControl>*/}
            </DialogContent>
        )
    }

    renderGoToDeliveryStep = () => {
        const { classes } = this.props

        return (
            <DialogContent>
                {/*<TextField
                    required
                    fullWidth
                    error={this.state.finalNoteError}
                    id="finalNote"
                    label="Nota d'avaluació:"
                    name="finalNote"
                    placeholder='0'
                    type="number"
                    value={this.state.finalNote}
                    onChange={(event) => this.setState({ finalNote: event.target.value })}
                    margin="normal"
                    variant="outlined"
                    className={classes.textField}
                    InputLabelProps={{
                        shrink: true,
                    }}/>*/}
                <FormControl fullWidth required variant="outlined" className={classes.textField}>
                    <InputLabel
                        ref={(ref) => {
                            this.ValorationInputLabelRef = ref
                        }}
                        htmlFor="assembly-student">
                        Valoració final
                    </InputLabel>
                    <Select
                        native
                        error={this.state.finalValorationError}
                        value={this.state.finalValoration}
                        onChange={this.handlePickerSelection('finalValoration')}
                        input={
                            <OutlinedInput
                                name="supplier"
                                /*labelWidth={this.state.studentLabelWidth}*/ id="assembly-student"
                            />
                        }>
                        <option value="" />
                        <option value={'Passa'}>{'Passa'}</option>
                        <option value={'No passa'}>{'No passa'}</option>
                    </Select>
                </FormControl>
            </DialogContent>
        )
    }

    renderGoToEndStep = () => {
        const { classes } = this.props

        var dialogContent = null
        if (this.state.role === ROLE_CAST.ADMIN || this.state.role === ROLE_CAST.MST) {
            dialogContent = (
                <FormControl fullWidth required variant="outlined" className={classes.textField}>
                    <InputLabel
                        ref={(ref) => {
                            this.StudentInputLabelRef = ref
                        }}
                        htmlFor="assembly-student">
                        Entregat per
                    </InputLabel>
                    <Select
                        native
                        error={this.state.deliveryStudentError}
                        value={this.state.deliveryStudent}
                        onChange={this.handlePickerSelection('deliveryStudent')}
                        input={
                            <OutlinedInput
                                name="supplier"
                                /*labelWidth={this.state.studentLabelWidth}*/ id="assembly-student"
                            />
                        }>
                        <option value="" />
                        {this.state.students
                            ? this.state.students.map((item, index) => {
                                  return (
                                      <option value={item.id}>
                                          {item.user.name?.toUpperCase() + ' ' + item.user.lastName?.toUpperCase()}
                                      </option>
                                  )
                              })
                            : null}
                    </Select>
                </FormControl>
            )
        } else {
            dialogContent = null
        }

        return <DialogContent>{dialogContent}</DialogContent>
    }

    renderStepTitle = () => {
        var title = 'Introdueixi les dades següents:'
        if (this.props.actualStep === DELIVERY_TO_END && this.state.role !== ROLE_CAST.ADMIN) {
            title = "Està segur que vol confirmar l'entrega d'aquest muntatge?"
        } else if (this.props.actualStep === REVISION_TO_VALUATION) {
            title = "Seleccioni si el muntatge és correcte o s'ha de repetir"
        }

        return title
    }

    handleRepeat = () => {
        if (this.props.actualStep !== REVISION_TO_VALUATION && this.validateData()) {
            const mutation = this.getMutation()

            if (mutation !== null) {
                this.props.client.mutate(mutation).then((result) => {
                    this.props.handleRepeat()
                })
            }
        } else {
            this.props.handleRepeat()
        }
    }

    renderRepeatButton = () => {
        const { actualStep, classes } = this.props

        if (
            (actualStep === ASSEMBLY_TO_REVISION && this.state.selfcorrectionNote === '1') ||
            actualStep === REVISION_TO_VALUATION ||
            actualStep === VALUATION_TO_DELIVERY
        ) {
            return (
                <Button onClick={() => this.handleRepeat()} color="secondary" className={classes.repeatButton}>
                    Repetir
                </Button>
            )
        }

        return null
    }

    renderConfirmButton = () => {
        const { actualStep } = this.props

        if (actualStep === ASSEMBLY_TO_REVISION && this.state.selfcorrectionNote === '1') {
            return null
        }

        var buttonText = 'Confirmar'
        if (actualStep === VALUATION_TO_DELIVERY) {
            buttonText = 'Per entregar'
        } else if (actualStep === REVISION_TO_VALUATION) {
            buttonText = 'Correcte'
        }

        return (
            <Button onClick={this.handleConfirm} color="primary" autoFocus>
                {buttonText}
            </Button>
        )
    }

    renderNextStepDialog = () => {
        const { actualStep, isEvaluable, classes } = this.props

        var content = null
        switch (actualStep) {
            case APPOINTMENT_TO_ORDER:
                content = this.renderGoToOrderStep()
                break
            case ORDER_TO_ASSEMBLY:
                content = this.renderGoToAssemblyStep()
                break
            case ASSEMBLY_TO_REVISION:
                content = this.renderGoToRevisionStep()
                break
            case REVISION_TO_VALUATION:
                content = this.renderGoToValuationStep()
                break
            case VALUATION_TO_DELIVERY:
                content = this.renderGoToDeliveryStep()
                break
            case DELIVERY_TO_END:
                content = this.renderGoToEndStep()
                break
        }

        if (content !== null) {
            return (
                <Dialog
                    open={this.props.showDialog}
                    onClose={this.props.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">{this.renderStepTitle()}</DialogTitle>
                    {content}
                    <DialogActions>
                        <Button onClick={this.props.handleClose} color="secondary">
                            Cancel·lar
                        </Button>
                        {this.renderRepeatButton()}
                        {this.renderConfirmButton()}
                    </DialogActions>
                </Dialog>
            )
        }
    }

    render() {
        return <div>{this.renderNextStepDialog()}</div>
    }
}

const styles = (theme) => ({
    labelTitle: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
        color: theme.palette.primary.light,
    },
    textField: {
        marginTop: 16,
        marginBottom: 16,
    },
    repeatButton: {
        color: amber[700],
    },
})

UpdateAssemblyDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    assemblyId: PropTypes.string.isRequired,
    actualStep: PropTypes.string.isRequired,
    showDialog: PropTypes.bool.isRequired,
    isEvaluable: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleConfirm: PropTypes.func.isRequired,
    handleRepeat: PropTypes.func,
}

UpdateAssemblyDialog.defaultProps = {
    showDialog: false,
    handleRepeat: () => {},
}

export default compose(withApollo, withStyles(styles))(UpdateAssemblyDialog)
