import React from 'react'
import PropTypes from 'prop-types'

// Design imports
import { Checkbox, TableCell, TableHead, TableRow, TableSortLabel, Tooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

// Constants

class EnhancedTableHead extends React.Component {
    createSortHandler = (property) => (event) => {
        this.props.onRequestSort(event, property)
    }

    renderColumn = (row) => {
        const { order, orderBy } = this.props

        /* TODO Limitar las columnas que se pueden ordernar */
        return (
            <TableCell
                key={row.id}
                numeric={row.numeric}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.id ? order : false}>
                <Tooltip title="Sort" placement={row.numeric ? 'bottom-end' : 'bottom-start'} enterDelay={300}>
                    <TableSortLabel
                        active={orderBy === row.id}
                        direction={order}
                        onClick={this.createSortHandler(row.id)}>
                        {row.label}
                    </TableSortLabel>
                </Tooltip>
            </TableCell>
        )
    }

    render() {
        const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, columns } = this.props

        return (
            <TableHead>
                <TableRow>
                    <TableCell padding="checkbox" className={classes.checkbox}>
                        <Checkbox
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={numSelected === rowCount && rowCount > 0}
                            onChange={onSelectAllClick}
                        />
                    </TableCell>
                    {columns.map((row) => this.renderColumn(row), this)}
                </TableRow>
            </TableHead>
        )
    }
}

const styles = (theme) => ({
    checkbox: {
        width: '64px',
    },
})

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    columns: PropTypes.object.isRequired,
}

export default withStyles(styles)(EnhancedTableHead)
