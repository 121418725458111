import grey from '@material-ui/core/colors/grey'

export default (theme) => ({
    root: {
        display: 'flex',
        height: '100vh',
        flex: 1,
        backgroundColor: '#FAFAFA',
        flexDirection: 'column',
        overflow: 'scroll',
    },
    header: {
        backgroundColor: theme.palette.primary.main,
        flex: 4,
        minHeight: 250,
    },
    body: {
        flex: 8,
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: -225,
    },
    paper: {
        margin: theme.spacing.unit * 2,
        minWidth: '50%',
        /*padding: theme.spacing.unit * 3,
        paddingBottom: theme.spacing.unit * 4,*/
    },
    formControl: {
        marginTop: 16,
    },
    button: {
        height: 48,
        //marginTop: theme.spacing.unit * 3
    },
    forgottenPassword: {
        marginTop: theme.spacing.unit * 2,
        cursor: 'pointer',
        color: theme.palette.primary.light,
    },
    forgottenMessage: {
        color: theme.palette.primary.light,
    },
    title: {
        color: theme.palette.primary.main, //'#FFFFFF',
        marginTop: theme.spacing.unit * 2,
        fontWeight: 'bold',
    },
    newUserSection: {
        backgroundColor: '#FAFAFA',
        // marginLeft: -(theme.spacing.unit * 3),
        // marginRight: -(theme.spacing.unit * 3),
    },
    contactContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2,
    },
    contactOption: {
        cursor: 'Pointer',
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2,
        fontSize: 14,
    },
    link: {
        textDecoration: 'none',
    },
    pdiButton: {
        marginTop: theme.spacing.unit * 4,
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2,
    },
    buttonProgress: {
        //color: green[500],
        color: grey[50],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    cardContainer: {
        padding: theme.spacing.unit * 3,
    },
    successMessage: {
        color: theme.palette.primary.light,
        marginTop: theme.spacing.unit * 1,
        marginBottom: theme.spacing.unit * 1,
    },
    logo: {
        maxWidth: '80%',
        height: 'auto',
        margin: 'auto',
        marginBottom: theme.spacing.unit * 3,
    },
})
