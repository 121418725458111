import React from 'react'
import PropTypes from 'prop-types'
import { AUTH_TOKEN, ROLE, ROLE_CAST } from '../../constants'

// Images
import logo from '../../images/logo_cuv.png'
import partners from '../../images/partners.png'
import partnersXS from '../../images/partners_xs.png'

// Apollo client
import { withApollo } from '@apollo/client/react/hoc'
import { login, recoveryPassword } from '../../grapql/auth'

// Design imports
import {
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    Paper,
    TextField,
    Typography,
    withWidth,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { isWidthUp } from '@material-ui/core/withWidth'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import styles from './styles'
import compose from '../../utils/functions'

class Login extends React.Component {
    state = {
        email: '',
        password: '',
        showPassword: false,
        rememberSession: false,
        emailError: false,
        passwordError: false,
        loading: false,
        loginError: false,
        errorMessage: '',
        showRecoveryPassword: false,
        showRecoveryMessage: false,
    }

    saveUserData = (token, role) => {
        if (this.state.rememberSession) {
            localStorage.setItem(AUTH_TOKEN, token)
            localStorage.setItem(ROLE, role)
        } else {
            sessionStorage.setItem(AUTH_TOKEN, token)
            sessionStorage.setItem(ROLE, role)
        }
    }

    login = () => {
        if (this.validateData()) {
            const mutation = login(this.state.email, this.state.password)
            this.setState({ loading: true })
            this.props.client
                .mutate(mutation)
                .then((result) => {
                    this.setState({ loading: false })
                    this.saveUserData(result.data.login.token, ROLE_CAST[result.data.login.role])
                    this.props.loginConfirmed(ROLE_CAST[result.data.login.role])
                })
                .catch((error) => {
                    var errorMessage = 'Les dades no son correctes'
                    if (error.graphQLErrors.length > 0 && error.graphQLErrors[0].message === 'User not validated') {
                        errorMessage = 'Usuari no validat'
                    }

                    this.setState({ loading: false, loginError: true, errorMessage })
                    setTimeout(() => {
                        this.setState({ loginError: false })
                    }, 3000)
                })
        }
    }

    recoveryPassword = () => {
        if (this.validateData()) {
            const mutation = recoveryPassword(this.state.email)
            this.props.client.mutate(mutation).then((result) => {
                this.setState({ showRecoveryMessage: true })
            })
        }
    }

    validateData = () => {
        var dataIsValid = true

        var errors = {
            emailError: false,
            passwordError: false,
        }

        // TODO Revisar regexp, da error con mails como "Feest.Warren@Beatty.org"
        //const emailRe = RegExp('[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?');

        if (this.state.email === '' /*|| !emailRe.test(this.state.email)*/) {
            errors.emailError = true
            dataIsValid = false
        }
        if (this.state.password === '' && this.state.showRecoveryPassword === false) {
            errors.passwordError = true
            dataIsValid = false
        }
        // TODO Revisar validacion del email

        this.setState(errors)

        return dataIsValid
    }

    handleChange = (name) => (event) => {
        this.setState({
            [name]: event.target.value,
        })
    }

    handleChecboxChange = (name) => (event) => {
        this.setState({ [name]: event.target.checked })
    }

    handleClickShowPassword = () => {
        this.setState({
            showPassword: !this.state.showPassword,
        })
    }

    renderLoginButton = () => {
        const { classes } = this.props
        const { loading, loginError } = this.state

        if (loading) return <CircularProgress size={24} className={classes.buttonProgress} />
        if (loginError) return this.state.errorMessage
        return 'Iniciar sessió'
    }

    renderRecoverySection = () => {
        const { classes } = this.props
        const { loading, loginError } = this.state

        return (
            <Paper className={classes.paper}>
                <Grid container spacing={24} justify="center">
                    <Grid item xs={12} sm={10}>
                        <Typography align="center" variant="h2" className={classes.title}>
                            CUV Social
                        </Typography>
                    </Grid>
                    <Grid item xs={10} sm={10}>
                        <TextField
                            fullWidth
                            error={this.state.emailError}
                            id="email"
                            label="Email"
                            name="email"
                            type="email"
                            className={classes.textField}
                            value={this.state.email}
                            onChange={this.handleChange('email')}
                            margin="normal"
                        />
                    </Grid>
                    {this.state.showRecoveryMessage ? (
                        <Grid item xs={10} sm={10}>
                            <Typography align="center" variant="subtitle1" className={classes.forgottenMessage}>
                                Si l'email introduït correspon a un usuari existent, s'enviarà un missatge amb les
                                instruccions per recuperar la vostra contrasenya. Si us plau, comprovi la carpeta de
                                SPAM
                            </Typography>
                        </Grid>
                    ) : null}
                    <Grid item xs={10} sm={10}>
                        <Button
                            fullWidth
                            variant="contained"
                            color={'primary'}
                            size="large"
                            className={classes.button}
                            //disabled={loading}
                            onClick={() => this.recoveryPassword()}>
                            Recuperar contrasenya
                        </Button>
                        <a
                            className={classes.link}
                            onClick={(event) => {
                                event.stopPropagation()
                                this.setState({ showRecoveryPassword: false })
                            }}>
                            <Typography align="center" variant="subtitle2" className={classes.forgottenPassword}>
                                Iniciar sessió
                            </Typography>
                        </a>
                    </Grid>
                </Grid>
            </Paper>
        )
    }

    render() {
        const { classes } = this.props
        const { loading, loginError } = this.state

        return (
            <div className={classes.root}>
                <div className={classes.header} />
                <div className={classes.body}>
                    <Grid container spacing={24} justify="center">
                        <Grid item xs={12} sm={8} md={6} lg={4}>
                            <div className={classes.container}>
                                <img src={logo} alt="Logo del CUV" className={classes.logo} />
                                {/*<Typography align='center' variant='h2' className={classes.title}>
                                    CUV Social
                                </Typography>*/}
                                {this.state.showRecoveryPassword ? (
                                    this.renderRecoverySection()
                                ) : (
                                    <Paper className={classes.paper}>
                                        <form
                                            onSubmit={(event) => {
                                                event.preventDefault()
                                                this.login()
                                                return false
                                            }}>
                                            <Grid container spacing={24} justify="center">
                                                <Grid item xs={12} sm={10}>
                                                    <Typography align="center" variant="h2" className={classes.title}>
                                                        CUV Social
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={10} sm={10}>
                                                    <TextField
                                                        fullWidth
                                                        error={this.state.emailError}
                                                        id="email"
                                                        label="Email"
                                                        name="email"
                                                        type="email"
                                                        className={classes.textField}
                                                        value={this.state.email}
                                                        onChange={this.handleChange('email')}
                                                        margin="normal"
                                                    />
                                                </Grid>
                                                <Grid item xs={10} sm={10}>
                                                    <FormControl fullWidth className={classes.formControl}>
                                                        <InputLabel htmlFor="password">Contrasenya</InputLabel>
                                                        <Input
                                                            id="password"
                                                            error={this.state.passwordError}
                                                            type={this.state.showPassword ? 'text' : 'password'}
                                                            value={this.state.password}
                                                            onChange={this.handleChange('password')}
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="Toggle password visibility"
                                                                        onClick={this.handleClickShowPassword}>
                                                                        {this.state.showPassword ? (
                                                                            <VisibilityIcon />
                                                                        ) : (
                                                                            <VisibilityOffIcon />
                                                                        )}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={10} sm={10}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={this.state.rememberSession}
                                                                onChange={this.handleChecboxChange('rememberSession')}
                                                                value="rememberSession"
                                                                color="primary"
                                                            />
                                                        }
                                                        label="Mantenir la sessió iniciada"
                                                    />
                                                </Grid>
                                                <Grid item xs={10} sm={10}>
                                                    <Button
                                                        fullWidth
                                                        type="submit"
                                                        variant="contained"
                                                        color={loginError ? 'secondary' : 'primary'}
                                                        size="large"
                                                        className={classes.button}
                                                        //disabled={loading}
                                                        onClick={() => this.login()}>
                                                        {this.renderLoginButton()}
                                                    </Button>
                                                    <a
                                                        className={classes.link}
                                                        onClick={(event) => {
                                                            event.stopPropagation()
                                                            this.setState({ showRecoveryPassword: true })
                                                        }}>
                                                        <Typography
                                                            align="center"
                                                            variant="subtitle2"
                                                            className={classes.forgottenPassword}>
                                                            He oblidat la contrasenya
                                                        </Typography>
                                                    </a>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </Paper>
                                )}
                                <div className={classes.contactContainer}>
                                    <a className={classes.link} href="https://cuv.upc.edu/ca" target="_blank">
                                        <Typography variant="caption" className={classes.contactOption}>
                                            Vull col·laborar amb el CUV
                                        </Typography>
                                    </a>
                                    <a className={classes.link} href="https://cuv.upc.edu/ca/contact" target="_blank">
                                        <Typography variant="caption" className={classes.contactOption}>
                                            Contacte
                                        </Typography>
                                    </a>
                                </div>
                                <div>
                                    <Typography variant="h6" align="center" className={classes.partners}>
                                        Col·laboradors
                                    </Typography>
                                    {isWidthUp('sm', this.props.width) ? (
                                        <div className={classes.partnersImgContainer}>
                                            <img src={partners} alt="Col·laboradors" className={classes.partnersImg} />
                                        </div>
                                    ) : (
                                        <div className={classes.partnersImgContainer}>
                                            <img
                                                src={partnersXS}
                                                alt="Col·laboradors"
                                                className={classes.partnersImg}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        )
    }
}

Login.propTypes = {
    classes: PropTypes.object.isRequired,
    loginConfirmed: PropTypes.func.isRequired,
}

export default compose(withApollo, withWidth(), withStyles(styles))(Login)
