import React from 'react'
import PropTypes from 'prop-types'
import compose from '../../../utils/functions'
import { withRouter } from 'react-router'

// Images
import logo from '../../../images/logo_cuv.png'

// Apollo client
import { withApollo } from '@apollo/client/react/hoc'
import { signupSocialWorker } from '../../../grapql/user'

// Design imports
import {
    Button,
    CircularProgress,
    Divider,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Paper,
    TextField,
    Typography,
    withWidth,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { isWidthUp } from '@material-ui/core/withWidth'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import ReactDOM from 'react-dom'
import styles from './styles'

class SocialWorkerSignup extends React.Component {
    state = {
        name: '',
        lastName: '',
        phone: '',
        collegiateNumber: '',
        email: '',
        password: '',
        verifyPassword: '',
        entityId: '',
        nameError: false,
        lastNameError: false,
        phoneError: false,
        collegiateNumberError: false,
        emailError: false,
        passwordError: false,
        verifyPasswordError: false,
        loading: false,
        loginError: false,
        errorMessage: '',
        showPassword: false,
        subjectLabelWidth: 0,
        passwordLabelWidth: 0,
        showSuccessMessage: false,
    }

    componentDidMount() {
        const urlPaths = this.props.location.pathname.split('/')
        const entityId = urlPaths.length === 4 ? urlPaths[3] : ''

        this.setState({
            entityId,
            passwordLabelWidth: ReactDOM.findDOMNode(this.PasswordLabelRef).offsetWidth,
            verifyPasswordLabelWidth: ReactDOM.findDOMNode(this.VerifyPasswordLabelRef).offsetWidth,
        })
    }

    validateData = () => {
        var dataIsValid = true

        var errors = {
            nameError: false,
            lastNameError: false,
            phoneError: false,
            collegiateNumberError: false,
            emailError: false,
            passwordError: false,
            verifyPasswordError: false,
        }

        if (this.state.name === '') {
            errors.nameError = true
            dataIsValid = false
        }
        if (this.state.lastName === '') {
            errors.lastNameError = true
            dataIsValid = false
        }
        if (this.state.phone === '') {
            errors.phoneError = true
            dataIsValid = false
        }
        if (this.state.collegiateNumber === '') {
            errors.collegiateNumberError = true
            dataIsValid = false
        }
        if (this.state.entityId === '') {
            dataIsValid = false
        }
        if (this.state.email === '') {
            errors.emailError = true
            dataIsValid = false
        }
        if (this.state.password === '') {
            errors.passwordError = true
            dataIsValid = false
        }
        if (this.state.verifyPassword === '' || this.state.password !== this.state.verifyPassword) {
            errors.verifyPasswordError = true
            dataIsValid = false
        }

        this.setState(errors)

        return dataIsValid
    }

    signup = () => {
        const { name, lastName, phone, email, collegiateNumber, entityId, password } = this.state

        if (this.validateData()) {
            const mutation = signupSocialWorker(name, lastName, phone, email, collegiateNumber, entityId, password)
            this.props.client
                .mutate(mutation)
                .then((result) => {
                    this.setState({ showSuccessMessage: true })
                })
                .catch((error) => {
                    this.setState({ loginError: true, errorMessage: "No s'ha pogut crear el compte" })
                    setTimeout(() => {
                        this.setState({ loginError: false })
                    }, 3000)
                })
        }
    }

    handleChange = (name) => (event) => {
        this.setState({
            [name]: event.target.value,
        })
    }

    handleChecboxChange = (name) => (event) => {
        this.setState({ [name]: event.target.checked })
    }

    handleClickShowPassword = () => {
        this.setState({
            showPassword: !this.state.showPassword,
        })
    }

    handleSubjectChange = (name) => (event) => {
        this.setState({
            subject: event.target.value,
        })
    }

    renderSignupButton = () => {
        const { classes } = this.props
        const { loading, loginError } = this.state

        if (loading) return <CircularProgress size={24} className={classes.buttonProgress} />
        if (loginError) return this.state.errorMessage
        return 'Crear compte'
    }

    renderSuccessMessage = () => {
        const { classes } = this.props
        const { loading, loginError } = this.state

        return (
            <Paper className={classes.paper}>
                <Grid className={classes.cardContainer} container spacing={24}>
                    <Grid item xs={12} sm={12}>
                        <Typography align="center" variant="h2" className={classes.title}>
                            CUV Social
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography align="center" variant="subtitle1" className={classes.successMessage}>
                            Usuari creat correctament, un cop hagi estat validat per l'equip del CUV ja podràs accedir a
                            l'aplicatiu
                        </Typography>
                    </Grid>
                </Grid>
            </Paper>
        )
    }

    renderSignupForm = () => {
        const { classes } = this.props
        const { loading, loginError } = this.state

        return (
            <Paper className={classes.paper}>
                <Grid className={classes.cardContainer} container spacing={24}>
                    <Grid item xs={12} sm={12}>
                        <Typography align="center" variant="h2" className={classes.title}>
                            CUV Social
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            fullWidth
                            error={this.state.nameError}
                            id="worker-name"
                            label="Nom"
                            name="name"
                            type="text"
                            className={classes.textField}
                            value={this.state.name}
                            onChange={this.handleChange('name')}
                            margin="normal"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            fullWidth
                            error={this.state.lastNameError}
                            id="worker-lastname"
                            label="Cognoms"
                            name="lastname"
                            type="text"
                            className={classes.textField}
                            value={this.state.lastName}
                            onChange={this.handleChange('lastName')}
                            margin="normal"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <TextField
                            fullWidth
                            required
                            error={this.state.phoneError}
                            id="worker-phone"
                            label="Telèfon"
                            name="phone"
                            type="tel"
                            pattern="^[0-9-+s()]*$"
                            className={classes.textField}
                            value={this.state.phone}
                            onChange={this.handleChange('phone')}
                            margin="normal"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            fullWidth
                            error={this.state.collegiateNumberError}
                            id="worker-collegiateNumber"
                            label="Núm. de col·legiat"
                            name="collegiateNumber"
                            type="text"
                            className={classes.textField}
                            value={this.state.collegiateNumber}
                            onChange={this.handleChange('collegiateNumber')}
                            margin="normal"
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
                <Divider />
                <Grid className={classes.cardContainer} container spacing={24}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            required
                            error={this.state.emailError}
                            id="worker-email"
                            label="Email"
                            name="email"
                            type="email"
                            className={classes.textField}
                            value={this.state.email}
                            onChange={this.handleChange('email')}
                            margin="normal"
                            variant="outlined"
                        />
                    </Grid>
                    {isWidthUp('sm', this.props.width) ? <Grid item xs={0} sm={6}></Grid> : null}
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth className={classes.formControl} variant="outlined">
                            <InputLabel
                                ref={(ref) => {
                                    this.PasswordLabelRef = ref
                                }}
                                htmlFor="password">
                                Contrasenya
                            </InputLabel>
                            <OutlinedInput
                                id="password"
                                error={this.state.passwordError}
                                type={this.state.showPassword ? 'text' : 'password'}
                                value={this.state.password}
                                onChange={this.handleChange('password')}
                                labelWidth={this.state.passwordLabelWidth}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="Toggle password visibility"
                                            onClick={this.handleClickShowPassword}>
                                            {this.state.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth className={classes.formControl} variant="outlined">
                            <InputLabel
                                ref={(ref) => {
                                    this.VerifyPasswordLabelRef = ref
                                }}
                                htmlFor="password">
                                Verifica la contrasenya
                            </InputLabel>
                            <OutlinedInput
                                id="password"
                                error={this.state.verifyPasswordError}
                                type={this.state.showPassword ? 'text' : 'password'}
                                value={this.state.verifyPassword}
                                onChange={this.handleChange('verifyPassword')}
                                labelWidth={this.state.verifyPasswordLabelWidth}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="Toggle password visibility"
                                            onClick={this.handleClickShowPassword}>
                                            {this.state.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Divider />
                <Grid className={classes.cardContainer} container spacing={24}>
                    <Grid item xs={12} sm={12}>
                        <Button
                            fullWidth
                            variant="contained"
                            color={loginError ? 'secondary' : 'primary'}
                            size="large"
                            className={classes.button}
                            onClick={() => this.signup()}>
                            {this.renderSignupButton()}
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        )
    }

    render() {
        const { classes } = this.props
        const { loading, loginError } = this.state

        return (
            <div className={classes.root}>
                <div className={classes.header} />
                <div className={classes.body}>
                    <Grid container spacing={24} justify="center">
                        <Grid item xs={12} sm={8} md={6} lg={4}>
                            <div className={classes.container}>
                                <a className={classes.link} href="https://cuvsocial.upc.edu" align="center">
                                    <img src={logo} alt="Logo del CUV" className={classes.logo} />
                                    {/*<Typography align='center' variant='h2' className={classes.title}>
                                        CUV Social
                                    </Typography>*/}
                                </a>
                                {this.state.showSuccessMessage ? this.renderSuccessMessage() : this.renderSignupForm()}
                                <div className={classes.contactContainer}>
                                    <a className={classes.link} href="https://cuv.upc.edu/ca" target="_blank">
                                        <Typography variant="caption" className={classes.contactOption}>
                                            Vull col·laborar amb el CUV
                                        </Typography>
                                    </a>
                                    <a className={classes.link} href="https://cuv.upc.edu/ca/contact" target="_blank">
                                        <Typography variant="caption" className={classes.contactOption}>
                                            Contacte
                                        </Typography>
                                    </a>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        )
    }
}

SocialWorkerSignup.propTypes = {
    classes: PropTypes.object.isRequired,
    loginConfirmed: PropTypes.func.isRequired,
}

export default compose(withWidth(), withApollo, withRouter, withStyles(styles))(SocialWorkerSignup)
