import gql from 'graphql-tag'

export const login = (email, password) => {
    return {
        mutation: gql`
            mutation Login($email: String, $password: String) {
                login(email: $email, password: $password) {
                    token
                    role
                }
            }
        `,
        variables: {
            email: email,
            password: password,
        },
    }
}

export const recoveryPassword = (email) => {
    return {
        mutation: gql`
            mutation RecoveryPassword($email: String) {
                recoveryPassword(email: $email)
            }
        `,
        variables: {
            email: email,
        },
    }
}
