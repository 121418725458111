import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import compose from '../../utils/functions'

// Design imports
import { IconButton, Snackbar, SnackbarContent } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import green from '@material-ui/core/colors/green'

class FeedbackSnackbar extends React.Component {
    render() {
        const { classes, showSnackbar, timeShowed, text } = this.props

        if (this.props.variant === 'error') {
            return (
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={showSnackbar}
                    autoHideDuration={timeShowed}
                    onClose={this.props.handleClose}>
                    <SnackbarContent
                        className={classes.error}
                        aria-describedby="message-id"
                        message={
                            <span id="client-snackbar" className={classes.message}>
                                <ErrorIcon className={classNames(classes.icon, classes.iconVariant)} />
                                {text}
                            </span>
                        }
                        action={[
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                className={classes.close}
                                onClick={this.props.handleClose}>
                                <CloseIcon />
                            </IconButton>,
                        ]}></SnackbarContent>
                </Snackbar>
            )
        } else {
            return (
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={showSnackbar}
                    autoHideDuration={timeShowed}
                    onClose={this.props.handleClose}>
                    <SnackbarContent
                        className={classes.success}
                        aria-describedby="message-id"
                        message={
                            <span id="client-snackbar" className={classes.message}>
                                <CheckCircleIcon className={classNames(classes.icon, classes.iconVariant)} />
                                {text}
                            </span>
                        }
                        action={[
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                className={classes.close}
                                onClick={this.props.handleClose}>
                                <CloseIcon />
                            </IconButton>,
                        ]}></SnackbarContent>
                </Snackbar>
            )
        }
    }
}

const styles = (theme) => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    icon: {
        fontSize: 24,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing.unit,
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
})

FeedbackSnackbar.propTypes = {
    classes: PropTypes.object.isRequired,
    showSnackbar: PropTypes.bool.isRequired,
    timeShowed: PropTypes.number,
    text: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired,
    variant: PropTypes.string,
}

FeedbackSnackbar.defaultProps = {
    timeShowed: 3000,
    variant: 'success',
}

export default compose(withStyles(styles))(FeedbackSnackbar)
