import gql from 'graphql-tag'

export const getPatients = (offset, limit, order, orderDirection, filter) => {
    return {
        query: gql`
            query GetPatients($offset: Int, $limit: Int, $order: String, $orderDirection: String, $filter: String) {
                allPatients(
                    offset: $offset
                    limit: $limit
                    order: $order
                    orderDirection: $orderDirection
                    filter: $filter
                ) {
                    patients {
                        id
                        name
                        lastName
                        birthdate
                        email
                        phone
                        entity {
                            id
                            name
                        }
                    }
                    totalCount
                }
            }
        `,
        variables: {
            offset: offset,
            limit: limit,
            order: order,
            orderDirection: orderDirection,
            filter: filter,
        },
    }
}

export const getPatientsNames = (filter) => {
    return {
        query: gql`
            query GetPatientsNames($filter: String) {
                allPatients(filter: $filter) {
                    patients {
                        id
                        name
                        lastName
                    }
                }
            }
        `,
        variables: {
            filter: filter,
        },
    }
}

export const getPatientById = (id) => {
    return {
        query: gql`
            query GetPatientById($id: ID) {
                patient(id: $id) {
                    id
                    name
                    lastName
                    birthdate
                    email
                    phone
                    idNumber
                    nationality
                    address
                    city
                    postalCode
                    entity {
                        id
                        name
                    }
                    socialWorkerId
                    socialWorker {
                        name
                        lastName
                    }
                    appointments {
                        id
                        proposedDate
                        proposedHour
                        confirmed
                    }
                    incidences {
                        id
                        title
                        createdAt
                    }
                    assemblies {
                        id
                        casnum
                        actualStep
                        createdAt
                    }
                    lastAssembly {
                        id
                        casnum
                        appointmentDate
                        orderDate
                        arrivalDate
                        startAssemblyDate
                        endAssemblyDate
                        expectedDeliveryDate
                        deliveryDate
                        actualStep
                    }
                }
            }
        `,
        variables: {
            id: id,
        },
    }
}

export const getPatientByIdForTS = (id) => {
    return {
        query: gql`
            query GetPatientById($id: ID) {
                patient(id: $id) {
                    id
                    name
                    lastName
                    birthdate
                    email
                    phone
                    idNumber
                    nationality
                    address
                    city
                    postalCode
                    entity {
                        id
                        name
                    }
                    socialWorkerId
                    socialWorker {
                        name
                        lastName
                    }
                    appointments {
                        id
                        proposedDate
                        proposedHour
                        confirmed
                    }
                    incidences {
                        id
                        title
                        createdAt
                    }
                    lastAssembly {
                        id
                        casnum
                        appointmentDate
                        endAssemblyDate
                        expectedDeliveryDate
                        deliveryDate
                        actualStep
                    }
                }
            }
        `,
        variables: {
            id: id,
        },
    }
}

export const addPatient = (
    name,
    lastName,
    birthdate,
    email,
    phone,
    entityId,
    idNumber,
    nationality,
    address,
    city,
    postalCode,
) => {
    return {
        mutation: gql`
            mutation AddPatient(
                $name: String
                $lastName: String
                $birthdate: String
                $email: String
                $phone: String
                $entityId: ID
                $idNumber: String
                $nationality: String
                $address: String
                $city: String
                $postalCode: String
            ) {
                addPatient(
                    input: {
                        name: $name
                        lastName: $lastName
                        birthdate: $birthdate
                        email: $email
                        phone: $phone
                        entityId: $entityId
                        idNumber: $idNumber
                        nationality: $nationality
                        address: $address
                        city: $city
                        postalCode: $postalCode
                    }
                ) {
                    id
                }
            }
        `,
        variables: {
            name: name,
            lastName: lastName,
            birthdate: birthdate,
            email: email,
            phone: phone,
            entityId: entityId,
            idNumber: idNumber,
            nationality: nationality,
            address: address,
            city: city,
            postalCode: postalCode,
        },
    }
}

export const updatePatient = (
    id,
    name,
    lastName,
    birthdate,
    email,
    phone,
    socialWorkerId,
    idNumber,
    nationality,
    address,
    city,
    postalCode,
) => {
    return {
        mutation: gql`
            mutation UpdatePatient(
                $id: ID
                $name: String
                $lastName: String
                $birthdate: String
                $email: String
                $phone: String
                $socialWorker: ID
                $idNumber: String
                $nationality: String
                $address: String
                $city: String
                $postalCode: String
            ) {
                updatePatient(
                    id: $id
                    input: {
                        name: $name
                        lastName: $lastName
                        birthdate: $birthdate
                        email: $email
                        phone: $phone
                        socialWorker: $socialWorker
                        idNumber: $idNumber
                        nationality: $nationality
                        address: $address
                        city: $city
                        postalCode: $postalCode
                    }
                ) {
                    id
                }
            }
        `,
        variables: {
            id: id,
            name: name,
            lastName: lastName,
            birthdate: birthdate,
            email: email,
            phone: phone,
            socialWorker: socialWorkerId,
            idNumber: idNumber,
            nationality: nationality,
            address: address,
            city: city,
            postalCode: postalCode,
        },
    }
}

export const removePatient = (id) => {
    return {
        mutation: gql`
            mutation RemovePatient($id: ID) {
                removePatient(id: $id) {
                    id
                }
            }
        `,
        variables: {
            id: id,
        },
    }
}
