import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import compose from '../../../utils/functions'

// Apollo client
import { withApollo } from '@apollo/client/react/hoc'
import { getUserById, grantAccess, revokeAccess, updateUser } from '../../../grapql/user'
import { getSubjectsNames } from '../../../grapql/subject'

// Component imports
import AssignedTasks from '../../../components/assigned_tasks'
import TaskHistory from '../../../components/task_history'

// Design imports
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormControl,
    Grid,
    IconButton,
    Input,
    InputLabel,
    Paper,
    Select,
    Snackbar,
    SnackbarContent,
    TextField,
    Tooltip,
    Typography,
    withWidth,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import UserIcon from '@material-ui/icons/AccountCircle'
import EditIcon from '@material-ui/icons/Edit'
import WarningIcon from '@material-ui/icons/Warning'
import CorrectIcon from '@material-ui/icons/CheckCircle'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CloseIcon from '@material-ui/icons/Close'
import green from '@material-ui/core/colors/green'
import { ROLE_API } from '../../../constants'

class UserDetail extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            user: {},
            subjects: [],
            revokeDialogOpened: false,
            confirmedRevokeSnackbarOpened: false,
            confirmSnackbarOpened: false,
            deleteDialogOpened: false,
            confirmDeleteSnackbarOpened: false,
            showEditUser: false,
            name: '',
            lastName: '',
            email: '',
            phone: '',
            collegiateNumber: '',
            role: '',
            subject: '',
            nameError: false,
            lastNameError: false,
            phoneError: false,
            emailError: false,
            collegiateNumberError: false,
            roleError: false,
            subjectError: false,
        }
    }

    castRoles = (roleFromAPI) => {
        switch (roleFromAPI) {
            case 'ADMIN':
                return 'Administrador'
            case 'PDI':
                return 'PDI'
            case 'TS':
                return 'Treballador social'
            case 'ST':
                return 'Estudiant'
            case 'MST':
                return 'Estudiant de gestió'
            default:
                return ''
        }

        return ''
    }

    parseData = (userFromAPI) => {
        const user = {
            id: userFromAPI.id,
            name: userFromAPI.name?.toUpperCase(),
            lastName: userFromAPI.lastName?.toUpperCase(),
            email: userFromAPI.email?.toUpperCase(),
            phone: userFromAPI.phone,
            role: userFromAPI.role,
            entityOrSubjectId: userFromAPI.entityOrSubject !== null ? userFromAPI.entityOrSubject.id : null,
            entityOrSubjectName:
                userFromAPI.entityOrSubject !== null ? userFromAPI.entityOrSubject.name?.toUpperCase() : null,
            collegiateNumber: userFromAPI.collegiateNumber,
            validated: userFromAPI.validated,
        }

        this.setState({
            user: user,
            name: user.name,
            lastName: user.lastName,
            email: user.email,
            phone: user.phone,
            collegiateNumber: user.collegiateNumber,
            subject: user.entityOrSubjectId,
            role: user.role,
        })
    }

    fetchUserInfo = () => {
        this.props.client
            .query(getUserById(this.props.match.params.id))
            .then((result) => this.parseData(result.data.user))
    }

    componentDidMount() {
        this.fetchUserInfo()
        this.props.client
            .query(getSubjectsNames())
            .then((result) => this.setState({ subjects: result.data.allSubjects.subjects }))
    }

    validateData = () => {
        var dataIsValid = true

        var errors = {
            nameError: false,
            lastNameError: false,
            phoneError: false,
            emailError: false,
            collegiateNumberError: false,
            roleError: false,
            subjectError: false,
        }

        if (this.state.name === '') {
            errors.nameError = true
            dataIsValid = false
        }
        if (this.state.lastName === '') {
            errors.lastNameError = true
            dataIsValid = false
        }
        if (this.state.phone === '') {
            errors.phoneError = true
            dataIsValid = false
        }
        if (this.state.email === '') {
            errors.emailError = true
            dataIsValid = false
        }
        if (this.state.role === ROLE_API.TS && this.state.collegiateNumber === '') {
            errors.collegiateNumberError = true
            dataIsValid = false
        }
        if ((this.state.role === ROLE_API.ST || this.state.role === ROLE_API.MST) && this.state.subject === '') {
            errors.subjectError = true
            dataIsValid = false
        }

        this.setState(errors)

        return dataIsValid
    }

    handleValidateUser = () => {
        this.props.client.mutate(grantAccess(this.props.match.params.id)).then((result) => this.fetchUserInfo())
    }

    handleUpdateUser = () => {
        const { name, lastName, email, phone, collegiateNumber, role, subject } = this.state

        if (this.validateData()) {
            this.props.client
                .mutate(
                    updateUser(
                        this.props.match.params.id,
                        name,
                        lastName,
                        email,
                        phone,
                        role,
                        subject,
                        collegiateNumber,
                    ),
                )
                .then((result) => this.fetchUserInfo())
            this.setState({ showEditUser: false })
        }
    }

    handleSubjectChange = (name) => (event) => {
        this.setState({
            subject: event.target.value,
        })
    }

    handleRoleChange = (name) => (event) => {
        this.setState({
            role: event.target.value,
        })
    }

    handleChange = (name) => (event) => {
        this.setState({
            [name]: event.target.value,
        })
    }

    handleRevokeButtonClicked = () => {
        this.setState({ revokeDialogOpened: true })
    }

    handleDeleteButtonClicked = () => {
        this.setState({ deleteDialogOpened: true })
    }

    handleClose = () => {
        this.setState({
            revokeDialogOpened: false,
            confirmedRevokeSnackbarOpened: false,
            deleteDialogOpened: false,
            confirmDeleteSnackbarOpened: false,
        })
    }

    handleConfirmButtonClick = () => {
        this.props.client.mutate(revokeAccess(this.props.match.params.id)).then((result) => {
            this.setState({ revokeDialogOpened: false, confirmedRevokeSnackbarOpened: true })
            this.fetchUserInfo()
        })
    }

    handleConfirmDeleteButtonClick = () => {
        // TODO Añadir query para eliminar usuario

        this.setState({ deleteDialogOpened: false, confirmDeleteSnackbarOpened: true })
        setTimeout(() => {
            this.props.history.goBack()
        }, 3000)
    }

    renderRoleDependentInfo = () => {
        const { classes } = this.props
        const { user } = this.state

        var role = ''
        switch (user.role) {
            case ROLE_API.ADMIN:
                role = 'Administrador'
                break
            case ROLE_API.TS:
                role = 'Treballador social'
                break
            case ROLE_API.PDI:
                role = 'PDI'
                break
            case ROLE_API.ST:
                role = 'Estudiant'
                break
            case ROLE_API.MST:
                role = 'Estudiant de gestió'
                break
        }

        if (user.role === ROLE_API.TS) {
            return (
                <div>
                    <Divider />
                    <Grid className={classes.cardContainer} container spacing={16}>
                        <Grid item xs={12} sm={6}>
                            <Typography className={classes.labelTitle} variant="subtitle2">
                                Rol:
                            </Typography>
                            <Typography className={classes.label} variant="h6">
                                {role}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography className={classes.labelTitle} variant="subtitle2">
                                Núm. de col·legiat:
                            </Typography>
                            {/* TODO Añadir num de col·legiat a la API */}
                            <Typography className={classes.label} variant="h6">
                                {user.collegiateNumber !== null ? user.collegiateNumber : '-'}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography className={classes.labelTitle} variant="subtitle2">
                                Entitat:
                            </Typography>
                            <Typography className={classes.label} variant="h6">
                                {user.entityOrSubjectName}
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            )
        } else if (user.role === ROLE_API.ST || user.role === ROLE_API.MST) {
            return (
                <div>
                    <Divider />
                    <Grid className={classes.cardContainer} container spacing={16}>
                        <Grid item xs={12} sm={6}>
                            <Typography className={classes.labelTitle} variant="subtitle2">
                                Rol:
                            </Typography>
                            <Typography className={classes.label} variant="h6">
                                {role}
                            </Typography>
                        </Grid>
                        <Grid item xs={0} sm={6}></Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography className={classes.labelTitle} variant="subtitle2">
                                Assignatura:
                            </Typography>
                            <Typography className={classes.label} variant="h6">
                                {user.entityOrSubjectName}
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            )
        } else if (user.role === ROLE_API.PDI || user.role === ROLE_API.ADMIN) {
            return (
                <div>
                    <Divider />
                    <Grid className={classes.cardContainer} container spacing={16}>
                        <Grid item xs={12} sm={6}>
                            <Typography className={classes.labelTitle} variant="subtitle2">
                                Rol:
                            </Typography>
                            <Typography className={classes.label} variant="h6">
                                {role}
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            )
        }
    }

    renderUserValidation = () => {
        const { classes } = this.props
        const { user } = this.state

        if (user.validated) {
            return (
                <Paper>
                    <div className={classes.cardHeader}>
                        <div className={classes.cardTitleContainer}>
                            <CorrectIcon color="primary" style={{ fontSize: 28 }} />
                            <Typography className={classes.title} variant="h6" color="primary">
                                Usuari validat correctament
                            </Typography>
                        </div>
                    </div>
                    <Divider />
                    <div className={classes.cardContainer}>
                        <Typography variant="subtitle1" color="textPrimary" gutterBottom>
                            Aquest usuari ha estat validat correctament i per tant te accés a la informació relativa al
                            seu rol. Si vol donar de baixa a l'usuari, només ha de revocar l'accés prement el botó
                            corresponent.
                        </Typography>
                    </div>
                    <Divider />
                    <div className={classes.cardFooter}>
                        <Button
                            variant="outlined"
                            color="secondary"
                            size="large"
                            className={classes.actionButton}
                            onClick={this.handleRevokeButtonClicked}>
                            Revocar accés
                        </Button>
                    </div>
                </Paper>
            )
        }

        return (
            <Paper>
                <div className={classes.cardHeader}>
                    <div className={classes.cardTitleContainer}>
                        <WarningIcon color="secondary" style={{ fontSize: 28 }} />
                        <Typography className={classes.title} variant="h6" color="secondary">
                            Usuari no validat
                        </Typography>
                    </div>
                </div>
                <Divider />
                <div className={classes.cardContainer}>
                    <Typography variant="subtitle1" color="textPrimary" gutterBottom>
                        Aquest usuari encara no ha estat validat, i per tant no te accés al sistema. Si us plau
                        verifiqui la informació de l'usuari i valida-ho per donar-li accés a CUV Social.
                    </Typography>
                    <Typography variant="subtitle1" color="textPrimary">
                        En cas que l'usuari no sigui vàlid pot eliminar-ho al botó corresponent.
                    </Typography>
                </div>
                <Divider />
                <div className={classes.cardFooter}>
                    <Button
                        variant="outlined"
                        color="secondary"
                        size="large"
                        className={classes.actionButton}
                        onClick={() => this.handleDeleteButtonClicked()}>
                        Eliminar
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        className={classes.actionButton}
                        onClick={() => this.handleValidateUser()}>
                        Validar usuari
                    </Button>
                </div>
            </Paper>
        )
    }

    renderStudentTaskSection = () => {
        const { classes } = this.props
        const { user } = this.state

        if (user.role === 'student' || user.role === 'managementStudent') {
            return (
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <AssignedTasks />
                    </Grid>
                    <Grid item xs={12}>
                        <TaskHistory />
                    </Grid>
                </Grid>
            )
        }
    }

    renderRevokeDialogAndSnackbar = () => {
        const { classes } = this.props
        const { user } = this.state

        return (
            <div>
                <Dialog
                    open={this.state.revokeDialogOpened}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">
                        {"Està segur que revocar l'accés a aquest usuari?"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Un cop revocat l'accés a l'usuari aquest no podrà accedir a CUV Social ni veure cap data del
                            sistema.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="secondary">
                            No
                        </Button>
                        <Button onClick={this.handleConfirmButtonClick} color="primary" autoFocus>
                            Sí, revocar accés
                        </Button>
                    </DialogActions>
                </Dialog>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={this.state.confirmedRevokeSnackbarOpened}
                    autoHideDuration={1500}
                    onClose={this.handleClose}>
                    <SnackbarContent
                        className={classes.success}
                        aria-describedby="message-id"
                        message={
                            <span id="client-snackbar" className={classes.message}>
                                <CheckCircleIcon className={classNames(classes.icon, classes.iconVariant)} />
                                Accés revocat correctament
                            </span>
                        }
                        action={[
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                className={classes.close}
                                onClick={this.handleClose}>
                                <CloseIcon />
                            </IconButton>,
                        ]}></SnackbarContent>
                </Snackbar>
            </div>
        )
    }

    renderDeleteDialogAndSnackbar = () => {
        const { classes } = this.props
        const { user } = this.state

        return (
            <div>
                <Dialog
                    open={this.state.deleteDialogOpened}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">{'Està segur que vol eliminar aquest usuari?'}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Aquesta acció es irreversible, un cop eliminat l'usuari aquest queda esborrat del sistema.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="secondary">
                            No
                        </Button>
                        <Button onClick={this.handleConfirmDeleteButtonClick} color="primary" autoFocus>
                            Sí, eliminar usuari
                        </Button>
                    </DialogActions>
                </Dialog>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={this.state.confirmDeleteSnackbarOpened}
                    autoHideDuration={1500}
                    onClose={this.handleClose}>
                    <SnackbarContent
                        className={classes.success}
                        aria-describedby="message-id"
                        message={
                            <span id="client-snackbar" className={classes.message}>
                                <CheckCircleIcon className={classNames(classes.icon, classes.iconVariant)} />
                                Usuari eliminat correctament
                            </span>
                        }
                        action={[
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                className={classes.close}
                                onClick={this.handleClose}>
                                <CloseIcon />
                            </IconButton>,
                        ]}></SnackbarContent>
                </Snackbar>
            </div>
        )
    }

    renderRoleDependentEditSection = () => {
        const { classes } = this.props
        const { user } = this.state

        var role = ''
        switch (user.role) {
            case ROLE_API.ADMIN:
                role = 'Administrador'
                break
            case ROLE_API.TS:
                role = 'Treballador social'
                break
            case ROLE_API.PDI:
                role = 'PDI'
                break
            case ROLE_API.ST:
                role = 'Estudiant'
                break
            case ROLE_API.MST:
                role = 'Estudiant de gestió'
                break
        }

        if (user.role === ROLE_API.TS) {
            return (
                <div>
                    <Divider />
                    <Grid className={classes.cardContainer} container spacing={16}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                error={this.state.collegiateNumberError}
                                id="user-collegiateNumber"
                                label="Núm. de col·legiat:"
                                name="collegiateNumber"
                                type="text"
                                className={classes.textField}
                                value={this.state.collegiateNumber !== null ? this.state.collegiateNumber : '-'}
                                onChange={this.handleChange('collegiateNumber')}
                                margin="normal"
                            />
                        </Grid>
                    </Grid>
                </div>
            )
        } else if (user.role === ROLE_API.ST || user.role === ROLE_API.MST) {
            return (
                <div>
                    <Divider />
                    <Grid className={classes.cardContainer} container spacing={16}>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth required className={classes.formControl}>
                                <InputLabel
                                    ref={(ref) => {
                                        this.SubjectLabelRef = ref
                                    }}
                                    htmlFor="user-subject">
                                    Rol
                                </InputLabel>
                                <Select
                                    native
                                    error={this.state.roleError}
                                    value={this.state.role}
                                    onChange={this.handleRoleChange()}
                                    input={<Input name="user-role" id="user-role" />}>
                                    <option value={ROLE_API.ST}>Estudiant</option>
                                    <option value={ROLE_API.MST}>Estudiant de gestió</option>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth required className={classes.formControl}>
                                <InputLabel
                                    ref={(ref) => {
                                        this.SubjectLabelRef = ref
                                    }}
                                    htmlFor="user-subject">
                                    Assignatura
                                </InputLabel>
                                <Select
                                    native
                                    error={this.state.subjectError}
                                    value={this.state.subject}
                                    onChange={this.handleSubjectChange()}
                                    input={<Input name="user-subject" id="user-subject" />}>
                                    <option value="" />
                                    {this.state.subjects.map((item, index) => {
                                        return <option value={item.id}>{item.name}</option>
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </div>
            )
        }
    }

    renderEditUser = () => {
        const { classes } = this.props
        const { user } = this.state

        return (
            <div>
                <Divider />
                <Grid className={classes.cardContainer} container spacing={16}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            fullWidth
                            error={this.state.nameError}
                            id="user-name"
                            label="Nom"
                            name="name"
                            type="text"
                            className={classes.textField}
                            value={this.state.name}
                            onChange={this.handleChange('name')}
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            fullWidth
                            error={this.state.lastNameError}
                            id="user-lastname"
                            label="Cognom"
                            name="lastname"
                            type="text"
                            className={classes.textField}
                            value={this.state.lastName}
                            onChange={this.handleChange('lastName')}
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            fullWidth
                            error={this.state.emailError}
                            id="user-email"
                            label="Email"
                            name="email"
                            type="text"
                            className={classes.textField}
                            value={this.state.email}
                            onChange={this.handleChange('email')}
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            fullWidth
                            error={this.state.phoneError}
                            id="user-phone"
                            label="Telefon"
                            name="phone"
                            type="text"
                            className={classes.textField}
                            value={this.state.phone}
                            onChange={this.handleChange('phone')}
                            margin="normal"
                        />
                    </Grid>
                </Grid>
                <Divider />
                {this.renderRoleDependentEditSection()}
                <Divider />
                <div className={classes.cardFooter}>
                    <Button
                        variant="outlined"
                        color="secondary"
                        size="large"
                        className={classes.actionButton}
                        onClick={() => this.setState({ showEditUser: false })}>
                        Cancel·lar
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        className={classes.actionButton}
                        onClick={() => this.handleUpdateUser()}>
                        Desar els canvis
                    </Button>
                </div>
            </div>
        )
    }

    render() {
        const { classes } = this.props
        const { user } = this.state

        return (
            <div className={classes.root}>
                <Grid container spacing={24}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <Paper className={classes.paperRoot}>
                                    <div className={classes.cardHeader}>
                                        <div className={classes.cardTitleContainer}>
                                            <UserIcon color="primary" style={{ fontSize: 28 }} />
                                            <Typography className={classes.title} variant="h6" color="primary">
                                                Informació de l'usuari
                                            </Typography>
                                        </div>
                                        <Tooltip title="Editar">
                                            {this.state.showEditUser ? (
                                                <IconButton
                                                    aria-label="Editar"
                                                    className={classes.actions}
                                                    onClick={() => this.setState({ showEditUser: false })}>
                                                    <CloseIcon />
                                                </IconButton>
                                            ) : (
                                                <IconButton
                                                    aria-label="Editar"
                                                    className={classes.actions}
                                                    onClick={() => this.setState({ showEditUser: true })}>
                                                    <EditIcon />
                                                </IconButton>
                                            )}
                                        </Tooltip>
                                    </div>
                                    {this.state.showEditUser ? (
                                        this.renderEditUser()
                                    ) : (
                                        <div>
                                            <Grid className={classes.cardContainer} container spacing={16}>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography className={classes.labelTitle} variant="subtitle2">
                                                        Nom:
                                                    </Typography>
                                                    <Typography className={classes.label} variant="h6">
                                                        {user.name}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography className={classes.labelTitle} variant="subtitle2">
                                                        Cognoms:
                                                    </Typography>
                                                    <Typography className={classes.label} variant="h6">
                                                        {user.lastName}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography className={classes.labelTitle} variant="subtitle2">
                                                        Email:
                                                    </Typography>
                                                    <a className={classes.link} href={'mailto:' + user.email}>
                                                        <Typography className={classes.label} variant="h6">
                                                            {user.email}
                                                        </Typography>
                                                    </a>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography className={classes.labelTitle} variant="subtitle2">
                                                        Telefon:
                                                    </Typography>
                                                    <a className={classes.link} href={'tel:' + user.phone}>
                                                        <Typography className={classes.label} variant="h6">
                                                            {user.phone}
                                                        </Typography>
                                                    </a>
                                                </Grid>
                                            </Grid>
                                            {this.renderRoleDependentInfo()}
                                        </div>
                                    )}
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                {this.renderUserValidation()}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        {this.renderStudentTaskSection()}
                    </Grid>
                </Grid>
                {this.renderRevokeDialogAndSnackbar()}
                {this.renderDeleteDialogAndSnackbar()}
            </div>
        )
    }
}

const styles = (theme) => ({
    root: {
        display: 'flex',
        flex: 1,
    },
    paperRoot: {},
    cardHeader: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        padding: theme.spacing.unit * 3,
        justifyContent: 'space-between',
        height: '80px',
    },
    cardFooter: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        padding: theme.spacing.unit * 3,
        justifyContent: 'flex-end',
        height: '80px',
    },
    cardTitleContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
    title: {
        paddingLeft: '8px',
    },
    cardContainer: {
        padding: theme.spacing.unit * 3,
    },
    labelTitle: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
        color: theme.palette.primary.light,
    },
    label: {
        fontWeight: 'normal',
    },
    actions: {
        //color: theme.palette.text.secondary,
        marginRight: -theme.spacing.unit * 2,
    },
    actionButton: {
        //width: '200px',
        marginLeft: '8px',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginTop: 8,
            marginBottom: 8,
        },
    },
    success: {
        backgroundColor: green[600],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing.unit,
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    link: {
        textDecoration: 'none',
    },
})

UserDetail.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default compose(withWidth(), withApollo, withStyles(styles))(UserDetail)
