import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import compose from '../../../utils/functions'
import ca from 'moment/locale/ca'
import { getValueFromStorage } from '../../../utils'

// Apollo client
import { withApollo } from '@apollo/client/react/hoc'
import { getAssemblyById, undoLastStep, updateAssembly, updateObservations } from '../../../grapql/assembly'
import { getLoggedUser } from '../../../grapql/user'

// Date managment
import MomentUtils from '@date-io/moment'
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers'
import moment from 'moment'

// Components imports
import SupplierPicker from '../../../components/supplier_picker'
import UpdateAssemblyDialog from '../../../components/update_assembly_dialog'

// Design imports
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MobileStepper,
    OutlinedInput,
    Paper,
    Select,
    Snackbar,
    SnackbarContent,
    TextField,
    Typography,
} from '@material-ui/core'
import { SpeedDial, SpeedDialAction } from '@material-ui/lab/'
import { withStyles } from '@material-ui/core/styles'
import AssemblyIcon from '@material-ui/icons/AllInbox'
import SupplierIcon from '@material-ui/icons/LocalShipping'
import BuildIcon from '@material-ui/icons/Build'
import DeliveryIcon from '@material-ui/icons/ThumbUp'
import NotesIcon from '@material-ui/icons/Notes'
import TrackIcon from '@material-ui/icons/TrackChanges'
import HistoryIcon from '@material-ui/icons/History'
import UndoIcon from '@material-ui/icons/Undo'
import EditIcon from '@material-ui/icons/Edit'
import EvaluationIcon from '@material-ui/icons/Label'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import CheckIcon from '@material-ui/icons/Check'
import { ASSEMBLY_STATUS_API, ROLE, ROLE_CAST } from '../../../constants'
import CloseIcon from '@material-ui/icons/Close'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import green from '@material-ui/core/colors/green'
import { getManagementStudents } from '../../../grapql/students'

// Constants
const APPOINTMENT_SECTION = 0
const ORDER_SECTION = 1
const WORKING_SECTION = 2
const REVISION_SECTION = 3
const VALUATION_SECTION = 4
const DELIVERY_SECTION = 5
const END_SECTION = 6
const steps = [
    {
        label: 'Visita programada',
        labelAfter: 'Visita realitzada',
        sectionId: APPOINTMENT_SECTION,
    },
    {
        label: 'Comanda realitzada',
        labelAfter: 'Comanda rebuda',
        sectionId: ORDER_SECTION,
    },
    {
        label: 'En muntatge',
        labelAfter: 'Muntatge realitzat',
        sectionId: WORKING_SECTION,
    },
    {
        label: 'Per revisar',
        labelAfter: 'Revisió realitzada',
        sectionId: REVISION_SECTION,
    },
    {
        label: 'Per valorar',
        labelAfter: 'Valoració realitzada',
        sectionId: VALUATION_SECTION,
    },
    {
        label: 'Per entregar',
        labelAfter: 'Entrega realitzada',
        sectionId: DELIVERY_SECTION,
    },
    {
        label: 'Tractament finalitzat',
        sectionId: END_SECTION,
    },
]

const actions = [
    { icon: <HistoryIcon />, name: 'Repetir muntatge' },
    // { icon: <NewWarning />, name: 'Crear incidencia' },
    // { icon: <DeleteIcon />, name: 'Eliminar muntatge' }, // TODO Eliminar montaje
]

const actionsPDI = [
    { icon: <HistoryIcon />, name: 'Repetir muntatge' },
    { icon: <UndoIcon />, name: "Tornar a l'últim estat" },
]

class AssemblyDetail extends React.Component {
    // TODO añadir la opcion para eliminar un montaje
    // TODO Un estudiante de gestion puede rellenar el trabajo asignado a otro estudiante de gestión?

    constructor(props) {
        super(props)

        const role = getValueFromStorage(ROLE)

        this.state = {
            assembly: {
                subject: {},
            },
            activeStep: 0,
            lastStepCompleted: END_SECTION,
            fabOpened: false,
            observations: '',
            showNextStepDialog: false,
            role: role,
            userId: '',
            showObservationSavedSnackbar: false,
            snackbarMessage: '',
            showEditAssembly: false,
            casnum: '',
            historyNum: '',
            orderDate: moment(),
            arrivalDate: moment(),
            startAssembly: moment(),
            endAssembly: moment(),
            finalNote: null,
            expectedDelivery: moment(),
            delivery: moment(),
            suppliers: [],
            assemblyStudents: [],
            assemblyStudent: '',
            casnumError: false,
            historyNumError: false,
            orderDateError: false,
            arrivalDateError: false,
            startAssemblyError: false,
            endAssemblyError: false,
            finalNoteError: false,
            expectedDeliveryError: false,
            deliveryError: false,
            assemblyStudentError: false,
            showUndoLastStepDialog: false,
        }
    }

    parseData = (assemblyFromApi) => {
        var assembly = {
            id: assemblyFromApi.id,
            casnum: assemblyFromApi.casnum,
            appointmentDate: assemblyFromApi.appointmentDate,
            orderDate: assemblyFromApi.orderDate,
            arrivalDate: assemblyFromApi.arrivalDate,
            assemblerId: assemblyFromApi.assembler !== null ? assemblyFromApi.assembler.id : '',
            assembler:
                assemblyFromApi.assembler !== null
                    ? assemblyFromApi.assembler.name?.toUpperCase() +
                      ' ' +
                      assemblyFromApi.assembler.lastName?.toUpperCase()
                    : '-',
            startAssembly: assemblyFromApi.startAssemblyDate,
            endAssembly: assemblyFromApi.endAssemblyDate,
            selfcorrectionNote: assemblyFromApi.selfcorrectionNote,
            cabinetStudentNote: assemblyFromApi.cabinetStudentNote,
            finalNote: assemblyFromApi.finalNote,
            finalValoration: assemblyFromApi.finalValoration,
            expectedDelivery: assemblyFromApi.expectedDeliveryDate,
            delivery: assemblyFromApi.deliveryDate,
            patientId: assemblyFromApi.patient !== null ? assemblyFromApi.patient.id : null,
            patientName:
                assemblyFromApi.patient !== null
                    ? assemblyFromApi.patient.name?.toUpperCase() +
                      ' ' +
                      assemblyFromApi.patient.lastName?.toUpperCase()
                    : '-',
            historyNum: assemblyFromApi.historyNum,
            canceled: assemblyFromApi.canceled,
            cabinetStudentId: assemblyFromApi.cabinetStudentId !== null ? assemblyFromApi.cabinetStudentId : null,
            cabinetStudent:
                assemblyFromApi.cabinetStudent !== null
                    ? assemblyFromApi.cabinetStudent.name?.toUpperCase() +
                      ' ' +
                      assemblyFromApi.cabinetStudent.lastName?.toUpperCase()
                    : '-',
            deliveryStudent:
                assemblyFromApi.deliveryStudent !== null
                    ? assemblyFromApi.deliveryStudent.name?.toUpperCase() +
                      ' ' +
                      assemblyFromApi.deliveryStudent.lastName?.toUpperCase()
                    : '-',
            teacher:
                assemblyFromApi.teacher !== null
                    ? assemblyFromApi.teacher.name?.toUpperCase() +
                      ' ' +
                      assemblyFromApi.teacher.lastName?.toUpperCase()
                    : '',
            suppliersIds: assemblyFromApi.suppliers.map((supplier) => supplier.id),
            subjectId: assemblyFromApi.subject !== null ? assemblyFromApi.subject.id : '',
            subject: assemblyFromApi.subject !== null ? assemblyFromApi.subject : {},
        }

        var activeStep = APPOINTMENT_SECTION

        switch (assemblyFromApi.actualStep) {
            case ASSEMBLY_STATUS_API.ORDER:
                activeStep = ORDER_SECTION
                break
            case ASSEMBLY_STATUS_API.ASSEMBLY:
                activeStep = WORKING_SECTION
                break
            case ASSEMBLY_STATUS_API.REVISION:
                activeStep = REVISION_SECTION
                break
            case ASSEMBLY_STATUS_API.VALUATION:
                activeStep = VALUATION_SECTION
                break
            case ASSEMBLY_STATUS_API.DELIVERY:
                activeStep = DELIVERY_SECTION
                break
            case ASSEMBLY_STATUS_API.END:
                activeStep = END_SECTION
                break
            default:
                activeStep = APPOINTMENT_SECTION
        }

        this.setState({
            assembly: assembly,
            observations: assemblyFromApi.observations,
            activeStep: activeStep,
            lastStepCompleted: activeStep,
            casnum: assembly.casnum,
            historyNum: assembly.historyNum,
            orderDate: assembly.orderDate !== null ? moment(parseInt(assembly.orderDate)) : null,
            arrivalDate: assembly.arrivalDate !== null ? moment(parseInt(assembly.arrivalDate)) : null,
            startAssembly: assembly.startAssembly !== null ? moment(parseInt(assembly.startAssembly)) : null,
            endAssembly: assembly.endAssembly !== null ? moment(parseInt(assembly.endAssembly)) : null,
            finalNote: assembly.finalNote,
            expectedDelivery: assembly.expectedDelivery !== null ? moment(parseInt(assembly.expectedDelivery)) : null,
            delivery: assembly.delivery !== null ? moment(parseInt(assembly.delivery)) : null,
            suppliers: assembly.suppliersIds,
            assemblyStudent: assembly.assemblerId,
        })
    }

    fetchAssembly = () => {
        // TODO Añadir fetchPolicy from network para evitar que al actualizar el estado del montaje no se coja de la cache
        const query = getAssemblyById(this.props.match.params.id)
        query.fetchPolicy = 'network-only'
        this.props.client.query(query).then((result) => this.parseData(result.data.assembly))
    }

    componentDidMount() {
        this.props.client.query(getLoggedUser()).then((result) => this.setState({ userId: result.data.loggedUser.id }))
        this.props.client
            .query(getManagementStudents())
            .then((result) => this.setState({ assemblyStudents: result.data.allManagementStudents }))
        this.fetchAssembly()
    }

    validateData = () => {
        var dataIsValid = true

        var errors = {
            casnumError: false,
            historyNumError: false,
            orderDateError: false,
            arrivalDateError: false,
            startAssemblyError: false,
            endAssemblyError: false,
            expectedDeliveryError: false,
            deliveryError: false,
            assemblyStudentError: false,
            finalNoteError: false,
        }

        if (this.state.casnum === '') {
            errors.casnumError = true
            dataIsValid = false
        }
        if (this.state.historyNum === '') {
            errors.historyNumError = true
            dataIsValid = false
        }
        if (this.state.role === ROLE_CAST.PDI) {
            if (this.state.finalNote !== null && this.state.finalNote !== undefined) {
                const note = parseInt(this.state.finalNote)
                if (note < 0 || note > 10) {
                    errors.finalNoteError = true
                    dataIsValid = false
                }
            }
        }

        this.setState(errors)

        return dataIsValid
    }

    updateAssembly = () => {
        const {
            assembly,
            casnum,
            historyNum,
            orderDate,
            arrivalDate,
            startAssembly,
            endAssembly,
            expectedDelivery,
            delivery,
            assemblyStudents,
            assemblyStudent,
            finalNote,
        } = this.state

        if (this.validateData()) {
            const orderDateUnix = orderDate !== null ? orderDate.valueOf().toString() : null
            const arrivalDateUnix = arrivalDate !== null ? arrivalDate.valueOf().toString() : null
            const startAssemblyUnix = startAssembly !== null ? startAssembly.valueOf().toString() : null
            const endAssemblyUnix = endAssembly !== null ? endAssembly.valueOf().toString() : null
            const newAssemblyStudent = assemblyStudents.find((item) => {
                return item.user.id === assemblyStudent
            })
            const expectedDeliveryUnix = expectedDelivery !== null ? expectedDelivery.valueOf().toString() : null
            const deliveryUnix = delivery !== null ? delivery.valueOf().toString() : null

            // TODO Remove casnum and historyNum from the update process
            const mutation = updateAssembly(
                assembly.id,
                casnum,
                historyNum,
                orderDateUnix,
                arrivalDateUnix,
                startAssemblyUnix,
                endAssemblyUnix,
                expectedDeliveryUnix,
                deliveryUnix,
                newAssemblyStudent !== undefined ? newAssemblyStudent.id : null,
                finalNote !== '' ? finalNote : null,
            )
            this.props.client.mutate(mutation).then((result) => {
                this.fetchAssembly()
                this.setState({
                    showEditAssembly: false,
                    showObservationSavedSnackbar: true,
                    snackbarMessage: 'Muntatge desat correctament',
                })
                setTimeout(() => {
                    this.setState({ showObservationSavedSnackbar: false })
                }, 3000)
            })
        }
    }

    saveObservations = () => {
        const { assembly, observations } = this.state
        const mutation = updateObservations(assembly.id, observations)
        this.props.client.mutate(mutation).then((result) => {
            this.setState({ showObservationSavedSnackbar: true, snackbarMessage: 'Observacions desades correctament' })
            setTimeout(() => {
                this.setState({ showObservationSavedSnackbar: false })
            }, 3000)
        })
    }

    goToNewAppointment = () => {
        //this.props.history.push('/new-appointment');
    }

    goToNewRepetition = () => {
        const {
            id,
            patientId,
            patientName,
            casnum,
            historyNum,
            cabinetStudentId,
            cabinetStudent,
            appointmentDate,
            expectedDelivery,
            subject,
            subjectId,
        } = this.state.assembly
        const { observations } = this.state

        this.props.history.push({
            pathname: '/new-repetition',
            state: {
                assemblyId: id,
                patientId: patientId,
                patientName: patientName,
                casnum: casnum,
                historyNum: historyNum,
                cabinetStudentId: cabinetStudentId,
                cabinetStudentName: cabinetStudent,
                appointmentDate: appointmentDate,
                observations: observations,
                expectedDelivery: expectedDelivery,
                subjectId: subjectId,
                subject: subject.name,
            },
        })
    }

    goToNewIncidence = () => {
        //this.props.history.push('/new-incidence');
    }

    handleUndoLastStep = (id) => {
        const mutation = undoLastStep(this.state.assembly.id)
        this.props.client.mutate(mutation).then((result) => {
            this.fetchAssembly()
            setTimeout(() => {
                this.setState({ showObservationSavedSnackbar: false })
            }, 3000)
        })
    }

    handleNext = () => {
        if (this.state.activeStep + 1 <= this.state.lastStepCompleted) {
            this.setState((state) => ({
                activeStep: state.activeStep + 1,
            }))
        } else if (this.state.activeStep === this.state.lastStepCompleted) {
            this.setState({
                showNextStepDialog: true,
            })
        }
    }

    handleBack = () => {
        this.setState((state) => ({
            activeStep: state.activeStep - 1,
        }))
    }

    handleClick = () => {
        if (this.state.showEditAssembly) {
            this.updateAssembly()
        } else {
            this.setState((state) => ({ showEditAssembly: !state.showEditAssembly }))
        }
    }

    handleActionClick = (action) => {
        const speedDialActions = this.state.role === ROLE_CAST.ADMIN ? actionsPDI : actions

        if (action === speedDialActions[0].name) {
            this.goToNewRepetition()
        } else if (speedDialActions.length > 1 && action === speedDialActions[1].name) {
            this.setState({ showUndoLastStepDialog: true })
        } else {
            this.handleClick()
        }
        /*else if (action === actions[1].name) {
            this.goToNewAppointment();
        } */
    }

    handleOpen = () => {
        this.setState({
            fabOpened: true,
        })
    }

    handleClose = () => {
        this.setState({
            fabOpened: false,
            showObservationSavedSnackbar: false,
        })
    }

    handleChange = (name) => (event) => {
        this.setState({
            [name]: event.target.value,
        })
    }

    handleConfirmDialog = () => {
        this.handleCloseDialog()
        this.fetchAssembly()
    }

    handleRepeatDialog = () => {
        this.handleCloseDialog()
        this.goToNewRepetition()
    }

    handleCloseDialog = () => {
        this.setState({ showNextStepDialog: false, showUndoLastStepDialog: false, fabOpened: false })
    }

    handleObservationsChanges = (name) => (event) => {
        this.setState({
            observations: event.target.value,
        })
    }

    renderTrackSection = () => {
        const { classes } = this.props
        const { activeStep } = this.state

        switch (activeStep) {
            case APPOINTMENT_SECTION:
                return (
                    <Grid container spacing={16}>
                        <Grid item xs={12} sm={6}>
                            <Typography className={classes.labelTitle} variant="subtitle2">
                                Data de la visita:
                            </Typography>
                            <Typography className={classes.label} variant="h6">
                                {this.state.assembly.appointmentDate !== null
                                    ? moment(parseInt(this.state.assembly.appointmentDate))
                                          .locale('ca', ca)
                                          .format('dddd D MMMM - DD/MM/YYYY')
                                    : '-'}
                            </Typography>
                        </Grid>
                    </Grid>
                )
            case ORDER_SECTION:
                return (
                    <Grid container spacing={16}>
                        <Grid item xs={12} sm={6}>
                            <Typography className={classes.labelTitle} variant="subtitle2">
                                Data comanda:
                            </Typography>
                            <Typography className={classes.label} variant="h6">
                                {/*Dijous 5 de Juliol - 05/07/2018*/}
                                {this.state.assembly.orderDate !== null
                                    ? moment(parseInt(this.state.assembly.orderDate))
                                          .locale('ca', ca)
                                          .format('dddd D MMMM - DD/MM/YYYY')
                                    : '-'}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography className={classes.labelTitle} variant="subtitle2">
                                Data arribada:
                            </Typography>
                            <Typography className={classes.label} variant="h6">
                                {/*Dimecres 12 de Juliol - 12/07/2018*/}
                                {this.state.assembly.arrivalDate !== null
                                    ? moment(parseInt(this.state.assembly.arrivalDate))
                                          .locale('ca', ca)
                                          .format('dddd D MMMM - DD/MM/YYYY')
                                    : '-'}
                            </Typography>
                        </Grid>
                    </Grid>
                )
            case WORKING_SECTION:
                return (
                    <Grid container spacing={16}>
                        <Grid item xs={12} sm={6}>
                            <Typography className={classes.labelTitle} variant="subtitle2">
                                Data assignació:
                            </Typography>
                            <Typography className={classes.label} variant="h6">
                                {/*Dilluns 16 de Juliol - 16/07/2018*/}
                                {this.state.assembly.startAssembly !== null
                                    ? moment(parseInt(this.state.assembly.startAssembly))
                                          .locale('ca', ca)
                                          .format('dddd D MMMM - DD/MM/YYYY')
                                    : '-'}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography className={classes.labelTitle} variant="subtitle2">
                                Data de finalització:
                            </Typography>
                            <Typography className={classes.label} variant="h6">
                                {this.state.assembly.endAssembly !== null
                                    ? moment(parseInt(this.state.assembly.endAssembly))
                                          .locale('ca', ca)
                                          .format('dddd D MMMM - DD/MM/YYYY')
                                    : '-'}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography className={classes.labelTitle} variant="subtitle2">
                                Muntador:
                            </Typography>
                            <Typography className={classes.label} variant="h6">
                                {this.state.assembly.assembler}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography className={classes.labelTitle} variant="subtitle2">
                                Assignatura:
                            </Typography>
                            <Typography className={classes.label} variant="h6">
                                {this.state.assembly.subject?.name?.toUpperCase()}
                            </Typography>
                        </Grid>
                    </Grid>
                )
            case REVISION_SECTION:
                return (
                    <Grid container spacing={16}>
                        <Grid item xs={12} sm={6}>
                            <Typography className={classes.labelTitle} variant="subtitle2">
                                Nota d'autoavaluació:
                            </Typography>
                            <Typography className={classes.label} variant="h6">
                                {this.state.assembly.selfcorrectionNote}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography className={classes.labelTitle} variant="subtitle2">
                                Nota revisió estudiant de gestió:
                            </Typography>
                            <Typography className={classes.label} variant="h6">
                                {this.state.assembly.cabinetStudentNote !== null
                                    ? this.state.assembly.cabinetStudentNote
                                    : '-'}
                            </Typography>
                        </Grid>
                    </Grid>
                )
            case VALUATION_SECTION:
                return (
                    <div>
                        {this.state.assembly.subject.evaluable ? (
                            <Grid container spacing={16}>
                                {/*<Grid item xs={12} sm={6}>
                                    <Typography className={classes.labelTitle} variant='subtitle2'>
                                        Nota d'avaluació:
                                    </Typography>
                                    <Typography className={classes.label} variant='h6'>
                                        {(this.state.assembly.finalNote !== null) ? this.state.assembly.finalNote : '-'}
                                    </Typography>
                                </Grid>*/}
                                <Grid item xs={12} sm={6}>
                                    <Typography className={classes.labelTitle} variant="subtitle2">
                                        Valoració final:
                                    </Typography>
                                    <Typography className={classes.label} variant="h6">
                                        {this.state.assembly.finalValoration !== null
                                            ? this.state.assembly.finalValoration
                                            : '-'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography className={classes.labelTitle} variant="subtitle2">
                                        Docent
                                    </Typography>
                                    <Typography className={classes.label} variant="h6">
                                        {this.state.assembly.teacher !== '' ? this.state.assembly.teacher : '-'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        ) : (
                            <div className={classes.trackEndSection}>
                                <Typography className={classes.trackEndTitle} variant="subtitle1">
                                    Assignatura no avaluable
                                </Typography>
                            </div>
                        )}
                    </div>
                )
            case DELIVERY_SECTION:
                return (
                    <Grid container spacing={16}>
                        <Grid item xs={12} sm={6}>
                            <Typography className={classes.labelTitle} variant="subtitle2">
                                Data d'entrega programada:
                            </Typography>
                            <Typography className={classes.label} variant="h6">
                                {/*Dimarts 24 de Juliol - 24/07/2018*/}
                                {this.state.assembly.expectedDelivery !== null
                                    ? moment(parseInt(this.state.assembly.expectedDelivery))
                                          .locale('ca', ca)
                                          .format('dddd D MMMM - DD/MM/YYYY')
                                    : 'Avisar'}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography className={classes.labelTitle} variant="subtitle2">
                                Data d'entrega:
                            </Typography>
                            <Typography className={classes.label} variant="h6">
                                {this.state.assembly.delivery !== null
                                    ? moment(parseInt(this.state.assembly.delivery))
                                          .locale('ca', ca)
                                          .format('dddd D MMMM - DD/MM/YYYY')
                                    : '-'}
                            </Typography>
                        </Grid>
                    </Grid>
                )
            case END_SECTION:
                return (
                    <div className={classes.trackEndSection}>
                        <CheckIcon className={classes.trackEndIcon} style={{ fontSize: 40 }} />
                        <Typography className={classes.trackEndTitle} variant="subtitle1">
                            Tractament finalitzat amb èxit
                        </Typography>
                    </div>
                )
        }
    }

    isNextButtonDisabled = () => {
        const { lastStepCompleted, activeStep, role, userId } = this.state

        var disabled = false
        if (
            (lastStepCompleted === APPOINTMENT_SECTION || lastStepCompleted === ORDER_SECTION) &&
            role !== ROLE_CAST.ADMIN &&
            role !== ROLE_CAST.MST
        ) {
            disabled = true
        } else if (
            lastStepCompleted === REVISION_SECTION &&
            role !== ROLE_CAST.ADMIN &&
            role !== ROLE_CAST.MST &&
            role !== ROLE_CAST.PDI
        ) {
            disabled = true
        } else if (
            lastStepCompleted === DELIVERY_SECTION &&
            role !== ROLE_CAST.ADMIN &&
            role !== ROLE_CAST.MST &&
            role !== ROLE_CAST.ST
        ) {
            disabled = true
        } else if (lastStepCompleted === VALUATION_SECTION && role !== ROLE_CAST.ADMIN && role !== ROLE_CAST.PDI) {
            disabled = true
        } else if (
            lastStepCompleted === WORKING_SECTION &&
            role !== ROLE_CAST.ADMIN &&
            (role === ROLE_CAST.MST || role === ROLE_CAST.ST) &&
            userId !== this.state.assembly.assemblerId
        ) {
            disabled = true
        }

        if (this.state.assembly.canceled) {
            disabled = true
        }

        return disabled
    }

    renderNextButton = () => {
        if (this.state.lastStepCompleted !== END_SECTION && this.state.activeStep === this.state.lastStepCompleted) {
            return (
                <Button
                    size="small"
                    variant="raised"
                    color="primary"
                    onClick={this.handleNext}
                    disabled={this.isNextButtonDisabled()}>
                    Següent
                    <KeyboardArrowRight />
                </Button>
            )
        }

        return (
            <Button size="small" onClick={this.handleNext} disabled={this.state.activeStep === steps.length - 1}>
                Següent
                <KeyboardArrowRight />
            </Button>
        )
    }

    render() {
        const { classes, match } = this.props
        const { assembly, activeStep, fabOpened } = this.state
        const speedDialActions =
            this.state.role === ROLE_CAST.ADMIN && this.state.activeStep !== APPOINTMENT_SECTION ? actionsPDI : actions

        return (
            <div className={classes.root}>
                {(this.state.role === ROLE_CAST.ADMIN ||
                    this.state.role === ROLE_CAST.MST ||
                    this.state.role === ROLE_CAST.PDI) &&
                this.state.assembly.canceled === false ? (
                    <SpeedDial
                        ariaLabel="Actions"
                        className={classes.fab}
                        hidden={false}
                        icon={this.state.showEditAssembly ? <CheckIcon /> : <EditIcon />}
                        onBlur={this.handleClose}
                        onClick={this.handleClick}
                        onClose={this.handleClose}
                        onFocus={this.handleOpen}
                        onMouseEnter={this.handleOpen}
                        onMouseLeave={this.handleClose}
                        open={fabOpened}>
                        {speedDialActions.map((action) => (
                            <SpeedDialAction
                                className={classes.actionDial}
                                key={action.name}
                                icon={action.icon}
                                tooltipTitle={action.name}
                                onClick={() => this.handleActionClick(action.name)}
                            />
                        ))}
                    </SpeedDial>
                ) : null}

                <Grid container spacing={24}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Paper className={classes.paperRoot}>
                            <div className={classes.cardHeader}>
                                <div className={classes.cardTitleContainer}>
                                    <AssemblyIcon color="primary" style={{ fontSize: 28 }} />
                                    <Typography className={classes.title} variant="h6" color="primary">
                                        Informació del muntatge
                                    </Typography>
                                </div>
                                {this.state.assembly.canceled ? (
                                    <div>
                                        <Typography
                                            className={classes.canceledTitle}
                                            variant="subtitle1"
                                            color="secondary">
                                            CANCEL·LAT
                                        </Typography>
                                    </div>
                                ) : null}
                            </div>
                            <Divider />
                            <Grid className={classes.cardContainer} container spacing={16}>
                                <Grid item xs={12} sm={6}>
                                    <Typography className={classes.labelTitle} variant="subtitle2">
                                        CAS NUM:
                                    </Typography>
                                    <Typography className={classes.label} variant="h6">
                                        {assembly.casnum}
                                    </Typography>
                                </Grid>
                                {/*{(this.state.showEditAssembly) ?
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            fullWidth
                                            error={this.state.casnumError}
                                            id="casnum"
                                            label="CAS NUM"
                                            name="casnum"
                                            type="text"
                                            className={classes.textField}
                                            value={this.state.casnum}
                                            onChange={this.handleChange('casnum')}
                                            margin="normal"
                                            variant="outlined"/>
                                    </Grid>
                                    :
                                    <Grid item xs={12} sm={6}>
                                        <Typography className={classes.labelTitle} variant='subtitle2'>
                                            CAS NUM:
                                        </Typography>
                                        <Typography className={classes.label} variant='h6'>
                                            {assembly.casnum}
                                        </Typography>
                                    </Grid>
                                }*/}
                                <Grid item xs={12} sm={6}>
                                    <Typography className={classes.labelTitle} variant="subtitle2">
                                        Num. història:
                                    </Typography>
                                    <Typography className={classes.label} variant="h6">
                                        {assembly.historyNum}
                                    </Typography>
                                </Grid>
                                {/*{(this.state.showEditAssembly) ?
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            fullWidth
                                            error={this.state.historyNumError}
                                            id="patient-name"
                                            label="Num. història:"
                                            name="history-num"
                                            type="text"
                                            className={classes.textField}
                                            value={this.state.historyNum}
                                            onChange={this.handleChange('historyNum')}
                                            margin="normal"
                                            variant="outlined"/>
                                    </Grid>
                                    :
                                    <Grid item xs={12} sm={6}>
                                        <Typography className={classes.labelTitle} variant='subtitle2'>
                                            Num. història:
                                        </Typography>
                                        <Typography className={classes.label} variant='h6'>
                                            {assembly.historyNum}
                                        </Typography>
                                    </Grid>
                                }*/}
                                <Grid item xs={12} sm={6}>
                                    <Typography className={classes.labelTitle} variant="subtitle2">
                                        Pacient:
                                    </Typography>
                                    <Typography className={classes.label} variant="h6">
                                        {assembly.patientName}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography className={classes.labelTitle} variant="subtitle2">
                                        Alumne del gabinet:
                                    </Typography>
                                    <Typography className={classes.label} variant="h6">
                                        {assembly.cabinetStudent}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                        <Paper className={classes.paperRoot}>
                            <div className={classes.cardHeader}>
                                <div className={classes.cardTitleContainer}>
                                    <TrackIcon color="primary" style={{ fontSize: 28 }} />
                                    <Typography className={classes.title} variant="h6" color="primary">
                                        Seguiment del muntatge:
                                    </Typography>
                                </div>
                            </div>
                            <Divider />
                            <div>
                                <div className={classes.trackContainer}>{this.renderTrackSection()}</div>
                                <div className={classes.trackSectionTitleContainer}>
                                    <Typography
                                        className={classes.trackSectionTitle}
                                        variant="subtitle1"
                                        color="primary">
                                        {steps[activeStep].label}
                                    </Typography>
                                </div>
                                <MobileStepper
                                    className={classes.mobileStepper}
                                    variant="progress"
                                    steps={steps.length}
                                    position="static"
                                    activeStep={this.state.activeStep}
                                    nextButton={this.renderNextButton()}
                                    backButton={
                                        <Button
                                            size="small"
                                            onClick={this.handleBack}
                                            disabled={this.state.activeStep === 0}>
                                            <KeyboardArrowLeft />
                                            Anterior
                                        </Button>
                                    }
                                />
                            </div>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4}>
                        <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                            <Paper className={classes.paperRoot}>
                                <div className={classes.cardHeader}>
                                    <div className={classes.cardTitleContainer}>
                                        <SupplierIcon color="primary" style={{ fontSize: 28 }} />
                                        <Typography className={classes.title} variant="h6" color="primary">
                                            Proveïdor
                                        </Typography>
                                    </div>
                                </div>
                                <Divider />
                                {this.state.showEditAssembly ? (
                                    <Grid className={classes.cardContainer} container spacing={16}>
                                        <Grid item xs={12} sm={6}>
                                            <DatePicker
                                                autoOk
                                                fullWidth
                                                keyboard
                                                clearable
                                                placeholder={'DD/MM/YYYY'}
                                                mask={(value) =>
                                                    value
                                                        ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
                                                        : []
                                                }
                                                error={this.state.orderDateError}
                                                label="Data comanda:"
                                                format={'DD/MM/YYYY'}
                                                value={this.state.orderDate}
                                                variant="outlined"
                                                className={classes.textField}
                                                onChange={(date) => this.setState({ orderDate: date })}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <DatePicker
                                                autoOk
                                                fullWidth
                                                keyboard
                                                clearable
                                                placeholder={'DD/MM/YYYY'}
                                                mask={(value) =>
                                                    value
                                                        ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
                                                        : []
                                                }
                                                error={this.state.arrivalDateError}
                                                label="Data arribada:"
                                                format={'DD/MM/YYYY'}
                                                value={this.state.arrivalDate}
                                                variant="outlined"
                                                className={classes.textField}
                                                onChange={(date) => this.setState({ arrivalDate: date })}
                                            />
                                        </Grid>
                                        {/*<Grid item xs={12} sm={12}>
                                        <SupplierPicker suppliersSelected={this.state.assembly.suppliersIds} disabled={false}/>
                                    </Grid>*/}
                                    </Grid>
                                ) : (
                                    <Grid className={classes.cardContainer} container spacing={16}>
                                        <Grid item xs={12} sm={6}>
                                            <Typography className={classes.labelTitle} variant="subtitle2">
                                                Data comanda:
                                            </Typography>
                                            <Typography className={classes.label} variant="h6">
                                                {assembly.orderDate !== null
                                                    ? moment(parseInt(assembly.orderDate)).format('DD/MM/YYYY')
                                                    : '-'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography className={classes.labelTitle} variant="subtitle2">
                                                Data arribada:
                                            </Typography>
                                            <Typography className={classes.label} variant="h6">
                                                {assembly.arrivalDate !== null
                                                    ? moment(parseInt(assembly.arrivalDate)).format('DD/MM/YYYY')
                                                    : '-'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <SupplierPicker
                                                suppliersSelected={this.state.assembly.suppliersIds}
                                                disabled={true}
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                            </Paper>
                        </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4}>
                        <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                            <Paper className={classes.paperRoot}>
                                <div className={classes.cardHeader}>
                                    <div className={classes.cardTitleContainer}>
                                        <BuildIcon color="primary" style={{ fontSize: 28 }} />
                                        <Typography className={classes.title} variant="h6" color="primary">
                                            Muntatge
                                        </Typography>
                                    </div>
                                </div>
                                <Divider />
                                {this.state.showEditAssembly ? (
                                    <Grid className={classes.cardContainer} container spacing={16}>
                                        <Grid item xs={12} sm={6}>
                                            <DatePicker
                                                autoOk
                                                fullWidth
                                                keyboard
                                                clearable
                                                placeholder={'DD/MM/YYYY'}
                                                mask={(value) =>
                                                    value
                                                        ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
                                                        : []
                                                }
                                                error={this.state.startAssemblyError}
                                                label="Data assignació:"
                                                format={'DD/MM/YYYY'}
                                                value={this.state.startAssembly}
                                                variant="outlined"
                                                className={classes.textField}
                                                onChange={(date) => this.setState({ startAssembly: date })}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <DatePicker
                                                autoOk
                                                fullWidth
                                                keyboard
                                                clearable
                                                placeholder={'DD/MM/YYYY'}
                                                mask={(value) =>
                                                    value
                                                        ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
                                                        : []
                                                }
                                                error={this.state.endAssemblyError}
                                                label="Data de finaliztació:"
                                                format={'DD/MM/YYYY'}
                                                value={this.state.endAssembly}
                                                variant="outlined"
                                                className={classes.textField}
                                                onChange={(date) => this.setState({ endAssembly: date })}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <FormControl
                                                fullWidth
                                                required
                                                variant="outlined"
                                                className={classes.textField}>
                                                <InputLabel
                                                    ref={(ref) => {
                                                        this.StudentInputLabelRef = ref
                                                    }}
                                                    htmlFor="assembly-student">
                                                    Estudiant de muntatge
                                                </InputLabel>
                                                <Select
                                                    native
                                                    error={this.state.assemblyStudentError}
                                                    value={this.state.assemblyStudent}
                                                    onChange={this.handleChange('assemblyStudent')}
                                                    input={
                                                        <OutlinedInput
                                                            name="supplier"
                                                            /*labelWidth={this.state.studentLabelWidth}*/ id="assembly-student"
                                                        />
                                                    }>
                                                    <option value="" />
                                                    {this.state.assemblyStudents.map((item, index) => {
                                                        return (
                                                            <option value={item.user.id}>
                                                                {item.user.name?.toUpperCase() +
                                                                    ' ' +
                                                                    item.user.lastName?.toUpperCase()}
                                                            </option>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        {/*<Grid item xs={12} sm={12}>
                                            <Typography className={classes.labelTitle} variant='subtitle2'>
                                                Muntador:
                                            </Typography>
                                            <Typography className={classes.label} variant='h6'>
                                                {assembly.assembler}
                                            </Typography>
                                        </Grid>*/}
                                    </Grid>
                                ) : (
                                    <Grid className={classes.cardContainer} container spacing={16}>
                                        <Grid item xs={12} sm={6}>
                                            <Typography className={classes.labelTitle} variant="subtitle2">
                                                Data assignació:
                                            </Typography>
                                            <Typography className={classes.label} variant="h6">
                                                {assembly.startAssembly !== null
                                                    ? moment(parseInt(assembly.startAssembly)).format('DD/MM/YYYY')
                                                    : '-'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography className={classes.labelTitle} variant="subtitle2">
                                                Data de finalització:
                                            </Typography>
                                            <Typography className={classes.label} variant="h6">
                                                {assembly.endAssembly !== null
                                                    ? moment(parseInt(assembly.endAssembly)).format('DD/MM/YYYY')
                                                    : '-'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography className={classes.labelTitle} variant="subtitle2">
                                                Muntador:
                                            </Typography>
                                            <Typography className={classes.label} variant="h6">
                                                {assembly.assembler}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography className={classes.labelTitle} variant="subtitle2">
                                                Assignatura:
                                            </Typography>
                                            <Typography className={classes.label} variant="h6">
                                                {this.state.assembly.subject?.name?.toUpperCase()}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )}
                            </Paper>
                        </MuiPickersUtilsProvider>
                    </Grid>

                    {this.state.role === ROLE_CAST.PDI && this.state.assembly.subject.evaluable ? (
                        <Grid item xs={12} sm={12} md={4}>
                            <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                                <Paper className={classes.paperRoot}>
                                    <div className={classes.cardHeader}>
                                        <div className={classes.cardTitleContainer}>
                                            <EvaluationIcon color="primary" style={{ fontSize: 28 }} />
                                            <Typography className={classes.title} variant="h6" color="primary">
                                                Avaluació
                                            </Typography>
                                        </div>
                                    </div>
                                    <Divider />
                                    {this.state.showEditAssembly ? (
                                        <Grid className={classes.cardContainer} container spacing={16}>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    error={this.state.finalNoteError}
                                                    id="finalNote"
                                                    label="Nota d'avaluació:"
                                                    name="finalNote"
                                                    placeholder="0"
                                                    type="number"
                                                    value={this.state.finalNote}
                                                    onChange={(event) =>
                                                        this.setState({ finalNote: event.target.value })
                                                    }
                                                    variant="outlined"
                                                    className={classes.textField}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        <Grid className={classes.cardContainer} container spacing={16}>
                                            {/*<Grid item xs={12} sm={6}>
                                                <Typography className={classes.labelTitle} variant='subtitle2'>
                                                    Nota d'avaluació:
                                                </Typography>
                                                <Typography className={classes.label} variant='h6'>
                                                    {(this.state.finalNote !== null) ? this.state.finalNote : '-'}
                                                </Typography>
                                            </Grid>*/}
                                            <Grid item xs={12} sm={6}>
                                                <Typography className={classes.labelTitle} variant="subtitle2">
                                                    Valoració final:
                                                </Typography>
                                                <Typography className={classes.label} variant="h6">
                                                    {this.state.assembly.finalValoration !== null
                                                        ? this.state.assembly.finalValoration
                                                        : '-'}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Typography className={classes.labelTitle} variant="subtitle2">
                                                    Docent
                                                </Typography>
                                                <Typography className={classes.label} variant="h6">
                                                    {this.state.assembly.teacher !== ''
                                                        ? this.state.assembly.teacher
                                                        : '-'}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Paper>
                            </MuiPickersUtilsProvider>
                        </Grid>
                    ) : null}

                    <Grid item xs={12} sm={12} md={4}>
                        <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                            <Paper className={classes.paperRoot}>
                                <div className={classes.cardHeader}>
                                    <div className={classes.cardTitleContainer}>
                                        <DeliveryIcon color="primary" style={{ fontSize: 28 }} />
                                        <Typography className={classes.title} variant="h6" color="primary">
                                            Entrega
                                        </Typography>
                                    </div>
                                </div>
                                <Divider />
                                {this.state.showEditAssembly ? (
                                    <Grid className={classes.cardContainer} container spacing={16}>
                                        <Grid item xs={12} sm={6}>
                                            <DatePicker
                                                autoOk
                                                fullWidth
                                                keyboard
                                                clearable
                                                placeholder={'DD/MM/YYYY'}
                                                mask={(value) =>
                                                    value
                                                        ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
                                                        : []
                                                }
                                                error={this.state.expectedDeliveryError}
                                                label=" Data entrega prevista:"
                                                format={'DD/MM/YYYY'}
                                                value={this.state.expectedDelivery}
                                                variant="outlined"
                                                className={classes.textField}
                                                onChange={(date) => this.setState({ expectedDelivery: date })}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <DatePicker
                                                autoOk
                                                fullWidth
                                                keyboard
                                                clearable
                                                placeholder={'DD/MM/YYYY'}
                                                mask={(value) =>
                                                    value
                                                        ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
                                                        : []
                                                }
                                                error={this.state.deliveryError}
                                                label="Data de entrega:"
                                                format={'DD/MM/YYYY'}
                                                value={this.state.delivery}
                                                variant="outlined"
                                                className={classes.textField}
                                                onChange={(date) => this.setState({ delivery: date })}
                                            />
                                        </Grid>
                                        {/*<Grid item xs={12} sm={6}>
                                            <Typography className={classes.labelTitle} variant='subtitle2'>
                                                Entregat per:
                                            </Typography>
                                            <Typography className={classes.label} variant='h6'>
                                                {(assembly.deliveryStudent !== null) ? assembly.deliveryStudent : '-'}
                                            </Typography>
                                        </Grid>*/}
                                    </Grid>
                                ) : (
                                    <Grid className={classes.cardContainer} container spacing={16}>
                                        <Grid item xs={12} sm={6}>
                                            <Typography className={classes.labelTitle} variant="subtitle2">
                                                Data entrega prevista:
                                            </Typography>
                                            <Typography className={classes.label} variant="h6">
                                                {assembly.expectedDelivery !== null
                                                    ? moment(parseInt(assembly.expectedDelivery)).format('DD/MM/YYYY')
                                                    : 'Avisar'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography className={classes.labelTitle} variant="subtitle2">
                                                Data de entrega:
                                            </Typography>
                                            <Typography className={classes.label} variant="h6">
                                                {assembly.delivery !== null
                                                    ? moment(parseInt(assembly.delivery)).format('DD/MM/YYYY')
                                                    : '-'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography className={classes.labelTitle} variant="subtitle2">
                                                Entregat per:
                                            </Typography>
                                            <Typography className={classes.label} variant="h6">
                                                {assembly.deliveryStudent !== null ? assembly.deliveryStudent : '-'}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )}
                            </Paper>
                        </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                        <Paper className={classes.paperRoot}>
                            <div className={classes.cardHeader}>
                                <div className={classes.cardTitleContainer}>
                                    <NotesIcon color="primary" style={{ fontSize: 28 }} />
                                    <Typography className={classes.title} variant="h6" color="primary">
                                        Observacions
                                    </Typography>
                                </div>
                            </div>
                            <Divider />
                            <Grid className={classes.cardContainer} container spacing={16}>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        fullWidth
                                        required
                                        id="assembly-description"
                                        label="Observacions"
                                        multiline
                                        rowsMax="5"
                                        rows="3"
                                        value={this.state.observations}
                                        onChange={this.handleObservationsChanges()}
                                        className={classes.textField}
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                            <Divider />
                            <div className={classes.cardFooter}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    className={classes.actionButton}
                                    onClick={this.saveObservations}>
                                    Desar els canvis
                                </Button>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
                <UpdateAssemblyDialog
                    assemblyId={this.props.match.params.id}
                    actualStep={this.state.lastStepCompleted}
                    showDialog={this.state.showNextStepDialog}
                    isEvaluable={this.state.assembly.subject.evaluable}
                    handleClose={this.handleCloseDialog}
                    handleConfirm={this.handleConfirmDialog}
                    handleRepeat={this.handleRepeatDialog}
                />
                {/*<FeedbackSnackbar showSnackbar={this.state.showObservationSavedSnackbar}
                                  handleClose={() => this.handleClose()}
                                  timeShowed={3000}
                                  text={this.state.snackbarMessage}/>*/}
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={this.state.showObservationSavedSnackbar}
                    autoHideDuration={3000}
                    onClose={this.handleClose}>
                    <SnackbarContent
                        className={classes.success}
                        aria-describedby="message-id"
                        message={
                            <span id="client-snackbar" className={classes.message}>
                                <CheckCircleIcon className={classNames(classes.icon, classes.iconVariant)} />
                                {this.state.snackbarMessage}
                            </span>
                        }
                        action={[
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                className={classes.close}
                                onClick={this.props.handleClose}>
                                <CloseIcon />
                            </IconButton>,
                        ]}></SnackbarContent>
                </Snackbar>
                <Dialog
                    open={this.state.showUndoLastStepDialog}
                    onClose={this.handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">{"Està segur que vol tornar a l'estat anterior?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Tota la informació d'aquest pas es perdrà. Aquesta acció no es pot desfer
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseDialog} color="secondary">
                            No
                        </Button>
                        <Button
                            onClick={() => {
                                this.handleCloseDialog()
                                this.handleUndoLastStep()
                            }}
                            color="primary"
                            autoFocus>
                            Sí, tornar a l'estat anterior
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

const styles = (theme) => ({
    root: {
        display: 'flex',
    },
    paperRoot: {
        //padding: theme.spacing.unit * 3,
    },
    cardHeader: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        padding: theme.spacing.unit * 3,
        justifyContent: 'space-between',
        height: '80px',
    },
    cardTitleContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
    cardContainer: {
        padding: theme.spacing.unit * 3,
    },
    title: {
        //fontWeight: 'bold',
        paddingLeft: '8px',
    },
    canceledTitle: {
        fontWeight: 'bold',
    },
    labelTitle: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
        color: theme.palette.primary.light,
    },
    label: {
        fontWeight: 'normal',
    },
    link: {
        textDecoration: 'none',
    },
    actions: {
        //color: theme.palette.text.secondary,
        marginRight: -theme.spacing.unit * 2,
    },
    list: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing.unit * 2,
        right: theme.spacing.unit * 2,
    },
    listItem: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '40px',
    },
    listItemDate: {
        color: theme.palette.primary.light,
    },
    warningListItem: {},
    warningDateListItem: {
        paddingTop: '8px',
        color: theme.palette.primary.light,
    },
    trackContainer: {
        paddingTop: theme.spacing.unit * 3,
        paddingBottom: theme.spacing.unit * 3,
        paddingLeft: theme.spacing.unit * 3,
        paddingRight: theme.spacing.unit * 3,
    },
    trackSectionTitleContainer: {
        paddingLeft: theme.spacing.unit * 2,
        paddingRight: theme.spacing.unit * 2,
        paddingTop: theme.spacing.unit * 1,
        marginBottom: -(theme.spacing.unit * 2),
        //height: '52px',
        backgroundColor: '#FAFAFA',
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    trackSectionTitle: {
        fontWeight: 'bold',
    },
    trackEndSection: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        // paddingTop: theme.spacing.unit * 2,
        // paddingBottom: theme.spacing.unit * 2,
    },
    trackEndTitle: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
        color: theme.palette.primary.light,
    },
    trackEndIcon: {
        color: theme.palette.primary.light,
        paddingRight: '12px',
    },
    actionDial: {
        backgroundColor: '#FFFFFF',
    },
    mobileStepper: {
        borderBottomLeftRadius: '4px',
        borderBottomRightRadius: '4px',
    },
    cardFooter: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        padding: theme.spacing.unit * 3,
        justifyContent: 'flex-end',
        height: '80px',
    },
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    icon: {
        fontSize: 24,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing.unit,
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
})

AssemblyDetail.propTypes = {
    assemblyId: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
}

AssemblyDetail.defaultProps = {}

export default compose(withApollo, withStyles(styles))(AssemblyDetail)
