import React from 'react'
import PropTypes from 'prop-types'

// Apollo client
import { withApollo } from '@apollo/client/react/hoc'
import { closeIncidence, getIncidenceById } from '../../grapql/incidence'
import { addMessage } from '../../grapql/message'

// Components imports
import ChatMessage from '../../components/chat_message'
import CloseIncidenceDialog from '../../components/closeIncidenceDialog'

// Design imports
import { Button, Divider, Grid, Paper, TextField, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import WarningIcon from '@material-ui/icons/Warning'
import ChatIcon from '@material-ui/icons/Chat'
import SendIcon from '@material-ui/icons/Send'
import moment from 'moment/moment'
import styles from './styles'
import compose from '../../utils/functions'

// Constants

class IncidenceDetail extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            notification: {},
            messages: [],
            response: '',
            responseError: false,
            showCloseIncidenceDialog: false,
        }
    }

    componentDidMount() {
        this.fetchMessages()
    }

    parseData = (incidenceFromAPI) => {
        const incidence = {
            id: incidenceFromAPI.id,
            patientName:
                incidenceFromAPI.patient?.name?.toUpperCase() + ' ' + incidenceFromAPI.patient?.lastName?.toUpperCase(),
            title: incidenceFromAPI.title,
            createdBy: incidenceFromAPI.creator?.name?.toUpperCase(),
            date: moment(parseInt(incidenceFromAPI.createdAt)).format('DD/MM/YYYY HH:mm'),
            open: incidenceFromAPI.open,
        }

        var messages = []
        incidenceFromAPI.messages.forEach((message) => {
            messages.push({
                message: message.message,
                //createdBy: message.creator.name,
                date: moment(parseInt(message.createdAt)).format('DD/MM/YYYY HH:mm'),
                isMine: message.isMine,
            })
        })

        this.setState({ notification: incidence, messages: messages })
        this.scrollToBottom()
    }

    fetchMessages = () => {
        this.props.client.query(getIncidenceById(this.props.match.params.id)).then((result) => {
            this.parseData(result.data.incidence)
        })
    }

    validateData = () => {
        var dataIsValid = true

        var errors = {
            responseError: false,
        }

        if (this.state.response === '') {
            errors.responseError = true
            dataIsValid = false
        }

        this.setState(errors)

        return dataIsValid
    }

    addMessage = () => {
        if (this.validateData()) {
            const mutation = addMessage(this.state.response, this.state.notification.id)

            this.props.client.mutate(mutation).then((result) => {
                this.fetchMessages()
                this.setState({ response: '' })
            })
        }
    }

    handleResponseChanges = () => (event) => {
        this.setState({ response: event.target.value })
    }

    scrollToBottom = () => {
        this.messagesEnd.scrollIntoView({ behavior: 'smooth' })
    }

    handleCloseIncidence = () => {
        this.setState({ showCloseIncidenceDialog: true })
    }

    handleConfirmDialog = (message) => {
        this.setState({ showCloseIncidenceDialog: false })
        const mutation = closeIncidence(message, this.state.notification.id)

        this.props.client.mutate(mutation).then((result) => {
            this.fetchMessages()
        })
    }

    render() {
        const { classes, match } = this.props
        const { notification } = this.state

        return (
            <div className={classes.root}>
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <Paper className={classes.paperRoot}>
                            <div className={classes.cardHeader}>
                                <div className={classes.cardTitleContainer}>
                                    <WarningIcon color="primary" style={{ fontSize: 28 }} />
                                    <Typography className={classes.title} variant="h6" color="primary">
                                        Detalls de la incidencia
                                    </Typography>
                                </div>
                                <Button
                                    variant="outlined"
                                    size="small"
                                    color="secondary"
                                    disabled={!notification.open}
                                    onClick={this.handleCloseIncidence}>
                                    {notification.open ? 'Tancar incidencia' : 'Incidencia tancada'}
                                </Button>
                            </div>
                            <Divider />
                            <div className={classes.cardContainer}>
                                <Grid container spacing={16}>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <Typography className={classes.labelTitle} variant="subtitle2">
                                            Títol:
                                        </Typography>
                                        <Typography className={classes.label} variant="subtitle1">
                                            {notification.title}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <Typography className={classes.labelTitle} variant="subtitle2">
                                            Pacient:
                                        </Typography>
                                        <Typography className={classes.label} variant="subtitle1">
                                            {notification.patientName}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <Typography className={classes.labelTitle} variant="subtitle2">
                                            Codi incidència:
                                        </Typography>
                                        <Typography className={classes.label} variant="subtitle1">
                                            {notification.id}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <Typography className={classes.labelTitle} variant="subtitle2">
                                            Creat:
                                        </Typography>
                                        <Typography className={classes.label} variant="subtitle1">
                                            {notification.createdBy} - {notification.date}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper className={classes.paperRoot}>
                            <div className={classes.cardHeader}>
                                <div className={classes.cardTitleContainer}>
                                    <ChatIcon color="primary" style={{ fontSize: 28 }} />
                                    <Typography className={classes.title} variant="h6" color="primary">
                                        Missatges
                                    </Typography>
                                </div>
                            </div>
                            <Divider />
                            <div className={classes.chatContainer}>
                                {this.state.messages.map((message) => (
                                    <ChatMessage text={message.message} date={message.date} isMine={message.isMine} />
                                ))}
                                <div
                                    style={{ float: 'left', clear: 'both' }}
                                    ref={(el) => {
                                        this.messagesEnd = el
                                    }}></div>
                                <div className={classes.warningMessage}>
                                    <Typography variant="body1" color="textSecondary">
                                        Els missatges enviats no seran resposts immediatament. S'enviarà una notificació
                                        via email un cop el teu missatge rebi una resposta.
                                    </Typography>
                                </div>
                            </div>
                            <Divider />
                            {notification.open ? (
                                <div>
                                    <div className={classes.cardContainer}>
                                        <Grid container spacing={24}>
                                            <Grid item xs={12} sm={10} lg={11}>
                                                <TextField
                                                    fullWidth
                                                    error={this.state.responseError}
                                                    id="response-message"
                                                    label="Escriu aquí el teu missatge"
                                                    multiline
                                                    rowsMax="4"
                                                    value={this.state.response}
                                                    onChange={this.handleResponseChanges()}
                                                    //helperText="Per exemple, dies de la setmana que no pot anar, si necessita algun tractament especial, si ha d'anar acompanyat, etc."
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={2} lg={1}>
                                                <Button
                                                    fullWidth
                                                    variant="outlined"
                                                    color="primary"
                                                    className={classes.sendButton}
                                                    onClick={() => this.addMessage()}>
                                                    <SendIcon className={classes.rightIcon}>send</SendIcon>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            ) : (
                                <div className={classes.cardContainer}>
                                    <Grid container spacing={24}>
                                        <Grid item xs={12}>
                                            <Typography variant="button" color="secondary" align="center">
                                                Incidencia tancada
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                            )}
                        </Paper>
                    </Grid>
                </Grid>
                <CloseIncidenceDialog
                    showDialog={this.state.showCloseIncidenceDialog}
                    handleClose={() => this.setState({ showCloseIncidenceDialog: false })}
                    handleConfirm={this.handleConfirmDialog}
                />
            </div>
        )
    }
}

IncidenceDetail.propTypes = {
    classes: PropTypes.object.isRequired,
}

IncidenceDetail.defaultProps = {}

export default compose(withApollo, withStyles(styles))(IncidenceDetail)
