import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import compose from '../../../utils/functions'

// Date managment
import MomentUtils from '@date-io/moment'
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers'
import moment from 'moment'

// Apollo client
import { withApollo } from '@apollo/client/react/hoc'
import { deleteSupplierById, getSupplierDetailById, updateSupplier } from '../../../grapql/supplier'

// Design imports
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Paper,
    Snackbar,
    SnackbarContent,
    TextField,
    Tooltip,
    Typography,
    withWidth,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/Edit'
import SuppliersIcon from '@material-ui/icons/LocalShipping'
import CloseIcon from '@material-ui/icons/Close'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import green from '@material-ui/core/colors/green'

class SupplierDetail extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            supplier: {},
            deleteDialogOpened: false,
            confirmedDeleteSnackbarOpened: false,
            showEditSupplier: false,
            name: '',
            startDate: '',
            endDate: '',
            contactPerson: '',
            phone: '',
            email: '',
            nameError: false,
            startDateError: false,
            endDateError: false,
            contactPersonError: false,
            phoneError: false,
            emailError: false,
        }
    }

    fetchSupplier = () => {
        this.props.client.query(getSupplierDetailById(this.props.match.params.id)).then((result) => {
            const supplier = Object.assign({}, result.data.supplier)
            const startDateMoment = moment(parseInt(supplier.startAgreementDate))
            const endDateMoment = moment(parseInt(supplier.endAgreementDate))

            supplier.startAgreementDate = startDateMoment.format('DD/MM/YYYY')
            supplier.endAgreementDate = endDateMoment.format('DD/MM/YYYY')

            this.setState({
                supplier,
                name: supplier.name?.toUpperCase(),
                startDate: startDateMoment,
                endDate: endDateMoment,
                contactPerson: supplier.contactPerson?.toUpperCase(),
                phone: supplier.phone,
                email: supplier.email?.toUpperCase(),
            })
        })
    }

    componentDidMount() {
        this.fetchSupplier()
    }

    validateData = () => {
        var dataIsValid = true

        var errors = {
            nameError: false,
            startDateError: false,
            endDateError: false,
            contactPersonError: false,
            phoneError: false,
            emailError: false,
        }

        if (this.state.name === '') {
            errors.nameError = true
            dataIsValid = false
        }
        if (this.state.startDate === '') {
            errors.startDateError = true
            dataIsValid = false
        }
        if (this.state.endDate === '') {
            errors.endDateError = true
            dataIsValid = false
        }
        if (this.state.contactPerson === '') {
            errors.contactPersonError = true
            dataIsValid = false
        }
        if (this.state.phone === '') {
            errors.phoneError = true
            dataIsValid = false
        }
        if (this.state.email === '') {
            errors.emailError = true
            dataIsValid = false
        }

        this.setState(errors)

        return dataIsValid
    }

    updateSupplier = () => {
        const { name, startDate, endDate, contactPerson, phone, email } = this.state

        const unixStartDate = moment(startDate, 'DD/MM/YYYY').valueOf().toString()
        const unixEndDate = moment(endDate, 'DD/MM/YYYY').valueOf().toString()

        if (this.validateData()) {
            this.props.client
                .mutate(
                    updateSupplier(
                        this.props.match.params.id,
                        name,
                        contactPerson,
                        email,
                        phone,
                        unixStartDate,
                        unixEndDate,
                    ),
                )
                .then((result) => this.fetchSupplier())
            this.setState({ showEditSupplier: false })
        }
    }

    handleChange = (name) => (event) => {
        this.setState({
            [name]: event.target.value,
        })
    }

    handleDeleteButtonClicked = () => {
        this.setState({ deleteDialogOpened: true })
    }

    handleClose = () => {
        this.setState({
            deleteDialogOpened: false,
            confirmedDeleteSnackbarOpened: false,
        })
    }

    handleConfirmButtonClick = () => {
        const mutation = deleteSupplierById(this.props.match.params.id)
        this.props.client.mutate(mutation).then((result) => {
            this.setState({ deleteDialogOpened: false, confirmedDeleteSnackbarOpened: true })
            setTimeout(() => {
                this.props.history.goBack()
            }, 3000)
        })
    }

    renderRevokeDialogAndSnackbar = () => {
        const { classes } = this.props
        const { user } = this.state

        return (
            <div>
                <Dialog
                    open={this.state.deleteDialogOpened}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">{'Està segur que vol eliminar aquest proveïdor?'}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Un cop eliminat el proveïdor no es podra recuperar.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="secondary">
                            No
                        </Button>
                        <Button onClick={this.handleConfirmButtonClick} color="primary" autoFocus>
                            Sí, eliminar proveïdor
                        </Button>
                    </DialogActions>
                </Dialog>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={this.state.confirmedDeleteSnackbarOpened}
                    autoHideDuration={1500}
                    onClose={this.handleClose}>
                    <SnackbarContent
                        className={classes.success}
                        aria-describedby="message-id"
                        message={
                            <span id="client-snackbar" className={classes.message}>
                                <CheckCircleIcon className={classNames(classes.icon, classes.iconVariant)} />
                                Proveïdor eliminat correctament
                            </span>
                        }
                        action={[
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                className={classes.close}
                                onClick={this.handleClose}>
                                <CloseIcon />
                            </IconButton>,
                        ]}></SnackbarContent>
                </Snackbar>
            </div>
        )
    }

    renderEditSupplier = () => {
        const { classes } = this.props

        return (
            <div>
                <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                    <Grid className={classes.cardContainer} container spacing={16}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                error={this.state.nameError}
                                id="supplier-name"
                                label="Nom"
                                name="name"
                                type="text"
                                className={classes.textField}
                                value={this.state.name}
                                onChange={this.handleChange('name')}
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}></Grid>
                        <Grid item xs={12} sm={6}>
                            <DatePicker
                                autoOk
                                fullWidth
                                keyboard
                                placeholder={'DD/MM/YYYY'}
                                mask={(value) =>
                                    value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : []
                                }
                                error={this.state.startDateError}
                                label="Data inici del conveni:"
                                format={'DD/MM/YYYY'}
                                value={this.state.startDate}
                                className={classes.textField}
                                onChange={(date) => this.setState({ startDate: date })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DatePicker
                                autoOk
                                fullWidth
                                keyboard
                                placeholder={'DD/MM/YYYY'}
                                mask={(value) =>
                                    value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : []
                                }
                                error={this.state.endDateError}
                                label="Data final del conveni:"
                                format={'DD/MM/YYYY'}
                                value={this.state.endDate}
                                className={classes.textField}
                                onChange={(date) => this.setState({ endDate: date })}
                            />
                        </Grid>
                    </Grid>
                    <Divider />
                    <Grid className={classes.cardContainer} container spacing={16}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                error={this.state.contactPersonError}
                                id="supplier-contactPerson"
                                label="Persona de contacte"
                                name="contactPerson"
                                type="text"
                                className={classes.textField}
                                value={this.state.contactPerson}
                                onChange={this.handleChange('contactPerson')}
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}></Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                error={this.state.phoneError}
                                id="supplier-phone"
                                label="Telèfon"
                                name="phone"
                                type="text"
                                className={classes.textField}
                                value={this.state.phone}
                                onChange={this.handleChange('phone')}
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                error={this.state.emailError}
                                id="supplier-email"
                                label="Email"
                                name="email"
                                type="text"
                                className={classes.textField}
                                value={this.state.email}
                                onChange={this.handleChange('email')}
                                margin="normal"
                            />
                        </Grid>
                    </Grid>
                    <Divider />
                    <div className={classes.cardFooter}>
                        <Button
                            variant="outlined"
                            color="secondary"
                            size="large"
                            className={classes.actionButton}
                            onClick={() => this.setState({ showEditSupplier: false })}>
                            Cancel·lar
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            className={classes.actionButton}
                            onClick={() => this.updateSupplier()}>
                            Desar els canvis
                        </Button>
                    </div>
                </MuiPickersUtilsProvider>
            </div>
        )
    }

    render() {
        const { classes } = this.props
        const { supplier } = this.state

        return (
            <div className={classes.root}>
                <Grid container spacing={24}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Paper className={classes.paperRoot}>
                            <div className={classes.cardHeader}>
                                <div className={classes.cardTitleContainer}>
                                    <SuppliersIcon color="primary" style={{ fontSize: 28 }} />
                                    <Typography className={classes.title} variant="h6" color="primary">
                                        Informació del proveïdor
                                    </Typography>
                                </div>
                                <Tooltip title="Editar">
                                    {this.state.showEditSupplier ? (
                                        <IconButton
                                            aria-label="Editar"
                                            className={classes.actions}
                                            onClick={() => this.setState({ showEditSupplier: false })}>
                                            <CloseIcon />
                                        </IconButton>
                                    ) : (
                                        <IconButton
                                            aria-label="Editar"
                                            className={classes.actions}
                                            onClick={() => this.setState({ showEditSupplier: true })}>
                                            <EditIcon />
                                        </IconButton>
                                    )}
                                </Tooltip>
                            </div>
                            <Divider />
                            {this.state.showEditSupplier ? (
                                this.renderEditSupplier()
                            ) : (
                                <div>
                                    <Grid className={classes.cardContainer} container spacing={16}>
                                        <Grid item xs={12} sm={12}>
                                            <Typography className={classes.labelTitle} variant="subtitle2">
                                                Nom:
                                            </Typography>
                                            <Typography className={classes.label} variant="h6">
                                                {this.state.name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography className={classes.labelTitle} variant="subtitle2">
                                                Data inici del conveni:
                                            </Typography>
                                            <Typography className={classes.label} variant="h6">
                                                {supplier.startAgreementDate}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography className={classes.labelTitle} variant="subtitle2">
                                                Data final del conveni:
                                            </Typography>
                                            <Typography className={classes.label} variant="h6">
                                                {supplier.endAgreementDate}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                    <Grid className={classes.cardContainer} container spacing={16}>
                                        <Grid item xs={12} sm={12}>
                                            <Typography className={classes.labelTitle} variant="subtitle2">
                                                Persona de contacte:
                                            </Typography>
                                            <Typography className={classes.label} variant="h6">
                                                {this.state.contactPerson}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography className={classes.labelTitle} variant="subtitle2">
                                                Telèfon:
                                            </Typography>
                                            <a className={classes.link} href={'tel:' + this.state.phone}>
                                                <Typography className={classes.label} variant="h6">
                                                    {this.state.phone}
                                                </Typography>
                                            </a>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography className={classes.labelTitle} variant="subtitle2">
                                                Email:
                                            </Typography>
                                            <a className={classes.link} href={'mailto:' + this.state.email}>
                                                <Typography className={classes.label} variant="h6">
                                                    {this.state.email}
                                                </Typography>
                                            </a>
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                    <div className={classes.cardFooter}>
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            size="large"
                                            className={classes.actionButton}
                                            onClick={() => this.handleDeleteButtonClicked()}>
                                            Eliminar proveïdor
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </Paper>
                    </Grid>
                </Grid>
                {this.renderRevokeDialogAndSnackbar()}
            </div>
        )
    }
}

const styles = (theme) => ({
    root: {
        display: 'flex',
        flex: 1,
    },
    paperRoot: {},
    cardHeader: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        padding: theme.spacing.unit * 3,
        justifyContent: 'space-between',
        height: '80px',
    },
    cardFooter: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        padding: theme.spacing.unit * 3,
        justifyContent: 'flex-end',
        height: '80px',
    },
    cardTitleContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
    title: {
        paddingLeft: '8px',
    },
    cardContainer: {
        padding: theme.spacing.unit * 3,
    },
    labelTitle: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
        color: theme.palette.primary.light,
    },
    label: {
        fontWeight: 'normal',
    },
    actions: {
        //color: theme.palette.text.secondary,
        marginRight: -theme.spacing.unit * 2,
    },
    actionButton: {
        //width: '200px',
        marginLeft: '8px',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginTop: 8,
            marginBottom: 8,
        },
    },
    success: {
        backgroundColor: green[600],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing.unit,
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    link: {
        textDecoration: 'none',
    },
})

SupplierDetail.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default compose(withWidth(), withApollo, withStyles(styles))(SupplierDetail)
