import gql from 'graphql-tag'

export const getLoggedUser = () => {
    return {
        query: gql`
            {
                loggedUser {
                    id
                    name
                    lastName
                    email
                    phone
                    role
                    entityOrSubject {
                        id
                        name
                    }
                    collegiateNumber
                }
            }
        `,
    }
}

export const getUsers = (offset, limit, order, orderDirection, search, filter) => {
    return {
        query: gql`
            query GetUsers(
                $offset: Int
                $limit: Int
                $order: String
                $orderDirection: String
                $search: String
                $filter: String
            ) {
                allUsers(
                    offset: $offset
                    limit: $limit
                    order: $order
                    orderDirection: $orderDirection
                    search: $search
                    filter: $filter
                ) {
                    users {
                        id
                        name
                        lastName
                        email
                        phone
                        role
                        validated
                        entityOrSubject {
                            id
                            name
                        }
                    }
                    totalCount
                }
            }
        `,
        variables: {
            offset: offset,
            limit: limit,
            order: order,
            orderDirection: orderDirection,
            search: search,
            filter: filter,
        },
    }
}

export const getUserById = (id) => {
    return {
        query: gql`
            query GetUserById($id: ID) {
                user(id: $id) {
                    id
                    name
                    lastName
                    email
                    phone
                    role
                    validated
                    entityOrSubject {
                        id
                        name
                    }
                    collegiateNumber
                }
            }
        `,
        variables: {
            id: id,
        },
    }
}

export const addUser = (name, lastName, email, phone, role, entityOrSubjectId, collegiateNumber) => {
    return {
        mutation: gql`
            mutation AddUser(
                $name: String
                $lastName: String
                $email: String
                $phone: String
                $role: String
                $entityOrSubjectId: ID
                $collegiateNumber: String
            ) {
                addUser(
                    input: {
                        name: $name
                        lastName: $lastName
                        email: $email
                        phone: $phone
                        role: $role
                        entityOrSubjectId: $entityOrSubjectId
                        collegiateNumber: $collegiateNumber
                    }
                ) {
                    id
                }
            }
        `,
        variables: {
            name: name,
            lastName: lastName,
            email: email,
            phone: phone,
            role: role,
            entityOrSubjectId: entityOrSubjectId,
            collegiateNumber: collegiateNumber,
        },
    }
}

export const signupStudent = (name, lastName, phone, email, subjectId, password) => {
    return {
        mutation: gql`
            mutation SignupStudent(
                $name: String
                $lastName: String
                $phone: String
                $email: String
                $entityOrSubjectId: ID
                $password: String
            ) {
                signupStudent(
                    input: {
                        name: $name
                        lastName: $lastName
                        phone: $phone
                        email: $email
                        entityOrSubjectId: $entityOrSubjectId
                        password: $password
                    }
                ) {
                    id
                }
            }
        `,
        variables: {
            name: name,
            lastName: lastName,
            phone: phone,
            email: email,
            entityOrSubjectId: subjectId,
            password: password,
        },
    }
}

export const signupSocialWorker = (name, lastName, phone, email, collegiateNumber, entityId, password) => {
    return {
        mutation: gql`
            mutation SignupSocialWorker(
                $name: String
                $lastName: String
                $phone: String
                $email: String
                $collegiateNumber: String
                $entityOrSubjectId: ID
                $password: String
            ) {
                signupSocialWorker(
                    input: {
                        name: $name
                        lastName: $lastName
                        phone: $phone
                        email: $email
                        collegiateNumber: $collegiateNumber
                        entityOrSubjectId: $entityOrSubjectId
                        password: $password
                    }
                ) {
                    id
                }
            }
        `,
        variables: {
            name: name,
            lastName: lastName,
            phone: phone,
            email: email,
            collegiateNumber: collegiateNumber,
            entityOrSubjectId: entityId,
            password: password,
        },
    }
}

export const updateUser = (id, name, lastName, email, phone, role, entityOrSubjectId, collegiateNumber) => {
    return {
        mutation: gql`
            mutation UpdateUser(
                $id: ID
                $name: String
                $lastName: String
                $email: String
                $phone: String
                $role: String
                $entityOrSubjectId: ID
                $collegiateNumber: String
            ) {
                updateUser(
                    id: $id
                    input: {
                        name: $name
                        lastName: $lastName
                        email: $email
                        phone: $phone
                        role: $role
                        entityOrSubjectId: $entityOrSubjectId
                        collegiateNumber: $collegiateNumber
                    }
                ) {
                    id
                }
            }
        `,
        variables: {
            id: id,
            name: name,
            lastName: lastName,
            email: email,
            phone: phone,
            role: role,
            entityOrSubjectId: entityOrSubjectId,
            collegiateNumber: collegiateNumber,
        },
    }
}

export const updateUserCredentials = (actualPassword, newPassword) => {
    return {
        mutation: gql`
            mutation UpdateUserCredentials($actualPassword: String, $newPassword: String) {
                updateUserCredentials(input: { actualPassword: $actualPassword, newPassword: $newPassword }) {
                    id
                }
            }
        `,
        variables: {
            actualPassword: actualPassword,
            newPassword: newPassword,
        },
    }
}

export const grantAccess = (id) => {
    return {
        mutation: gql`
            mutation GrantAccess($id: ID) {
                grantAccess(id: $id) {
                    id
                }
            }
        `,
        variables: {
            id: id,
        },
    }
}

export const grantAccessBulk = (ids) => {
    return {
        mutation: gql`
            mutation GrantAccessBulk($ids: [ID]) {
                grantAccessBulk(ids: $ids) {
                    id
                }
            }
        `,
        variables: {
            ids: ids,
        },
    }
}

export const revokeAccess = (id) => {
    return {
        mutation: gql`
            mutation RevokeAccess($id: ID) {
                revokeAccess(id: $id) {
                    id
                }
            }
        `,
        variables: {
            id: id,
        },
    }
}
