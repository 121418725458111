import PropTypes from 'prop-types'
import { Button, CircularProgress } from '@material-ui/core'
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined'
import { generateAppointmentPaper } from '../../data/generateAppointmentPaper'
import styles from './style'
import compose from '../../utils/functions'

class DownloadAppointmentPaper extends React.Component {
    state = {
        isLoading: false,
    }

    async generateAndDownloadPaper() {
        this.setState({ isLoading: true })
        const { entityName, fullName, age, appointmentDate, appointmentTime, socialWorker } = this.props

        try {
            const response = await generateAppointmentPaper(
                entityName,
                fullName,
                age,
                appointmentDate,
                appointmentTime,
                socialWorker,
            )

            // Crear un URL temporal para el Blob
            const url = window.URL.createObjectURL(new Blob([response.data]))

            // Crear un elemento de enlace para descargar el PDF
            const link = document.createElement('a')
            link.href = url

            const userFileName = fullName.length > 20 ? fullName.substring(0, 20) : fullName
            const fileName = 'full-visita-' + userFileName.replace(/ /g, '-').toLowerCase() + '.pdf'

            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()

            // Limpiar y remover el enlace
            link.parentNode.removeChild(link)
            window.URL.revokeObjectURL(url)
        } catch (e) {
            console.log(e)
        }

        this.setState({ isLoading: false })
    }

    render() {
        const { classes } = this.props
        const { isLoading } = this.state

        return (
            <Button
                variant="outlined"
                size="large"
                color="primary"
                disabled={isLoading}
                className={classes.actionButton}
                onClick={() => this.generateAndDownloadPaper()}>
                {isLoading ? (
                    <CircularProgress size={24} className={classes.buttonProgress} />
                ) : (
                    <>
                        <DownloadIcon className={classes.rightIcon} />
                        Full de visita
                    </>
                )}
            </Button>
        )
    }
}

DownloadAppointmentPaper.propTypes = {
    entityName: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired,
    age: PropTypes.string.isRequired,
    appointmentDate: PropTypes.string.isRequired,
    appointmentTime: PropTypes.string.isRequired,
    socialWorker: PropTypes.string.isRequired,
}

DownloadAppointmentPaper.defaultProps = {
    entityName: '',
    fullName: '',
    age: '',
    appointmentDate: '',
    appointmentTime: '',
    socialWorker: '',
}

export default compose(withStyles(styles))(DownloadAppointmentPaper)
