import React from 'react'
import PropTypes from 'prop-types'
import compose from '../../../utils/functions'
import { DEFAULT_ROWS_PER_PAGE } from '../../../constants'

// Apollo client
import { withApollo } from '@apollo/client/react/hoc'
import { getSubjects } from '../../../grapql/subject'

// Components imports
import ManagementTableWrapper from '../../../components/management_table_wrapper'

// Design imports
import { Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

// Constants
const columns = [
    { id: 'name', numeric: false, disablePadding: false, label: 'Nom' },
    { id: 'evaluable', numeric: false, disablePadding: false, label: 'Avaluable' },
    { id: 'numberOfStudents', numeric: false, disablePadding: false, label: "Nº d'estudiants", sortable: false },
]

class Subjects extends React.Component {
    state = {
        data: [],
        totalCount: 0,
    }

    componentDidMount() {
        this.fetchData(0, DEFAULT_ROWS_PER_PAGE)
    }

    parseData = (subjectsFromAPI) => {
        var subjects = []

        subjectsFromAPI.forEach((subject) => {
            subjects.push({
                id: subject.id,
                name: subject.name?.toUpperCase(),
                evaluable: subject.evaluable ? 'Sí' : 'No',
                numberOfStudents: subject.numberOfStudents,
            })
        })

        this.setState({ data: subjects })
    }

    fetchData = (offset, limit, orderBy, orderDirection, searchText) => {
        this.props.client.query(getSubjects(offset, limit, orderBy, orderDirection, searchText)).then((result) => {
            this.parseData(result.data.allSubjects.subjects)
            this.setState({ totalCount: result.data.allSubjects.totalCount })
        })
    }

    goToNewSubject = () => {
        this.props.history.push('/new-subject')
    }

    handleClick = (id) => {
        this.props.history.push('/subject/' + id)
    }

    render() {
        const { classes } = this.props
        const { data, totalCount } = this.state

        const actionButtons = (
            <div>
                <Button variant="outlined" color="primary" onClick={() => this.goToNewSubject()}>
                    Nova assignatura
                </Button>
            </div>
        )

        return (
            <ManagementTableWrapper
                title="Llistat d'assignatures"
                rows={data}
                columns={columns}
                actionButtons={actionButtons}
                onRowClick={(id) => this.handleClick(id)}
                totalCount={totalCount}
                fetchData={this.fetchData}
            />
        )
    }
}

const styles = (theme) => ({})

Subjects.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default compose(withApollo, withStyles(styles))(Subjects)
