import React from 'react'
import PropTypes from 'prop-types'
import compose from '../../../utils/functions'
import { ASSEMBLY_STATUS_API, DEFAULT_ROWS_PER_PAGE, ROLE, ROLE_CAST } from '../../../constants'
import { getValueFromStorage } from '../../../utils'

// Apollo client
import { withApollo } from '@apollo/client/react/hoc'
import { getAssemblies } from '../../../grapql/assembly'

// Components imports
import ManagementTableWrapper from '../../../components/management_table_wrapper'

// Design imports
import { Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

// Constants
const columns = [
    // { id: 'type', numeric: false, disablePadding: false, label: 'Tipus' },
    { id: 'casnum', numeric: false, disablePadding: false, label: 'CAS NUM' },
    { id: 'createdAt', numeric: false, date: true, disablePadding: false, label: 'Creat:' },
    // { id: 'group', numeric: false, disablePadding: false, label: 'Grup' },
    { id: 'status', numeric: false, disablePadding: false, label: 'Seguiment' },
    { id: 'orderDate', numeric: false, date: true, disablePadding: false, label: 'Data comanda' },
    { id: 'arrivalDate', numeric: false, date: true, disablePadding: false, label: 'Data arribada' },
    { id: 'assembler', numeric: false, disablePadding: false, label: 'Muntador', sortable: false },
    { id: 'startAssembly', numeric: false, date: true, disablePadding: false, label: 'Inici muntatge' },
    { id: 'endAssembly', numeric: false, date: true, disablePadding: false, label: 'Muntatge acabat' },
    { id: 'expectedDelivery', numeric: false, date: true, disablePadding: false, label: 'Entrega prevista' },
    { id: 'delivery', numeric: false, date: true, disablePadding: false, label: 'Entrega' },
    { id: 'patient', numeric: false, disablePadding: false, label: 'Pacient', sortable: false },
    { id: 'subject', numeric: false, disablePadding: false, label: 'Assignatura', sortable: false },
]

const filterOptions = [
    {
        columnId: 'actualStep',
        label: 'Estat del muntatge',
        options: [
            {
                value: ASSEMBLY_STATUS_API.APPOINTMENT,
                label: 'Visita realitzada',
            },
            {
                value: ASSEMBLY_STATUS_API.ORDER,
                label: 'Comanda realitzada',
            },
            {
                value: ASSEMBLY_STATUS_API.ASSEMBLY,
                label: 'En muntatge',
            },
            {
                value: ASSEMBLY_STATUS_API.REVISION,
                label: 'En revisió',
            },
            {
                value: ASSEMBLY_STATUS_API.VALUATION,
                label: 'Avaluació',
            },
            {
                value: ASSEMBLY_STATUS_API.DELIVERY,
                label: 'Per entregar',
            },
            {
                value: ASSEMBLY_STATUS_API.END,
                label: 'Entregat',
            },
            {
                value: ASSEMBLY_STATUS_API.CANCEL,
                label: 'Cancel·lat',
            },
        ],
    },
]

class Assemblies extends React.Component {
    constructor(props) {
        super(props)

        const role = getValueFromStorage(ROLE)

        this.state = {
            data: [],
            totalCount: 0,
            role: role,
        }
    }

    parseData = (assembliesFromAPI) => {
        var assemblies = []

        assembliesFromAPI.forEach((assembly) => {
            var status = ''
            switch (assembly.actualStep) {
                case ASSEMBLY_STATUS_API.APPOINTMENT:
                    status = 'Visita realitzada'
                    break
                case ASSEMBLY_STATUS_API.ORDER:
                    status = 'Comanda realitzada'
                    break
                case ASSEMBLY_STATUS_API.ASSEMBLY:
                    status = 'En muntatge'
                    break
                case ASSEMBLY_STATUS_API.REVISION:
                    status = 'En revisió'
                    break
                case ASSEMBLY_STATUS_API.VALUATION:
                    status = 'Avaluació'
                    break
                case ASSEMBLY_STATUS_API.DELIVERY:
                    status = 'Per entregar'
                    break
                case ASSEMBLY_STATUS_API.END:
                    status = 'Entregat'
                    break
                /*case ASSEMBLY_STATUS_API.CANCEL:
                    status = 'Cancel·lat';
                    break;*/
            }

            if (assembly.canceled) status = 'Cancel·lat'

            assemblies.push({
                id: assembly.id,
                casnum: assembly.casnum,
                status: status,
                orderDate: assembly.orderDate,
                arrivalDate: assembly.arrivalDate,
                assembler:
                    assembly.assembler !== null
                        ? assembly.assembler.name?.toUpperCase() + ' ' + assembly.assembler.lastName?.toUpperCase()
                        : '',
                startAssembly: assembly.startAssemblyDate,
                endAssembly: assembly.endAssemblyDate,
                expectedDelivery: assembly.expectedDeliveryDate,
                delivery: assembly.deliveryDate,
                patient:
                    assembly.patient !== null
                        ? assembly.patient.name?.toUpperCase() + ' ' + assembly.patient.lastName?.toUpperCase()
                        : '',
                canceled: assembly.canceled,
                createdAt: assembly.createdAt,
                subject: assembly.subject !== null ? assembly.subject.name?.toUpperCase() : '',
            })
        })

        this.setState({ data: assemblies })
    }

    componentDidMount() {
        this.fetchData(0, DEFAULT_ROWS_PER_PAGE)
    }

    fetchData = (offset, limit, orderBy, orderDirection, searchText, filterText) => {
        var castOrderBy = orderBy
        if (orderBy === 'startAssembly') castOrderBy = 'startAssemblyDate'
        else if (orderBy === 'endAssembly') castOrderBy = 'endAssemblyDate'
        else if (orderBy === 'expectedDelivery') castOrderBy = 'expectedDeliveryDate'
        else if (orderBy === 'delivery') castOrderBy = 'deliveryDate'

        this.props.client
            .query(getAssemblies(offset, limit, castOrderBy, orderDirection, searchText, filterText))
            .then((result) => {
                this.parseData(result.data.allAssemblies.assemblies)
                this.setState({ totalCount: result.data.allAssemblies.totalCount })
            })
    }

    goToNewAssembly = () => {
        this.props.history.push('/new-assembly')
    }

    handleClick = (id) => {
        this.props.history.push('/assembly/' + id)
    }

    render() {
        const { classes } = this.props
        const { data, totalCount } = this.state

        const actionButtons =
            this.state.role !== ROLE_CAST.ST ? (
                <div>
                    <Button variant="outlined" color="primary" onClick={() => this.goToNewAssembly()}>
                        Nou muntatge
                    </Button>
                </div>
            ) : (
                <div></div>
            )

        return (
            <ManagementTableWrapper
                title="Llistat de muntatges"
                rows={data}
                columns={columns}
                filterOptions={filterOptions}
                actionButtons={actionButtons}
                onRowClick={(id) => this.handleClick(id)}
                totalCount={totalCount}
                fetchData={this.fetchData}
            />
        )
    }
}

const styles = (theme) => ({})

Assemblies.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default compose(withApollo, withStyles(styles))(Assemblies)
