import React from 'react'
import PropTypes from 'prop-types'
import { getValueFromStorage } from '../../utils'

// Design imports
import { Avatar, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { ROLE, ROLE_CAST } from '../../constants'
import compose from '../../utils/functions'

class ChatMessage extends React.Component {
    state = {
        role: '',
    }

    componentDidMount() {
        const role = getValueFromStorage(ROLE)
        this.setState({ role })
    }

    render() {
        const { classes, text, date, isMine } = this.props

        if (isMine) {
            return (
                <div className={classes.ownMessageContainer}>
                    <Avatar className={classes.ownAvatar}>JO</Avatar>
                    <div className={classes.ownMessageContent}>
                        <div className={classes.ownMessageText}>
                            <Typography variant="body1" classes={{ root: classes.ownMessageTextStyle }}>
                                {text}
                            </Typography>
                        </div>
                        <Typography variant="caption" className={classes.ownMessageDate}>
                            {date}
                        </Typography>
                    </div>
                </div>
            )
        }

        return (
            <div className={classes.messageContainer}>
                <Avatar className={classes.avatar}>{this.state.role != ROLE_CAST.TS ? 'TS' : 'CUV'}</Avatar>
                <div className={classes.messageContent}>
                    <div className={classes.messageText}>
                        <Typography variant="body1">{text}</Typography>
                    </div>
                    <Typography variant="caption" align="right" className={classes.messageDate}>
                        {date}
                    </Typography>
                </div>
            </div>
        )
    }
}

const styles = (theme) => ({
    avatar: {
        height: 48,
        width: 48,
        backgroundColor: theme.palette.primary.light,
    },
    messageContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'flex-start',
        [theme.breakpoints.up('md')]: {
            maxWidth: '80%',
        },
        marginBottom: 16,
    },
    messageContent: {
        marginLeft: 16,
    },
    messageText: {
        padding: 24,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: theme.palette.primary.light,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 16,
        borderBottomLeftRadius: 16,
        borderBottomRightRadius: 16,
        //backgroundColor: '#FAFAFA'
    },
    messageDate: {
        marginTop: 4,
        marginRight: 8,
    },
    ownAvatar: {
        height: 48,
        width: 48,
        backgroundColor: theme.palette.primary.main,
    },
    ownMessageContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row-reverse',
        alignItems: 'flex-start',
        [theme.breakpoints.up('md')]: {
            marginLeft: '20%',
        },
        marginBottom: 16,
    },
    ownMessageContent: {
        marginRight: 16,
    },
    ownMessageText: {
        padding: 24,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: theme.palette.primary.main,
        borderTopLeftRadius: 16,
        borderTopRightRadius: 0,
        borderBottomLeftRadius: 16,
        borderBottomRightRadius: 16,
        backgroundColor: theme.palette.primary.main,
    },
    ownMessageDate: {
        marginTop: 4,
        marginLeft: 8,
    },
    ownMessageTextStyle: {
        color: '#FFFFFF',
    },
})

ChatMessage.propTypes = {
    classes: PropTypes.object.isRequired,
    text: PropTypes.string.isRequired,
    date: PropTypes.object.isRequired,
    isMine: PropTypes.bool,
}

ChatMessage.defaultProps = {
    isMine: false,
}

export default compose(withStyles(styles))(ChatMessage)
