import React from 'react'
import PropTypes from 'prop-types'

// Apollo client
import { withApollo } from '@apollo/client/react/hoc'

// Design imports
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import compose from '../../utils/functions'

const APPOINTMENT_TO_ORDER = 0
const ORDER_TO_ASSEMBLY = 1
const ASSEMBLY_TO_REVISION = 2
const REVISION_TO_VALUATION = 3
const VALUATION_TO_DELIVERY = 4
const DELIVERY_TO_END = 5

class CloseIncidenceDialog extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            closeMessage: '',
            closeMessageError: false,
        }
    }

    validateData = () => {
        var dataIsValid = true

        var errors = {
            closeMessageError: false,
        }

        if (this.state.closeMessage === '') {
            errors.closeMessageError = true
            dataIsValid = false
        }

        this.setState(errors)

        return dataIsValid
    }

    handleConfirm = () => {
        if (this.validateData()) {
            this.props.handleConfirm(this.state.closeMessage)
        }
    }

    render() {
        const { classes } = this.props

        return (
            <Dialog
                open={this.props.showDialog}
                onClose={this.props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">Indiqui perquè s'ha tancat l'incidència:</DialogTitle>
                <DialogContent>
                    <TextField
                        fullWidth
                        error={this.state.closeMessageError}
                        id="assembly-observations"
                        label="Observacions"
                        placeholder="Indiqui si s'ha solucionat la incidència correctament o qualsevol altra observació relativa"
                        multiline
                        rowsMax="4"
                        rows="3"
                        value={this.state.closeMessage}
                        onChange={(event) => this.setState({ closeMessage: event.target.value })}
                        className={classes.textField}
                        variant="outlined"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.handleClose} color="secondary">
                        Cancel·lar
                    </Button>
                    <Button onClick={this.handleConfirm} color="primary" autoFocus>
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

const styles = (theme) => ({
    labelTitle: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
        color: theme.palette.primary.light,
    },
    textField: {
        minWidth: '50%',
        marginTop: 16,
        marginBottom: 16,
    },
})

CloseIncidenceDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    showDialog: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleConfirm: PropTypes.func.isRequired,
}

CloseIncidenceDialog.defaultProps = {
    showDialog: false,
}

export default compose(withApollo, withStyles(styles))(CloseIncidenceDialog)
