import React from 'react'
import PropTypes from 'prop-types'
import compose from '../../utils/functions'

import { withRouter } from 'react-router'

// Apollo client
import { withApollo } from '@apollo/client/react/hoc'
import { getIncidencesList } from '../../grapql/incidence'

// Design imports
import { Button, Divider, List, ListItem, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import moment from 'moment/moment'

class NotificationList extends React.Component {
    // TODO Add limit to incidences list query

    state = {
        incidences: [],
    }

    componentDidMount() {
        this.props.client.query(getIncidencesList()).then((result) => {
            this.setState({ incidences: result.data.allIncidences.incidences })
        })
    }

    goToPatient = (id) => {
        this.props.history.push('/patient/' + id)
    }

    goToNotificationsList = () => {
        this.props.history.push('/incidences')
    }

    handleRowClick = (id, type) => {
        this.props.history.push('/incidence/' + id)
        /*if (type === 'warning') {
            this.props.history.push('/incidence/' + id);
        } else {
            this.props.history.push('/appointment/' + id);
        }*/
    }

    renderListItem = (item, index) => {
        const { classes } = this.props

        var actions = null
        actions = (
            <Button
                variant="outlined"
                color="primary"
                size="small"
                className={classes.actionButton}
                onClick={() => this.handleRowClick(item.id, item.type)}>
                Veure més
            </Button>
        )
        /*if (item.confirmed) {
            actions = (
                <CheckIcon className={classes.actionConfirmedIcon}/>
            );
        } else if (item.type === 'warning') {
            actions = (
                <Button variant='outlined' color='primary' size='small' className={classes.actionButton}>
                    Rebut
                </Button>
            );
        } else if (item.type === 'appointment') {
            actions = (
                <div>
                    <Button variant='flat' color='secondary' size='small' className={classes.actionButton}>
                        Cancel·lar
                    </Button>
                    <Button variant='outlined' color='primary' size='small' className={classes.actionButton}>
                        Confirmar
                    </Button>
                </div>
            );
        }*/

        var warningEdge = null
        if (item.type === 'warning') {
            warningEdge = <div className={classes.warningEdge} />
        }

        return (
            <ListItem divider={index < 4} className={classes.listItem}>
                {warningEdge}
                <div className={classes.listItemContainer}>
                    <Typography variant="subtitle1" color="textPrimary">
                        {item.title}
                    </Typography>
                    <Typography variant="body1" color="textSecondary">
                        {item.messages !== null && item.messages.length !== 0 ? item.messages[0].message : '-'}
                    </Typography>
                    <div className={classes.listItemFooter}>
                        <a
                            className={classes.link}
                            onClick={(event) => {
                                event.stopPropagation()
                                this.goToPatient(item.patient.id)
                            }}>
                            <Typography className={classes.listItemDate} variant="subtitle2" color="textSecondary">
                                {moment(parseInt(item.createdAt)).format('DD/MM/YYYY - HH:mm')} -{' '}
                                {item.patient?.name?.toUpperCase() + ' ' + item.patient?.lastName?.toUpperCase()}
                            </Typography>
                        </a>
                        {actions}
                    </div>
                </div>
            </ListItem>
        )
    }

    render() {
        const { classes } = this.props
        const { incidences } = this.state

        return (
            <List className={classes.list}>
                <div className={classes.listContent}>
                    {incidences.slice(0, 5).map((item, index) => this.renderListItem(item, index))}
                </div>
                <Divider />
                <ListItem>
                    <Button fullWidth color="primary" onClick={() => this.goToNotificationsList()}>
                        Veure totes
                    </Button>
                </ListItem>
            </List>
        )
    }
}

const styles = (theme) => ({
    list: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    listItem: {
        paddingRight: '16px',
        paddingLeft: '24px',
    },
    listItemContainer: {
        flex: 1,
    },
    listItemDate: {
        paddingTop: '8px',
        color: theme.palette.primary.light,
    },
    link: {
        textDecoration: 'none',
        cursor: 'pointer',
    },
    listItemFooter: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingTop: '8px',
    },
    actionButton: {
        width: '100px',
        marginLeft: '8px',
    },
    actionConfirmedIcon: {
        color: theme.palette.primary.light,
    },
    warningEdge: {
        position: 'absolute',
        width: '4px',
        height: '100%',
        marginLeft: '-24px',
        marginRight: '20px',
        backgroundColor: theme.palette.secondary.main,
    },
    listContent: {
        maxHeight: '200px',
        overflow: 'hidden',
        overflowY: 'scroll',
    },
})

NotificationList.propTypes = {
    classes: PropTypes.object.isRequired,
    //notifications: PropTypes.array.isRequired
}

export default compose(withRouter, withApollo, withStyles(styles))(NotificationList)
