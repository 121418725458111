import React from 'react'
import PropTypes from 'prop-types'
import compose from '../../utils/functions'
import { DEFAULT_ROWS_PER_PAGE } from '../../constants'
import moment from 'moment'

// Components imports
import EnhancedTableHead from '../../components/enhanced_table_head'
import EnhancedTableToolbar from '../../components/enhanced_table_toolbar'
import TablePaginationActions from '../../components/table_pagination_actions'

// Design imports
import {
    Checkbox,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TablePagination,
    TableRow,
    withWidth,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { isWidthUp } from '@material-ui/core/withWidth'

// Auxiliar functions

function desc(a, b, orderBy) {
    if (a[orderBy] === '' || a[orderBy] === null || a[orderBy] === '-') {
        return 1
    }
    if (b[orderBy] === '' || b[orderBy] === null || b[orderBy] === '-') {
        return -1
    }

    if (typeof a[orderBy] === 'string' && typeof b[orderBy] === 'string') {
        return a[orderBy].localeCompare(b[orderBy])
    }

    if (b[orderBy] < a[orderBy]) {
        return -1
    }
    if (b[orderBy] > a[orderBy]) {
        return 1
    }
    return 0
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index])

    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0])
        if (order !== 0) return order
        return a[1] - b[1]
    })

    return stabilizedThis.map((el) => el[0])
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy)
}

function isSortable(order, columns) {
    for (let column of columns) {
        if (column.id === order && column.sortable === false) {
            return false
        }
    }

    return true
}

// Class
class EnhancedTable extends React.Component {
    state = {
        order: this.props.order,
        orderBy: this.props.orderBy,
        selected: [],
        page: 0,
        rowsPerPage: this.props.rowsPerPage,
    }

    handleRequestSort = (event, property) => {
        const orderBy = property

        if (isSortable(orderBy, this.props.columns)) {
            let order = 'asc'

            if (this.state.orderBy === property && this.state.order === 'asc') {
                order = 'desc'
            }

            this.setState({ order, orderBy })
            this.props.handleRequestSort(orderBy, order, this.state.rowsPerPage)
        }
    }

    handleSelectAllClick = (event) => {
        if (event.target.checked) {
            this.setState((state) => ({ selected: this.props.rows.map((n) => n.id) }))
            return
        }
        this.setState({ selected: [] })
    }

    handleClick = (event, id, isCheckbox) => {
        if (this.props.onRowClick !== null && isCheckbox === false) {
            this.props.onRowClick(id)
        } else {
            const { selected } = this.state
            const selectedIndex = selected.indexOf(id)
            let newSelected = []

            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selected, id)
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selected.slice(1))
            } else if (selectedIndex === selected.length - 1) {
                newSelected = newSelected.concat(selected.slice(0, -1))
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
            }

            this.setState({ selected: newSelected })
        }

        //event.stopPropagation();
    }

    handleChangePage = (event, page) => {
        this.setState({ page, selected: [] })
        this.props.handleChangePage(page, this.state.rowsPerPage)
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value, selected: [] })
        this.props.handleChangePage(this.state.page, event.target.value)
    }

    isSelected = (id) => {
        return this.state.selected.indexOf(id) !== -1
    }

    /*renderColumn = (index) => {
        return (

        );
    };*/

    renderRow = (row) => {
        const { classes, columns } = this.props
        const isSelected = this.isSelected(row.id)

        return (
            <TableRow
                hover
                onClick={(event) => this.handleClick(event, row.id, false)}
                role="checkbox"
                aria-checked={isSelected}
                tabIndex={-1}
                key={row.id}
                selected={isSelected}
                className={classes.pointer}>
                <TableCell padding="checkbox">
                    <Checkbox
                        onClick={(event, checked) => {
                            event.stopPropagation()
                            this.handleClick(event, row.id, true)
                        }}
                        checked={isSelected}
                    />
                </TableCell>
                {columns.map((item, index) => {
                    var value = row[columns[index].id]
                    if (columns[index].date === true) {
                        if (value === '' || value === null || value === undefined) {
                            value = ''
                        } else {
                            value = moment(parseInt(value)).format('DD/MM/YYYY')
                        }
                    }
                    return <TableCell className={classes.tableCell}>{value}</TableCell>
                })}
                {/*<TableCell component='th' scope='row' >{ row[columns[0].id] }</TableCell>
                <TableCell>{ row[columns[1].id] }</TableCell>
                <TableCell>{ (row.scheduledVisit === null) ? '-' : row.scheduledVisit }</TableCell>
                <TableCell>{ (row.trackingStatus === null) ? '-' : row.trackingStatus }</TableCell>*/}
            </TableRow>
        )
    }

    render() {
        const { classes } = this.props
        const { order, orderBy, selected, data, page, rowsPerPage } = this.state
        const { title, columns, rows, actionButtons, filterOptions, onValidateClicked, searchDisabled } = this.props

        // const rowsFromPage = (rows[page] !== undefined) ? rows[page] : [];
        const rowsFromPage = rows.length <= rowsPerPage ? rows : rows.slice(0, rowsPerPage)
        const emptyRows =
            rowsPerPage -
            rowsFromPage.length /*rows.length*/ /*Math.min(rowsPerPage, rows[page].length - page * rowsPerPage)*/

        return (
            <Paper className={classes.root}>
                <EnhancedTableToolbar
                    numSelected={selected.length}
                    title={title}
                    filterOptions={filterOptions}
                    onSearchTextChange={(value) => this.props.onSearchTextChange(value, rowsPerPage)}
                    onFilterUpdate={(selectedValues) => this.props.onFilterUpdate(selectedValues, rowsPerPage)}
                    onFilterReset={() => this.props.onFilterReset(rowsPerPage)}
                    onValidateClicked={
                        onValidateClicked !== null
                            ? () => this.props.onValidateClicked(selected, page, rowsPerPage)
                            : null
                    }
                    searchDisabled={searchDisabled}
                />
                <div className={classes.tableWrapper}>
                    <Table className={classes.table}>
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={this.handleSelectAllClick}
                            onRequestSort={this.handleRequestSort}
                            rowCount={rows.length}
                            columns={columns}
                        />
                        <TableBody>
                            {
                                /*stableSort(rowsFromPage, getSorting(order, orderBy))
                                    /!*.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)*!/
                                    .map(row => this.renderRow(row))*/
                                rowsFromPage.map((row) => this.renderRow(row))
                            }
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 49 * emptyRows }}>
                                    <TableCell colSpan={columns.length + 1} />
                                </TableRow>
                            )}
                        </TableBody>
                        {/* Paginations buttons for mobile version */}
                        {isWidthUp('md', this.props.width) === false ? (
                            <TableFooter>
                                <TableRow>
                                    <TableCell colSpan={2}>{actionButtons}</TableCell>
                                    <TablePagination
                                        colSpan={columns.length}
                                        count={this.props.totalCount > 0 ? this.props.totalCount : rows.length}
                                        rowsPerPage={rowsPerPage}
                                        rowsPerPageOptions={[5, 10, 15, 20, 25]}
                                        labelRowsPerPage="Fileres per pàgina:"
                                        page={page}
                                        onChangePage={this.handleChangePage}
                                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                    />
                                </TableRow>
                            </TableFooter>
                        ) : null}
                    </Table>
                </div>
                {/* TODO: Revisar si hay alguna manera mejor de controlar el footer con colSpan */}
                {isWidthUp('md', this.props.width) ? (
                    <div className={classes.cardFooter}>
                        {actionButtons}
                        <TablePagination
                            component="div"
                            count={this.props.totalCount > 0 ? this.props.totalCount : rows.length}
                            rowsPerPage={rowsPerPage}
                            rowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                            labelRowsPerPage="Fileres per pàgina:"
                            page={page}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </div>
                ) : null}
            </Paper>
        )
    }
}

const styles = (theme) => ({
    root: {
        flex: 1,
    },
    table: {
        minWidth: 700,
        //tableLayout: 'auto'
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    tableFooter: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    tableCell: {
        minWidth: 180,
    },
    tablePagination: {
        flex: 1,
    },
    pointer: {
        cursor: 'pointer',
    },
    cardFooter: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-between',
        minHeight: 56,
        overflow: 'hidden',
        marginLeft: 24,
    },
})

EnhancedTable.propTypes = {
    title: PropTypes.string.isRequired,
    //numSelected: PropTypes.number,
    //onRequestSort: PropTypes.func,
    //onSelectAllClick: PropTypes.func,
    onRowClick: PropTypes.func,
    order: PropTypes.string,
    orderBy: PropTypes.string,
    columns: PropTypes.object.isRequired,
    filterOptions: PropTypes.array,
    rows: PropTypes.object.isRequired,
    //onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    totalCount: PropTypes.number,
    handleChangePage: PropTypes.func,
    handleRequestSort: PropTypes.func,
    onSearchTextChange: PropTypes.func,
    onFilterUpdate: PropTypes.func,
    onFilterReset: PropTypes.func,
    onValidateClicked: PropTypes.func,
}

EnhancedTable.defaultProps = {
    order: 'asc',
    onRowClick: null,
    orderBy: '',
    page: 0,
    totalCount: 0,
    filterOptions: [],
    rowsPerPage: DEFAULT_ROWS_PER_PAGE,
    handleChangePage: (page, rowsPerPage) => {},
    handleRequestSort: (orderBy, orderDirection, rowsPerPage) => {},
    onSearchTextChange: (value, rowsPerPage) => {},
    onFilterUpdate: (selectedValues, rowsPerPage) => {},
    onFilterReset: (rowsPerPage) => {},
    onValidateClicked: null,
    searchDisabled: false,
}

export default compose(withWidth(), withStyles(styles))(EnhancedTable)
