import axios from 'axios'

export const generateAppointmentPaper = (entityName, fullName, age, appointmentDate, appointmentTime, socialWorker) => {
    const BASE_URL = 'https://cuv-social-pdf-generator-production.up.railway.app'
    // const BASE_URL = 'http://localhost:3500'

    return axios({
        method: 'post',
        url: BASE_URL + '/generate-pdf',
        data: {
            entityName,
            fullName,
            age,
            appointmentDate,
            appointmentTime,
            socialWorker,
        },
        responseType: 'blob',
    })
}
