import React from 'react'
import PropTypes from 'prop-types'
import compose from '../../utils/functions'

// Apollo client
import { withApollo } from '@apollo/client/react/hoc'

// Components imports
import NewIncidenceStepper from '../../components/new_incidence_stepper'
import FeedbackSnackbar from '../../components/feedback_snackbar'

// Design imports
import { withStyles } from '@material-ui/core/styles'
import styles from './styles'

class NewIncidence extends React.Component {
    state = {
        confirmSnackbarOpened: false,
    }

    handleClose = () => {
        this.setState({ confirmSnackbarOpened: false })
    }

    handleConfirmButtonClick = () => {
        this.setState({ confirmSnackbarOpened: true })
        setTimeout(() => {
            this.props.history.goBack()
        }, 3000)
    }

    render() {
        const { classes } = this.props

        const patient = this.props.location.state !== null ? this.props.location.state : null

        return (
            <div className={classes.root}>
                <NewIncidenceStepper handleConfirm={() => this.handleConfirmButtonClick()} selectedPatient={patient} />
                <FeedbackSnackbar
                    showSnackbar={this.state.confirmSnackbarOpened}
                    handleClose={() => this.handleClose()}
                    timeShowed={3000}
                    text={'Incidència creada correctament'}
                />
            </div>
        )
    }
}

NewIncidence.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default compose(withApollo, withStyles(styles))(NewIncidence)
