import React from 'react'
import PropTypes from 'prop-types'
import compose, { getCityName, getNationalityName } from '../../../utils/functions'
import ca from 'moment/locale/ca'
import { getValueFromStorage } from '../../../utils'

// Date managment
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from 'material-ui-pickers'
import moment from 'moment'

// Components imports
import FeedbackSnackbar from '../../../components/feedback_snackbar/index'

// Apollo client
import { withApollo } from '@apollo/client/react/hoc'
import { getPatientById, removePatient, updatePatient } from '../../../grapql/patient'
import { getAllSocialWorkerFromEntity } from '../../../grapql/social_workers'

// Design imports
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    MobileStepper,
    Paper,
    Tooltip,
    Typography,
} from '@material-ui/core'
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@material-ui/lab/'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import UserIcon from '@material-ui/icons/PermIdentity'
import TrackIcon from '@material-ui/icons/TrackChanges'
import HistoryIcon from '@material-ui/icons/History'
import WarningIcon from '@material-ui/icons/Warning'
import EditIcon from '@material-ui/icons/Edit'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import CheckIcon from '@material-ui/icons/Check'
import NewWarning from '@material-ui/icons/PlaylistAdd'
import DeleteIcon from '@material-ui/icons/Delete'
import VisibilityIcon from '@material-ui/icons/Visibility'
import InboxIcon from '@material-ui/icons/AllInbox'
import { ASSEMBLY_STATUS_API, ROLE, ROLE_CAST } from '../../../constants'
import TextLabel from '../../../components/atom/TextLabel'
import NewPatientComponent from '../../../components/molecule/NewPatient/NewPatient'

// Constants
const APPOINTMENT_SECTION = 0
const ORDER_SECTION = 1
const WORKING_SECTION = 2
const DELIVERY_SECTION = 3
const END_SECTION = 4
const steps = [
    {
        label: 'Visita programada',
        labelAfter: 'Visita realitzada',
        sectionId: APPOINTMENT_SECTION,
    },
    {
        label: 'Comanda realitzada',
        labelAfter: 'Comanda rebuda',
        sectionId: ORDER_SECTION,
    },
    {
        label: 'En muntatge',
        labelAfter: 'Muntatge realitzat',
        sectionId: WORKING_SECTION,
    },
    {
        label: 'Per entregar',
        labelAfter: 'Entrega realitzada',
        sectionId: DELIVERY_SECTION,
    },
    {
        label: 'Tractament finalitzat',
        sectionId: END_SECTION,
    },
]

const actions = [
    { icon: <InboxIcon />, name: 'Crear muntatge' },
    { icon: <HistoryIcon />, name: 'Donar visita' },
    { icon: <NewWarning />, name: 'Crear incidencia' },
    { icon: <DeleteIcon />, name: 'Eliminar pacient' },
]

class PatientDetailPDI extends React.Component {
    constructor(props) {
        super(props)
        this.newPatientComponentRef = React.createRef()

        const role = getValueFromStorage(ROLE)

        this.state = {
            patient: {
                appointments: [],
                incidences: [],
                assemblies: [],
                lastAssembly: {},
            },
            newPatientData: null,
            socialWorkers: [],
            activeStep: 0,
            lastStepCompleted: WORKING_SECTION,
            fabOpened: false,
            userRole: role,
            deleteDialogOpened: false,
            confirmedDeleteSnackbarOpened: false,
            showEditPatient: false,
            socialWorker: '',
            socialWorkerError: false,
        }
    }

    parseData = (patientFromAPI) => {
        const age = moment().diff(moment(parseInt(patientFromAPI.birthdate)), 'years')
        const birthdateMoment = moment(parseInt(patientFromAPI.birthdate))

        const patient = {
            id: patientFromAPI.id,
            name: patientFromAPI.name?.toUpperCase(),
            lastName: patientFromAPI.lastName?.toUpperCase(),
            birthdate: moment(parseInt(patientFromAPI.birthdate)).format('DD/MM/YYYY'),
            age: age,
            email: patientFromAPI.email ? patientFromAPI.email.toUpperCase() : '-',
            phone: patientFromAPI.phone,
            centerId: patientFromAPI.entity.id,
            center: patientFromAPI.entity.name?.toUpperCase(),
            socialWorkerId: patientFromAPI.socialWorkerId,
            socialWorkerName:
                patientFromAPI.socialWorker !== null
                    ? patientFromAPI.socialWorker.name?.toUpperCase() +
                      ' ' +
                      patientFromAPI.socialWorker.lastName?.toUpperCase()
                    : '-',
            appointments: patientFromAPI.appointments,
            incidences: patientFromAPI.incidences,
            //assemblies: patientFromAPI.assemblies,
            lastAssembly: patientFromAPI.lastAssembly,
            idNumber: patientFromAPI.idNumber,
            nationality: patientFromAPI.nationality,
            address: patientFromAPI.address,
            city: patientFromAPI.city,
            postalCode: patientFromAPI.postalCode,
        }

        const newPatientData = {
            name: patient.name,
            lastName: patient.lastName,
            idNumber: patient.idNumber,
            nationality: patient.nationality,
            birthdate: birthdateMoment,
            phone: patient.phone,
            email: patient.email,
            address: patient.address,
            city: patient.city,
            postalCode: patient.postalCode,
            center: patient.center,
            socialWorker: patient.socialWorkerId,
        }

        if (patientFromAPI.assemblies !== null) {
            patient.assemblies = patientFromAPI.assemblies.map((item) => {
                var status = ''
                switch (item.actualStep) {
                    case ASSEMBLY_STATUS_API.APPOINTMENT:
                        status = 'Visita realitzada'
                        break
                    case ASSEMBLY_STATUS_API.ORDER:
                        status = 'Comanda realitzada'
                        break
                    case ASSEMBLY_STATUS_API.ASSEMBLY:
                        status = 'En muntatge'
                        break
                    case ASSEMBLY_STATUS_API.REVISION:
                        status = 'En revisió'
                        break
                    case ASSEMBLY_STATUS_API.VALUATION:
                        status = 'Avaluació'
                        break
                    case ASSEMBLY_STATUS_API.DELIVERY:
                        status = 'Per entregar'
                        break
                    case ASSEMBLY_STATUS_API.END:
                        status = 'Entregat'
                        break
                    case ASSEMBLY_STATUS_API.CANCEL:
                        status = 'Cancel·lat'
                        break
                }

                return {
                    id: item.id,
                    casnum: item.casnum,
                    actualStep: status,
                    createdAt: item.createdAt,
                }
            })
        }

        var activeStep = 0
        var lastStep = 0

        if (patient.lastAssembly !== null) {
            const actualStepAPI = patient.lastAssembly.actualStep
            if (actualStepAPI === ASSEMBLY_STATUS_API.ORDER) {
                activeStep = ORDER_SECTION
                lastStep = ORDER_SECTION
            } else if (
                actualStepAPI === ASSEMBLY_STATUS_API.ASSEMBLY ||
                actualStepAPI === ASSEMBLY_STATUS_API.REVISION ||
                actualStepAPI === ASSEMBLY_STATUS_API.VALUATION
            ) {
                activeStep = WORKING_SECTION
                lastStep = WORKING_SECTION
            } else if (actualStepAPI === ASSEMBLY_STATUS_API.DELIVERY) {
                activeStep = DELIVERY_SECTION
                lastStep = DELIVERY_SECTION
            } else if (actualStepAPI === ASSEMBLY_STATUS_API.END) {
                activeStep = END_SECTION
                lastStep = END_SECTION
            }
        }

        this.setState({
            patient: patient,
            newPatientData: newPatientData,
            activeStep: activeStep,
            lastStepCompleted: lastStep,
            name: patient.name,
            lastName: patient.lastName,
            birthdate: birthdateMoment,
            email: patient.email,
            phone: patient.phone,
            idNumber: patient.idNumber,
            nationality: patient.nationality,
            address: patient.address,
            city: patient.city,
            postalCode: patient.postalCode,
        })

        this.fetchSocialWorkers(patient.centerId, patient.socialWorkerId)
    }

    fetchPatientDetail = () => {
        this.props.client
            .query(getPatientById(this.props.match.params.id))
            .then((result) => this.parseData(result.data.patient))
    }

    fetchSocialWorkers = (entityId, socialWorkerId) => {
        this.props.client.query(getAllSocialWorkerFromEntity(entityId)).then((result) => {
            this.setState({ socialWorkers: result.data.allSocialWorkersFromEntity, socialWorker: socialWorkerId })
        })
    }

    componentDidMount() {
        this.fetchPatientDetail()
    }

    goToNewAppointment = () => {
        this.props.history.push({
            pathname: '/new-appointment',
            state: {
                id: this.props.match.params.id,
                name: this.state.patient.name,
                lastName: this.state.patient.lastName,
            },
        })
    }

    goToNewAssembly = () => {
        this.props.history.push({
            pathname: '/new-assembly',
            state: {
                id: this.props.match.params.id,
                name: this.state.patient.name,
                lastName: this.state.patient.lastName,
            },
        })
    }

    goToNewIncidence = () => {
        this.props.history.push({
            pathname: '/new-incidence',
            state: {
                id: this.props.match.params.id,
                name: this.state.patient.name,
                lastName: this.state.patient.lastName,
            },
        })
    }

    goToAppointment = (id) => {
        this.props.history.push('/appointment/' + id)
    }

    goToIncidences = (id) => {
        this.props.history.push('/incidence/' + id)
    }

    goToAssembly = (id) => {
        this.props.history.push('/assembly/' + id)
    }

    updatePatient = () => {
        const newPatientComponent = this.newPatientComponentRef.current

        if (newPatientComponent.validateData()) {
            let patient = newPatientComponent.state.patient

            if (patient) {
                const mutation = updatePatient(
                    this.props.match.params.id,
                    patient.name,
                    patient.lastName,
                    patient.birthdate.valueOf().toString(),
                    patient.email,
                    patient.phone,
                    patient.socialWorker,
                    patient.idNumber,
                    patient.nationality,
                    patient.address,
                    patient.city,
                    patient.postalCode,
                )

                this.props.client.mutate(mutation).then((result) => this.fetchPatientDetail())
                this.setState({ showEditPatient: false, showEditSocialWorker: false })
            }
        }
    }

    deletePatient = () => {
        const mutation = removePatient(this.props.match.params.id)
        this.props.client.mutate(mutation).then((result) => {
            this.setState({ deleteDialogOpened: false, confirmedDeleteSnackbarOpened: true })
            setTimeout(() => {
                this.props.history.goBack()
            }, 3000)
        })
    }

    handleCloseDialog = () => {
        this.setState({
            deleteDialogOpened: false,
            confirmedDeleteSnackbarOpened: false,
        })
    }

    handleDeletePatient = () => {
        this.setState({ deleteDialogOpened: true })
    }

    handleNext = () => {
        if (this.state.activeStep + 1 <= this.state.lastStepCompleted) {
            this.setState((state) => ({
                activeStep: state.activeStep + 1,
            }))
        }
    }

    handleBack = () => {
        this.setState((state) => ({
            activeStep: state.activeStep - 1,
        }))
    }

    handleClick = () => {
        this.setState((state) => ({
            fabOpened: !state.fabOpened,
        }))
    }

    handleActionClick = (action) => {
        if (action === actions[0].name) {
            this.goToNewAssembly()
        } else if (action === actions[1].name) {
            this.goToNewAppointment()
        } else if (action === actions[2].name) {
            this.goToNewIncidence()
        } else if (action === actions[3].name) {
            this.handleDeletePatient()
        }

        this.handleClick()
    }

    handleOpen = () => {
        this.setState({
            fabOpened: true,
        })
    }

    handleClose = () => {
        this.setState({
            fabOpened: false,
        })
    }

    handleSocialWorkerChange = (name) => (event) => {
        this.setState({
            socialWorker: event.target.value,
        })
    }

    renderTrackSection = () => {
        const { classes } = this.props
        const { activeStep, patient } = this.state

        if (this.state.patient.lastAssembly === null) {
            return (
                <div className={classes.trackEndSection}>
                    {/*<CheckIcon className={classes.trackEndIcon} style={{ fontSize: 40 }}/>*/}
                    <Typography className={classes.trackEndTitle} variant="subtitle1">
                        No hi ha cap muntatge en curs
                    </Typography>
                </div>
            )
        }

        switch (activeStep) {
            case APPOINTMENT_SECTION:
                return (
                    <Grid container spacing={16}>
                        <Grid item xs={12} sm={6}>
                            <Typography className={classes.labelTitle} variant="subtitle2">
                                Data de la visita:
                            </Typography>
                            <Typography className={classes.label} variant="h6">
                                {moment(parseInt(patient.lastAssembly.appointmentDate))
                                    .locale('ca', ca)
                                    .format('dddd D MMMM - DD/MM/YYYY')}
                            </Typography>
                        </Grid>
                    </Grid>
                )
            case ORDER_SECTION:
                return (
                    <Grid container spacing={16}>
                        <Grid item xs={12} sm={6}>
                            <Typography className={classes.labelTitle} variant="subtitle2">
                                Data comanda:
                            </Typography>
                            <Typography className={classes.label} variant="h6">
                                {moment(parseInt(patient.lastAssembly.orderDate))
                                    .locale('ca', ca)
                                    .format('dddd D MMMM - DD/MM/YYYY')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography className={classes.labelTitle} variant="subtitle2">
                                Data arribada:
                            </Typography>
                            <Typography className={classes.label} variant="h6">
                                {patient.lastAssembly.arrivalDate !== null
                                    ? moment(parseInt(patient.lastAssembly.arrivalDate))
                                          .locale('ca', ca)
                                          .format('dddd D MMMM - DD/MM/YYYY')
                                    : '-'}
                            </Typography>
                        </Grid>
                    </Grid>
                )
            case WORKING_SECTION:
                return (
                    <Grid container spacing={16}>
                        <Grid item xs={12} sm={6}>
                            <Typography className={classes.labelTitle} variant="subtitle2">
                                Data assignació:
                            </Typography>
                            <Typography className={classes.label} variant="h6">
                                {moment(parseInt(patient.lastAssembly.startAssemblyDate))
                                    .locale('ca', ca)
                                    .format('dddd D MMMM - DD/MM/YYYY')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography className={classes.labelTitle} variant="subtitle2">
                                Data de finalització:
                            </Typography>
                            <Typography className={classes.label} variant="h6">
                                {patient.lastAssembly.endAssemblyDate !== null
                                    ? moment(parseInt(patient.lastAssembly.endAssemblyDate))
                                          .locale('ca', ca)
                                          .format('dddd D MMMM - DD/MM/YYYY')
                                    : '-'}
                            </Typography>
                        </Grid>
                    </Grid>
                )
            case DELIVERY_SECTION:
                return (
                    <Grid container spacing={16}>
                        <Grid item xs={12} sm={6}>
                            <Typography className={classes.labelTitle} variant="subtitle2">
                                Data d'entrega programada:
                            </Typography>
                            <Typography className={classes.label} variant="h6">
                                {patient.lastAssembly.expectedDeliveryDate !== null
                                    ? moment(parseInt(patient.lastAssembly.expectedDeliveryDate))
                                          .locale('ca', ca)
                                          .format('dddd D MMMM - DD/MM/YYYY')
                                    : '-'}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography className={classes.labelTitle} variant="subtitle2">
                                Data d'entrega:
                            </Typography>
                            <Typography className={classes.label} variant="h6">
                                {patient.lastAssembly.deliveryDate !== null
                                    ? moment(parseInt(patient.lastAssembly.deliveryDate))
                                          .locale('ca', ca)
                                          .format('dddd D MMMM - DD/MM/YYYY')
                                    : '-'}
                            </Typography>
                        </Grid>
                    </Grid>
                )
            case END_SECTION:
                return (
                    <div className={classes.trackEndSection}>
                        <CheckIcon className={classes.trackEndIcon} style={{ fontSize: 40 }} />
                        <Typography className={classes.trackEndTitle} variant="subtitle1">
                            Tractament finalitzat amb èxit
                        </Typography>
                    </div>
                )
        }
    }

    renderRemoveDialogAndSnackbar = () => {
        const { classes } = this.props
        const { user } = this.state

        return (
            <div>
                <Dialog
                    open={this.state.deleteDialogOpened}
                    onClose={this.handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">{'Està segur que vol eliminar aquest pacient?'}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Un cop eliminat el pacient no es podra recuperar.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseDialog} color="secondary">
                            No
                        </Button>
                        <Button onClick={this.deletePatient} color="primary" autoFocus>
                            Sí, eliminar pacient
                        </Button>
                    </DialogActions>
                </Dialog>
                <FeedbackSnackbar
                    showSnackbar={this.state.confirmedDeleteSnackbarOpened}
                    handleClose={() => this.handleClose()}
                    timeShowed={3000}
                    text={'Pacient eliminat correctament'}
                />
            </div>
        )
    }

    renderEditPatient = () => {
        const { classes } = this.props
        const { newPatientData, socialWorkers } = this.state

        return (
            <div>
                <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                    <NewPatientComponent
                        innerRef={this.newPatientComponentRef}
                        patient={newPatientData}
                        socialWorkers={socialWorkers}
                        isUpdating={true}
                        isSocialWorker={false}
                    />
                    <Divider />
                    <div className={classes.cardFooter}>
                        <Button
                            variant="outlined"
                            color="secondary"
                            size="large"
                            className={classes.actionButton}
                            onClick={() => this.setState({ showEditPatient: false })}>
                            Cancel·lar
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            className={classes.actionButton}
                            onClick={() => this.updatePatient()}>
                            Desar els canvis
                        </Button>
                    </div>
                </MuiPickersUtilsProvider>
            </div>
        )
    }

    render() {
        const { classes, match } = this.props
        const { patient, activeStep, fabOpened } = this.state

        return (
            <div className={classes.root}>
                <SpeedDial
                    ariaLabel="Actions"
                    className={classes.fab}
                    hidden={false}
                    icon={<SpeedDialIcon />}
                    onBlur={this.handleClose}
                    onClick={this.handleClick}
                    onClose={this.handleClose}
                    onFocus={this.handleOpen}
                    onMouseEnter={this.handleOpen}
                    onMouseLeave={this.handleClose}
                    open={fabOpened}>
                    {actions.map((action) => (
                        <SpeedDialAction
                            className={classes.actionDial}
                            key={action.name}
                            icon={action.icon}
                            tooltipTitle={action.name}
                            onClick={() => this.handleActionClick(action.name)}
                        />
                    ))}
                </SpeedDial>

                <Grid container spacing={24}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Paper className={classes.paperRoot}>
                            <div className={classes.cardHeader}>
                                <div className={classes.cardTitleContainer}>
                                    <UserIcon color="primary" style={{ fontSize: 28 }} />
                                    <Typography className={classes.title} variant="h6" color="primary">
                                        Informació del pacient
                                    </Typography>
                                </div>
                                <Tooltip title="Editar">
                                    {this.state.showEditPatient ? (
                                        <IconButton
                                            aria-label="Editar"
                                            className={classes.actions}
                                            onClick={() => this.setState({ showEditPatient: false })}>
                                            <CloseIcon />
                                        </IconButton>
                                    ) : (
                                        <IconButton
                                            aria-label="Editar"
                                            className={classes.actions}
                                            onClick={() => this.setState({ showEditPatient: true })}>
                                            <EditIcon />
                                        </IconButton>
                                    )}
                                </Tooltip>
                            </div>
                            <Divider />
                            {this.state.showEditPatient ? (
                                this.renderEditPatient()
                            ) : (
                                <div>
                                    <Grid className={classes.cardContainer} container spacing={16}>
                                        <TextLabel title={'Nom:'} value={patient.name} xs={12} sm={6} />
                                        <TextLabel title={'Cognoms:'} value={patient.lastName} xs={12} sm={6} />
                                        <TextLabel
                                            title={'Data de naixement:'}
                                            value={patient.birthdate}
                                            xs={12}
                                            sm={6}
                                        />
                                        <TextLabel title={'Edat:'} value={patient.age + ' anys'} xs={12} sm={6} />
                                        <TextLabel
                                            title={'DNI/NIE/Passaport:'}
                                            value={patient.idNumber}
                                            xs={12}
                                            sm={6}
                                        />
                                        <TextLabel
                                            title={'Nacionalitat:'}
                                            value={getNationalityName(patient.nationality)}
                                            xs={12}
                                            sm={6}
                                        />
                                        <TextLabel
                                            title={'Email del pacient:'}
                                            value={patient.email}
                                            type={'email'}
                                            xs={12}
                                            sm={6}
                                        />
                                        <TextLabel
                                            title={'Telèfon:'}
                                            value={patient.phone}
                                            type={'phone'}
                                            xs={12}
                                            sm={6}
                                        />
                                        <TextLabel title={'Adreça:'} value={patient.address} xs={12} sm={6} />
                                        <TextLabel
                                            title={'Municipi:'}
                                            value={getCityName(patient.city)}
                                            xs={12}
                                            sm={6}
                                        />
                                        <TextLabel title={'Codi postal:'} value={patient.postalCode} xs={12} sm={6} />
                                        <TextLabel title={'Entitat:'} value={patient.center} xs={12} sm={6} />
                                        <TextLabel
                                            title={'Treballador social:'}
                                            value={patient.socialWorkerName}
                                            xs={12}
                                            sm={6}
                                        />
                                    </Grid>
                                </div>
                            )}
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                        <Paper className={classes.paperRoot}>
                            <div className={classes.cardHeader}>
                                <div className={classes.cardTitleContainer}>
                                    <TrackIcon color="primary" style={{ fontSize: 28 }} />
                                    <Typography className={classes.title} variant="h6" color="primary">
                                        Seguiment
                                    </Typography>
                                </div>
                                <Typography variant="subtitle1" color="textSecondary">
                                    CAS NUM: {patient.lastAssembly !== null ? patient.lastAssembly.casnum : '-'}
                                </Typography>
                            </div>
                            <Divider />
                            <div>
                                <div className={classes.trackContainer}>{this.renderTrackSection()}</div>
                                <div className={classes.trackSectionTitleContainer}>
                                    <Typography
                                        className={classes.trackSectionTitle}
                                        variant="subtitle1"
                                        color="primary">
                                        {steps[activeStep].label}
                                    </Typography>
                                </div>
                                <MobileStepper
                                    className={classes.mobileStepper}
                                    variant="progress"
                                    steps={steps.length}
                                    position="static"
                                    activeStep={this.state.activeStep}
                                    nextButton={
                                        <Button
                                            size="small"
                                            onClick={this.handleNext}
                                            disabled={
                                                this.state.activeStep === steps.length - 1 ||
                                                this.state.activeStep === this.state.lastStepCompleted
                                            }>
                                            Següent
                                            <KeyboardArrowRight />
                                        </Button>
                                    }
                                    backButton={
                                        <Button
                                            size="small"
                                            onClick={this.handleBack}
                                            disabled={this.state.activeStep === 0}>
                                            <KeyboardArrowLeft />
                                            Anterior
                                        </Button>
                                    }
                                />
                            </div>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4}>
                        <Paper className={classes.paperRoot}>
                            <div className={classes.cardHeader}>
                                <div className={classes.cardTitleContainer}>
                                    <HistoryIcon color="primary" style={{ fontSize: 28 }} />
                                    <Typography className={classes.title} variant="h6" color="primary">
                                        Historial de visites
                                    </Typography>
                                </div>
                                <Typography variant="subtitle1" color="textSecondary">
                                    {patient.appointments.length +
                                        ' ' +
                                        (patient.appointments.length !== 1 ? 'visites' : 'visita')}
                                </Typography>
                            </div>
                            <Divider />
                            <List className={classes.list}>
                                {patient.appointments.map((item) => {
                                    return (
                                        <ListItem divider button onClick={() => this.goToAppointment(item.id)}>
                                            <div className={classes.listItem}>
                                                <Typography variant="subtitle1" color="textPrimary">
                                                    {item.confirmed ? 'Visita confirmada' : 'No confirmada'}
                                                </Typography>
                                                <Typography
                                                    className={classes.listItemDate}
                                                    variant="subtitle2"
                                                    color="textSecondary">
                                                    {item.proposedDate !== null && item.proposedHour
                                                        ? moment(parseInt(item.proposedDate)).format('DD/MM/YYYY') +
                                                          ' - ' +
                                                          item.proposedHour
                                                        : '-'}
                                                </Typography>
                                            </div>
                                        </ListItem>
                                    )
                                })}
                            </List>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4}>
                        <Paper className={classes.paperRoot}>
                            <div className={classes.cardHeader}>
                                <div className={classes.cardTitleContainer}>
                                    <VisibilityIcon color="primary" style={{ fontSize: 28 }} />
                                    <Typography className={classes.title} variant="h6" color="primary">
                                        Historial de muntatges
                                    </Typography>
                                </div>
                                <Typography variant="subtitle1" color="textSecondary">
                                    {patient.assemblies.length +
                                        ' ' +
                                        (patient.assemblies.length !== 1 ? 'muntatges' : 'muntatge')}
                                </Typography>
                            </div>
                            <Divider />
                            <List className={classes.list}>
                                {patient.assemblies.map((item) => {
                                    return (
                                        <ListItem divider button onClick={() => this.goToAssembly(item.id)}>
                                            <div className={classes.listItem}>
                                                <Typography variant="subtitle1" color="textPrimary">
                                                    {item.casnum + ' - ' + item.actualStep}
                                                </Typography>
                                                <Typography
                                                    className={classes.listItemDate}
                                                    variant="subtitle2"
                                                    color="textSecondary">
                                                    {moment(parseInt(item.createdAt)).format('DD/MM/YYYY')}
                                                </Typography>
                                            </div>
                                        </ListItem>
                                    )
                                })}
                            </List>
                        </Paper>
                    </Grid>

                    {this.state.userRole === ROLE_CAST.ADMIN ? (
                        <Grid item xs={12} sm={12} md={4}>
                            <Paper className={classes.paperRoot}>
                                <div className={classes.cardHeader}>
                                    <div className={classes.cardTitleContainer}>
                                        <WarningIcon color="primary" style={{ fontSize: 28 }} />
                                        <Typography className={classes.title} variant="h6" color="primary">
                                            Incidències
                                        </Typography>
                                    </div>
                                    <Typography variant="subtitle1" color="textSecondary">
                                        {patient.incidences.length +
                                            ' ' +
                                            (patient.incidences.length !== 1 ? 'incidencies' : 'incidencia')}
                                    </Typography>
                                </div>
                                <Divider />
                                <List className={classes.list}>
                                    {patient.incidences.map((item) => {
                                        return (
                                            <ListItem divider button onClick={() => this.goToIncidences(item.id)}>
                                                <div className={classes.listItem}>
                                                    <Typography variant="subtitle1" color="textPrimary">
                                                        {item.title}
                                                    </Typography>
                                                    <Typography
                                                        className={classes.listItemDate}
                                                        variant="subtitle2"
                                                        color="textSecondary">
                                                        {moment(parseInt(item.createdAt)).format('DD/MM/YYYY - HH:mm')}
                                                    </Typography>
                                                </div>
                                            </ListItem>
                                        )
                                    })}
                                </List>
                            </Paper>
                        </Grid>
                    ) : null}
                </Grid>
                {this.renderRemoveDialogAndSnackbar()}
            </div>
        )
    }
}

const styles = (theme) => ({
    root: {
        display: 'flex',
    },
    paperRoot: {
        //padding: theme.spacing.unit * 3,
    },
    cardHeader: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        padding: theme.spacing.unit * 3,
        justifyContent: 'space-between',
        height: '80px',
    },
    cardTitleContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
    cardContainer: {
        padding: theme.spacing.unit * 3,
    },
    title: {
        //fontWeight: 'bold',
        paddingLeft: '8px',
    },
    labelTitle: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
        color: theme.palette.primary.light,
    },
    label: {
        fontWeight: 'normal',
    },
    link: {
        textDecoration: 'none',
    },
    actions: {
        //color: theme.palette.text.secondary,
        marginRight: -theme.spacing.unit * 2,
    },
    list: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing.unit * 2,
        right: theme.spacing.unit * 2,
    },
    listItem: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '40px',
    },
    listItemDate: {
        color: theme.palette.primary.light,
        flexShrink: 0,
        paddingLeft: 16,
    },
    warningListItem: {},
    warningDateListItem: {
        paddingTop: '8px',
        color: theme.palette.primary.light,
    },
    trackContainer: {
        paddingTop: theme.spacing.unit * 3,
        paddingBottom: theme.spacing.unit * 3,
        paddingLeft: theme.spacing.unit * 3,
        paddingRight: theme.spacing.unit * 3,
    },
    trackSectionTitleContainer: {
        paddingLeft: theme.spacing.unit * 2,
        paddingRight: theme.spacing.unit * 2,
        paddingTop: theme.spacing.unit * 1,
        marginBottom: -(theme.spacing.unit * 2),
        //height: '52px',
        backgroundColor: '#FAFAFA',
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    trackSectionTitle: {
        fontWeight: 'bold',
    },
    trackEndSection: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        // paddingTop: theme.spacing.unit * 2,
        // paddingBottom: theme.spacing.unit * 2,
    },
    trackEndTitle: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
        color: theme.palette.primary.light,
        paddingLeft: '12px',
    },
    trackEndIcon: {
        color: theme.palette.primary.light,
    },
    actionDial: {
        backgroundColor: '#FFFFFF',
    },
    mobileStepper: {
        borderBottomLeftRadius: '4px',
        borderBottomRightRadius: '4px',
    },
    cardFooter: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        padding: theme.spacing.unit * 3,
        justifyContent: 'flex-end',
        height: '80px',
    },
    actionButton: {
        //width: '200px',
        marginLeft: '8px',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginTop: 8,
            marginBottom: 8,
        },
    },
})

PatientDetailPDI.propTypes = {
    patientId: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
}

PatientDetailPDI.defaultProps = {}

export default compose(withApollo, withStyles(styles))(PatientDetailPDI)
