import React from 'react'
import PropTypes from 'prop-types'
import compose from '../../../utils/functions'
import { ROLE } from '../../../constants'
import { getValueFromStorage } from '../../../utils'

// Apollo client
import { withApollo } from '@apollo/client/react/hoc'
import { getPatientsNames } from '../../../grapql/patient'
import { getSuppliersOnlyName } from '../../../grapql/supplier'
import { createAssemblyRepetition } from '../../../grapql/assembly'

// Date managment
import MomentUtils from '@date-io/moment'
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers'
import moment from 'moment'

// Components imports
import FeedbackSnackbar from '../../../components/feedback_snackbar/index'

// Design imports
import {
    Button,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    OutlinedInput,
    Paper,
    Select,
    TextField,
    Typography,
    withWidth,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import InboxIcon from '@material-ui/icons/AllInbox'
import ReactDOM from 'react-dom'

class NewAssemblyRepetition extends React.Component {
    constructor(props) {
        super(props)

        const role = getValueFromStorage(ROLE)

        this.state = {
            patients: [],
            suppliers: [],
            students: [],
            confirmSnackbarOpened: false,
            labelWidth: 0,
            assemblyId: '',
            patient: '',
            patientName: '',
            appointmentDate: moment(),
            casnum: '',
            historyNum: '',
            observations: '',
            student: '',
            studentName: '',
            expectedDelivery: moment(),
            orderDate: moment(),
            supplier: '',
            patientError: false,
            casnumError: false,
            historyNumError: false,
            expectedDeliveryError: false,
            studentError: false,
            appointmentDateError: false,
            orderDateError: false,
            supplierError: false,
            role: role,
        }
    }

    componentDidMount() {
        this.props.client
            .query(getSuppliersOnlyName())
            .then((result) => this.setState({ suppliers: result.data.allSuppliers.suppliers }))

        if (this.props.location.state !== null && this.props.location.state !== undefined) {
            const {
                assemblyId,
                patientId,
                patientName,
                casnum,
                historyNum,
                cabinetStudentId,
                cabinetStudentName,
                appointmentDate,
                observations,
                expectedDelivery,
                subject,
                subjectId,
            } = this.props.location.state

            var newCasnum = ''
            if (casnum.includes('-R')) {
                const sections = casnum.split('-R')
                newCasnum = sections[0] + '-R' + (parseInt(sections[1]) + 1)
            } else {
                newCasnum = casnum + '-R1'
            }

            this.setState({
                assemblyId: assemblyId,
                patient: patientId,
                patientName: patientName,
                casnum: newCasnum,
                historyNum: historyNum,
                student: cabinetStudentId,
                studentName: cabinetStudentName,
                appointmentDate: appointmentDate,
                observations: observations,
                expectedDelivery: expectedDelivery !== null ? moment(parseInt(expectedDelivery)) : moment(),
                subject: subject?.toUpperCase(),
                subjectId: subjectId,
            })
        }

        // Get the width for select patient selector label.
        this.setState({
            supplierLabelWidth: ReactDOM.findDOMNode(this.SupplierInputLabelRef).offsetWidth,
        })
    }

    validateData = () => {
        var dataIsValid = true

        var errors = {
            patientError: false,
            casnumError: false,
            historyNumError: false,
            expectedDeliveryError: false,
            studentError: false,
            appointmentDateError: false,
            orderDateError: false,
            supplierError: false,
        }

        if (this.state.patient === '' || this.state.patient === null) {
            errors.patientError = true
            dataIsValid = false
        }
        if (this.state.casnum === '') {
            errors.casnumError = true
            dataIsValid = false
        }
        if (this.state.historyNum === '') {
            errors.historyNumError = true
            dataIsValid = false
        }
        /*if (this.state.expectedDelivery === '') {
            errors.expectedDeliveryError = true;
            dataIsValid = false;
        }*/
        if (this.state.student === '') {
            errors.studentError = true
            dataIsValid = false
        }
        if (this.state.appointmentDate === '' && this.state.observations === '') {
            errors.appointmentDateError = true
            dataIsValid = false
        }
        if (this.state.orderDate === '') {
            errors.orderDateError = true
            dataIsValid = false
        }
        if (this.state.supplier === '') {
            errors.supplierError = true
            dataIsValid = false
        }

        this.setState(errors)

        return dataIsValid
    }

    parsePatients = (patientsFromAPI) => {
        var patients = []

        patientsFromAPI.forEach((patient) => {
            patients.push({
                value: patient.id,
                label: patient.name + ' ' + patient.lastName,
            })
        })

        this.setState({ patients: patients })
    }

    fetchPatients = (search = undefined) => {
        this.props.client
            .query(getPatientsNames(search))
            .then((result) => this.parsePatients(result.data.allPatients.patients))
    }

    handlePatientSelection = (name) => (value) => {
        this.setState({ patient: value })
    }

    onSearchInputChange = (name) => (value) => {
        if (value.length >= 2) {
            this.fetchPatients(value)
        }
    }

    handleChange = (name) => (event) => {
        this.setState({
            [name]: event.target.value,
        })
    }

    handleClose = () => {
        this.setState({ confirmSnackbarOpened: false })
    }

    handleConfirmButtonClick = () => {
        if (this.validateData()) {
            const mutation = createAssemblyRepetition(
                this.state.assemblyId,
                this.state.casnum,
                this.state.historyNum,
                this.state.observations,
                this.state.patient,
                this.state.expectedDelivery !== null ? this.state.expectedDelivery.valueOf().toString() : null,
                this.state.student,
                this.state.orderDate.valueOf().toString(),
                this.state.supplier,
                this.state.appointmentDate !== null ? this.state.appointmentDate.valueOf().toString() : null,
                this.state.subjectId,
            )

            this.props.client.mutate(mutation).then((result) => {
                this.setState({ confirmSnackbarOpened: true })
                setTimeout(() => {
                    //this.props.history.goBack();
                    this.props.history.push('/assembly/' + result.data.createAssemblyRepetition.id)
                }, 3000)
            })
        }
    }

    handlePickerSelection = (name) => (event) => {
        this.setState({ [name]: event.target.value })
    }

    render() {
        const { classes } = this.props
        const {
            patient,
            patientName,
            casnum,
            historyNum,
            student,
            studentName,
            appointmentDate,
            observations,
            expectedDelivery,
        } = this.state

        return (
            <div className={classes.root}>
                <Grid container spacing={24} justify="center">
                    <Grid item xs={12} sm={12} md={8} lg={6}>
                        <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                            <Paper className={classes.paperRoot}>
                                <div className={classes.cardHeader}>
                                    <div className={classes.cardTitleContainer}>
                                        <InboxIcon color="primary" style={{ fontSize: 28 }} />
                                        <Typography className={classes.title} variant="h6" color="primary">
                                            Nova repetició del muntatge
                                        </Typography>
                                    </div>
                                </div>
                                <Divider />
                                <Grid className={classes.cardContainer} container spacing={24}>
                                    <Grid item xs={12} sm={6}>
                                        <Typography className={classes.labelTitle} variant="subtitle2">
                                            Pacient:
                                        </Typography>
                                        <Typography className={classes.label} variant="h6">
                                            {patientName}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <Grid className={classes.cardContainer} container spacing={24}>
                                    <Grid item xs={12} sm={6}>
                                        <Typography className={classes.labelTitle} variant="subtitle2">
                                            Número del cas:
                                        </Typography>
                                        <Typography className={classes.label} variant="h6">
                                            {casnum}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography className={classes.labelTitle} variant="subtitle2">
                                            Número d'història:
                                        </Typography>
                                        <Typography className={classes.label} variant="h6">
                                            {historyNum}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography className={classes.labelTitle} variant="subtitle2">
                                            Alumne del gabinet:
                                        </Typography>
                                        <Typography className={classes.label} variant="h6">
                                            {studentName}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography className={classes.labelTitle} variant="subtitle2">
                                            Data de la visita:
                                        </Typography>
                                        <Typography className={classes.label} variant="h6">
                                            {appointmentDate !== null
                                                ? moment(parseInt(appointmentDate)).format('DD/MM/YYYY')
                                                : '-'}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <DatePicker
                                            autoOk
                                            fullWidth
                                            keyboard
                                            disablePast
                                            placeholder={'DD/MM/YYYY'}
                                            mask={(value) =>
                                                value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : []
                                            }
                                            error={this.state.expectedDeliveryError}
                                            label="Data de entrega prevista"
                                            format={'DD/MM/YYYY'}
                                            value={this.state.expectedDelivery}
                                            variant="outlined"
                                            className={classes.formControl}
                                            onChange={(date) => this.setState({ expectedDelivery: date })}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography className={classes.labelTitle} variant="subtitle2">
                                            Assignatura:
                                        </Typography>
                                        <Typography className={classes.label} variant="h6">
                                            {this.state.subject}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <Grid className={classes.cardContainer} container spacing={24}>
                                    <Grid item xs={12} sm={6}>
                                        <DatePicker
                                            autoOk
                                            fullWidth
                                            keyboard
                                            placeholder={'DD/MM/YYYY'}
                                            mask={(value) =>
                                                value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : []
                                            }
                                            error={this.state.orderDateError}
                                            label="Data de comanda"
                                            format={'DD/MM/YYYY'}
                                            value={this.state.orderDate}
                                            variant="outlined"
                                            className={classes.formControl}
                                            onChange={(date) => this.setState({ orderDate: date })}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            fullWidth
                                            required
                                            variant="outlined"
                                            className={classes.formControl}>
                                            <InputLabel
                                                ref={(ref) => {
                                                    this.SupplierInputLabelRef = ref
                                                }}
                                                htmlFor="assembly-supplier">
                                                Proveïdor
                                            </InputLabel>
                                            <Select
                                                native
                                                error={this.state.supplierError}
                                                value={this.state.supplier}
                                                onChange={this.handlePickerSelection('supplier')}
                                                input={
                                                    <OutlinedInput
                                                        name="supplier"
                                                        labelWidth={this.state.supplierLabelWidth}
                                                        id="assembly-supplier"
                                                    />
                                                }>
                                                <option value="" />
                                                {this.state.suppliers.map((item, index) => {
                                                    return <option value={item.id}>{item.name?.toUpperCase()}</option>
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <Grid className={classes.cardContainer} container spacing={24}>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            error={this.state.appointmentDateError}
                                            id="assembly-observations"
                                            label="Observacions"
                                            multiline
                                            rowsMax="4"
                                            rows="3"
                                            value={this.state.observations}
                                            onChange={this.handleChange('observations')}
                                            className={classes.textField}
                                            helperText="Si no hi ha visita prèvia del pacient, indica perquè s'està generant aquest nou muntatge"
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                                <Divider />
                                <Grid className={classes.cardContainer} container spacing={24}>
                                    <Grid item xs={12}>
                                        <Typography
                                            className={classes.warningMessage}
                                            align="center"
                                            variant="subtitle2">
                                            Si es crea aquesta repetició, el muntatge original quedarà com cancel·lat i
                                            no es podrà modificar
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <div className={classes.cardFooter}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        fullWidth={this.props.width === 'xs'}
                                        onClick={() => this.handleConfirmButtonClick()}>
                                        Crear muntatge
                                    </Button>
                                </div>
                            </Paper>
                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>
                <FeedbackSnackbar
                    showSnackbar={this.state.confirmSnackbarOpened}
                    handleClose={() => this.handleClose()}
                    timeShowed={3000}
                    text={'Muntatge creat correctament'}
                />
            </div>
        )
    }
}

const styles = (theme) => ({
    root: {
        display: 'flex',
        flex: 1,
    },
    paperRoot: {
        //flex: 0.5
        //padding: theme.spacing.unit * 3,
    },
    cardHeader: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        padding: theme.spacing.unit * 3,
        justifyContent: 'space-between',
        height: '80px',
    },
    cardFooter: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        padding: theme.spacing.unit * 3,
        justifyContent: 'flex-end',
        height: '80px',
    },
    cardTitleContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
    title: {
        paddingLeft: '8px',
    },
    cardContainer: {
        padding: theme.spacing.unit * 3,
    },
    textField: {
        //marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    textFieldWidth: {
        marginRight: theme.spacing.unit,
        minWidth: '200px',
    },
    formControl: {
        marginTop: 16,
    },
    searchControl: {
        marginTop: 16,
        zIndex: 1000,
    },
    labelTitle: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
        color: theme.palette.primary.light,
    },
    label: {
        fontWeight: 'normal',
    },
    warningMessage: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
        color: theme.palette.secondary.light,
    },
})

NewAssemblyRepetition.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default compose(withApollo, withWidth(), withStyles(styles))(NewAssemblyRepetition)
