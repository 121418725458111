import gql from 'graphql-tag'

export const getSuppliers = (offset, limit, order, orderDirection, filter) => {
    return {
        query: gql`
            query GetSuppliers($offset: Int, $limit: Int, $order: String, $orderDirection: String, $filter: String) {
                allSuppliers(
                    offset: $offset
                    limit: $limit
                    order: $order
                    orderDirection: $orderDirection
                    filter: $filter
                ) {
                    suppliers {
                        id
                        name
                        contactPerson
                        email
                        phone
                        startAgreementDate
                        endAgreementDate
                    }
                    totalCount
                }
            }
        `,
        variables: {
            offset: offset,
            limit: limit,
            order: order,
            orderDirection: orderDirection,
            filter: filter,
        },
    }
}

export const getSuppliersOnlyName = () => {
    return {
        query: gql`
            {
                allSuppliers {
                    suppliers {
                        id
                        name
                    }
                }
            }
        `,
    }
}

export const getSupplierDetailById = (id) => {
    return {
        query: gql`
            query GetSupplierDetail($id: String) {
                supplier(id: $id) {
                    id
                    name
                    contactPerson
                    email
                    phone
                    startAgreementDate
                    endAgreementDate
                }
            }
        `,
        variables: {
            id: id,
        },
    }
}

export const addSupplier = (name, contactPerson, email, phone, startAgreementDate, endAgreementDate) => {
    return {
        mutation: gql`
            mutation AddSupplier(
                $name: String
                $contactPerson: String
                $email: String
                $phone: String
                $startAgreementDate: String
                $endAgreementDate: String
            ) {
                addSupplier(
                    input: {
                        name: $name
                        contactPerson: $contactPerson
                        email: $email
                        phone: $phone
                        startAgreementDate: $startAgreementDate
                        endAgreementDate: $endAgreementDate
                    }
                ) {
                    id
                }
            }
        `,
        variables: {
            name: name,
            contactPerson: contactPerson,
            email: email,
            phone: phone,
            startAgreementDate: startAgreementDate,
            endAgreementDate: endAgreementDate,
        },
    }
}

export const updateSupplier = (id, name, contactPerson, email, phone, startAgreementDate, endAgreementDate) => {
    return {
        mutation: gql`
            mutation UpdateSupplier(
                $id: ID
                $name: String
                $contactPerson: String
                $email: String
                $phone: String
                $startAgreementDate: String
                $endAgreementDate: String
            ) {
                updateSupplier(
                    id: $id
                    input: {
                        name: $name
                        contactPerson: $contactPerson
                        email: $email
                        phone: $phone
                        startAgreementDate: $startAgreementDate
                        endAgreementDate: $endAgreementDate
                    }
                ) {
                    id
                }
            }
        `,
        variables: {
            id: id,
            name: name,
            contactPerson: contactPerson,
            email: email,
            phone: phone,
            startAgreementDate: startAgreementDate,
            endAgreementDate: endAgreementDate,
        },
    }
}

export const deleteSupplierById = (id) => {
    return {
        mutation: gql`
            mutation RemoveSupplier($id: ID) {
                removeSupplier(id: $id) {
                    id
                }
            }
        `,
        variables: {
            id: id,
        },
    }
}
