import gql from 'graphql-tag'

export const getAppointments = (offset, limit, order, orderDirection, search, filter) => {
    return {
        query: gql`
            query GetAppointments(
                $offset: Int
                $limit: Int
                $order: String
                $orderDirection: String
                $search: String
                $filter: String
            ) {
                allAppointments(
                    offset: $offset
                    limit: $limit
                    order: $order
                    orderDirection: $orderDirection
                    search: $search
                    filter: $filter
                ) {
                    appointments {
                        id
                        preference
                        observations
                        proposedDate
                        proposedHour
                        confirmed
                        createdAt
                        patient {
                            id
                            name
                            lastName
                            birthdate
                            phone
                            entity {
                                id
                                name
                            }
                        }
                    }
                    totalCount
                }
            }
        `,
        variables: {
            offset: offset,
            limit: limit,
            order: order,
            orderDirection: orderDirection,
            search: search,
            filter: filter,
        },
    }
}

export const getLastAppointments = () => {
    return {
        query: gql`
            {
                allAppointments {
                    appointments {
                        id
                        proposedDate
                        proposedHour
                        confirmed
                        createdAt
                        patient {
                            id
                            name
                            lastName
                        }
                    }
                }
            }
        `,
    }
}

export const getAppointmentById = (id) => {
    return {
        query: gql`
            query GetAppointmentById($id: ID) {
                appointment(id: $id) {
                    id
                    preference
                    observations
                    proposedDate
                    proposedHour
                    confirmed
                    createdAt
                    patient {
                        id
                        name
                        lastName
                        phone
                        birthdate
                        entity {
                            id
                            name
                        }
                    }
                    createdByUser {
                        id
                        name
                        lastName
                    }
                }
            }
        `,
        variables: {
            id: id,
        },
    }
}

export const addAppointment = (proposedDate, proposedHour, patientId, observations) => {
    return {
        mutation: gql`
            mutation AddAppointment(
                $proposedDate: String
                $proposedHour: String
                $patientId: ID
                $observations: String
            ) {
                addAppointment(
                    input: {
                        proposedDate: $proposedDate
                        proposedHour: $proposedHour
                        patientId: $patientId
                        observations: $observations
                    }
                ) {
                    id
                }
            }
        `,
        variables: {
            proposedDate: proposedDate,
            proposedHour: proposedHour,
            patientId: patientId,
            observations: observations,
        },
    }
}

export const addAppointmentFromEntity = (preference, observations, patientId, socialWorkerId) => {
    return {
        mutation: gql`
            mutation AddAppointment($preference: String, $observations: String, $patientId: ID, $socialWorkerId: ID) {
                addAppointment(
                    input: {
                        preference: $preference
                        observations: $observations
                        patientId: $patientId
                        socialWorkerId: $socialWorkerId
                    }
                ) {
                    id
                }
            }
        `,
        variables: {
            preference: preference,
            observations: observations,
            patientId: patientId,
            socialWorkerId: socialWorkerId,
        },
    }
}

export const proposeAppointmentDate = (id, proposedDate, proposedHour) => {
    return {
        mutation: gql`
            mutation ProposeAppointmentDate($id: ID, $proposedDate: String, $proposedHour: String) {
                proposeAppointmentDate(id: $id, proposedDate: $proposedDate, proposedHour: $proposedHour) {
                    id
                }
            }
        `,
        variables: {
            id: id,
            proposedDate: proposedDate,
            proposedHour: proposedHour,
        },
    }
}

export const confirmAppointment = (id) => {
    return {
        mutation: gql`
            mutation ConfirmAppointment($id: ID) {
                confirmAppointment(id: $id) {
                    id
                }
            }
        `,
        variables: {
            id: id,
        },
    }
}

export const cancelAppointment = (id) => {
    return {
        mutation: gql`
            mutation CancelAppointment($id: ID) {
                cancelAppointment(id: $id) {
                    id
                    incidenceId
                }
            }
        `,
        variables: {
            id: id,
        },
    }
}
