import React from 'react'
import PropTypes from 'prop-types'
import compose, { getCityName, getNationalityName } from '../../utils/functions'

import SearchSelect from 'react-select'

// Apollo client
import { withApollo } from '@apollo/client/react/hoc'
import { addPatient, getPatientsNames } from '../../grapql/patient'
import { addAppointment, confirmAppointment } from '../../grapql/appointment'

// Date managment
import MomentUtils from '@date-io/moment'
import { DatePicker, MuiPickersUtilsProvider, TimePicker } from 'material-ui-pickers'
import moment from 'moment'

// Design imports
import {
    Button,
    Divider,
    FormControl,
    Grid,
    Paper,
    Step,
    StepButton,
    StepContent,
    StepLabel,
    Stepper,
    TextField,
    Typography,
    withWidth,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import SocialWorkerIcon from '@material-ui/icons/SupervisorAccount'
import ScheduleIcon from '@material-ui/icons/WatchLater'
import SendIcon from '@material-ui/icons/Send'
import NotesIcon from '@material-ui/icons/Notes'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import { getEntitiesNames } from '../../grapql/entity'
import NewPatient from '../molecule/NewPatient/NewPatient'
import TextLabel from '../atom/TextLabel'

// Constants
const PATIENT_STEP = 0
const SCHEDULE_STEP = 1
const OBSERVATIONS_STEP = 2
const SUMMARY_STEP = 3

function getSteps() {
    return [
        'Seleccioni un pacient',
        "Seleccioni l'hora i data de la visita",
        'Observacions (Opcional)',
        'Resum i confirmació de la visita',
    ]
}

class VisitRequestStepperPDI extends React.Component {
    constructor(props) {
        super(props)

        this.newPatientComponentRef = React.createRef()

        this.state = {
            entities: [],
            patients: [],
            newPatient: null,
            activeStep: 0,
            completedSteps: [],
            selectedPatientId: null,
            showNewPatient: false,
            favoriteTimeValue: 'morning',
            observations: '',
            appointmentHour: moment(),
            appointmentDate: moment(),
            selectedPatientError: false,
            appointmentHourError: false,
            appointmentDateError: false,
        }
    }

    parseData = (patientsFromAPI) => {
        var patients = []

        patientsFromAPI.forEach((patient) => {
            patients.push({
                value: patient.id,
                label: patient.name?.toUpperCase() + ' ' + patient.lastName?.toUpperCase(),
            })
        })

        this.setState({ patients: patients })
    }

    componentDidMount() {
        this.props.client
            .query(getEntitiesNames())
            .then((result) => this.setState({ entities: result.data.allEntities.entities }))

        if (this.props.selectedPatient !== null) {
            const selectedPatient = this.props.selectedPatient
            const patients = [
                { value: selectedPatient.id, label: selectedPatient.name + ' ' + selectedPatient.lastName },
            ]

            this.setState({
                patients,
                selectedPatientId: selectedPatient.id,
            })
        }
    }

    fetchPatients = (search = undefined) => {
        this.props.client
            .query(getPatientsNames(search))
            .then((result) => this.parseData(result.data.allPatients.patients))
    }

    validateData = () => {
        var dataIsValid = true

        var errors = {
            selectedPatientError: false,
            appointmentHourError: false,
            appointmentDateError: false,
        }

        if (!this.state.showNewPatient && this.state.selectedPatientId === null) {
            errors.selectedPatientError = true
            dataIsValid = false
        }

        if (this.state.appointmentHour === '') {
            errors.appointmentHourError = true
            dataIsValid = false
        }
        if (this.state.appointmentDate === '') {
            errors.appointmentDateError = true
            dataIsValid = false
        }

        this.setState(errors)
        return dataIsValid
    }

    sendAddAppointmentQuery = (id) => {
        const proposedDate = this.state.appointmentDate.valueOf().toString()
        const appointmentHour = this.state.appointmentHour.format('HH:mm')
        const mutation = addAppointment(proposedDate, appointmentHour, id, this.state.observations) // TODO Add Social worker

        this.props.client.mutate(mutation).then((result) => {
            if (result.data?.addAppointment?.id) {
                this.props.client.mutate(confirmAppointment(result.data.addAppointment.id))
            }
            this.props.handleConfirm()
        })
    }

    handleConfirm = () => {
        if (this.validateData()) {
            const { showNewPatient, newPatient } = this.state

            if (showNewPatient) {
                const mutation = addPatient(
                    newPatient.name,
                    newPatient.lastName,
                    newPatient.birthdate.valueOf().toString(),
                    newPatient.email,
                    newPatient.phone,
                    newPatient.center,
                    newPatient.idNumber,
                    newPatient.nationality,
                    newPatient.address,
                    newPatient.city,
                    newPatient.postalCode,
                )

                this.props.client.mutate(mutation).then((result) => {
                    this.sendAddAppointmentQuery(result.data.addPatient.id)
                })
            } else {
                this.sendAddAppointmentQuery(this.state.selectedPatientId.value)
            }
        } else {
            this.setState({ activeStep: SCHEDULE_STEP })
        }
    }

    handleNext = () => {
        const { activeStep, completedSteps, showNewPatient } = this.state

        if (activeStep + 1 === SUMMARY_STEP && !this.state.completedSteps.includes(0)) {
            return
        }

        if (activeStep === PATIENT_STEP && showNewPatient) {
            if (!this.newPatientComponentRef.current.validateData()) return
            else
                this.setState({
                    newPatient: { ...this.newPatientComponentRef.current.state.patient },
                })
        }

        const newCompletedSteps = completedSteps
        if (completedSteps.indexOf(activeStep) < 0) {
            newCompletedSteps.push(activeStep)
        }

        this.setState((state) => ({
            activeStep: state.activeStep + 1,
            completedSteps: newCompletedSteps,
        }))
    }

    handleBack = () => {
        const { activeStep, completedSteps } = this.state

        const newCompletedSteps = completedSteps
        const index = completedSteps.indexOf(activeStep)
        if (index >= 0) {
            newCompletedSteps.splice(index, 1)
        }

        this.setState((state) => ({
            activeStep: state.activeStep - 1,
            completedSteps: newCompletedSteps,
        }))
    }

    handleStep = (step) => () => {
        if (this.state.activeStep === PATIENT_STEP && this.state.showNewPatient) {
            if (!this.newPatientComponentRef.current.validateData()) return
            else
                this.setState({
                    newPatient: { ...this.newPatientComponentRef.current.state.patient },
                })
        }

        if (
            step === SUMMARY_STEP &&
            !(
                this.state.completedSteps.includes(0) &&
                this.state.completedSteps.includes(1) &&
                this.state.completedSteps.includes(2)
            )
        ) {
            return
        }

        this.setState({
            activeStep: step,
        })
    }

    handlePatientToggleButton = (showNewPatient) => {
        this.setState({ showNewPatient: showNewPatient })
    }

    handlePatientSelection = (name) => (value) => {
        this.setState({ selectedPatientId: value })
    }

    onSearchInputChange = (name) => (value) => {
        if (value.length >= 2) {
            this.fetchPatients(value)
        }
    }

    handlePreferredScheduleSelected = () => (event) => {
        this.setState({ favoriteTimeValue: event.target.value })
    }

    handleObservationsChanges = () => (event) => {
        this.setState({ observations: event.target.value })
    }

    renderInfoStep = () => {
        const { classes } = this.props

        var patientContent = null
        if (this.state.showNewPatient) {
            patientContent = (
                <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                    <Paper className={classes.paperRoot}>
                        <div className={classes.cardHeader}>
                            <div className={classes.cardTitleContainer}>
                                <PersonAddIcon color="primary" style={{ fontSize: 28 }} />
                                <Typography className={classes.title} variant="h6" color="primary">
                                    Nou pacient
                                </Typography>
                            </div>
                        </div>
                        <Divider />
                        <NewPatient
                            innerRef={this.newPatientComponentRef}
                            centers={this.state.entities}
                            isUpdating={false}
                            isSocialWorker={false}
                            patient={this.state.newPatient}
                        />
                    </Paper>
                </MuiPickersUtilsProvider>
            )
        } else {
            patientContent = (
                <Paper className={classes.paperRoot}>
                    <div className={classes.cardHeader}>
                        <div className={classes.cardTitleContainer}>
                            <SocialWorkerIcon color="primary" style={{ fontSize: 28 }} />
                            <Typography className={classes.title} variant="h6" color="primary">
                                Selecciona un pacient existent:
                            </Typography>
                        </div>
                    </div>
                    <Divider />
                    <div className={classes.cardContainer}>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            <SearchSelect
                                value={this.state.selectedPatientId}
                                onChange={this.handlePatientSelection()}
                                onInputChange={this.onSearchInputChange()}
                                options={this.state.patients}
                                placeholder={'Escriu dues lletres per començar la cerca'}
                                noOptionsMessage={() => "No s'ha trobat cap pacient"}
                                isClearable
                            />
                        </FormControl>
                    </div>
                </Paper>
            )
        }

        return (
            <Grid container spacing={16}>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                    {patientContent}
                </Grid>
            </Grid>
        )
    }

    renderScheduleStep = () => {
        const { classes } = this.props

        return (
            <Grid container spacing={16}>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                    <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                        <Paper className={classes.paperRoot}>
                            <div className={classes.cardHeader}>
                                <div className={classes.cardTitleContainer}>
                                    <ScheduleIcon color="primary" style={{ fontSize: 28 }} />
                                    <Typography className={classes.title} variant="h6" color="primary">
                                        Selecciona quin horari prefereix el pacient
                                    </Typography>
                                </div>
                            </div>
                            <Divider />
                            <div className={classes.cardContainer}>
                                <Grid container spacing={32}>
                                    <Grid item xs={12} sm={6}>
                                        <TimePicker
                                            keyboard
                                            fullWidth
                                            mask={[/\d/, /\d/, ':', /\d/, /\d/]}
                                            placeholder={'10:00'}
                                            ampm={false}
                                            label={'Hora de la visita'}
                                            value={this.state.appointmentHour}
                                            onChange={(hour) => {
                                                this.setState({ appointmentHour: hour })
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <DatePicker
                                            autoOk
                                            fullWidth
                                            keyboard
                                            disablePast
                                            placeholder={'DD/MM/YYYY'}
                                            mask={(value) =>
                                                value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : []
                                            }
                                            error={this.state.appointmentDateError}
                                            label="Data de la visita"
                                            format={'DD/MM/YYYY'}
                                            value={this.state.appointmentDate}
                                            className={classes.textField}
                                            onChange={(date) => this.setState({ appointmentDate: date })}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </Paper>
                    </MuiPickersUtilsProvider>
                </Grid>
            </Grid>
        )
    }

    renderObservationStep = () => {
        const { classes } = this.props

        return (
            <Grid container spacing={16}>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                    <Paper className={classes.paperRoot}>
                        <div className={classes.cardHeader}>
                            <div className={classes.cardTitleContainer}>
                                <NotesIcon color="primary" style={{ fontSize: 28 }} />
                                <Typography className={classes.title} variant="h6" color="primary">
                                    Afegeix les observacions oportunes (Opcional)
                                </Typography>
                            </div>
                        </div>
                        <Divider />
                        <div className={classes.cardContainer}>
                            <TextField
                                fullWidth
                                id="appointment-observations"
                                label="Observacions"
                                multiline
                                rowsMax="4"
                                value={this.state.observations}
                                onChange={this.handleObservationsChanges('multiline')}
                                className={classes.textField}
                                helperText="Per exemple, treballador social que ha demanat l'hora..."
                                variant="outlined"
                            />
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        )
    }

    renderSummaryStep = () => {
        const { classes } = this.props

        var patientData = null
        if (this.state.showNewPatient) {
            let patient = this.state.newPatient
            if (!patient) patientData = null
            else {
                const entityIndex = this.state.entities.findIndex((item) => {
                    return item.id === patient.center
                })
                const entity = entityIndex >= 0 ? this.state.entities[entityIndex].name : ''

                patientData = (
                    <Grid item xs={12}>
                        <Grid container spacing={16}>
                            <TextLabel title={'Nom:'} value={patient.name} xs={12} sm={6} />
                            <TextLabel title={'Cognoms:'} value={patient.lastName} xs={12} sm={6} />
                            <TextLabel title={'DNI/NIE/Passaport:'} value={patient.idNumber} xs={12} sm={6} />
                            <TextLabel
                                title={'Nacionalitat:'}
                                value={getNationalityName(patient.nationality)}
                                xs={12}
                                sm={6}
                            />
                            <TextLabel
                                title={'Data de naixement:'}
                                value={patient.birthdate?.format('DD/MM/YYYY')}
                                xs={12}
                                sm={6}
                            />
                            <TextLabel title={'Telèfon:'} value={patient.phone} type={'phone'} xs={12} sm={6} />
                            <TextLabel
                                title={'Email del pacient:'}
                                value={patient.email}
                                type={'email'}
                                xs={12}
                                sm={6}
                            />
                            <TextLabel title={'Adreça:'} value={patient.address} xs={12} sm={6} />
                            <TextLabel title={'Municipi:'} value={getCityName(patient.city)} xs={12} sm={6} />
                            <TextLabel title={'Codi postal:'} value={patient.postalCode} xs={12} sm={6} />
                            <TextLabel title={'Entitat:'} value={entity} xs={12} sm={6} />
                        </Grid>
                    </Grid>
                )
            }
        } else {
            const patientIndex = this.state.patients.findIndex((item) => {
                const selectedPatientId =
                    this.state.selectedPatientId !== null ? this.state.selectedPatientId.value : null
                return item.value === selectedPatientId
            })

            const patientName = patientIndex >= 0 ? this.state.patients[patientIndex].label : 'Cap pacient seleccionat'

            patientData = (
                <Grid item xs={12}>
                    <Typography className={classes.labelTitle} variant="subtitle2">
                        Pacient:
                    </Typography>
                    <Typography className={classes.label} variant="subtitle1">
                        {patientName}
                    </Typography>
                </Grid>
            )
        }

        return (
            <Grid container spacing={16}>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                    <Paper className={classes.paperRoot}>
                        <div className={classes.cardHeader}>
                            <div className={classes.cardTitleContainer}>
                                <SendIcon color="primary" style={{ fontSize: 28 }} />
                                <Typography className={classes.title} variant="h6" color="primary">
                                    Revisi les dades i confirmi la petició d'hora
                                </Typography>
                            </div>
                        </div>
                        <Divider />
                        <div className={classes.cardContainer}>
                            <Grid container spacing={16}>
                                {patientData}
                            </Grid>
                        </div>
                        <Divider />
                        <div className={classes.cardContainer}>
                            <Grid container spacing={16}>
                                <Grid item xs={12} sm={6}>
                                    <Typography className={classes.labelTitle} variant="subtitle2">
                                        Hora proposada:
                                    </Typography>
                                    <Typography className={classes.label} variant="subtitle1">
                                        {this.state.appointmentHour !== ''
                                            ? this.state.appointmentHour.format('HH:mm')
                                            : '-'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography className={classes.labelTitle} variant="subtitle2">
                                        Data proposada:
                                    </Typography>
                                    <Typography className={classes.label} variant="subtitle1">
                                        {this.state.appointmentDate !== ''
                                            ? this.state.appointmentDate.format('DD/MM/YYYY')
                                            : '-'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                        <Divider />
                        <div className={classes.cardContainer}>
                            <Grid item xs={12}>
                                <Typography className={classes.labelTitle} variant="subtitle2">
                                    Observacions:
                                </Typography>
                                <Typography className={classes.label} variant="subtitle1">
                                    {this.state.observations === '' ? '-' : this.state.observations}
                                </Typography>
                            </Grid>
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        )
    }

    renderStepContent = (index) => {
        switch (index) {
            case PATIENT_STEP:
                return this.renderInfoStep()
            case SCHEDULE_STEP:
                return this.renderScheduleStep()
            case OBSERVATIONS_STEP:
                return this.renderObservationStep()
            case SUMMARY_STEP:
                return this.renderSummaryStep()
            default:
                return null
        }
    }

    renderNewPatientButton = (index) => {
        const { classes } = this.props

        if (index === PATIENT_STEP) {
            if (this.state.showNewPatient) {
                return (
                    <Button
                        variant="flat"
                        color="primary"
                        onClick={() => this.handlePatientToggleButton(false)}
                        className={classes.button}>
                        Seleccionar un pacient existent
                    </Button>
                )
            }

            return (
                <Button
                    variant="flat"
                    color="primary"
                    onClick={() => this.handlePatientToggleButton(true)}
                    className={classes.button}>
                    Donar d'alta un nou pacient
                </Button>
            )
        }
    }

    renderStep = (label, index) => {
        const { classes } = this.props
        const { activeStep, completedSteps } = this.state
        const steps = getSteps()

        const completed = completedSteps.indexOf(index) >= 0

        var stepLabel = <StepLabel completed={completed}>{label}</StepLabel>
        if (activeStep !== index) {
            stepLabel = (
                <StepButton onClick={this.handleStep(index)} completed={completed}>
                    {label}
                </StepButton>
            )
        }

        return (
            <Step key={label}>
                {stepLabel}
                <StepContent>
                    {this.renderStepContent(index)}
                    <div className={classes.actionsContainer}>
                        <div>
                            <Button disabled={activeStep === 0} onClick={this.handleBack} className={classes.button}>
                                Anterior
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    if (activeStep === steps.length - 1) {
                                        this.handleConfirm()
                                    } else {
                                        this.handleNext()
                                    }
                                }}
                                className={classes.button}>
                                {activeStep === steps.length - 1 ? 'Confirmar' : 'Següent'}
                            </Button>
                            {this.renderNewPatientButton(index)}
                        </div>
                    </div>
                </StepContent>
            </Step>
        )
    }

    render() {
        const { classes } = this.props
        const { activeStep } = this.state
        const steps = getSteps()

        return (
            <div className={classes.root}>
                <Stepper
                    nonLinear
                    activeStep={activeStep}
                    orientation="vertical"
                    classes={{ root: classes.stepperRoot }}>
                    {steps.map((label, index) => this.renderStep(label, index))}
                </Stepper>
            </div>
        )
    }
}

const styles = (theme) => ({
    root: {
        width: '100%',
        //padding: '8px',
    },
    stepperRoot: {
        backgroundColor: '#FAFAFA',
        padding: theme.spacing.unit,
    },
    button: {
        marginTop: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    actionsContainer: {
        marginTop: theme.spacing.unit,
    },
    resetContainer: {
        padding: theme.spacing.unit * 3,
    },
    formControl: {
        //margin: theme.spacing.unit,
        /*marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,*/
        //maxWidth: 300,
    },
    stepContainer: {
        minHeight: 200,
        padding: theme.spacing.unit * 2,
        margin: '0px',
    },
    paperRoot: {
        /*height: '100%',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'flex-start'*/
    },
    cardHeader: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        padding: theme.spacing.unit * 3,
        justifyContent: 'space-between',
        maxHeight: '100px',
    },
    cardTitleContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
    cardContainer: {
        padding: theme.spacing.unit * 3,
    },
    title: {
        paddingLeft: '8px',
    },
    radioButton: {
        marginRight: '56px',
    },
    labelTitle: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
        color: theme.palette.primary.light,
    },
    label: {
        fontWeight: 'normal',
    },
    textFieldWidth: {
        marginRight: theme.spacing.unit,
        minWidth: '200px',
    },
})

VisitRequestStepperPDI.propTypes = {
    classes: PropTypes.object.isRequired,
    handleConfirm: PropTypes.func.isRequired,
    selectedPatient: PropTypes.object,
}

VisitRequestStepperPDI.defaultProps = {
    selectedPatient: null,
}

export default compose(withWidth(), withApollo, withStyles(styles))(VisitRequestStepperPDI)
