export const AUTH_TOKEN = 'auth-token'
export const ROLE = 'role'

export const ROLE_CAST = {
    ADMIN: 'admin',
    PDI: 'pdi',
    TS: 'ts',
    ST: 'student',
    MST: 'management_student',
}

export const ROLE_API = {
    ADMIN: 'ADMIN',
    PDI: 'PDI',
    TS: 'TS',
    ST: 'ST',
    MST: 'MST',
}

export const APPOINTMENT_PREFERENCE_API = {
    MORNING: 'MORNING',
    AFTERNOON: 'AFTERNOON',
    ANYTIME: 'ANYTIME',
}

export const ASSEMBLY_STATUS_API = {
    APPOINTMENT: 'APPOINTMENT',
    ORDER: 'ORDER',
    ASSEMBLY: 'ASSEMBLY',
    REVISION: 'REVISION',
    VALUATION: 'VALUATION',
    DELIVERY: 'DELIVERY',
    END: 'END',
    CANCEL: 'CANCELED',
}

export const DEFAULT_ROWS_PER_PAGE = 10
