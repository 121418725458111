import React from 'react'
import PropTypes from 'prop-types'
import compose from '../../../utils/functions'
import moment from 'moment'
import { DEFAULT_ROWS_PER_PAGE } from '../../../constants'

// Apollo client
import { withApollo } from '@apollo/client/react/hoc'
import { getPatients } from '../../../grapql/patient'

// Components imports
import ManagementTableWrapper from '../../../components/management_table_wrapper'

// Design imports
import { Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles'

// Constants
const columns = [
    { id: 'name', numeric: false, disablePadding: false, label: 'Nom' },
    { id: 'lastName', numeric: false, disablePadding: false, label: 'Cognoms' },
    { id: 'age', numeric: false, disablePadding: false, label: 'Edat' },
    // TODO Add this fields to table
    /*{ id: 'scheduledVisit', numeric: false, disablePadding: false, label: 'Visita programada' },
    { id: 'trackingStatus', numeric: false, disablePadding: false, label: 'Seguiment' },*/
]

class Patients extends React.Component {
    state = {
        data: [],
        totalCount: 0,
    }

    parseData = (patientsFromAPI) => {
        var patients = []

        if (patientsFromAPI !== null) {
            patientsFromAPI.forEach((patient) => {
                patients.push({
                    id: patient.id,
                    name: patient.name?.toUpperCase(),
                    lastName: patient.lastName?.toUpperCase(),
                    age: moment().diff(moment(parseInt(patient.birthdate)), 'years'),
                })
            })
        }

        this.setState({ data: patients })
    }

    componentDidMount() {
        this.fetchData(0, DEFAULT_ROWS_PER_PAGE)
    }

    fetchData = (offset, limit, orderBy, orderDirection, searchText) => {
        var castOrderBy = orderBy
        if (orderBy === 'age') castOrderBy = 'birthdate'

        this.props.client.query(getPatients(offset, limit, castOrderBy, orderDirection, searchText)).then((result) => {
            this.parseData(result.data.allPatients.patients)
            this.setState({ totalCount: result.data.allPatients.totalCount })
        })
    }

    goToNewPatient = () => {
        this.props.history.push('/new-patient')
    }

    handleClick = (id) => {
        this.props.history.push('/patient/' + id)
    }

    render() {
        const { classes } = this.props
        const { data, totalCount } = this.state

        const actionButtons = (
            <div>
                <Button variant="outlined" color="primary" onClick={() => this.goToNewPatient()}>
                    Nou pacient
                </Button>
            </div>
        )

        return (
            <ManagementTableWrapper
                title="Llistat de pacients"
                rows={data}
                columns={columns}
                actionButtons={actionButtons}
                onRowClick={(id) => this.handleClick(id)}
                totalCount={totalCount}
                fetchData={this.fetchData}
            />
        )
    }
}

Patients.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default compose(withApollo, withStyles(styles))(Patients)
