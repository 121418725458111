import React from 'react'
import PropTypes from 'prop-types'

// Apollo client
// Date managment
// JSON Files
// Design imports
import { IconButton, Snackbar, SnackbarContent } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import classNames from 'classnames'
import CloseIcon from '@material-ui/icons/Close'
import { withStyles } from '@material-ui/core/styles'
import styles from './style'

class SnackbarSuccess extends React.Component {
    render() {
        const { classes, open, title, onClose } = this.props

        return (
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                open={open}
                autoHideDuration={1500}
                onClose={onClose}>
                <SnackbarContent
                    className={classes.success}
                    aria-describedby="message-id"
                    message={
                        <span id="client-snackbar" className={classes.message}>
                            <CheckCircleIcon className={classNames(classes.icon, classes.iconVariant)} />
                            {title}
                        </span>
                    }
                    action={[
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            className={classes.close}
                            onClick={onClose}>
                            <CloseIcon />
                        </IconButton>,
                    ]}></SnackbarContent>
            </Snackbar>
        )
    }
}

SnackbarSuccess.propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
}

export default withStyles(styles)(SnackbarSuccess)
