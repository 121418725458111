export default (theme) => ({
    root: {
        display: 'flex',
    },
    paperRoot: {
        //flex: 0.5
        //padding: theme.spacing.unit * 3,
    },
    cardHeader: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        padding: theme.spacing.unit * 3,
        justifyContent: 'space-between',
        height: '80px',
    },
    cardTitleContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
    title: {
        paddingLeft: '8px',
    },
    phoneTitle: {
        paddingTop: '12px',
    },
    alertTextPadding: {
        paddingTop: '12px',
    },
    cardContainer: {
        padding: theme.spacing.unit * 3,
    },
    actions: {
        marginRight: -theme.spacing.unit * 2,
    },
    quickActionButton: {
        height: '100%',
    },
    label: {
        margin: '8px',
    },
})
