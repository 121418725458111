import React from 'react'
import PropTypes from 'prop-types'
import compose from '../../../utils/functions'

// Apollo client
import { withApollo } from '@apollo/client/react/hoc'
import { cancelAppointment, confirmAppointment, getAppointmentById } from '../../../grapql/appointment'

// Components imports
import HistoryIcon from '@material-ui/icons/History'

// Design imports
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Grid,
    Paper,
    Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { APPOINTMENT_PREFERENCE_API } from '../../../constants'
import moment from 'moment/moment'
import styles from './styles'
import DownloadAppointmentPaper from '../../../components/downloadAppointmentPaper/DownloadAppointmentPaper'
import SnackbarSuccess from '../../../components/molecule/SnackbarSuccess/SnackbarSuccess'

// Constants

class AppointmentDetail extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            notification: {},
            deleteDialogOpened: false,
            confirmSnackbarOpened: false,
            deleteSnackbarOpened: false,
            modifyDialogOpened: false,
        }
    }

    parseData = (appointmentFromAPI) => {
        var favoriteTime = ''
        switch (appointmentFromAPI.preference) {
            case APPOINTMENT_PREFERENCE_API.MORNING:
                favoriteTime = 'Mati'
                break
            case APPOINTMENT_PREFERENCE_API.AFTERNOON:
                favoriteTime = 'Tarda'
                break
            default:
                favoriteTime = 'Indiferent'
        }

        var proposedDate =
            appointmentFromAPI.proposedDate !== null
                ? appointmentFromAPI.proposedHour +
                  ' - ' +
                  moment(parseInt(appointmentFromAPI.proposedDate)).format('DD/MM/YYYY')
                : ''

        const appointment = {
            id: appointmentFromAPI.id,
            name:
                appointmentFromAPI.patient?.name?.toUpperCase() +
                ' ' +
                appointmentFromAPI.patient?.lastName?.toUpperCase(),
            patient: appointmentFromAPI.patient,
            phone: appointmentFromAPI.patient.phone,
            birthdate: appointmentFromAPI.patient.birthdate,
            entity: appointmentFromAPI.patient?.entity?.name?.toUpperCase(),
            favoriteTime: favoriteTime,
            observations: appointmentFromAPI.observations ? appointmentFromAPI.observations : '-',
            proposedDate: proposedDate,
            confirmed: appointmentFromAPI.confirmed,
            haveProposedDate: appointmentFromAPI.proposedDate !== '' && appointmentFromAPI.proposedDate !== null,
            createdByUser: appointmentFromAPI.createdByUser,
        }

        this.setState({ notification: appointment })
    }

    fetchAppointment = () => {
        this.props.client
            .query(getAppointmentById(this.props.match.params.id))
            .then((result) => this.parseData(result.data.appointment))
    }

    componentDidMount() {
        this.fetchAppointment()
    }

    goToNewAppointment = () => {
        const patient = this.state.notification.patient
        this.props.history.push({
            pathname: '/new-appointment',
            state: { id: patient.id, name: patient.name, lastName: patient.lastName, prevPath: this.props.match.path },
        })
    }

    handleConfirmButtonClick = () => {
        const mutation = confirmAppointment(this.props.match.params.id)

        this.props.client.mutate(mutation).then((result) => {
            this.setState({ confirmSnackbarOpened: true })
            this.fetchAppointment()
        })
    }

    handleDeleteButtonClick = () => {
        this.setState({ deleteDialogOpened: true })
    }

    handleClose = () => {
        this.setState({
            deleteDialogOpened: false,
            modifyDialogOpened: false,
            confirmSnackbarOpened: false,
            deleteSnackbarOpened: false,
        })
    }

    handleConfirmDelete = () => {
        this.handleClose()
        const mutation = cancelAppointment(this.props.match.params.id)

        this.props.client.mutate(mutation).then((result) => {
            const incidenceId = result.data.cancelAppointment.incidenceId
            this.setState({ deleteSnackbarOpened: true })
            setTimeout(() => {
                this.props.history.push('/incidence/' + incidenceId)
            }, 3000)
        })
    }

    handleModifyAppointment = () => {
        this.handleClose()
        const mutation = cancelAppointment(this.props.match.params.id)

        this.props.client.mutate(mutation).then((result) => {
            this.goToNewAppointment()
        })
    }

    renderActionButtons = () => {
        const { classes, match } = this.props
        const { notification } = this.state

        if (notification.haveProposedDate && notification.confirmed === false) {
            return (
                <div className={classes.cardFooter}>
                    <Button
                        variant="outlined"
                        color="secondary"
                        size="large"
                        className={classes.actionButton}
                        onClick={() => this.setState({ modifyDialogOpened: true })}>
                        Obrir incidència
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        className={classes.actionButton}
                        onClick={() => this.handleConfirmButtonClick()}>
                        Confirmar
                    </Button>
                </div>
            )
        } else if (notification.haveProposedDate && notification.confirmed === true) {
            return (
                <div className={classes.cardFooter}>
                    <DownloadAppointmentPaper
                        entityName={notification?.entity || ''}
                        fullName={notification?.name || ''}
                        age={
                            moment()
                                .diff(moment(parseInt(notification?.birthdate)), 'years')
                                .toString() || ''
                        }
                        appointmentDate={notification?.proposedDate.split(' - ')[1] || ''}
                        appointmentTime={notification?.proposedDate.split(' - ')[0] || ''}
                        socialWorker={
                            notification?.createdByUser
                                ? notification?.createdByUser?.name?.toUpperCase() +
                                  ' ' +
                                  notification?.createdByUser?.lastName?.toUpperCase()
                                : ''
                        }
                    />
                    <Button
                        variant="outlined"
                        color="secondary"
                        size="large"
                        className={classes.actionButton}
                        onClick={() => this.setState({ modifyDialogOpened: true })}>
                        Obrir incidència
                    </Button>
                </div>
            )
        } else {
            return (
                <div className={classes.cardFooter}>
                    <Button
                        variant="outlined"
                        color="secondary"
                        size="large"
                        className={classes.actionButton}
                        onClick={() => this.handleDeleteButtonClick()}>
                        Cancel·lar visita
                    </Button>
                </div>
            )
        }
    }

    render() {
        const { classes, match } = this.props
        const { notification } = this.state

        return (
            <div className={classes.root}>
                <Grid container spacing={24}>
                    <Grid item xs={12} sm={12} md={8} lg={6}>
                        <Paper className={classes.paperRoot}>
                            <div className={classes.cardHeader}>
                                <div className={classes.cardTitleContainer}>
                                    <HistoryIcon color="primary" style={{ fontSize: 28 }} />
                                    <Typography className={classes.title} variant="h6" color="primary">
                                        Detalls de la petició de visita
                                    </Typography>
                                </div>
                            </div>
                            <Divider />
                            <div className={classes.cardContainer}>
                                <Grid container spacing={16}>
                                    <Grid item xs={12} sm={6}>
                                        <Typography className={classes.labelTitle} variant="subtitle2">
                                            Data i hora proposada:
                                        </Typography>
                                        <Typography className={classes.label} variant="h6">
                                            {notification.proposedDate !== '' ? notification.proposedDate : '-'}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography className={classes.labelTitle} variant="subtitle2">
                                            Visita confirmada:
                                        </Typography>
                                        <Typography className={classes.label} variant="h6">
                                            {notification.confirmed ? 'Sí' : 'No'}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography className={classes.labelTitle} variant="subtitle2">
                                            Pacient:
                                        </Typography>
                                        <Typography className={classes.label} variant="h6">
                                            {notification.name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography className={classes.labelTitle} variant="subtitle2">
                                            Telèfon:
                                        </Typography>
                                        <Typography className={classes.label} variant="h6">
                                            {notification.phone}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography className={classes.labelTitle} variant="subtitle2">
                                            Preferència:
                                        </Typography>
                                        <Typography className={classes.label} variant="h6">
                                            {notification.favoriteTime}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <Typography className={classes.labelTitle} variant="subtitle2">
                                            Observacions:
                                        </Typography>
                                        <Typography className={classes.label} variant="h6">
                                            {notification.observations}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <Typography className={classes.labelTitle} variant="subtitle2">
                                            Treballador social:
                                        </Typography>
                                        <Typography className={classes.label} variant="h6">
                                            {notification.createdByUser !== null &&
                                            notification.createdByUser !== undefined
                                                ? notification.createdByUser.name?.toUpperCase() +
                                                  ' ' +
                                                  notification.createdByUser.lastName?.toUpperCase()
                                                : '-'}
                                        </Typography>
                                    </Grid>
                                    {/*<Grid item xs={12} sm={6}>
                                        <Typography className={classes.labelTitle} variant='subtitle2'>
                                            Codi visita:
                                        </Typography>
                                        <Typography className={classes.label} variant='h6'>
                                            839052
                                        </Typography>
                                    </Grid>*/}
                                </Grid>
                            </div>
                            <Divider />
                            {this.renderActionButtons()}
                        </Paper>
                    </Grid>
                </Grid>
                <Dialog
                    open={this.state.deleteDialogOpened}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">{'Està segur que vol cancel·lar la visita?'}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Un cop cancel·lada la visita, s'obrirà una incidència on podrà demanar una nova hora o
                            indicar perquè s'ha cancel·lat la visita. Si té algun dubte pot trucar al 937 398 349.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="secondary">
                            No
                        </Button>
                        <Button onClick={this.handleConfirmDelete} color="primary" autoFocus>
                            Sí, cancelar visita
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.modifyDialogOpened}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">{'Està segur que vol obrir una nova incidència?'}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Si obre una nova incidència, la visita quedarà cancel·lada automàticament. Podrà demanar una
                            nova visita a través d'aquesta incidència o bé cursant una nova petició a través de
                            l'aplicatiu. Si té algun dubte pot trucar al 937 398 349.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="secondary">
                            No
                        </Button>
                        <Button onClick={this.handleConfirmDelete} color="primary" autoFocus>
                            Sí, obrir incidència
                        </Button>
                    </DialogActions>
                </Dialog>
                <SnackbarSuccess
                    open={this.state.confirmSnackbarOpened}
                    title={'Visita confirmada correctament'}
                    onClose={this.handleClose}
                />
                <SnackbarSuccess
                    open={this.state.deleteSnackbarOpened}
                    title={'Visita cancel·lada correctament'}
                    onClose={this.handleClose}
                />
            </div>
        )
    }
}

AppointmentDetail.propTypes = {
    classes: PropTypes.object.isRequired,
}

AppointmentDetail.defaultProps = {}

export default compose(withApollo, withStyles(styles))(AppointmentDetail)
