import React from 'react'
import PropTypes from 'prop-types'

// Apollo client
// Date managment
import { DatePicker } from 'material-ui-pickers'

// JSON Files
import nationalities from '../../../assets/json/nacionalitats.json'
import cities from '../../../assets/json/municipis.json'

// Design imports
import { FormControl, Grid, InputLabel, OutlinedInput, Select, TextField } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import styles from './style'
import * as ReactDOM from 'react-dom'

class NewPatient extends React.Component {
    constructor(props) {
        super(props)

        let patient = {
            name: '',
            lastName: '',
            idNumber: '',
            nationality: '',
            birthdate: null,
            phone: '',
            email: '',
            address: '',
            city: '',
            postalCode: '',
            center: '',
            socialWorker: '',
        }
        if (props.patient && props.patient.name !== '') patient = props.patient

        this.state = {
            patient: patient,
            errors: {
                nameError: false,
                lastNameError: false,
                idNumberError: false,
                nationalityError: false,
                birthdateError: false,
                phoneError: false,
                emailError: false,
                addressError: false,
                cityError: false,
                postalCodeError: false,
                centerError: false,
                socialWorkerError: false,
            },
            centerLabelWidth: 0,
            nationalityLabelWidth: 0,
            cityLabelWidth: 0,
            socialWorkerLabelWidth: 0,
        }
    }

    componentDidMount() {
        const { isSocialWorker, isUpdating } = this.props

        // Get the width for center selector label.
        this.setState({
            centerLabelWidth: !isSocialWorker && !isUpdating ? ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth : 0,
            nationalityLabelWidth: ReactDOM.findDOMNode(this.NationalityLabelRef).offsetWidth,
            cityLabelWidth: ReactDOM.findDOMNode(this.CityLabelRef).offsetWidth,
            socialWorkerLabelWidth:
                !isSocialWorker && isUpdating ? ReactDOM.findDOMNode(this.SocialWorkerLabelRef).offsetWidth : 0,
        })
    }

    validateData = () => {
        let { patient } = this.state
        let { isSocialWorker, isUpdating } = this.props

        let dataIsValid = true
        let errors = {
            nameError: false,
            lastNameError: false,
            idNumberError: false,
            nationalityError: false,
            birthdateError: false,
            phoneError: false,
            emailError: false,
            addressError: false,
            cityError: false,
            postalCodeError: false,
            centerError: false,
            socialWorkerError: false,
        }

        if (!patient.name || patient.name === '') {
            errors.nameError = true
            dataIsValid = false
        }
        if (!patient.lastName || patient.lastName === '') {
            errors.lastNameError = true
            dataIsValid = false
        }
        if (!isUpdating && (!patient.idNumber || patient.idNumber === '')) {
            errors.idNumberError = true
            dataIsValid = false
        }
        if (!isUpdating && (!patient.nationality || patient.nationality === '')) {
            errors.nationalityError = true
            dataIsValid = false
        }
        if (!patient.birthdate || patient.birthdate === '') {
            errors.birthdateError = true
            dataIsValid = false
        }
        if (!patient.phone || patient.phone === '') {
            errors.phoneError = true
            dataIsValid = false
        }
        if (!isUpdating && (!patient.address || patient.address === '')) {
            errors.addressError = true
            dataIsValid = false
        }
        if (!isUpdating && (!patient.city || patient.city === '')) {
            errors.cityError = true
            dataIsValid = false
        }
        if (!isUpdating && (!patient.postalCode || patient.postalCode === '')) {
            errors.postalCodeError = true
            dataIsValid = false
        }
        if (!isSocialWorker && !isUpdating && (!patient.center || patient.center === '')) {
            errors.centerError = true
            dataIsValid = false
        }
        if (!isSocialWorker && isUpdating && (!patient.socialWorker || patient.socialWorker === '')) {
            errors.socialWorkerError = true
            dataIsValid = false
        }

        this.setState({
            errors: errors,
        })
        return dataIsValid
    }

    handleChange = (name, value) => {
        this.setState({
            patient: {
                ...this.state.patient,
                [name]: value,
            },
        })
    }

    handleInputChange = (name) => (event) => {
        this.handleChange(name, event.target.value)
    }

    render() {
        const { classes, centers, isSocialWorker, isUpdating, socialWorkers } = this.props
        const { patient, errors } = this.state

        return (
            <div className={classes.root}>
                <Grid className={classes.cardContainer} container spacing={24}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            fullWidth
                            error={errors.nameError}
                            id="patient-name"
                            label="Nom"
                            name="name"
                            type="text"
                            className={classes.textField}
                            value={patient.name}
                            onChange={this.handleInputChange('name')}
                            margin="normal"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            fullWidth
                            error={errors.lastNameError}
                            id="patient-lastname"
                            label="Cognoms"
                            name="lastname"
                            type="text"
                            className={classes.textField}
                            value={patient.lastName}
                            onChange={this.handleInputChange('lastName')}
                            margin="normal"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            fullWidth
                            error={errors.idNumberError}
                            id="patient-id-number"
                            label="DNI/NIE/Passaport"
                            name="idNumber"
                            type="text"
                            className={classes.textField}
                            value={patient.idNumber}
                            onChange={this.handleInputChange('idNumber')}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth required variant="outlined">
                            <InputLabel
                                ref={(ref) => {
                                    this.NationalityLabelRef = ref
                                }}
                                htmlFor="patient-nationality">
                                Nacionalitat
                            </InputLabel>
                            <Select
                                native
                                error={errors.nationalityError}
                                value={patient.nationality}
                                onChange={this.handleInputChange('nationality')}
                                input={
                                    <OutlinedInput
                                        name="center"
                                        labelWidth={this.state.nationalityLabelWidth}
                                        id="nationality-selection"
                                    />
                                }>
                                <option value="" />
                                {nationalities.map((item, index) => {
                                    return <option value={item.iso2}>{item.nom}</option>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <DatePicker
                            autoOk
                            fullWidth
                            keyboard
                            disableFuture
                            placeholder={'DD/MM/YYYY'}
                            mask={(value) => (value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : [])}
                            error={errors.birthdateError}
                            label="Data de naixement:"
                            format={'DD/MM/YYYY'}
                            value={patient.birthdate}
                            variant="outlined"
                            className={classes.textField}
                            onChange={(date) => this.handleChange('birthdate', date)}
                        />
                    </Grid>
                    <Grid item xs={0} sm={6}></Grid>

                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <TextField
                            fullWidth
                            required
                            error={errors.phoneError}
                            id="patient-phone"
                            label="Telèfon"
                            name="phone"
                            type="tel"
                            pattern="^[0-9-+s()]*$"
                            className={classes.textField}
                            value={patient.phone}
                            onChange={this.handleInputChange('phone')}
                            margin="normal"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            error={errors.emailError}
                            id="patient-email"
                            label="Email del pacient"
                            name="email"
                            type="email"
                            className={classes.textField}
                            value={patient.email}
                            onChange={this.handleInputChange('email')}
                            margin="normal"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            fullWidth
                            error={errors.addressError}
                            id="patient-address"
                            label="Adreça"
                            name="address"
                            type="text"
                            className={classes.textField}
                            value={patient.address}
                            onChange={this.handleInputChange('address')}
                            variant="outlined"
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth required variant="outlined">
                            <InputLabel
                                ref={(ref) => {
                                    this.CityLabelRef = ref
                                }}
                                htmlFor="patient-city">
                                Municipi
                            </InputLabel>
                            <Select
                                native
                                error={errors.cityError}
                                value={patient.city}
                                onChange={this.handleInputChange('city')}
                                input={
                                    <OutlinedInput
                                        name="center"
                                        labelWidth={this.state.cityLabelWidth}
                                        id="city-selection"
                                    />
                                }>
                                <option value="" />
                                {cities.map((item, index) => {
                                    return <option value={item.codi}>{item.nom}</option>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            fullWidth
                            error={errors.postalCodeError}
                            id="patient-postalcode"
                            label="Codi postal"
                            name="postalcode"
                            type="text"
                            className={classes.textField}
                            value={patient.postalCode}
                            onChange={this.handleInputChange('postalCode')}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={0} sm={6}></Grid>

                    {!isSocialWorker && !isUpdating && (
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth required variant="outlined">
                                <InputLabel
                                    ref={(ref) => {
                                        this.InputLabelRef = ref
                                    }}
                                    htmlFor="patient-center">
                                    Entitat
                                </InputLabel>
                                <Select
                                    native
                                    error={errors.centerError}
                                    value={patient.center}
                                    onChange={this.handleInputChange('center')}
                                    input={
                                        <OutlinedInput
                                            name="center"
                                            labelWidth={this.state.centerLabelWidth}
                                            id="patient-selection"
                                        />
                                    }>
                                    <option value="" />
                                    {centers.map((item, index) => {
                                        return <option value={item.id}>{item.name?.toUpperCase()}</option>
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}

                    {!isSocialWorker && isUpdating && (
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth required variant="outlined">
                                <InputLabel
                                    ref={(ref) => {
                                        this.SocialWorkerLabelRef = ref
                                    }}
                                    htmlFor="patient-social-worker">
                                    Treballador social
                                </InputLabel>
                                <Select
                                    native
                                    error={errors.socialWorkerError}
                                    value={patient.socialWorker}
                                    onChange={this.handleInputChange('socialWorker')}
                                    input={
                                        <OutlinedInput
                                            name="socialWorker"
                                            labelWidth={this.state.socialWorkerLabelWidth}
                                            id="socialWorker-selection"
                                        />
                                    }>
                                    <option value="" />
                                    {socialWorkers.map((item, index) => {
                                        return (
                                            <option value={item.id}>
                                                {item.user.name?.toUpperCase() +
                                                    ' ' +
                                                    item.user.lastName?.toUpperCase()}
                                            </option>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}
                </Grid>
            </div>
        )
    }
}

NewPatient.propTypes = {
    classes: PropTypes.object.isRequired,
    centers: PropTypes.array,
    socialWorkers: PropTypes.array,
    patient: PropTypes.object,
    isUpdating: PropTypes.bool,
    isSocialWorker: PropTypes.bool,
}

export default withStyles(styles)(NewPatient)
