import React from 'react'
import PropTypes from 'prop-types'
import compose from '../../../utils/functions'
import SearchSelect from 'react-select'
import { ROLE } from '../../../constants'
import { getValueFromStorage } from '../../../utils'

// Apollo client
import { withApollo } from '@apollo/client/react/hoc'
import { getPatientsNames } from '../../../grapql/patient'
import { getSuppliersOnlyName } from '../../../grapql/supplier'
import { getManagementStudents } from '../../../grapql/students'
import { createAssembly } from '../../../grapql/assembly'

// Date managment
import MomentUtils from '@date-io/moment'
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers'
import moment from 'moment'

// Components imports
import FeedbackSnackbar from '../../../components/feedback_snackbar/index'

// Design imports
import {
    Button,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    OutlinedInput,
    Paper,
    Select,
    TextField,
    Typography,
    withWidth,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import InboxIcon from '@material-ui/icons/AllInbox'
import ReactDOM from 'react-dom'
import { getSubjectsNames } from '../../../grapql/subject'

class NewAssembly extends React.Component {
    constructor(props) {
        super(props)

        const role = getValueFromStorage(ROLE)

        this.state = {
            patients: [],
            suppliers: [],
            students: [],
            subjects: [],
            confirmSnackbarOpened: false,
            errorSnackbarOpened: false,
            labelWidth: 0,
            patient: '',
            appointmentDate: moment(),
            casnum: '',
            historyNum: '',
            observations: '',
            student: '',
            subject: '',
            expectedDelivery: moment(),
            orderDate: moment(),
            supplier: '',
            patientError: false,
            casnumError: false,
            historyNumError: false,
            expectedDeliveryError: false,
            studentError: false,
            appointmentDateError: false,
            orderDateError: false,
            supplierError: false,
            subjectError: false,
            role: role,
        }
    }

    componentDidMount() {
        this.props.client
            .query(getSuppliersOnlyName())
            .then((result) => this.setState({ suppliers: result.data.allSuppliers.suppliers }))
        this.props.client
            .query(getManagementStudents())
            .then((result) => this.setState({ students: result.data.allManagementStudents }))
        this.props.client
            .query(getSubjectsNames())
            .then((result) => this.setState({ subjects: result.data.allSubjects.subjects }))

        const selectedPatient =
            this.props.location.state !== null && this.props.location.state !== undefined
                ? this.props.location.state
                : null
        if (selectedPatient !== null) {
            const patients = [
                { value: selectedPatient.id, label: selectedPatient.name + ' ' + selectedPatient.lastName },
            ]

            this.setState({
                patients,
                patient: selectedPatient.id,
            })
        }

        // Get the width for select patient selector label.
        this.setState({
            //labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
            supplierLabelWidth: ReactDOM.findDOMNode(this.SupplierInputLabelRef).offsetWidth,
            studentLabelWidth: ReactDOM.findDOMNode(this.StudentInputLabelRef).offsetWidth,
            subjectLabelWidth: ReactDOM.findDOMNode(this.SubjectLabelRef).offsetWidth,
        })
    }

    validateData = () => {
        var dataIsValid = true

        var errors = {
            patientError: false,
            casnumError: false,
            historyNumError: false,
            expectedDeliveryError: false,
            studentError: false,
            appointmentDateError: false,
            orderDateError: false,
            supplierError: false,
            subjectError: false,
        }

        if (this.state.patient === '' || this.state.patient === null) {
            errors.patientError = true
            dataIsValid = false
        }
        if (this.state.casnum === '') {
            errors.casnumError = true
            dataIsValid = false
        }
        if (this.state.historyNum === '') {
            errors.historyNumError = true
            dataIsValid = false
        }
        /*if (this.state.expectedDelivery === '') {
            errors.expectedDeliveryError = true;
            dataIsValid = false;
        }*/
        if (this.state.student === '') {
            errors.studentError = true
            dataIsValid = false
        }
        if (this.state.appointmentDate === null && this.state.observations === '') {
            errors.appointmentDateError = true
            dataIsValid = false
        }
        /*if (this.state.orderDate === '') {
            errors.orderDateError = true;
            dataIsValid = false;
        }*/
        /*if (this.state.supplier === '') {
            errors.supplierError = true;
            dataIsValid = false;
        }*/
        if (this.state.subject === '') {
            errors.subjectError = true
            dataIsValid = false
        }

        this.setState(errors)

        return dataIsValid
    }

    parsePatients = (patientsFromAPI) => {
        var patients = []

        patientsFromAPI.forEach((patient) => {
            patients.push({
                value: patient.id,
                label: patient.name?.toUpperCase() + ' ' + patient.lastName?.toUpperCase(),
            })
        })

        this.setState({ patients: patients })
    }

    fetchPatients = (search = undefined) => {
        this.props.client
            .query(getPatientsNames(search))
            .then((result) => this.parsePatients(result.data.allPatients.patients))
    }

    handlePatientSelection = (name) => (value) => {
        this.setState({ patient: value })
    }

    handleSubjectChange = (name) => (event) => {
        this.setState({
            subject: event.target.value,
        })
    }

    onSearchInputChange = (name) => (value) => {
        if (value.length >= 2) {
            this.fetchPatients(value)
        }
    }

    handleChange = (name) => (event) => {
        this.setState({
            [name]: event.target.value,
        })
    }

    handleClose = () => {
        this.setState({ confirmSnackbarOpened: false, errorSnackbarOpened: false })
    }

    handleConfirmButtonClick = () => {
        if (this.validateData()) {
            const mutation = createAssembly(
                this.state.casnum,
                this.state.historyNum,
                this.state.observations,
                this.state.patient.value,
                this.state.expectedDelivery !== '' && this.state.expectedDelivery !== null
                    ? this.state.expectedDelivery.valueOf().toString()
                    : null,
                this.state.student,
                this.state.orderDate !== '' && this.state.orderDate !== null
                    ? this.state.orderDate.valueOf().toString()
                    : null,
                this.state.supplier,
                this.state.appointmentDate !== '' && this.state.expectedDelivery !== null
                    ? this.state.appointmentDate.valueOf().toString()
                    : null,
                this.state.subject,
            )

            this.props.client
                .mutate(mutation)
                .then((result) => {
                    this.setState({ confirmSnackbarOpened: true })
                    setTimeout(() => {
                        //this.props.history.goBack();
                        this.props.history.push('/assembly/' + result.data.createAssembly.id)
                    }, 3000)
                })
                .catch((error) => {
                    if (error.graphQLErrors[0] !== null && error.graphQLErrors[0] !== undefined) {
                        if (error.graphQLErrors[0].extensions.exception.name === 'SequelizeUniqueConstraintError') {
                            this.setState({ errorSnackbarOpened: true })
                        }
                    }
                })
        }
    }

    handlePickerSelection = (name) => (event) => {
        this.setState({ [name]: event.target.value })
    }

    render() {
        const { classes } = this.props

        return (
            <div className={classes.root}>
                <Grid container spacing={24} justify="center">
                    <Grid item xs={12} sm={12} md={8} lg={6}>
                        <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                            <Paper className={classes.paperRoot}>
                                <div className={classes.cardHeader}>
                                    <div className={classes.cardTitleContainer}>
                                        <InboxIcon color="primary" style={{ fontSize: 28 }} />
                                        <Typography className={classes.title} variant="h6" color="primary">
                                            Nou muntatge
                                        </Typography>
                                    </div>
                                </div>
                                <Divider />
                                <Grid className={classes.cardContainer} container spacing={24}>
                                    <Grid item xs={12} sm={12}>
                                        <FormControl
                                            fullWidth
                                            required
                                            error={this.state.patientError}
                                            variant="outlined"
                                            className={classes.searchControl}>
                                            <SearchSelect
                                                required
                                                value={this.state.patient}
                                                onChange={this.handlePatientSelection()}
                                                onInputChange={this.onSearchInputChange()}
                                                options={this.state.patients}
                                                placeholder={'Escriu dues lletres per començar la cerca'}
                                                noOptionsMessage={() => "No s'ha trobat cap pacient"}
                                                isClearable
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <Grid className={classes.cardContainer} container spacing={24}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            fullWidth
                                            error={this.state.casnumError}
                                            id="assembly-casnum"
                                            label="Número del cas"
                                            helperText="NO introdueixi el prefix de l'any"
                                            name="casnum"
                                            type="text"
                                            className={classes.textField}
                                            value={this.state.casnum}
                                            onChange={this.handleChange('casnum')}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            fullWidth
                                            error={this.state.historyNumError}
                                            id="assembly-historynum"
                                            label="Número d'història"
                                            name="historyNum"
                                            type="text"
                                            className={classes.textField}
                                            value={this.state.historyNum}
                                            onChange={this.handleChange('historyNum')}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            fullWidth
                                            required
                                            variant="outlined"
                                            className={classes.formControl}>
                                            <InputLabel
                                                ref={(ref) => {
                                                    this.StudentInputLabelRef = ref
                                                }}
                                                htmlFor="assembly-student">
                                                Alumne del gabinet
                                            </InputLabel>
                                            <Select
                                                native
                                                error={this.state.studentError}
                                                value={this.state.student}
                                                onChange={this.handlePickerSelection('student')}
                                                input={
                                                    <OutlinedInput
                                                        name="supplier"
                                                        labelWidth={this.state.studentLabelWidth}
                                                        id="assembly-student"
                                                    />
                                                }>
                                                <option value="" />
                                                {this.state.students.map((item, index) => {
                                                    return (
                                                        <option value={item.id}>
                                                            {item.user.name?.toUpperCase() +
                                                                ' ' +
                                                                item.user.lastName?.toUpperCase()}
                                                        </option>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <DatePicker
                                            autoOk
                                            fullWidth
                                            keyboard
                                            clearable
                                            disableFuture
                                            placeholder={'DD/MM/YYYY'}
                                            mask={(value) =>
                                                value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : []
                                            }
                                            error={this.state.appointmentDateError}
                                            label="Data de la visita"
                                            format={'DD/MM/YYYY'}
                                            value={this.state.appointmentDate}
                                            variant="outlined"
                                            className={classes.formControl}
                                            onChange={(date) => this.setState({ appointmentDate: date })}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <DatePicker
                                            autoOk
                                            fullWidth
                                            keyboard
                                            clearable
                                            disablePast
                                            placeholder={'DD/MM/YYYY'}
                                            mask={(value) =>
                                                value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : []
                                            }
                                            error={this.state.expectedDeliveryError}
                                            label="Data d'entrega prevista"
                                            format={'DD/MM/YYYY'}
                                            value={this.state.expectedDelivery}
                                            variant="outlined"
                                            className={classes.formControl}
                                            onChange={(date) => this.setState({ expectedDelivery: date })}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            fullWidth
                                            required
                                            variant="outlined"
                                            className={classes.formControl}>
                                            <InputLabel
                                                ref={(ref) => {
                                                    this.SubjectLabelRef = ref
                                                }}
                                                htmlFor="user-subject">
                                                Assignatura
                                            </InputLabel>
                                            <Select
                                                native
                                                error={this.state.subjectError}
                                                value={this.state.subject}
                                                onChange={this.handleSubjectChange()}
                                                input={
                                                    <OutlinedInput
                                                        name="user-subject"
                                                        labelWidth={this.state.subjectLabelWidth}
                                                        id="user-subject"
                                                    />
                                                }>
                                                <option value="" />
                                                {this.state.subjects.map((item, index) => {
                                                    return <option value={item.id}>{item.name?.toUpperCase()}</option>
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <Grid className={classes.cardContainer} container spacing={24}>
                                    <Grid item xs={12} sm={6}>
                                        <DatePicker
                                            autoOk
                                            fullWidth
                                            keyboard
                                            clearable
                                            placeholder={'DD/MM/YYYY'}
                                            mask={(value) =>
                                                value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : []
                                            }
                                            error={this.state.orderDateError}
                                            label="Data de comanda"
                                            format={'DD/MM/YYYY'}
                                            value={this.state.orderDate}
                                            variant="outlined"
                                            className={classes.formControl}
                                            onChange={(date) => this.setState({ orderDate: date })}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                                            <InputLabel
                                                ref={(ref) => {
                                                    this.SupplierInputLabelRef = ref
                                                }}
                                                htmlFor="assembly-supplier">
                                                Proveïdor
                                            </InputLabel>
                                            <Select
                                                native
                                                error={this.state.supplierError}
                                                value={this.state.supplier}
                                                onChange={this.handlePickerSelection('supplier')}
                                                input={
                                                    <OutlinedInput
                                                        name="supplier"
                                                        labelWidth={this.state.supplierLabelWidth}
                                                        id="assembly-supplier"
                                                    />
                                                }>
                                                <option value="" />
                                                {this.state.suppliers.map((item, index) => {
                                                    return <option value={item.id}>{item.name?.toUpperCase()}</option>
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <Grid className={classes.cardContainer} container spacing={24}>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            error={this.state.appointmentDateError}
                                            id="assembly-observations"
                                            label="Observacions"
                                            multiline
                                            rowsMax="4"
                                            rows="3"
                                            value={this.state.observations}
                                            onChange={this.handleChange('observations')}
                                            className={classes.textField}
                                            helperText="Si no hi ha visita prèvia del pacient, indica perquè s'està generant aquest nou muntatge"
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                                <Divider />
                                <div className={classes.cardFooter}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        fullWidth={this.props.width === 'xs'}
                                        onClick={() => this.handleConfirmButtonClick()}>
                                        Crear muntatge
                                    </Button>
                                </div>
                            </Paper>
                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>
                <FeedbackSnackbar
                    showSnackbar={this.state.confirmSnackbarOpened}
                    handleClose={() => this.handleClose()}
                    timeShowed={3000}
                    text={'Muntatge creat correctament'}
                />
                <FeedbackSnackbar
                    showSnackbar={this.state.errorSnackbarOpened}
                    handleClose={() => this.handleClose()}
                    timeShowed={3000}
                    variant={'error'}
                    text={'Aquest CASNUM ja existeix'}
                />
            </div>
        )
    }
}

const styles = (theme) => ({
    root: {
        display: 'flex',
        flex: 1,
    },
    paperRoot: {
        //flex: 0.5
        //padding: theme.spacing.unit * 3,
    },
    cardHeader: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        padding: theme.spacing.unit * 3,
        justifyContent: 'space-between',
        height: '80px',
    },
    cardFooter: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        padding: theme.spacing.unit * 3,
        justifyContent: 'flex-end',
        height: '80px',
    },
    cardTitleContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
    title: {
        paddingLeft: '8px',
    },
    cardContainer: {
        padding: theme.spacing.unit * 3,
    },
    textField: {
        //marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    textFieldWidth: {
        marginRight: theme.spacing.unit,
        minWidth: '200px',
    },
    formControl: {
        marginTop: 16,
    },
    searchControl: {
        marginTop: 16,
        zIndex: 1000,
    },
})

NewAssembly.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default compose(withApollo, withWidth(), withStyles(styles))(NewAssembly)
