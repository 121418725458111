import gql from 'graphql-tag'

export const getAllSocialWorkerFromEntity = (entityId) => {
    return {
        query: gql`
            query AllSocialWorkersFromEntity($entityId: ID) {
                allSocialWorkersFromEntity(entityId: $entityId) {
                    id
                    user {
                        name
                        lastName
                    }
                }
            }
        `,
        variables: {
            entityId: entityId,
        },
    }
}

export const getLoggedSocialWorker = () => {
    return {
        query: gql`
            {
                loggedSocialWorker {
                    id
                    userId
                    entityId
                }
            }
        `,
    }
}
