import gql from 'graphql-tag'

export const getManagementStudents = () => {
    return {
        query: gql`
            {
                allManagementStudents {
                    id
                    user {
                        id
                        name
                        lastName
                    }
                }
            }
        `,
    }
}
