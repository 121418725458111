import React from 'react'
import PropTypes from 'prop-types'
import compose from '../../../utils/functions'
import { DEFAULT_ROWS_PER_PAGE } from '../../../constants'

// Apollo client
import { withApollo } from '@apollo/client/react/hoc'
import { getIncidences } from '../../../grapql/incidence'

// Components imports
import ManagementTableWrapper from '../../../components/management_table_wrapper'

// Design imports
import { Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import moment from 'moment/moment'

// Constants
const columns = [
    // { id: 'type', numeric: false, disablePadding: false, label: 'Tipus' },
    { id: 'id', numeric: false, disablePadding: false, label: 'Codi incidència' },
    { id: 'name', numeric: false, disablePadding: false, label: 'Pacient', sortable: false },
    { id: 'title', numeric: false, disablePadding: false, label: 'Títol' },
    // { id: 'description', numeric: false, disablePadding: false, label: 'Descripció' },
    { id: 'date', numeric: false, disablePadding: false, label: 'Data' },
    { id: 'open', numeric: false, disablePadding: false, label: 'Estat' },
]

const filterOptions = [
    {
        columnId: 'open',
        label: 'Estat',
        options: [
            {
                value: true,
                label: 'Oberta',
            },
            {
                value: false,
                label: 'Tancada',
            },
        ],
    },
]

class Incidences extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            data: [],
            totalCount: 0,
        }
    }

    parseData = (incidencesFromAPI) => {
        var incidences = []

        incidencesFromAPI.forEach((incidence) => {
            incidences.push({
                id: incidence.id,
                name: incidence.patient?.name?.toUpperCase() + ' ' + incidence.patient?.lastName?.toUpperCase(),
                title: incidence.title,
                date: moment(parseInt(incidence.createdAt)).format('DD/MM/YYYY - HH:mm'),
                open: incidence.open ? 'Oberta' : 'Tancada',
            })
        })

        this.setState({ data: incidences })
    }

    componentDidMount() {
        this.fetchData(0, DEFAULT_ROWS_PER_PAGE)
    }

    fetchData = (offset, limit, orderBy, orderDirection, searchText, filterText) => {
        var castOrderBy = orderBy
        if (orderBy === 'date') castOrderBy = 'createdAt'

        this.props.client
            .query(getIncidences(offset, limit, castOrderBy, orderDirection, searchText, filterText))
            .then((result) => {
                this.parseData(result.data.allIncidences.incidences)
                this.setState({ totalCount: result.data.allIncidences.totalCount })
            })
    }

    goToNewIncidence = () => {
        this.props.history.push('/new-incidence')
    }

    handleClick = (id) => {
        this.props.history.push('/incidence/' + id)
    }

    render() {
        const { classes } = this.props
        const { data, totalCount } = this.state

        const actionButtons = (
            <div>
                <Button variant="outlined" color="primary" onClick={() => this.goToNewIncidence()}>
                    Nova incidencia
                </Button>
            </div>
        )

        return (
            <ManagementTableWrapper
                title="Llistat d'incidències"
                rows={data}
                columns={columns}
                filterOptions={filterOptions}
                actionButtons={actionButtons}
                onRowClick={(id) => this.handleClick(id)}
                totalCount={totalCount}
                fetchData={this.fetchData}
            />
        )
    }
}

const styles = (theme) => ({})

Incidences.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default compose(withApollo, withStyles(styles))(Incidences)
