import React from 'react'
import PropTypes from 'prop-types'
import compose from '../../../utils/functions'
import { DEFAULT_ROWS_PER_PAGE } from '../../../constants'

// Apollo client
import { withApollo } from '@apollo/client/react/hoc'
import { getSuppliers } from '../../../grapql/supplier'

// Components imports
import ManagementTableWrapper from '../../../components/management_table_wrapper'

// Design imports
import { Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

// Constants
const columns = [
    { id: 'name', numeric: false, disablePadding: false, label: 'Nom' },
    // { id: 'lastName', numeric: false, disablePadding: false, label: 'Cognoms' },
    { id: 'contactPerson', numeric: false, disablePadding: false, label: 'Persona de contacte' },
    { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
    { id: 'phone', numeric: false, disablePadding: false, label: 'Telèfon' },
    { id: 'startAgreementDate', numeric: false, date: true, disablePadding: false, label: 'Data inici del conveni' },
    { id: 'endAgreementDate', numeric: false, date: true, disablePadding: false, label: 'Data final del conveni' },
]

class Suppliers extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            data: [],
            totalCount: 0,
        }
    }

    componentDidMount() {
        this.fetchData(0, DEFAULT_ROWS_PER_PAGE)
    }

    fetchData = (offset, limit, orderBy, orderDirection, searchText) => {
        this.props.client.query(getSuppliers(offset, limit, orderBy, orderDirection, searchText)).then((result) => {
            if (result.data.allSuppliers.suppliers) {
                const parsedSuppliers = result.data.allSuppliers.suppliers.map((supplier) => {
                    return {
                        id: supplier.id,
                        name: supplier.name?.toUpperCase(),
                        contactPerson: supplier.contactPerson?.toUpperCase(),
                        email: supplier.email?.toUpperCase(),
                        phone: supplier.phone,
                        startAgreementDate: supplier.startAgreementDate,
                        endAgreementDate: supplier.endAgreementDate,
                    }
                })

                this.setState({
                    data: parsedSuppliers,
                    totalCount: result.data.allSuppliers.totalCount,
                })
            }
        })
    }

    goToNewSupplier = () => {
        this.props.history.push('/new-supplier')
    }

    handleClick = (id) => {
        this.props.history.push('/supplier/' + id)
    }

    render() {
        const { classes } = this.props
        const { data, totalCount } = this.state

        const actionButtons = (
            <div>
                <Button variant="outlined" color="primary" onClick={() => this.goToNewSupplier()}>
                    Nou proveïdor
                </Button>
            </div>
        )

        return (
            <ManagementTableWrapper
                title="Llistat de proveïdors"
                rows={data}
                columns={columns}
                actionButtons={actionButtons}
                onRowClick={(id) => this.handleClick(id)}
                totalCount={totalCount}
                fetchData={this.fetchData}
            />
        )
    }
}

const styles = (theme) => ({})

Suppliers.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default compose(withApollo, withStyles(styles))(Suppliers)
