import gql from 'graphql-tag'

export const getIncidences = (offset, limit, order, orderDirection, search, filter) => {
    return {
        query: gql`
            query GetIncidences(
                $offset: Int
                $limit: Int
                $order: String
                $orderDirection: String
                $search: String
                $filter: String
            ) {
                allIncidences(
                    offset: $offset
                    limit: $limit
                    order: $order
                    orderDirection: $orderDirection
                    search: $search
                    filter: $filter
                ) {
                    incidences {
                        id
                        title
                        createdAt
                        patient {
                            id
                            name
                            lastName
                        }
                        open
                    }
                    totalCount
                }
            }
        `,
        variables: {
            offset: offset,
            limit: limit,
            order: order,
            orderDirection: orderDirection,
            search: search,
            filter: filter,
        },
    }
}

export const getIncidenceById = (id) => {
    return {
        query: gql`
            query GetIncidenceById($id: ID) {
                incidence(id: $id) {
                    id
                    title
                    createdAt
                    open
                    patient {
                        id
                        name
                        lastName
                    }
                    creator {
                        name
                    }
                    messages {
                        message
                        createdAt
                        isMine
                        creator {
                            name
                        }
                    }
                }
            }
        `,
        variables: {
            id: id,
        },
    }
}

export const getIncidencesList = () => {
    return {
        query: gql`
            {
                allIncidences {
                    incidences {
                        id
                        title
                        createdAt
                        patient {
                            id
                            name
                            lastName
                        }
                        messages {
                            message
                        }
                    }
                }
            }
        `,
    }
}

export const addIncidence = (title, patientId, message) => {
    return {
        mutation: gql`
            mutation AddIncidence($title: String, $patientId: ID, $message: String) {
                addIncidence(input: { title: $title, patientId: $patientId, message: $message }) {
                    id
                }
            }
        `,
        variables: {
            title: title,
            patientId: patientId,
            message: message,
        },
    }
}

export const closeIncidence = (message, incidenceId) => {
    return {
        mutation: gql`
            mutation CloseIncidence($message: String, $incidenceId: ID) {
                closeIncidence(input: { message: $message, incidenceId: $incidenceId }) {
                    id
                }
            }
        `,
        variables: {
            message: message,
            incidenceId: incidenceId,
        },
    }
}
