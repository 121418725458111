// JSON Files
import nationalities from '../assets/json/nacionalitats.json'
import cities from '../assets/json/municipis.json'

export const getNationalityName = (code) => {
    const nationality = nationalities.find((item) => item.iso2 === code)
    return nationality?.nom || '-'
}

export const getCityName = (cityCode) => {
    const city = cities.find((item) => item.codi === cityCode)
    return city?.nom || '-'
}

export default function compose(...funcs) {
    if (funcs.length === 0) {
        return (arg) => arg
    }

    if (funcs.length === 1) {
        return funcs[0]
    }

    return funcs.reduce(
        (a, b) =>
            (...args) =>
                a(b(...args)),
    )
}
