import React from 'react'

import classNames from 'classnames'
import { Route, withRouter } from 'react-router-dom'
import { AUTH_TOKEN, ROLE } from '../../constants'
import { getValueFromStorage } from '../../utils'

// Component imports
import Login from '../login'
import NewIncidence from '../new_incidence'
import IncidenceDetail from '../incidence_detail'
import UserProfile from '../user_profile'
import StudentSignup from '../signup/students'
import SocialWorkerSignup from '../signup/social_workers'

// TS Components
import Home from '../role_ts/home'
import Patients from '../role_ts/patients'
import NewPatient from '../role_ts/new_patient'
import PatientDetail from '../role_ts/patient_detail'
import Notifications from '../role_ts/notifications'
import Appointments from '../role_ts/appointments'
import AppointmentDetail from '../role_ts/appointment_detail'
import AppointmentRequest from '../role_ts/appointment_request'

// PDI Components
import HomePDI from '../role_pdi/home'
import PatientsPDI from '../role_pdi/patients'
import NewPatientPDI from '../role_pdi/new_patient'
import PatientDetailPDI from '../role_pdi/patient_detail'
import Assemblies from '../role_pdi/assemblies'
import AssembliesSearchResults from '../role_pdi/assemblies_search_results'
import NewAssembly from '../role_pdi/new_assembly'
import NewAssemblyRepetition from '../role_pdi/new_assembly_repetition'
import AssemblyDetail from '../role_pdi/assembly_detail'
import Users from '../role_pdi/users'
import UserDetail from '../role_pdi/user_detail'
import Entities from '../role_pdi/entities'
import NewEntity from '../role_pdi/new_entity'
import EntityDetail from '../role_pdi/entity_detail'
import Incidences from '../role_pdi/incidences'
import AppointmentsPDI from '../role_pdi/appointments'
import NewAppointmentPDI from '../role_pdi/new_appointment'
import AppointmentDetailPDI from '../role_pdi/appointment_detail'
import NewUser from '../role_pdi/new_user'
import Suppliers from '../role_pdi/suppliers'
import NewSupplier from '../role_pdi/new_supplier'
import SupplierDetail from '../role_pdi/supplier_detail'
import Subjects from '../role_pdi/subjects'
import NewSubject from '../role_pdi/new_subject'
import SubjectDetail from '../role_pdi/subject_detail'

// Student Components
import HomeStudent from '../role_students/home'

// Teacher Components
import HomeTeacher from '../role_teacher/home'

// Design imports
import {
    AppBar,
    Divider,
    Drawer,
    Hidden,
    IconButton,
    InputBase,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Toolbar,
    Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import MenuIcon from '@material-ui/icons/Menu'
import HomeIcon from '@material-ui/icons/Home'
import PeopleIcon from '@material-ui/icons/People'
import NotificationIcon from '@material-ui/icons/Notifications'
import UserIcon from '@material-ui/icons/AccountCircle'
import LogoutIcon from '@material-ui/icons/PowerSettingsNew'
import HistoryIcon from '@material-ui/icons/QueryBuilder'
import WarningIcon from '@material-ui/icons/Warning'
import InboxIcon from '@material-ui/icons/AllInbox'
import UsersIcon from '@material-ui/icons/SupervisorAccountOutlined'
import EntitiesIcon from '@material-ui/icons/AccountBalance'
import SuppliersIcon from '@material-ui/icons/LocalShipping'
import SubjectsIcon from '@material-ui/icons/Class'
import styles from './styles'
import SearchIcon from '@material-ui/icons/Search'

// CONSTANTS
const ROLES = {
    ADMIN: 'admin',
    PDI: 'pdi',
    STUDENT: 'student',
    MANAGEMENT_STUDENT: 'management_student',
    TS: 'ts',
}
const HOME_ICON = 'HomeIcon'
const PEOPLE_ICON = 'PeopleIcon'
const NOTIFICATION_ICON = 'NotificationIcon'
const USER_ICON = 'UserIcon'
const LOGOUT_ICON = 'LogoutIcon'
const HISTORY_ICON = 'HistoryIcon'
const WARNING_ICON = 'WarningIcon'
const INBOX_ICON = 'InboxIcon'
const USERS_ICON = 'UsersIcon'
const ENTITIES_ICON = 'EntitiesIcon'
const SUPPLIERS_ICON = 'SuppliersIcon'
const SUBJECTS_ICON = 'SubjectsIcon'
const menuTS = [
    {
        name: 'Inici',
        icon: HOME_ICON,
        path: '/',
    },
    {
        name: 'Pacients',
        icon: PEOPLE_ICON,
        path: '/patients',
    },
    {
        name: "Peticions d'hora",
        icon: HISTORY_ICON,
        path: '/appointments',
    },
    {
        name: 'Incidències',
        icon: WARNING_ICON,
        path: '/incidences',
    },
    {
        name: 'Divider',
    },
    {
        name: "Perfil d'usuari",
        icon: USER_ICON,
        path: '/user',
    },
    {
        name: 'Tancar sessió',
        icon: LOGOUT_ICON,
        path: 'logout',
    },
]
const menuPDI = [
    {
        name: 'Inici',
        icon: HOME_ICON,
        path: '/',
    },
    {
        name: 'Pacients',
        icon: PEOPLE_ICON,
        path: '/patients',
    },
    {
        name: 'Muntatges',
        icon: INBOX_ICON,
        path: '/assemblies',
    },
    {
        name: 'Incidències',
        icon: WARNING_ICON,
        path: '/incidences',
    },
    {
        name: "Peticions d'hora",
        icon: HISTORY_ICON,
        path: '/appointments',
    },
    {
        name: 'Usuaris',
        icon: USERS_ICON,
        path: '/users',
    },
    {
        name: 'Entitats',
        icon: ENTITIES_ICON,
        path: '/entities',
    },
    {
        name: 'Proveïdors',
        icon: SUPPLIERS_ICON,
        path: '/suppliers',
    },
    {
        name: 'Assignatures',
        icon: SUBJECTS_ICON,
        path: '/subjects',
    },
    {
        name: 'Divider',
    },
    {
        name: "Perfil d'usuari",
        icon: USER_ICON,
        path: '/user',
    },
    {
        name: 'Tancar sessió',
        icon: LOGOUT_ICON,
        path: 'logout',
    },
]
const menuStudent = [
    {
        name: 'Inici',
        icon: HOME_ICON,
        path: '/',
    },
    {
        name: 'Muntatges',
        icon: INBOX_ICON,
        path: '/assemblies',
    },
    {
        name: 'Divider',
    },
    {
        name: "Perfil d'usuari",
        icon: USER_ICON,
        path: '/user',
    },
    {
        name: 'Tancar sessió',
        icon: LOGOUT_ICON,
        path: 'logout',
    },
]
const menuManagmentStudent = [
    {
        name: 'Inici',
        icon: HOME_ICON,
        path: '/',
    },
    {
        name: 'Muntatges',
        icon: INBOX_ICON,
        path: '/assemblies',
    },
    {
        name: 'Pacients',
        icon: PEOPLE_ICON,
        path: '/patients',
    },
    {
        name: "Peticions d'hora",
        icon: HISTORY_ICON,
        path: '/appointments',
    },
    {
        name: 'Divider',
    },
    {
        name: "Perfil d'usuari",
        icon: USER_ICON,
        path: '/user',
    },
    {
        name: 'Tancar sessió',
        icon: LOGOUT_ICON,
        path: 'logout',
    },
]

class App extends React.Component {
    state = {
        open: false,
        actualMainComponentIndex: 0,
        showLogin: true,
        userRole: ROLES.PDI,
        searchText: '',
    }

    componentDidMount() {
        const token = getValueFromStorage(AUTH_TOKEN)
        if (token === null) {
            this.setState({ showLogin: true, userRole: '' })
        } else {
            const role = getValueFromStorage(ROLE)
            this.setState({ showLogin: false, userRole: role })
        }
    }

    handleDrawerToggle = () => {
        this.setState({ open: !this.state.open })
    }

    handleConfirm = (role = ROLES.TS) => {
        this.setState({
            showLogin: false,
            // DEBUG:
            userRole: role,
        })
    }

    navigateToPath = (path, index) => {
        this.props.history.push(path)
        this.setState({ open: false, actualMainComponentIndex: index })
    }

    logout = () => {
        this.props.history.push('/')
        this.setState({ showLogin: true, open: false, actualMainComponentIndex: 0 })
        localStorage.removeItem(AUTH_TOKEN)
        localStorage.removeItem(ROLE)
        sessionStorage.removeItem(AUTH_TOKEN)
        sessionStorage.removeItem(ROLE)
    }

    onChangeSearchText = (event) => {
        this.setState({ searchText: event.target.value })
    }

    renderDrawerItem = (item, index) => {
        if (item.name === 'Divider') {
            return <Divider />
        }

        var icon = null
        switch (item.icon) {
            case HOME_ICON:
                icon = <HomeIcon />
                break
            case PEOPLE_ICON:
                icon = <PeopleIcon />
                break
            case NOTIFICATION_ICON:
                icon = <NotificationIcon />
                break
            case USER_ICON:
                icon = <UserIcon />
                break
            case LOGOUT_ICON:
                icon = <LogoutIcon />
                break
            case WARNING_ICON:
                icon = <WarningIcon />
                break
            case HISTORY_ICON:
                icon = <HistoryIcon />
                break
            case INBOX_ICON:
                icon = <InboxIcon />
                break
            case USERS_ICON:
                icon = <UsersIcon />
                break
            case ENTITIES_ICON:
                icon = <EntitiesIcon />
                break
            case SUPPLIERS_ICON:
                icon = <SuppliersIcon />
                break
            case SUBJECTS_ICON:
                icon = <SubjectsIcon />
                break
            default:
                icon = <HomeIcon />
        }

        var action = () => this.navigateToPath(item.path, index)
        if (item.path === 'logout') {
            action = () => this.logout()
        }

        return (
            <ListItem button key={item.name} onClick={action}>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={item.name} />
            </ListItem>
        )
    }

    renderMainComponent = () => {
        const { classes, theme } = this.props

        if (this.state.searchText !== '' && this.state.userRole !== ROLES.TS) {
            return (
                <main className={classes.main}>
                    <AssembliesSearchResults
                        onAssembblySelected={() => this.setState({ searchText: '' })}
                        searchText={this.state.searchText}
                    />
                </main>
            )
        }

        if (this.state.userRole === ROLES.TS) {
            return (
                <main className={classes.main}>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/patients" component={Patients} />
                    <Route path="/patient/:id" component={PatientDetail} />
                    <Route exact path="/new-patient" component={NewPatient} />
                    <Route exact path="/new-appointment" component={AppointmentRequest} />
                    <Route exact path="/new-incidence" component={NewIncidence} />
                    <Route exact path="/incidences" component={Notifications} />
                    <Route exact path="/appointments" component={Appointments} />
                    <Route path="/incidence/:id" component={IncidenceDetail} />
                    <Route path="/appointment/:id" component={AppointmentDetail} />
                    <Route exact path="/user" component={UserProfile} />
                </main>
            )
        } else if (this.state.userRole === ROLES.ADMIN) {
            return (
                <main className={classes.main}>
                    <Route exact path="/" component={HomePDI} />
                    <Route exact path="/patients" component={PatientsPDI} />
                    <Route exact path="/new-patient" component={NewPatientPDI} />
                    <Route path="/patient/:id" component={PatientDetailPDI} />
                    <Route exact path="/assemblies" component={Assemblies} />
                    <Route exact path="/new-assembly" component={NewAssembly} />
                    <Route exact path="/new-repetition" component={NewAssemblyRepetition} />
                    <Route path="/assembly/:id" component={AssemblyDetail} />
                    <Route exact path="/incidences" component={Incidences} />
                    <Route exact path="/new-incidence" component={NewIncidence} />
                    <Route path="/incidence/:id" component={IncidenceDetail} />
                    <Route exact path="/appointments" component={AppointmentsPDI} />
                    <Route exact path="/new-appointment" component={NewAppointmentPDI} />
                    <Route path="/appointment/:id" component={AppointmentDetailPDI} />
                    <Route exact path="/users" component={Users} />
                    <Route path="/user/:id" component={UserDetail} />
                    <Route exact path="/new-user" component={NewUser} />
                    <Route exact path="/entities" component={Entities} />
                    <Route exact path="/new-entity" component={NewEntity} />
                    <Route path="/entity/:id" component={EntityDetail} />
                    <Route exact path="/suppliers" component={Suppliers} />
                    <Route exact path="/new-supplier" component={NewSupplier} />
                    <Route path="/supplier/:id" component={SupplierDetail} />
                    <Route exact path="/subjects" component={Subjects} />
                    <Route exact path="/new-subject" component={NewSubject} />
                    <Route path="/subject/:id" component={SubjectDetail} />
                    <Route exact path="/user" component={UserProfile} />
                </main>
            )
        } else if (this.state.userRole === ROLES.STUDENT) {
            return (
                <main className={classes.main}>
                    <Route exact path="/" component={HomeStudent} />
                    <Route exact path="/assemblies" component={Assemblies} />
                    <Route exact path="/new-assembly" component={NewAssembly} />
                    <Route exact path="/new-repetition" component={NewAssemblyRepetition} />
                    <Route path="/assembly/:id" component={AssemblyDetail} />
                    <Route exact path="/user" component={UserProfile} />
                </main>
            )
        } else if (this.state.userRole === ROLES.MANAGEMENT_STUDENT) {
            return (
                <main className={classes.main}>
                    <Route exact path="/" component={HomeStudent} />
                    <Route exact path="/assemblies" component={Assemblies} />
                    <Route exact path="/new-assembly" component={NewAssembly} />
                    <Route exact path="/new-repetition" component={NewAssemblyRepetition} />
                    <Route path="/assembly/:id" component={AssemblyDetail} />
                    <Route exact path="/patients" component={PatientsPDI} />
                    <Route path="/patient/:id" component={PatientDetailPDI} />
                    <Route exact path="/appointments" component={AppointmentsPDI} />
                    <Route path="/appointment/:id" component={AppointmentDetailPDI} />
                    <Route exact path="/user" component={UserProfile} />
                </main>
            )
        } else if (this.state.userRole === ROLES.PDI) {
            return (
                <main className={classes.main}>
                    <Route exact path="/" component={HomeTeacher} />
                    <Route exact path="/assemblies" component={Assemblies} />
                    <Route exact path="/new-assembly" component={NewAssembly} />
                    <Route path="/assembly/:id" component={AssemblyDetail} />
                    <Route exact path="/user" component={UserProfile} />
                </main>
            )
        }
    }

    renderSearchBar = () => {
        const { classes } = this.props

        var searchBar = null

        if (this.state.userRole !== ROLES.TS) {
            searchBar = (
                <div className={classes.globalSearch}>
                    <div className={classes.globalSearchIcon}>
                        <SearchIcon color="white" />
                    </div>
                    <InputBase
                        placeholder="Cercar per CAS NUM…"
                        onChange={this.onChangeSearchText}
                        value={this.state.searchText}
                        autoFocus={false}
                        autoComplete={'off'}
                        classes={{
                            root: classes.inputRoot,
                            input: classes.globalSearchInput,
                        }}
                    />
                </div>
            )
        }

        return searchBar
    }

    renderApp() {
        const { classes, theme } = this.props

        var drawerToShow = menuTS
        if (this.state.userRole === ROLES.ADMIN) {
            drawerToShow = menuPDI
        } else if (this.state.userRole === ROLES.MANAGEMENT_STUDENT) {
            drawerToShow = menuManagmentStudent
        } else if (this.state.userRole === ROLES.STUDENT || this.state.userRole === ROLES.PDI) {
            drawerToShow = menuStudent
        }

        const drawer = (
            <List>
                {drawerToShow.map((item, index) => this.renderDrawerItem(item, index))}
                <div style={{ textAlign: 'center', fontSize: '12px', color: '#a1a1a1', marginTop: 8, marginBottom: 8 }}>
                    v.3.0.2
                </div>
            </List>
        )

        return (
            <div className={classes.root}>
                <AppBar position="absolute" className={classNames(classes.appBar)}>
                    <Toolbar className={classes.toolbar} disableGutters={!this.state.open}>
                        <IconButton
                            color="inherit"
                            aria-label="Open drawer"
                            onClick={this.handleDrawerToggle}
                            className={classes.menuButton}>
                            <MenuIcon />
                        </IconButton>
                        <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                            {drawerToShow[this.state.actualMainComponentIndex].name}
                        </Typography>
                        <div className={classes.grow} />
                        {this.renderSearchBar()}
                    </Toolbar>
                </AppBar>

                <Hidden mdUp>
                    <Drawer
                        variant="temporary"
                        anchor={'left'}
                        open={this.state.open}
                        onClose={this.handleDrawerToggle}
                        classes={{ paper: classes.drawerPaper }}
                        ModalProps={{ keepMounted: true }}>
                        {drawer}
                    </Drawer>
                </Hidden>

                <Hidden smDown implementation="css">
                    <Drawer
                        variant="permanent"
                        classes={{
                            paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
                        }}
                        open={this.state.open}>
                        <div className={classes.toolbarSpacer} />
                        {drawer}
                    </Drawer>
                </Hidden>

                <div className={classes.content}>
                    <div className={classes.appBarSpacer} />
                    {this.renderMainComponent()}
                </div>
            </div>
        )
    }

    renderLogin() {
        const socialWorkerSignupUrlMatch = new RegExp('/signup/workers/.+')

        if (this.props.location.pathname === '/signup/students') {
            return <StudentSignup />
        } else if (socialWorkerSignupUrlMatch.test(this.props.location.pathname)) {
            /*this.props.location.pathname.includes("/signup/workers/")*/
            return <SocialWorkerSignup />
        }

        return <Login loginConfirmed={this.handleConfirm} />
    }

    render() {
        const { classes, theme } = this.props
        const { showLogin } = this.state

        // TODO: Search another way to change toolbar title based on path from router
        return <div>{showLogin ? this.renderLogin() : this.renderApp()}</div>
    }
}

export default withRouter(withStyles(styles)(App))
