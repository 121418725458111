export default (theme) => ({
    root: {
        display: 'flex',
    },
    paperRoot: {
        //padding: theme.spacing.unit * 3,
    },
    cardHeader: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        padding: theme.spacing.unit * 3,
        justifyContent: 'space-between',
        height: '80px',
    },
    cardTitleContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
    cardContainer: {
        padding: theme.spacing.unit * 3,
    },
    title: {
        //fontWeight: 'bold',
        paddingLeft: '8px',
    },
    labelTitle: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
        color: theme.palette.primary.light,
    },
    label: {
        fontWeight: 'normal',
    },
    link: {
        textDecoration: 'none',
    },
    actions: {
        //color: theme.palette.text.secondary,
        marginRight: -theme.spacing.unit * 2,
    },
    list: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing.unit * 2,
        right: theme.spacing.unit * 2,
    },
    listItem: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '40px',
    },
    listItemDate: {
        color: theme.palette.primary.light,
    },
    warningListItem: {},
    warningDateListItem: {
        paddingTop: '8px',
        color: theme.palette.primary.light,
    },
    trackContainer: {
        paddingTop: theme.spacing.unit * 3,
        paddingBottom: theme.spacing.unit * 3,
        paddingLeft: theme.spacing.unit * 3,
        paddingRight: theme.spacing.unit * 3,
    },
    trackSectionTitleContainer: {
        paddingLeft: theme.spacing.unit * 2,
        paddingRight: theme.spacing.unit * 2,
        paddingTop: theme.spacing.unit * 1,
        marginBottom: -(theme.spacing.unit * 2),
        //height: '52px',
        backgroundColor: '#FAFAFA',
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    trackSectionTitle: {
        fontWeight: 'bold',
    },
    trackEndSection: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        // paddingTop: theme.spacing.unit * 2,
        // paddingBottom: theme.spacing.unit * 2,
    },
    trackEndTitle: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
        color: theme.palette.primary.light,
        paddingLeft: '12px',
    },
    trackEndIcon: {
        color: theme.palette.primary.light,
    },
    actionDial: {
        backgroundColor: '#FFFFFF',
    },
    mobileStepper: {
        borderBottomLeftRadius: '4px',
        borderBottomRightRadius: '4px',
    },
    cardFooter: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        padding: theme.spacing.unit * 3,
        justifyContent: 'flex-end',
        height: '80px',
    },
    actionButton: {
        //width: '200px',
        marginLeft: '8px',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginTop: 8,
            marginBottom: 8,
        },
    },
})
