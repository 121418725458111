import React from 'react'
import PropTypes from 'prop-types'
import compose from '../../../utils/functions'

// Components imports
import FeedbackSnackbar from '../../../components/feedback_snackbar/index'

// Apollo client
import { withApollo } from '@apollo/client/react/hoc'
import { addSupplier } from '../../../grapql/supplier'

// Date managment
import MomentUtils from '@date-io/moment'
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers'
import moment from 'moment'

// Design imports
import { Button, Divider, Grid, Paper, TextField, Typography, withWidth } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { isWidthUp } from '@material-ui/core/withWidth'
import SuppliersIcon from '@material-ui/icons/LocalShipping'

class NewSupplier extends React.Component {
    state = {
        name: '',
        startAgreement: moment(),
        endAgreement: moment(),
        contactPerson: '',
        phone: '',
        email: '',
        confirmSnackbarOpened: false,
        labelWidth: 0,
        phones: [],
        nameError: false,
        startAgreementError: false,
        endAgreementError: false,
        contactPersonError: false,
        phoneError: false,
        emailError: false,
    }

    validateData = () => {
        var dataIsValid = true

        var errors = {
            nameError: false,
            startAgreementError: false,
            endAgreementError: false,
            contactPersonError: false,
            phoneError: false,
            emailError: false,
        }

        if (this.state.name === '') {
            errors.nameError = true
            dataIsValid = false
        }
        if (this.state.startAgreement === '') {
            errors.startAgreementError = true
            dataIsValid = false
        }
        if (this.state.endAgreement === '') {
            errors.endAgreementError = true
            dataIsValid = false
        }
        if (this.state.contactPerson === '') {
            errors.contactPersonError = true
            dataIsValid = false
        }
        if (this.state.phone === '') {
            errors.phoneError = true
            dataIsValid = false
        }
        // TODO Revisar validacion del email

        this.setState(errors)

        return dataIsValid
    }

    handleChange = (name) => (event) => {
        this.setState({
            [name]: event.target.value,
        })
    }

    handleClose = () => {
        this.setState({ confirmSnackbarOpened: false })
    }

    handleConfirmButtonClick = () => {
        if (this.validateData()) {
            const startDateUnix = this.state.startAgreement.valueOf().toString()
            const endDateUnix = this.state.endAgreement.valueOf().toString()

            const mutation = addSupplier(
                this.state.name,
                this.state.contactPerson,
                this.state.email,
                this.state.phone,
                startDateUnix,
                endDateUnix,
            )

            this.props.client.mutate(mutation).then((result) => {
                this.setState({ confirmSnackbarOpened: true })
                setTimeout(() => {
                    this.props.history.goBack()
                }, 3000)
            })
        }
    }

    handlePrefixSelection = () => (event) => {
        this.setState({ prefix: event.target.value })
    }

    handleCenterChange = () => (event) => {
        this.setState({ center: event.target.value })
    }

    render() {
        const { classes } = this.props

        return (
            <div className={classes.root}>
                <Grid container spacing={24} justify="center">
                    <Grid item xs={12} sm={12} md={8} lg={6}>
                        <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                            <Paper className={classes.paperRoot}>
                                <div className={classes.cardHeader}>
                                    <div className={classes.cardTitleContainer}>
                                        <SuppliersIcon color="primary" style={{ fontSize: 28 }} />
                                        <Typography className={classes.title} variant="h6" color="primary">
                                            Nou proveïdor
                                        </Typography>
                                    </div>
                                </div>
                                <Divider />
                                <Grid className={classes.cardContainer} container spacing={24}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            fullWidth
                                            error={this.state.nameError}
                                            id="entity-name"
                                            label="Nom"
                                            name="name"
                                            type="text"
                                            className={classes.textField}
                                            value={this.state.name}
                                            onChange={this.handleChange('name')}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    {isWidthUp('sm', this.props.width) ? <Grid item xs={0} sm={6}></Grid> : null}
                                    <Grid item xs={12} sm={6}>
                                        <DatePicker
                                            autoOk
                                            fullWidth
                                            keyboard
                                            placeholder={'DD/MM/YYYY'}
                                            mask={(value) =>
                                                value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : []
                                            }
                                            error={this.state.startAgreementError}
                                            label="Data inici del conveni"
                                            format={'DD/MM/YYYY'}
                                            value={this.state.startAgreement}
                                            variant="outlined"
                                            className={classes.textField}
                                            onChange={(date) => this.setState({ startAgreement: date })}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <DatePicker
                                            autoOk
                                            fullWidth
                                            keyboard
                                            placeholder={'DD/MM/YYYY'}
                                            mask={(value) =>
                                                value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : []
                                            }
                                            error={this.state.endAgreementError}
                                            label="Data final del conveni"
                                            format={'DD/MM/YYYY'}
                                            value={this.state.endAgreement}
                                            variant="outlined"
                                            className={classes.textField}
                                            onChange={(date) => this.setState({ endAgreement: date })}
                                        />
                                    </Grid>
                                </Grid>
                                <Divider />
                                <Grid className={classes.cardContainer} container spacing={24}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            fullWidth
                                            error={this.state.contactPersonError}
                                            id="entity-name"
                                            label="Nom de la persona de contacte"
                                            name="contactPerson"
                                            type="text"
                                            className={classes.textField}
                                            value={this.state.contactPerson}
                                            onChange={this.handleChange('contactPerson')}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <TextField
                                            fullWidth
                                            required
                                            error={this.state.phoneError}
                                            id="entity-phone"
                                            label="Telèfon"
                                            name="phone"
                                            type="tel"
                                            pattern="^[0-9-+s()]*$"
                                            className={classes.textField}
                                            value={this.state.phone}
                                            onChange={this.handleChange('phone')}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            error={this.state.emailError}
                                            id="entity-email"
                                            label="Email"
                                            name="email"
                                            type="email"
                                            className={classes.textField}
                                            value={this.state.email}
                                            onChange={this.handleChange('email')}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                                <Divider />
                                <div className={classes.cardFooter}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        fullWidth={this.props.width === 'xs'}
                                        onClick={() => this.handleConfirmButtonClick()}>
                                        Crear proveïdor
                                    </Button>
                                </div>
                            </Paper>
                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>
                <FeedbackSnackbar
                    showSnackbar={this.state.confirmSnackbarOpened}
                    handleClose={() => this.handleClose()}
                    timeShowed={3000}
                    text={"Proveïdor donada d'alta correctament"}
                />
            </div>
        )
    }
}

const styles = (theme) => ({
    root: {
        display: 'flex',
        flex: 1,
    },
    paperRoot: {
        //flex: 0.5
        //padding: theme.spacing.unit * 3,
    },
    cardHeader: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        padding: theme.spacing.unit * 3,
        justifyContent: 'space-between',
        height: '80px',
    },
    cardFooter: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        padding: theme.spacing.unit * 3,
        justifyContent: 'flex-end',
        height: '80px',
    },
    cardTitleContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
    title: {
        paddingLeft: '8px',
    },
    cardContainer: {
        padding: theme.spacing.unit * 3,
    },
    textField: {
        //marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    textFieldWidth: {
        marginRight: theme.spacing.unit,
        minWidth: '200px',
    },
    formControl: {
        marginTop: 16,
    },
})

NewSupplier.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default compose(withApollo, withWidth(), withStyles(styles))(NewSupplier)
