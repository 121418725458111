import React from 'react'
import PropTypes from 'prop-types'
import compose from '../../../utils/functions'
import { getValueFromStorage } from '../../../utils'

// Date managment
import MomentUtils from '@date-io/moment'
import { DatePicker, MuiPickersUtilsProvider, TimePicker } from 'material-ui-pickers'
import moment from 'moment'

// Apollo client
import {
    cancelAppointment,
    confirmAppointment,
    getAppointmentById,
    proposeAppointmentDate,
} from '../../../grapql/appointment'

// Components imports
import HistoryIcon from '@material-ui/icons/History'

// Design imports
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    Paper,
    Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { APPOINTMENT_PREFERENCE_API, ROLE, ROLE_CAST } from '../../../constants'
import DownloadAppointmentPaper from '../../../components/downloadAppointmentPaper/DownloadAppointmentPaper'
import DialogConfirmAction from '../../../components/molecule/DialogConfirmAction/DialogConfirmAction'
import styles from './style'
import SnackbarSuccess from '../../../components/molecule/SnackbarSuccess/SnackbarSuccess'
import { withApollo } from '@apollo/client/react/hoc'

// Constants

class AppointmentDetailPDI extends React.Component {
    constructor(props) {
        super(props)

        const role = getValueFromStorage(ROLE)

        this.state = {
            appointment: {},
            deleteDialogOpened: false,
            confirmAppointmentDialogOpened: false,
            confirmSnackbarOpened: false,
            deleteSnackbarOpened: false,
            appointmentDialogOpened: false,
            hourProposed: moment(),
            dateProposed: moment(),
            hourProposedError: false,
            dateProposedError: false,
            userRole: role,
        }
    }

    parseData = (appointmentFromAPI) => {
        var favoriteTime = ''
        switch (appointmentFromAPI.preference) {
            case APPOINTMENT_PREFERENCE_API.MORNING:
                favoriteTime = 'Mati'
                break
            case APPOINTMENT_PREFERENCE_API.AFTERNOON:
                favoriteTime = 'Tarda'
                break
            default:
                favoriteTime = 'Indiferent'
        }

        var proposedDate =
            appointmentFromAPI.proposedDate !== null
                ? appointmentFromAPI.proposedHour +
                  ' - ' +
                  moment(parseInt(appointmentFromAPI.proposedDate)).format('DD/MM/YYYY')
                : ''

        const appointment = {
            id: appointmentFromAPI.id,
            name:
                appointmentFromAPI.patient?.name?.toUpperCase() +
                ' ' +
                appointmentFromAPI.patient?.lastName?.toUpperCase(),
            birthdate: moment(parseInt(appointmentFromAPI.patient.birthdate)).format('DD/MM/YYYY'),
            phone: appointmentFromAPI.patient?.phone || '-',
            entity: appointmentFromAPI.patient?.entity?.name?.toUpperCase(),
            favoriteTime: favoriteTime,
            observations: appointmentFromAPI.observations ? appointmentFromAPI.observations : '-',
            proposedDate: proposedDate,
            confirmed: appointmentFromAPI.confirmed ? 'Confirmada' : '',
            haveProposedDate: appointmentFromAPI.proposedDate !== '' && appointmentFromAPI.proposedDate !== null,
            createdByUser: appointmentFromAPI.createdByUser,
            createdAt: moment(parseInt(appointmentFromAPI.createdAt)).format('DD/MM/YYYY'),
        }

        const dateProposed =
            appointmentFromAPI.proposedDate !== null ? moment(parseInt(appointmentFromAPI.proposedDate)) : moment()
        var hourProposed = moment(dateProposed)
        if (appointmentFromAPI.proposedHour !== null) {
            const hour = parseInt(appointmentFromAPI.proposedHour.split(':')[0])
            const minutes = parseInt(appointmentFromAPI.proposedHour.split(':')[1])
            hourProposed.hour(hour).minute(minutes)
        }

        this.setState({ appointment: appointment, hourProposed, dateProposed })
    }

    componentDidMount() {
        this.fetchAppointment()
    }

    fetchAppointment = () => {
        this.props.client
            .query(getAppointmentById(this.props.match.params.id))
            .then((result) => this.parseData(result.data.appointment))
    }

    validateData = () => {
        var dataIsValid = true

        var errors = {
            hourProposedError: false,
            dateProposedError: false,
        }

        if (this.state.hourProposed === '') {
            errors.hourProposedError = true
            dataIsValid = false
        }
        if (this.state.dateProposed === '') {
            errors.dateProposedError = true
            dataIsValid = false
        }

        this.setState(errors)

        return dataIsValid
    }

    handleProposeButtonClick = () => {
        if (this.validateData()) {
            const proposedDate = this.state.dateProposed.valueOf().toString()
            const proposedHour = this.state.hourProposed.format('HH:mm')
            const mutation = proposeAppointmentDate(this.props.match.params.id, proposedDate, proposedHour)

            this.props.client.mutate(mutation).then((result) => {
                this.setState({ confirmSnackbarOpened: true, appointmentDialogOpened: false })
                setTimeout(() => {
                    this.props.history.goBack()
                }, 3000)
            })
        }
    }

    handleDeleteButtonClick = () => {
        this.setState({ deleteDialogOpened: true })
    }

    handleConfirmButtonClick = () => {
        const mutation = confirmAppointment(this.props.match.params.id)

        this.props.client.mutate(mutation).then((result) => {
            this.setState({ confirmSnackbarOpened: true, confirmAppointmentDialogOpened: false })
            this.fetchAppointment()
        })
    }

    handleClose = () => {
        this.setState({
            deleteDialogOpened: false,
            confirmAppointmentDialogOpened: false,
            confirmSnackbarOpened: false,
            appointmentDialogOpened: false,
            deleteSnackbarOpened: false,
        })
    }

    handleConfirmDelete = () => {
        this.handleClose()
        const mutation = cancelAppointment(this.props.match.params.id)

        this.props.client.mutate(mutation).then((result) => {
            const incidenceId = result.data.cancelAppointment.incidenceId
            this.setState({ deleteSnackbarOpened: true })
            setTimeout(() => {
                this.props.history.push('/incidence/' + incidenceId)
            }, 3000)
        })
    }

    renderConfirmAppointmentPicker = () => {
        return (
            <DialogConfirmAction
                open={this.state.confirmAppointmentDialogOpened}
                title={'Està segur que vol confirmar la visita?'}
                description={
                    'Si us plau, confirma només la visita si heu confirmat amb el pacient la data i hora proposada, en cas contrari, deixeu la visita com a pendent de confirmar fins que el treballador social hagi parlat amb el pacient.'
                }
                cancelText={'No'}
                confirmText={'Sí, confirmar visita'}
                onCancel={this.handleClose}
                onConfirm={this.handleConfirmButtonClick}
                onClose={this.handleClose}
            />
        )
    }

    renderDeletePicker = () => {
        return (
            <DialogConfirmAction
                open={this.state.deleteDialogOpened}
                title={'Està segur que vol cancel·lar la visita?'}
                description={'Un cop cancel·lada la visita quedarà esborrada del sistema i no es podrà restaurar'}
                cancelText={'No'}
                confirmText={'Sí, cancel·lar visita'}
                onCancel={this.handleClose}
                onConfirm={this.handleConfirmDelete}
                onClose={this.handleClose}
            />
        )
    }

    renderAppointmentPicker = () => {
        const { classes, match } = this.props

        return (
            <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                <Dialog
                    open={this.state.appointmentDialogOpened}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">{"Seleccioni l'hora i data desitjada"}</DialogTitle>
                    <DialogContent>
                        <DatePicker
                            autoOk
                            fullWidth
                            keyboard
                            disablePast
                            placeholder={'DD/MM/YYYY'}
                            mask={(value) => (value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : [])}
                            error={this.state.dateProposedError}
                            label="Data de la visita"
                            format={'DD/MM/YYYY'}
                            value={this.state.dateProposed}
                            variant="outlined"
                            className={classes.textField}
                            onChange={(date) => this.setState({ dateProposed: date })}
                        />
                        <TimePicker
                            keyboard
                            fullWidth
                            mask={[/\d/, /\d/, ':', /\d/, /\d/]}
                            placeholder={'10:00'}
                            ampm={false}
                            label={'Hora de la visita'}
                            value={this.state.hourProposed}
                            variant="outlined"
                            onChange={(hour) => {
                                this.setState({ hourProposed: hour })
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="secondary">
                            Cancel·lar
                        </Button>
                        <Button onClick={this.handleProposeButtonClick} color="primary" autoFocus>
                            Assignar visita
                        </Button>
                    </DialogActions>
                </Dialog>
            </MuiPickersUtilsProvider>
        )
    }

    renderDateProposed = () => {
        const { classes, match } = this.props
        const { appointment } = this.state

        if (appointment.proposedDate !== '') {
            return (
                <div>
                    <Grid container spacing={16} className={classes.cardContainer}>
                        <Grid item xs={12} sm={6}>
                            <Typography className={classes.labelTitle} variant="subtitle2">
                                Data i hora proposada:
                            </Typography>
                            <Typography className={classes.label} variant="h6">
                                {appointment.proposedDate}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography className={classes.labelTitle} variant="subtitle2">
                                Visita confirmada
                            </Typography>
                            <Typography className={classes.label} variant="h6">
                                {appointment.confirmed ? 'Confirmada' : '-'}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider />
                </div>
            )
        }
    }

    renderActionButtons = () => {
        const { classes, match } = this.props
        const { appointment } = this.state

        if (this.state.userRole !== ROLE_CAST.ADMIN) return null

        if (!appointment.haveProposedDate) {
            return (
                <div className={classes.cardFooter}>
                    <Button
                        variant="outlined"
                        color="secondary"
                        size="large"
                        className={classes.actionButton}
                        onClick={() => this.handleDeleteButtonClick()}>
                        Cancel·lar visita
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        className={classes.actionButton}
                        onClick={() => this.setState({ appointmentDialogOpened: true })}>
                        Assignar visita
                    </Button>
                </div>
            )
        }

        return (
            <div className={classes.cardFooterLeftAndRight}>
                <div>
                    <DownloadAppointmentPaper
                        entityName={appointment?.entity || ''}
                        fullName={appointment?.name || ''}
                        age={moment().diff(moment(appointment?.birthdate, 'DD/MM/YYYY'), 'years').toString() || ''}
                        appointmentDate={appointment?.proposedDate.split(' - ')[1] || ''}
                        appointmentTime={appointment?.proposedDate.split(' - ')[0] || ''}
                        socialWorker={
                            appointment?.createdByUser
                                ? appointment?.createdByUser?.name?.toUpperCase() +
                                  ' ' +
                                  appointment?.createdByUser?.lastName?.toUpperCase()
                                : ''
                        }
                    />
                </div>
                <div>
                    <Button
                        variant="outlined"
                        color="secondary"
                        size="large"
                        className={classes.actionButton}
                        onClick={() => this.handleDeleteButtonClick()}>
                        Cancel·lar visita
                    </Button>
                    {!appointment.confirmed && (
                        <Button
                            variant="outlined"
                            size="large"
                            color="primary"
                            className={classes.actionButton}
                            onClick={() => this.setState({ confirmAppointmentDialogOpened: true })}>
                            Confirmar visita
                        </Button>
                    )}
                    <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        className={classes.actionButton}
                        onClick={() => this.setState({ appointmentDialogOpened: true })}>
                        Modificar visita
                    </Button>
                </div>
            </div>
        )
    }

    render() {
        const { classes } = this.props
        const { appointment } = this.state

        return (
            <div className={classes.root}>
                <Grid container spacing={24}>
                    <Grid item xs={12} sm={12} md={8} lg={6}>
                        <Paper className={classes.paperRoot}>
                            <div className={classes.cardHeader}>
                                <div className={classes.cardTitleContainer}>
                                    <HistoryIcon color="primary" style={{ fontSize: 28 }} />
                                    <Typography className={classes.title} variant="h6" color="primary">
                                        Detalls de la petició de visita
                                    </Typography>
                                </div>
                            </div>
                            <Divider />
                            {this.renderDateProposed()}
                            <Grid container spacing={16} className={classes.cardContainer}>
                                <Grid item xs={12} sm={6}>
                                    <Typography className={classes.labelTitle} variant="subtitle2">
                                        Pacient:
                                    </Typography>
                                    <Typography className={classes.label} variant="h6">
                                        {appointment.name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography className={classes.labelTitle} variant="subtitle2">
                                        Data de naixement:
                                    </Typography>
                                    <Typography className={classes.label} variant="h6">
                                        {appointment.birthdate}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography className={classes.labelTitle} variant="subtitle2">
                                        Telèfon:
                                    </Typography>
                                    <Typography className={classes.label} variant="h6">
                                        {appointment.phone}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography className={classes.labelTitle} variant="subtitle2">
                                        Entitat:
                                    </Typography>
                                    <Typography className={classes.label} variant="h6">
                                        {appointment.entity}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography className={classes.labelTitle} variant="subtitle2">
                                        Treballador social:
                                    </Typography>
                                    <Typography className={classes.label} variant="h6">
                                        {appointment.createdByUser !== null && appointment.createdByUser !== undefined
                                            ? appointment.createdByUser.name?.toUpperCase() +
                                              ' ' +
                                              appointment.createdByUser.lastName?.toUpperCase()
                                            : '-'}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                            <Grid container spacing={16} className={classes.cardContainer}>
                                <Grid item xs={12} sm={6}>
                                    <Typography className={classes.labelTitle} variant="subtitle2">
                                        Preferència:
                                    </Typography>
                                    <Typography className={classes.label} variant="h6">
                                        {appointment.favoriteTime}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography className={classes.labelTitle} variant="subtitle2">
                                        Data de la petició:
                                    </Typography>
                                    <Typography className={classes.label} variant="h6">
                                        {appointment.createdAt}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography className={classes.labelTitle} variant="subtitle2">
                                        Observacions:
                                    </Typography>
                                    <Typography className={classes.label} variant="h6">
                                        {appointment.observations === '' ? '-' : appointment.observations}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                            {this.renderActionButtons()}
                        </Paper>
                    </Grid>
                </Grid>
                {this.renderAppointmentPicker()}
                {this.renderDeletePicker()}
                {this.renderConfirmAppointmentPicker()}
                <SnackbarSuccess
                    open={this.state.confirmSnackbarOpened}
                    title={'Visita assignada correctament'}
                    onClose={this.handleClose}
                />
                <SnackbarSuccess
                    open={this.state.deleteSnackbarOpened}
                    title={'Visita cancel·lada correctament'}
                    onClose={this.handleClose}
                />
            </div>
        )
    }
}

AppointmentDetailPDI.propTypes = {
    classes: PropTypes.object.isRequired,
}

AppointmentDetailPDI.defaultProps = {}

export default compose(withApollo, withStyles(styles))(AppointmentDetailPDI)
