import React from 'react'
import PropTypes from 'prop-types'
import compose from '../../utils/functions'

// Components imports
import EnhancedTable from '../../components/enhanced_table'

// Design imports
import { withWidth } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

// Class
class ManagementTableWrapper extends React.Component {
    state = {
        orderBy: undefined,
        orderDirection: undefined,
        searchText: undefined,
        filterText: undefined,
    }

    fetchData = (
        offset,
        limit,
        orderBy = this.state.orderBy,
        orderDirection = this.state.orderDirection,
        searchText = this.state.searchText,
        filterText = this.state.filterText,
    ) => {
        this.props.fetchData(offset, limit, orderBy, orderDirection, searchText, filterText)
    }

    handleChangePage = (page, rowsPerPage) => {
        this.fetchData(page * rowsPerPage, rowsPerPage)
    }

    handleRequestSort = (orderBy, orderDirection, rowsPerPage) => {
        this.setState({ orderBy, orderDirection })
        this.fetchData(0, rowsPerPage, orderBy, orderDirection)
    }

    onSearchTextChange = (value, rowsPerPage) => {
        const searchText = value
        this.setState({ searchText })
        this.fetchData(0, rowsPerPage, undefined, undefined, searchText)
    }

    onFilterUpdate = (selectedValues, rowsPerPage) => {
        var filterText = null
        for (var i = 0; i < selectedValues.length; i++) {
            const value = selectedValues[i]
            if (value !== '' && value !== null) {
                var columnFilterQuery = this.props.filterOptions[i].columnId + ' = '

                if (typeof value === 'boolean' || typeof value === 'number') {
                    columnFilterQuery += value
                } else if (typeof value === 'string' && (value === 'NOT NULL' || value === 'NULL')) {
                    columnFilterQuery = this.props.filterOptions[i].columnId + ' IS ' + value
                } else {
                    columnFilterQuery += "'" + value + "'"
                }

                if (filterText === null) {
                    filterText = columnFilterQuery
                } else {
                    filterText += ' AND ' + columnFilterQuery
                }
            }
        }

        this.setState({ filterText })
        this.fetchData(0, rowsPerPage, undefined, undefined, undefined, filterText)
    }

    onFilterReset = (rowsPerPage) => {
        this.setState({ filterText: undefined })
        this.fetchData(0, rowsPerPage, undefined, undefined, undefined, null)
    }

    render() {
        const {
            classes,
            title,
            rows,
            columns,
            actionButtons,
            onRowClick,
            totalCount,
            filterOptions,
            onValidateClicked,
            searchDisabled,
        } = this.props

        return (
            <EnhancedTable
                title={title}
                rows={rows}
                columns={columns}
                filterOptions={filterOptions}
                actionButtons={actionButtons}
                onRowClick={(id) => onRowClick(id)}
                totalCount={totalCount}
                handleChangePage={(page, rowsPerPage) => this.handleChangePage(page, rowsPerPage)}
                handleRequestSort={(orderBy, orderDirection, rowsPerPage) =>
                    this.handleRequestSort(orderBy, orderDirection, rowsPerPage)
                }
                onSearchTextChange={(value, rowsPerPage) => this.onSearchTextChange(value, rowsPerPage)}
                onFilterUpdate={(selectedValues, rowsPerPage) => this.onFilterUpdate(selectedValues, rowsPerPage)}
                onFilterReset={(rowsPerPage) => this.onFilterReset(rowsPerPage)}
                onValidateClicked={
                    onValidateClicked !== null
                        ? (selected, page, rowsPerPage) =>
                              this.props.onValidateClicked(selected, () =>
                                  this.fetchData(page * rowsPerPage, rowsPerPage),
                              )
                        : null
                }
                searchDisabled={searchDisabled}
            />
        )
    }
}

const styles = (theme) => ({})

ManagementTableWrapper.propTypes = {
    title: PropTypes.string.isRequired,
    rows: PropTypes.object.isRequired,
    columns: PropTypes.object.isRequired,
    filterOptions: PropTypes.array,
    actionButtons: PropTypes.object,
    onRowClick: PropTypes.func,
    totalCount: PropTypes.number,
    fetchData: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    onValidateClicked: PropTypes.func,
}

ManagementTableWrapper.defaultProps = {
    filterOptions: [],
    onRowClick: null,
    totalCount: 0,
    fetchData: (offset, limit, orderBy, orderDirection, searchText) => {},
    onValidateClicked: null,
    searchDisabled: false,
}

export default compose(withWidth(), withStyles(styles))(ManagementTableWrapper)
