import grey from '@material-ui/core/colors/grey'

const styles = (theme) => ({
    actionButton: {
        //width: '200px',
        marginLeft: '8px',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginTop: 8,
            marginBottom: 8,
        },
    },
    rightIcon: {
        marginRight: theme.spacing.unit,
    },
    buttonProgress: {
        color: grey[500],
    },
})

export default styles
