import React from 'react'

// Components imports
import NotificationList from '../../../components/notification_list'
import AppointmentsList from '../../../components/appointment_list'
import LastUsersNoValidated from '../../../components/last_users'

// Design imports
import { Button, Divider, Grid, Paper, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import UsualActionsIcon from '@material-ui/icons/BookmarkBorder'
import NotificationIcon from '@material-ui/icons/Notifications'
import WarningIcon from '@material-ui/icons/Warning'

class HomePDI extends React.Component {
    goToNewAssembly = () => {
        this.props.history.push('/new-assembly')
    }

    goToNewPatient = () => {
        this.props.history.push('/new-patient')
    }

    goToNewIncidence = () => {
        this.props.history.push('/new-incidence')
    }

    goToNewUser = () => {
        this.props.history.push('/new-user')
    }

    goToNewAppointment = () => {
        this.props.history.push('/new-appointment')
    }

    render() {
        const { classes } = this.props

        return (
            <div className={classes.root}>
                <Grid container spacing={24}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <Paper>
                                    <div className={classes.cardHeader}>
                                        <div className={classes.cardTitleContainer}>
                                            <UsualActionsIcon color="primary" style={{ fontSize: 28 }} />
                                            <Typography className={classes.title} variant="h6" color="primary">
                                                Accions més habituals
                                            </Typography>
                                        </div>
                                    </div>
                                    <Divider />
                                    <Grid container spacing={16} className={classes.cardContainer} alignItems="stretch">
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Button
                                                fullWidth
                                                variant="outlined"
                                                color="primary"
                                                className={classes.quickActionButton}
                                                classes={{ label: classes.label }}
                                                onClick={() => this.goToNewAssembly()}>
                                                Nou muntatge
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Button
                                                fullWidth
                                                variant="outlined"
                                                color="primary"
                                                className={classes.quickActionButton}
                                                classes={{ label: classes.label }}
                                                onClick={() => this.goToNewPatient()}>
                                                Donar d'alta un nou pacient
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Button
                                                fullWidth
                                                variant="outlined"
                                                color="primary"
                                                className={classes.quickActionButton}
                                                classes={{ label: classes.label }}
                                                onClick={() => this.goToNewIncidence()}>
                                                Nova incidencia
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Button
                                                fullWidth
                                                variant="outlined"
                                                color="primary"
                                                className={classes.quickActionButton}
                                                classes={{ label: classes.label }}
                                                onClick={() => this.goToNewUser()}>
                                                Nou usuari
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Button
                                                fullWidth
                                                variant="outlined"
                                                color="primary"
                                                className={classes.quickActionButton}
                                                classes={{ label: classes.label }}
                                                onClick={() => this.goToNewAppointment()}>
                                                Introduir hora de visita
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <LastUsersNoValidated />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <Paper>
                                    <div className={classes.cardHeader}>
                                        <div className={classes.cardTitleContainer}>
                                            <NotificationIcon color="primary" style={{ fontSize: 28 }} />
                                            <Typography className={classes.title} variant="h6" color="primary">
                                                Últimes peticions d'hora
                                            </Typography>
                                        </div>
                                    </div>
                                    <Divider />
                                    <AppointmentsList />
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper>
                                    <div className={classes.cardHeader}>
                                        <div className={classes.cardTitleContainer}>
                                            <WarningIcon color="primary" style={{ fontSize: 28 }} />
                                            <Typography className={classes.title} variant="h6" color="primary">
                                                Últimes incidencies
                                            </Typography>
                                        </div>
                                    </div>
                                    <Divider />
                                    <NotificationList /> {/*notifications={notifications}*/}
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

const styles = (theme) => ({
    root: {
        display: 'flex',
    },
    paperRoot: {
        //flex: 0.5
        //padding: theme.spacing.unit * 3,
    },
    cardHeader: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        padding: theme.spacing.unit * 3,
        justifyContent: 'space-between',
        height: '80px',
    },
    cardTitleContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
    title: {
        paddingLeft: '8px',
    },
    phoneTitle: {
        paddingTop: '12px',
    },
    cardContainer: {
        padding: theme.spacing.unit * 3,
    },
    actions: {
        marginRight: -theme.spacing.unit * 2,
    },
    quickActionButton: {
        height: '100%',
    },
    label: {
        margin: '8px',
    },
})

export default withStyles(styles)(HomePDI)
