import React from 'react'
import PropTypes from 'prop-types'
import compose from '../../../utils/functions'

// Apollo client
import { withApollo } from '@apollo/client/react/hoc'
import { addPatient } from '../../../grapql/patient'
import { getEntitiesNames } from '../../../grapql/entity'

// Date managment
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from 'material-ui-pickers'
import moment from 'moment'

// Components imports
import FeedbackSnackbar from '../../../components/feedback_snackbar/index'
import NewPatient from '../../../components/molecule/NewPatient/NewPatient'

// Design imports
import { Button, Divider, Grid, Paper, Typography, withWidth } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import PersonAddIcon from '@material-ui/icons/PersonAdd'

class NewPatientPDI extends React.Component {
    constructor(props) {
        super(props)

        this.newPatientComponentRef = React.createRef()

        this.state = {
            confirmSnackbarOpened: false,
            centers: [],
        }
    }

    componentDidMount() {
        this.props.client
            .query(getEntitiesNames())
            .then((result) => this.setState({ centers: result.data.allEntities.entities }))
    }

    handleClose = () => {
        this.setState({ confirmSnackbarOpened: false })
    }

    handleConfirmButtonClick = () => {
        const newPatientComponent = this.newPatientComponentRef.current

        if (newPatientComponent.validateData()) {
            const patient = newPatientComponent.state.patient

            if (patient) {
                const mutation = addPatient(
                    patient.name,
                    patient.lastName,
                    patient.birthdate.valueOf().toString(),
                    patient.email,
                    patient.phone,
                    patient.center,
                    patient.idNumber,
                    patient.nationality,
                    patient.address,
                    patient.city,
                    patient.postalCode,
                )

                this.props.client.mutate(mutation).then((result) => {
                    this.setState({ confirmSnackbarOpened: true })
                    setTimeout(() => {
                        this.props.history.goBack()
                    }, 3000)
                })
            }
        }
    }

    handlePrefixSelection = () => (event) => {
        this.setState({ prefix: event.target.value })
    }

    handleCenterChange = () => (event) => {
        this.setState({ center: event.target.value })
    }

    render() {
        const { classes } = this.props

        return (
            <div className={classes.root}>
                <Grid container spacing={24} justify="center">
                    <Grid item xs={12} sm={12} md={8} lg={6}>
                        <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                            <Paper className={classes.paperRoot}>
                                <div className={classes.cardHeader}>
                                    <div className={classes.cardTitleContainer}>
                                        <PersonAddIcon color="primary" style={{ fontSize: 28 }} />
                                        <Typography className={classes.title} variant="h6" color="primary">
                                            Nou pacient
                                        </Typography>
                                    </div>
                                </div>
                                <Divider />
                                <NewPatient
                                    innerRef={this.newPatientComponentRef}
                                    centers={this.state.centers}
                                    isUpdating={false}
                                    isSocialWorker={false}
                                />
                                <Divider />
                                <div className={classes.cardFooter}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        fullWidth={this.props.width === 'xs'}
                                        onClick={() => this.handleConfirmButtonClick()}>
                                        Crear pacient
                                    </Button>
                                </div>
                            </Paper>
                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>
                <FeedbackSnackbar
                    showSnackbar={this.state.confirmSnackbarOpened}
                    handleClose={() => this.handleClose()}
                    timeShowed={3000}
                    text={"Pacient donat d'alta correctament"}
                />
            </div>
        )
    }
}

const styles = (theme) => ({
    root: {
        display: 'flex',
        flex: 1,
    },
    paperRoot: {
        //flex: 0.5
        //padding: theme.spacing.unit * 3,
    },
    cardHeader: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        padding: theme.spacing.unit * 3,
        justifyContent: 'space-between',
        height: '80px',
    },
    cardFooter: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        padding: theme.spacing.unit * 3,
        justifyContent: 'flex-end',
        height: '80px',
    },
    cardTitleContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
    title: {
        paddingLeft: '8px',
    },
    cardContainer: {
        padding: theme.spacing.unit * 3,
    },
    textField: {
        //marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    textFieldWidth: {
        marginRight: theme.spacing.unit,
        minWidth: '200px',
    },
    formControl: {
        marginTop: 16,
    },
})

NewPatientPDI.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default compose(withApollo, withWidth(), withStyles(styles))(NewPatientPDI)
