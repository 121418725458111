import React from 'react'
import PropTypes from 'prop-types'
import compose from '../../../utils/functions'

// Apollo client
import { withApollo } from '@apollo/client/react/hoc'
import { addUser } from '../../../grapql/user'
import { getEntitiesNames } from '../../../grapql/entity'
import { getSubjectsNames } from '../../../grapql/subject'

// Components imports
import FeedbackSnackbar from '../../../components/feedback_snackbar/index'

// Design imports
import {
    Button,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    OutlinedInput,
    Paper,
    Select,
    TextField,
    Typography,
    withWidth,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import ReactDOM from 'react-dom'
import { ROLE_API } from '../../../constants'

const roles = [
    {
        role: ROLE_API.ADMIN,
        name: 'Administrador',
    },
    {
        role: ROLE_API.TS,
        name: 'Treballador social',
    },
    {
        role: ROLE_API.PDI,
        name: 'PDI',
    },
    {
        role: ROLE_API.ST,
        name: 'Estudiant',
    },
    {
        role: ROLE_API.MST,
        name: 'Estudiant de gestió',
    },
]

class NewUser extends React.Component {
    state = {
        name: '',
        lastName: '',
        phone: '',
        prefix: 'ES',
        email: '',
        role: '',
        center: '',
        collegiateNumber: '',
        subject: '',
        group: '',
        confirmSnackbarOpened: false,
        prefixLabelWidth: 0,
        roleLabelWidth: 0,
        entityLabelWidth: 0,
        subjectLabelWidth: 0,
        groupLabelWidth: 0,
        nameError: false,
        lastNameError: false,
        phoneError: false,
        emailError: false,
        roleError: false,
        entityError: false,
        collegiateNumberError: false,
        subjectError: false,
    }

    componentDidMount() {
        this.props.client
            .query(getEntitiesNames())
            .then((result) => this.setState({ centers: result.data.allEntities.entities }))
        this.props.client
            .query(getSubjectsNames())
            .then((result) => this.setState({ subjects: result.data.allSubjects.subjects }))

        // Get the width for select patient selector label.
        this.setState({
            //prefixLabelWidth: ReactDOM.findDOMNode(this.PrefixLabelRef).offsetWidth,
            roleLabelWidth: ReactDOM.findDOMNode(this.RoleLabelRef).offsetWidth,
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.role != this.state.role) {
            if (this.state.role === ROLE_API.TS) {
                this.setState({
                    entityLabelWidth: ReactDOM.findDOMNode(this.EntityLabelRef).offsetWidth,
                })
            } else if (this.state.role === ROLE_API.ST || this.state.role === ROLE_API.MST) {
                this.setState({
                    subjectLabelWidth: ReactDOM.findDOMNode(this.SubjectLabelRef).offsetWidth,
                    //groupLabelWidth: ReactDOM.findDOMNode(this.GroupLabelRef).offsetWidth,
                })
            }
        }
    }

    validateData = () => {
        var dataIsValid = true

        var errors = {
            nameError: false,
            lastNameError: false,
            phoneError: false,
            emailError: false,
            roleError: false,
            entityError: false,
            collegiateNumberError: false,
            subjectError: false,
        }

        if (this.state.name === '') {
            errors.nameError = true
            dataIsValid = false
        }
        if (this.state.lastName === '') {
            errors.lastNameError = true
            dataIsValid = false
        }
        if (this.state.phone === '') {
            errors.phoneError = true
            dataIsValid = false
        }
        if (this.state.email === '') {
            errors.emailError = true
            dataIsValid = false
        }
        if (this.state.role === '') {
            errors.roleError = true
            dataIsValid = false
        }
        if (this.state.role === ROLE_API.TS && this.state.center === '') {
            errors.entityError = true
            dataIsValid = false
        }
        if (this.state.role === ROLE_API.TS && this.state.collegiateNumber === '') {
            errors.collegiateNumberError = true
            dataIsValid = false
        }
        if ((this.state.role === ROLE_API.ST || this.state.role === ROLE_API.MST) && this.state.subject === '') {
            errors.subjectError = true
            dataIsValid = false
        }

        this.setState(errors)

        return dataIsValid
    }

    handleChange = (name) => (event) => {
        this.setState({
            [name]: event.target.value,
        })
    }

    handleSubjectChange = (name) => (event) => {
        this.setState({
            subject: event.target.value,
            group: '',
        })
    }

    handleClose = () => {
        this.setState({ confirmSnackbarOpened: false })
    }

    handleConfirmButtonClick = () => {
        if (this.validateData()) {
            var entityOrSubjectId = null
            if (this.state.role === ROLE_API.TS) {
                entityOrSubjectId = this.state.center
            } else if (this.state.role === ROLE_API.ST || this.state.role === ROLE_API.MST) {
                entityOrSubjectId = this.state.subject
            }

            const mutation = addUser(
                this.state.name,
                this.state.lastName,
                this.state.email,
                this.state.phone,
                this.state.role,
                entityOrSubjectId,
                this.state.collegiateNumber,
            )

            this.props.client.mutate(mutation).then((result) => {
                this.setState({ confirmSnackbarOpened: true })
                setTimeout(() => {
                    this.props.history.goBack()
                }, 3000)
            })
        }
    }

    handlePrefixSelection = () => (event) => {
        this.setState({ prefix: event.target.value })
    }

    handleCenterChange = () => (event) => {
        this.setState({ center: event.target.value })
    }

    renderInputFromRole = () => {
        const { classes } = this.props
        const { role } = this.state

        if (role === null || role === '') {
            return null
        }

        var inputs = null
        if (role === ROLE_API.TS) {
            inputs = (
                <Grid className={classes.cardContainer} container spacing={24}>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth required variant="outlined" className={classes.formControl}>
                            <InputLabel
                                ref={(ref) => {
                                    this.EntityLabelRef = ref
                                }}
                                htmlFor="user-center">
                                Entitat
                            </InputLabel>
                            <Select
                                native
                                error={this.state.entityError}
                                value={this.state.center}
                                onChange={this.handleCenterChange()}
                                input={
                                    <OutlinedInput
                                        name="user-center"
                                        labelWidth={this.state.entityLabelWidth}
                                        id="user-center"
                                    />
                                }>
                                <option value="" />
                                {this.state.centers.map((item, index) => {
                                    return <option value={item.id}>{item.name}</option>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            fullWidth
                            error={this.state.collegiateNumberError}
                            id="collegiate-number"
                            label="Núm de col·legiat"
                            name="collegiate-number"
                            type="text"
                            className={classes.textField}
                            value={this.state.collegiateNumber}
                            onChange={this.handleChange('collegiateNumber')}
                            margin="normal"
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
            )
        } else if (role === ROLE_API.ST || role === ROLE_API.MST) {
            var subjectSelected = this.state.subjects.find((item) => item.subject === this.state.subject)
            var groupsForSubjectSelected = subjectSelected != undefined ? subjectSelected.groups : []

            inputs = (
                <Grid className={classes.cardContainer} container spacing={24}>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth required variant="outlined" className={classes.formControl}>
                            <InputLabel
                                ref={(ref) => {
                                    this.SubjectLabelRef = ref
                                }}
                                htmlFor="user-subject">
                                Assignatura
                            </InputLabel>
                            <Select
                                native
                                error={this.state.subjectError}
                                value={this.state.subject}
                                onChange={this.handleSubjectChange()}
                                input={
                                    <OutlinedInput
                                        name="user-subject"
                                        labelWidth={this.state.subjectLabelWidth}
                                        id="user-subject"
                                    />
                                }>
                                <option value="" />
                                {this.state.subjects.map((item, index) => {
                                    return <option value={item.id}>{item.name}</option>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    {/*<Grid item xs={12} sm={6}>
                        <FormControl fullWidth required variant="outlined" className={classes.formControl}>
                            <InputLabel ref={ref => { this.GroupLabelRef = ref}} htmlFor="user-group">
                                Grup
                            </InputLabel>
                            <Select
                                native
                                value={this.state.group}
                                onChange={this.handleChange('group')}
                                input={ <OutlinedInput name="user-group" labelWidth={this.state.groupLabelWidth} id="user-group"/> }>
                                <option value=""/>
                                {groupsForSubjectSelected.map((item, index) => {
                                    return (<option value={item}>{item}</option>);
                                })}
                            </Select>
                        </FormControl>
                    </Grid>*/}
                </Grid>
            )
        }

        return (
            <div>
                <Divider />
                {inputs}
            </div>
        )
    }

    render() {
        const { classes } = this.props

        return (
            <div className={classes.root}>
                <Grid container spacing={24} justify="center">
                    <Grid item xs={12} sm={12} md={8} lg={6}>
                        <Paper className={classes.paperRoot}>
                            <div className={classes.cardHeader}>
                                <div className={classes.cardTitleContainer}>
                                    <PersonAddIcon color="primary" style={{ fontSize: 28 }} />
                                    <Typography className={classes.title} variant="h6" color="primary">
                                        Nou usuari
                                    </Typography>
                                </div>
                            </div>
                            <Divider />
                            <Grid className={classes.cardContainer} container spacing={24}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        error={this.state.nameError}
                                        id="patient-name"
                                        label="Nom"
                                        name="name"
                                        type="text"
                                        className={classes.textField}
                                        value={this.state.name}
                                        onChange={this.handleChange('name')}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        error={this.state.lastNameError}
                                        id="patient-lastname"
                                        label="Cognoms"
                                        name="lastname"
                                        type="text"
                                        className={classes.textField}
                                        value={this.state.lastName}
                                        onChange={this.handleChange('lastName')}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <TextField
                                        fullWidth
                                        required
                                        error={this.state.phoneError}
                                        id="patient-phone"
                                        label="Telèfon"
                                        name="phone"
                                        type="tel"
                                        pattern="^[0-9-+s()]*$"
                                        className={classes.textField}
                                        value={this.state.phone}
                                        onChange={this.handleChange('phone')}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        required
                                        error={this.state.emailError}
                                        id="patient-email"
                                        label="Email"
                                        name="email"
                                        type="email"
                                        className={classes.textField}
                                        value={this.state.email}
                                        onChange={this.handleChange('email')}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <FormControl fullWidth required variant="outlined" className={classes.formControl}>
                                        <InputLabel
                                            ref={(ref) => {
                                                this.RoleLabelRef = ref
                                            }}
                                            htmlFor="user-role">
                                            Rol de l'usuari
                                        </InputLabel>
                                        <Select
                                            native
                                            error={this.state.roleError}
                                            value={this.state.role}
                                            onChange={this.handleChange('role')}
                                            input={
                                                <OutlinedInput
                                                    name="user-role"
                                                    labelWidth={this.state.roleLabelWidth}
                                                    id="user-role"
                                                />
                                            }>
                                            <option value="" />
                                            {roles.map((item, index) => {
                                                return <option value={item.role}>{item.name}</option>
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            {this.renderInputFromRole()}
                            <Divider />
                            <div className={classes.cardFooter}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    fullWidth={this.props.width === 'xs'}
                                    onClick={() => this.handleConfirmButtonClick()}>
                                    Crear usuari
                                </Button>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
                <FeedbackSnackbar
                    showSnackbar={this.state.confirmSnackbarOpened}
                    handleClose={() => this.handleClose()}
                    timeShowed={3000}
                    text={'Usuari creat correctament'}
                />
            </div>
        )
    }
}

const styles = (theme) => ({
    root: {
        display: 'flex',
        flex: 1,
    },
    paperRoot: {
        //flex: 0.5
        //padding: theme.spacing.unit * 3,
    },
    cardHeader: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        padding: theme.spacing.unit * 3,
        justifyContent: 'space-between',
        height: '80px',
    },
    cardFooter: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        padding: theme.spacing.unit * 3,
        justifyContent: 'flex-end',
        height: '80px',
    },
    cardTitleContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
    title: {
        paddingLeft: '8px',
    },
    cardContainer: {
        padding: theme.spacing.unit * 3,
    },
    textField: {
        //marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    textFieldWidth: {
        marginRight: theme.spacing.unit,
        minWidth: '200px',
    },
    formControl: {
        marginTop: 16,
    },
})

NewUser.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default compose(withApollo, withWidth(), withStyles(styles))(NewUser)
