export default (theme) => ({
    root: {
        display: 'flex',
    },
    paperRoot: {},
    cardHeader: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        padding: theme.spacing.unit * 3,
        justifyContent: 'space-between',
        maxHeight: '100px',
    },
    cardTitleContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
    cardContainer: {
        padding: theme.spacing.unit * 3,
    },
    chatContainer: {
        padding: theme.spacing.unit * 3,
        [theme.breakpoints.up('md')]: {
            maxHeight: '360px',
        },
        overflow: 'hidden',
        overflowY: 'scroll',
    },
    title: {
        paddingLeft: '8px',
    },
    labelTitle: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
        color: theme.palette.primary.light,
    },
    label: {
        fontWeight: 'normal',
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
    },
    sendButton: {
        height: '100%',
    },
    warningMessage: {
        textAlign: 'center',
    },
})
