import React from 'react'
import PropTypes from 'prop-types'

// Apollo client
// Date managment
// JSON Files
// Design imports
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'

class DialogConfirmAction extends React.Component {
    render() {
        const { open, title, description, cancelText, confirmText, onCancel, onConfirm, onClose } = this.props

        return (
            <Dialog open={open} onClose={onClose}>
                <DialogTitle>{title}</DialogTitle>
                {description && (
                    <DialogContent>
                        <DialogContentText>{description}</DialogContentText>
                    </DialogContent>
                )}
                <DialogActions>
                    <Button onClick={onCancel} color="secondary">
                        {cancelText}
                    </Button>
                    <Button onClick={onConfirm} color="primary" autoFocus>
                        {confirmText}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

DialogConfirmAction.propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    cancelText: PropTypes.string.isRequired,
    confirmText: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
}

export default DialogConfirmAction
