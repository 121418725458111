import React from 'react'
import PropTypes from 'prop-types'
import compose from '../../utils/functions'

import { withRouter } from 'react-router'

// Apollo client
import { withApollo } from '@apollo/client/react/hoc'
import { getUsers } from '../../grapql/user'

// Design imports
import { Button, Divider, List, ListItem, Paper, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import InboxIcon from '@material-ui/icons/AllInbox'
import ArrowForward from '@material-ui/icons/KeyboardArrowRight'

class LastUsersNoValidated extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            data: [],
        }
    }

    castRoles = (roleFromAPI) => {
        switch (roleFromAPI) {
            case 'ADMIN':
                return 'Administrador'
            case 'PDI':
                return 'PDI'
            case 'TS':
                return 'Treballador social'
            case 'ST':
                return 'Estudiant'
            case 'MST':
                return 'Estudiant de gestió'
            default:
                return ''
        }

        return ''
    }

    parseData = (usersFromAPI) => {
        var users = []

        usersFromAPI.forEach((user) => {
            users.push({
                id: user.id,
                name: user.name + ' ' + user.lastName,
                role: this.castRoles(user.role),
            })
        })

        this.setState({ data: users })
    }

    componentDidMount() {
        this.props.client
            .query(getUsers(0, 5, undefined, undefined, undefined, 'validated = false'))
            .then((result) => this.parseData(result.data.allUsers.users))
    }

    goToUsers = () => {
        this.props.history.push('/users')
    }

    goToUserDetail = (id) => {
        this.props.history.push('/user/' + id)
    }

    renderListItem = (item, index) => {
        const { classes } = this.props

        return (
            <ListItem divider button className={classes.listItem} onClick={() => this.goToUserDetail(item.id)}>
                <div className={classes.assemblyInfo}>
                    <Typography variant="h6" color="primary">
                        {item.name?.toUpperCase()}
                    </Typography>
                    <Typography variant="body1" color="textPrimary" className={classes.assemblyStatus}>
                        {item.role}
                    </Typography>
                </div>
                <ArrowForward color="primary" />
            </ListItem>
        )
    }

    render() {
        const { classes, notifications } = this.props

        return (
            <Paper>
                <div className={classes.cardHeader}>
                    <div className={classes.cardTitleContainer}>
                        <InboxIcon color="primary" style={{ fontSize: 28 }} />
                        <Typography className={classes.title} variant="h6" color="primary">
                            Últims usuaris no validats
                        </Typography>
                    </div>
                </div>
                <Divider />
                <List className={classes.list}>
                    {this.state.data.slice(0, 5).map((item, index) => this.renderListItem(item, index))}
                    <ListItem>
                        <Button fullWidth color="primary" onClick={() => this.goToUsers()}>
                            Veure tots
                        </Button>
                    </ListItem>
                </List>
            </Paper>
        )
    }
}

const styles = (theme) => ({
    list: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    listItem: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingRight: 16,
    },
    assemblyInfo: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    assemblyStatus: {
        marginLeft: theme.spacing.unit * 4,
        marginRight: theme.spacing.unit * 2,
    },
    paperRoot: {},
    cardHeader: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        padding: theme.spacing.unit * 3,
        justifyContent: 'space-between',
        height: '80px',
    },
    cardTitleContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
    title: {
        paddingLeft: '8px',
    },
    cardContainer: {
        padding: theme.spacing.unit * 3,
    },
})

LastUsersNoValidated.propTypes = {
    classes: PropTypes.object.isRequired,
    lastAssemblies: PropTypes.array.isRequired,
}

export default compose(withRouter, withApollo, withStyles(styles))(LastUsersNoValidated)
