const styles = (theme) => ({
    root: {
        display: 'flex',
        flex: 1,
    },
    cardContainer: {
        padding: theme.spacing.unit * 3,
    },
    textField: {
        marginRight: theme.spacing.unit,
    },
    textFieldWidth: {
        marginRight: theme.spacing.unit,
        minWidth: '200px',
    },
    formControl: {
        // marginTop: 16
    },
})

export default styles
