import React from 'react'
import PropTypes from 'prop-types'
import compose from '../../utils/functions'

// Apollo client
import { withApollo } from '@apollo/client/react/hoc'
import { getLoggedUser, updateUser, updateUserCredentials } from '../../grapql/user'
import { getSubjectsNames } from '../../grapql/subject'

// Components imports
import FeedbackSnackbar from '../../components/feedback_snackbar/index'

// Design imports
import {
    Button,
    Divider,
    FormControl,
    Grid,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    Paper,
    Select,
    TextField,
    Tooltip,
    Typography,
    withWidth,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import UserIcon from '@material-ui/icons/AccountCircle'
import EditIcon from '@material-ui/icons/Edit'
import CloseIcon from '@material-ui/icons/Close'
import PasswordIcon from '@material-ui/icons/Lock'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import { ROLE_API } from '../../constants'
import styles from './styles'

class UserProfile extends React.Component {
    state = {
        user: {},
        actualPassword: '',
        newPassword: '',
        confirmNewPassword: '',
        showActualPassword: false,
        showNewPassword: false,
        actualPasswordError: false,
        newPasswordError: false,
        confirmNewPasswordError: false,
        confirmSnackbarOpened: false,
        errorSnackbarOpened: false,
        showEditUser: false,
        subjects: [],
        name: '',
        lastName: '',
        email: '',
        phone: '',
        collegiateNumber: '',
        subject: '',
        nameError: false,
        lastNameError: false,
        phoneError: false,
        emailError: false,
        collegiateNumberError: false,
        subjectError: false,
    }

    parseData = (userFromAPI) => {
        this.setState({
            user: userFromAPI,
            name: userFromAPI.name?.toUpperCase(),
            lastName: userFromAPI.lastName?.toUpperCase(),
            email: userFromAPI.email?.toUpperCase(),
            phone: userFromAPI.phone,
            subject: userFromAPI.entityOrSubject !== null ? userFromAPI.entityOrSubject.id : null,
            collegiateNumber: userFromAPI.collegiateNumber,
        })
    }

    fetchUserInfo = () => {
        this.props.client.query(getLoggedUser()).then((result) => {
            this.parseData(result.data.loggedUser)
        })
    }

    componentDidMount() {
        this.fetchUserInfo()
        this.props.client
            .query(getSubjectsNames())
            .then((result) => this.setState({ subjects: result.data.allSubjects.subjects }))
    }

    validateData = () => {
        var dataIsValid = true

        var errors = {
            actualPasswordError: false,
            newPasswordError: false,
            confirmNewPasswordError: false,
        }

        if (this.state.actualPassword === '') {
            errors.actualPasswordError = true
            dataIsValid = false
        }
        if (this.state.newPassword === '') {
            errors.newPasswordError = true
            dataIsValid = false
        }
        if (this.state.confirmNewPassword === '' || this.state.newPassword !== this.state.confirmNewPassword) {
            errors.confirmNewPasswordError = true
            dataIsValid = false
        }

        this.setState(errors)

        return dataIsValid
    }

    validateUserData = () => {
        var dataIsValid = true

        var errors = {
            nameError: false,
            lastNameError: false,
            phoneError: false,
            emailError: false,
            collegiateNumberError: false,
            subjectError: false,
        }

        if (this.state.name === '') {
            errors.nameError = true
            dataIsValid = false
        }
        if (this.state.lastName === '') {
            errors.lastNameError = true
            dataIsValid = false
        }
        if (this.state.phone === '') {
            errors.phoneError = true
            dataIsValid = false
        }
        if (this.state.email === '') {
            errors.emailError = true
            dataIsValid = false
        }
        if (this.state.user.role === ROLE_API.TS && this.state.collegiateNumber === '') {
            errors.collegiateNumberError = true
            dataIsValid = false
        }
        if (
            (this.state.user.role === ROLE_API.ST || this.state.user.role === ROLE_API.MST) &&
            this.state.subject === ''
        ) {
            errors.subjectError = true
            dataIsValid = false
        }

        this.setState(errors)

        return dataIsValid
    }

    handleChange = (name) => (event) => {
        this.setState({
            [name]: event.target.value,
        })
    }

    handleClickShowPassword = (name) => {
        this.setState({
            [name]: !this.state[name],
        })
    }

    handleSubjectChange = (name) => (event) => {
        this.setState({
            subject: event.target.value,
        })
    }

    handleUpdatePassword = () => {
        if (this.validateData()) {
            const mutation = updateUserCredentials(this.state.actualPassword, this.state.newPassword)
            this.props.client
                .mutate(mutation)
                .then(() => {
                    this.setState({ confirmSnackbarOpened: true })
                })
                .catch((error) => {
                    this.setState({ errorSnackbarOpened: true })
                })
        }
    }

    handleUpdateUser = () => {
        const { name, lastName, email, phone, collegiateNumber, subject } = this.state

        if (this.validateUserData()) {
            this.props.client
                .mutate(
                    updateUser(
                        this.state.user.id,
                        name,
                        lastName,
                        email,
                        phone,
                        this.state.user.role,
                        subject,
                        collegiateNumber,
                    ),
                )
                .then((result) => this.fetchUserInfo())
            this.setState({ showEditUser: false })
        }
    }

    handleClose = () => {
        this.setState({ confirmSnackbarOpened: false, errorSnackbarOpened: false })
    }

    renderRoleDependentEditSection = () => {
        const { classes } = this.props
        const { user } = this.state

        var role = ''
        switch (user.role) {
            case ROLE_API.ADMIN:
                role = 'Administrador'
                break
            case ROLE_API.TS:
                role = 'Treballador social'
                break
            case ROLE_API.PDI:
                role = 'PDI'
                break
            case ROLE_API.ST:
                role = 'Estudiant'
                break
            case ROLE_API.MST:
                role = 'Estudiant de gestió'
                break
        }

        if (user.role === ROLE_API.TS) {
            return (
                <div>
                    <Divider />
                    <Grid className={classes.cardContainer} container spacing={16}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                error={this.state.collegiateNumberError}
                                id="user-collegiateNumber"
                                label="Núm. de col·legiat:"
                                name="collegiateNumber"
                                type="text"
                                className={classes.textField}
                                value={this.state.collegiateNumber !== null ? this.state.collegiateNumber : '-'}
                                onChange={this.handleChange('collegiateNumber')}
                                margin="normal"
                            />
                        </Grid>
                    </Grid>
                </div>
            )
        } else if (user.role === ROLE_API.ST || user.role === ROLE_API.MST) {
            return (
                <div>
                    <Divider />
                    <Grid className={classes.cardContainer} container spacing={16}>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth required className={classes.formControl}>
                                <InputLabel
                                    ref={(ref) => {
                                        this.SubjectLabelRef = ref
                                    }}
                                    htmlFor="user-subject">
                                    Assignatura
                                </InputLabel>
                                <Select
                                    native
                                    error={this.state.subjectError}
                                    value={this.state.subject}
                                    onChange={this.handleSubjectChange()}
                                    input={<Input name="user-subject" id="user-subject" />}>
                                    <option value="" />
                                    {this.state.subjects.map((item, index) => {
                                        return <option value={item.id}>{item.name}</option>
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </div>
            )
        }
    }

    renderEditUser = () => {
        const { classes } = this.props
        const { user } = this.state

        return (
            <div>
                <Divider />
                <Grid className={classes.cardContainer} container spacing={16}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            fullWidth
                            error={this.state.nameError}
                            id="user-name"
                            label="Nom"
                            name="name"
                            type="text"
                            className={classes.textField}
                            value={this.state.name}
                            onChange={this.handleChange('name')}
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            fullWidth
                            error={this.state.lastNameError}
                            id="user-lastname"
                            label="Cognom"
                            name="lastname"
                            type="text"
                            className={classes.textField}
                            value={this.state.lastName}
                            onChange={this.handleChange('lastName')}
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            fullWidth
                            error={this.state.emailError}
                            id="user-email"
                            label="Email"
                            name="email"
                            type="text"
                            className={classes.textField}
                            value={this.state.email}
                            onChange={this.handleChange('email')}
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            fullWidth
                            error={this.state.phoneError}
                            id="user-phone"
                            label="Telefon"
                            name="phone"
                            type="text"
                            className={classes.textField}
                            value={this.state.phone}
                            onChange={this.handleChange('phone')}
                            margin="normal"
                        />
                    </Grid>
                </Grid>
                <Divider />
                {this.renderRoleDependentEditSection()}
                <Divider />
                <div className={classes.cardFooter}>
                    <Button
                        variant="outlined"
                        color="secondary"
                        size="large"
                        className={classes.actionButton}
                        onClick={() => this.setState({ showEditUser: false })}>
                        Cancel·lar
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        className={classes.actionButton}
                        onClick={() => this.handleUpdateUser()}>
                        Desar els canvis
                    </Button>
                </div>
            </div>
        )
    }

    renderRolePart = () => {
        const { classes } = this.props
        const { user } = this.state

        var role = ''
        switch (user.role) {
            case ROLE_API.ADMIN:
                role = 'Administrador'
                break
            case ROLE_API.TS:
                role = 'Treballador social'
                break
            case ROLE_API.PDI:
                role = 'PDI'
                break
            case ROLE_API.ST:
                role = 'Estudiant'
                break
            case ROLE_API.MST:
                role = 'Estudiant de gestió'
                break
        }

        if (user.role === ROLE_API.TS) {
            return (
                <div>
                    <Divider />
                    <Grid className={classes.cardContainer} container spacing={16}>
                        <Grid item xs={12} sm={6}>
                            <Typography className={classes.labelTitle} variant="subtitle2">
                                Rol:
                            </Typography>
                            <Typography className={classes.label} variant="h6">
                                {role}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography className={classes.labelTitle} variant="subtitle2">
                                Núm. de col·legiat:
                            </Typography>
                            <Typography className={classes.label} variant="h6">
                                {user.collegiateNumber !== null ? user.collegiateNumber : '-'}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography className={classes.labelTitle} variant="subtitle2">
                                Entitat:
                            </Typography>
                            <Typography className={classes.label} variant="h6">
                                {user.entityOrSubject.name?.toUpperCase()}
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            )
        } else if (user.role === ROLE_API.ST || user.role === ROLE_API.MST) {
            return (
                <div>
                    <Divider />
                    <Grid className={classes.cardContainer} container spacing={16}>
                        <Grid item xs={12} sm={6}>
                            <Typography className={classes.labelTitle} variant="subtitle2">
                                Rol:
                            </Typography>
                            <Typography className={classes.label} variant="h6">
                                {role}
                            </Typography>
                        </Grid>
                        <Grid item xs={0} sm={6}></Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography className={classes.labelTitle} variant="subtitle2">
                                Assignatura:
                            </Typography>
                            <Typography className={classes.label} variant="h6">
                                {user.entityOrSubject.name?.toUpperCase()}
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            )
        } else if (user.role === ROLE_API.PDI || user.role === ROLE_API.ADMIN) {
            return (
                <div>
                    <Divider />
                    <Grid className={classes.cardContainer} container spacing={16}>
                        <Grid item xs={12} sm={6}>
                            <Typography className={classes.labelTitle} variant="subtitle2">
                                Rol:
                            </Typography>
                            <Typography className={classes.label} variant="h6">
                                {role}
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            )
        }
    }

    render() {
        const { classes } = this.props

        return (
            <div className={classes.root}>
                <Grid container spacing={24}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Paper className={classes.paperRoot}>
                            <div className={classes.cardHeader}>
                                <div className={classes.cardTitleContainer}>
                                    <UserIcon color="primary" style={{ fontSize: 28 }} />
                                    <Typography className={classes.title} variant="h6" color="primary">
                                        Informació de l'usuari
                                    </Typography>
                                </div>
                                <Tooltip title="Editar">
                                    {this.state.showEditUser ? (
                                        <IconButton
                                            aria-label="Editar"
                                            className={classes.actions}
                                            onClick={() => this.setState({ showEditUser: false })}>
                                            <CloseIcon />
                                        </IconButton>
                                    ) : (
                                        <IconButton
                                            aria-label="Editar"
                                            className={classes.actions}
                                            onClick={() => this.setState({ showEditUser: true })}>
                                            <EditIcon />
                                        </IconButton>
                                    )}
                                </Tooltip>
                            </div>
                            <Divider />
                            {this.state.showEditUser ? (
                                this.renderEditUser()
                            ) : (
                                <div>
                                    <Grid className={classes.cardContainer} container spacing={16}>
                                        <Grid item xs={12} sm={6}>
                                            <Typography className={classes.labelTitle} variant="subtitle2">
                                                Nom:
                                            </Typography>
                                            <Typography className={classes.label} variant="h6">
                                                {this.state.name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography className={classes.labelTitle} variant="subtitle2">
                                                Cognoms:
                                            </Typography>
                                            <Typography className={classes.label} variant="h6">
                                                {this.state.lastName}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography className={classes.labelTitle} variant="subtitle2">
                                                Email:
                                            </Typography>
                                            <Typography className={classes.label} variant="h6">
                                                {this.state.email}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography className={classes.labelTitle} variant="subtitle2">
                                                Telefon:
                                            </Typography>
                                            <Typography className={classes.label} variant="h6">
                                                {this.state.phone}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                    {this.renderRolePart()}
                                </div>
                            )}
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <Paper className={classes.paperRoot}>
                            <div className={classes.cardHeader}>
                                <div className={classes.cardTitleContainer}>
                                    <PasswordIcon color="primary" style={{ fontSize: 28 }} />
                                    <Typography className={classes.title} variant="h6" color="primary">
                                        Canviar contrasenya:
                                    </Typography>
                                </div>
                            </div>
                            <Divider />
                            <Grid className={classes.cardContainer} container spacing={16}>
                                <Grid item xs={12}>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <InputLabel htmlFor="password">Contrasenya actual</InputLabel>
                                        <Input
                                            error={this.state.actualPasswordError}
                                            id="actual-password"
                                            type={this.state.showActualPassword ? 'text' : 'password'}
                                            value={this.state.actualPassword}
                                            onChange={this.handleChange('actualPassword')}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="Toggle password visibility"
                                                        onClick={() =>
                                                            this.handleClickShowPassword('showActualPassword')
                                                        }>
                                                        {this.state.showActualPassword ? (
                                                            <VisibilityIcon />
                                                        ) : (
                                                            <VisibilityOffIcon />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <InputLabel htmlFor="password">Nova contrasenya</InputLabel>
                                        <Input
                                            error={this.state.newPasswordError}
                                            id="actual-password"
                                            type={this.state.showNewPassword ? 'text' : 'password'}
                                            value={this.state.newPassword}
                                            onChange={this.handleChange('newPassword')}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="Toggle password visibility"
                                                        onClick={() => this.handleClickShowPassword('showNewPassword')}>
                                                        {this.state.showNewPassword ? (
                                                            <VisibilityIcon />
                                                        ) : (
                                                            <VisibilityOffIcon />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <InputLabel htmlFor="password">Repeteix la nova contrasenya</InputLabel>
                                        <Input
                                            error={this.state.confirmNewPasswordError}
                                            id="actual-password"
                                            type={this.state.showNewPassword ? 'text' : 'password'}
                                            value={this.state.confirmNewPassword}
                                            onChange={this.handleChange('confirmNewPassword')}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Divider />
                            <div className={classes.cardFooter}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    fullWidth={this.props.width === 'xs'}
                                    onClick={() => this.handleUpdatePassword()}>
                                    Confirmar canvis
                                </Button>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
                <FeedbackSnackbar
                    showSnackbar={this.state.confirmSnackbarOpened}
                    handleClose={() => this.handleClose()}
                    timeShowed={3000}
                    text={'Contrasenya canviada correctament'}
                />
                <FeedbackSnackbar
                    showSnackbar={this.state.errorSnackbarOpened}
                    handleClose={() => this.handleClose()}
                    timeShowed={3000}
                    variant={'error'}
                    text={'La contrasenya actual no és correcta'}
                />
            </div>
        )
    }
}

UserProfile.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default compose(withWidth(), withApollo, withStyles(styles))(UserProfile)
